import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {decamelize} from 'humps';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {
  getTaskEditableFields,
  GroupMemberRole,
  IssueModel,
  TASK_DATE_PROPERTIES,
  TaskAssignees,
  TaskDateProperties,
  TaskDetailsModelDTO,
  TaskModelDTO,
} from 'shared/models/task';
import {GanttLinkTypeToTaskDependency, TaskDependencyType} from 'shared/models/TaskDependency';

import {updateTaskAssignees} from '../../store/tasks/actions';
import {CompanyWorker} from '../models/worker';

export enum TaskColorStatus {
  Scheduled = 'Scheduled',
  Done = 'Done',
  InProgress = 'InProgress',
  Blocked = 'Blocked',
  Failed = 'Failed',
}

export type GanttTaskColors = {
  [key in keyof typeof TaskColorStatus]: string;
};

/*
 * SSD/SED - Scheduled start/end date
 *  ASD/AED - Actual start/end date
 *  The next statuses always have certain color and do not depend on start/end dates:
 *  Approved, Done, Verified, Rework, Blocked, TBA, Assigned
 *  The inProgress status marked as past due if SED in the past(task in past)
 *  marked as it in progress if SED in the future
 *  marked as scheduled(default gray color) if SSD in the future
 */
export const mapGanttLinkTypeToTaskDepType = (gantt: GanttStatic, linkType: string): TaskDependencyType => {
  const reverseMap = Object.keys(gantt.config.links).reduce(
    (acc, key) => Object.assign(acc, {[gantt.config.links[key]]: key}),
    {},
  );
  return GanttLinkTypeToTaskDependency[reverseMap[linkType]];
};

function isDateField(field: string): field is TaskDateProperties {
  return TASK_DATE_PROPERTIES.includes(field) || TASK_DATE_PROPERTIES.map((key) => decamelize(key)).includes(field);
}

export function getTaskChangedFieldsOnly(
  original: Partial<TaskModelDTO> | Partial<GanttTask> | Partial<IssueModel>,
  updated: Partial<TaskModelDTO> | Partial<GanttTask> | Partial<IssueModel>,
  excluded: string[] = [],
): Partial<Omit<TaskModelDTO, 'lastChangedFields'>> {
  const diff = getTaskEditableFields().reduce((acc, field) => {
    if (!updated.hasOwnProperty(field)) {
      return acc;
    }
    if (isDateField(field)) {
      if (!updated[field] && !original?.[field]) return acc;
      if (!updated[field] && original?.[field]) {
        return Object.assign(acc, {[field]: updated[field]});
      }
      return !dayjs(updated[field]).isSame(original[field]) ? Object.assign(acc, {[field]: updated[field]}) : acc;
    }
    if (field === 'customFields' && updated[field].length) {
      return Object.assign(acc, {customFields: updated[field]});
    }
    return original[field] !== updated[field] ? Object.assign(acc, {[field]: updated[field]}) : acc;
  }, {} as Partial<TaskModelDTO>);

  if (!Object.keys(diff).length) {
    return diff;
  }

  const result = {...diff, id: original?.id || undefined, projectId: original?.projectId || undefined};
  if (excluded.length) {
    for (const field in result) {
      if (excluded.includes(field)) {
        delete result[field];
      }
    }
  }

  return result;
}

export function convertTaskLastChangedFieldsToArray(changedFields: ReturnType<typeof getTaskChangedFieldsOnly>): {
  field: string;
  newValue: unknown;
}[] {
  return Object.entries(changedFields).reduce((acc, [key, value]) => {
    acc.push({field: key, newValue: value});
    return acc;
  }, {} as {field: string; newValue: unknown}[]);
}

export const replaceOwnerWithAssignee = (members: TaskAssignees[] = []) => {
  return members.map((m) =>
    m.memberRole === GroupMemberRole.owner ? {...m, memberRole: GroupMemberRole.assignee} : m,
  );
};

export function isChangedTaskAssigneesList(original: TaskAssignees[], target: TaskAssignees[]) {
  return (
    original?.length !== target?.length ||
    target.some((member) => {
      const existed = original.find(({memberId}) => memberId === member.memberId);
      if (!existed) {
        return true;
      }
      return member.memberRole !== existed.memberRole;
    })
  );
}

export const getPreparedMember = (cworker: CompanyWorker, role: GroupMemberRole) => ({
  memberId: cworker.workerId,
  memberRole: role,
});

export async function switchTaskResponsible(
  gantt: GanttStatic,
  task: GanttTask,
  newResponsible: CompanyWorker,
): Promise<TaskDetailsModelDTO> {
  const members = [getPreparedMember(newResponsible, GroupMemberRole.responsible)];
  if (task.responsible?.length) {
    // Convert current responsible to assignee
    for (const member of task.responsible) {
      members.push({memberId: member.member_id, memberRole: GroupMemberRole.assignee});
    }
  }
  return await updateTaskAssignees(task.id, {members});
}
