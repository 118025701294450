import {Container} from 'inversify';

import {TaskUpdateFifoQueue} from 'services/TaskUpdateFifoQueue';
import {IOC_TYPES} from 'shared/models/ioc';
import {GanttStore, GanttStoreType} from 'shared/stores/GanttStore';
import {TasksStore, TasksStoreType} from 'shared/stores/TasksStore';
import {UIStoreType, UIStore} from 'shared/stores/UIStore';

export class IocContainer {
  container: Container;

  constructor() {
    this.container = new Container({
      autoBindInjectable: true,
      defaultScope: 'Transient',
    });
  }

  init = () => {
    this.container.bind<TasksStoreType>(IOC_TYPES.TasksStore).to(TasksStore).inSingletonScope();
    this.container.bind<GanttStoreType>(IOC_TYPES.GanttStore).to(GanttStore).inSingletonScope();
    this.container.bind<TaskUpdateFifoQueue>(IOC_TYPES.TaskUpdateFifoQueue).to(TaskUpdateFifoQueue).inSingletonScope();
    this.container.bind<UIStoreType>(IOC_TYPES.UIStore).to(UIStore).inSingletonScope();

    return this.container;
  };
}

export const container = new IocContainer().init();
