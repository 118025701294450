import env from 'shared/constants/env';
import {useMount} from 'shared/hooks/core';

export function useFacebookTracking() {
  useMount(() => {
    if (env.social.facebookDomainVerification) {
      const meta = document.createElement('meta');
      meta.name = 'facebook-domain-verification';
      meta.content = env.social.facebookDomainVerification;
      document.head.append(meta);

      return () => {
        const selectedMeta = document.querySelector("[name='facebook-domain-verification']");
        if (selectedMeta) selectedMeta.remove();
      };
    }
  });
}
