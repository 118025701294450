import classnames from 'classnames';
import {FC, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import {Icon, Tooltip} from 'shared/components';
import {useAppIsReady} from 'shared/hooks/useAppIsReady';

import {NavItem, SubItems} from '../NavMenu';

function NavMenuSubItem({data: {isActive, onClick, onClickEdit, title}, children}: {data: SubItems; children: string}) {
  const ref = useRef<HTMLLIElement>();
  useEffect(() => {
    if (isActive) {
      requestAnimationFrame(() => ref.current?.scrollIntoView({block: 'center'}));
    }
  }, [isActive]);

  return (
    <li className="nav__sublist-item" ref={ref}>
      <button className={classnames('nav__subitem', {'is-active': isActive})} onClick={onClick} type="button">
        <Icon name="folder" className="nav__subitem-icon" colorFill size={24} />
        <span className="nav__subitem-text nav__item-text">{title}</span>
      </button>
      {!!onClickEdit && (
        <button onClick={onClickEdit} className="nav__button-tasks" type="button">
          {children}
        </button>
      )}
    </li>
  );
}

type Props = {
  item: NavItem;
};

const NavMenuItem: FC<Props> = ({item}: Props) => {
  const {title, icon, isActive, onClick, subItems, tooltip, dataCy} = item;
  const isAppReady = useAppIsReady();
  const {t} = useTranslation('left_menu');
  const isActiveAnyProjects = subItems?.some((sub) => sub.isActive);
  const navSublistActive = {
    maxHeight: 216,
    paddingTop: 16,
    paddingBottom: 16,
  };

  return (
    <li className="nav__list-item">
      {isAppReady ? (
        <div style={{position: 'relative'}}>
          <Tooltip isHide={!tooltip} title="Report" text={tooltip} placement="right">
            <button
              data-cy={dataCy}
              className={classnames('nav__item', {'is-active': isActive && !isActiveAnyProjects})}
              type="button"
              onClick={onClick}
            >
              <Icon name={icon} className="nav__item-icon" colorFill size={24} />
              <span className="nav__item-text">{title}</span>
            </button>
          </Tooltip>
          {!!subItems?.length && (
            <ul className="nav__sublist" style={navSublistActive}>
              {subItems.map((subItem, index) => {
                return (
                  <NavMenuSubItem key={index} data={subItem}>
                    {t('tabs.buttons.edit', 'Edit')}
                  </NavMenuSubItem>
                );
              })}
            </ul>
          )}
        </div>
      ) : (
        <button className="nav__item" style={{display: 'block'}}>
          <Skeleton height={28} width="100%" />
        </button>
      )}
    </li>
  );
};

export default NavMenuItem;
