import {forwardRef, ForwardRefExoticComponent, LegacyRef, PropsWithoutRef, RefAttributes, useMemo} from 'react';
import Select, {components, GroupTypeBase, OptionTypeBase, Props} from 'react-select';

import CreatableSelect from './CreatableSelect';
import DropdownIndicator from './DropdownIndicator';
import {defaultReactSelectStyles} from './styles';
import {OptionType, SelectProps} from './types';

export const MenuPortal = (props: any) => {
  return <components.MenuPortal {...props} className={props.selectProps.className} />;
};

const Common = <
  OptionT extends OptionTypeBase = OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionT> = GroupTypeBase<OptionT>,
>(
  {
    value,
    onChange,
    options,
    components,
    className,
    size,
    isSearchable = false,
    isClearable = false,
    isMulti,
    ...selectProps
  }: SelectProps & Props<OptionT, IsMulti, GroupType>,
  ref: LegacyRef<Select<OptionT, IsMulti, GroupType>>,
) => {
  const selected = useMemo((): OptionT | OptionT[] => {
    if (isMulti) {
      const selectedValues = [].concat(value);
      return options.filter((o) =>
        selectedValues?.some((item) => (typeof item === 'string' ? o.value === item : o.value === item.value)),
      ) as OptionT[];
    }
    const selectedOptions = options?.find((option) => option.value === value) as OptionT;
    return selectedOptions ? [selectedOptions] : null;
  }, [isMulti, value, options]);

  const getValue = (): OptionT[] | OptionT => {
    if (isMulti) {
      return selected;
    }
    if (typeof value === 'string' || typeof value === 'boolean') {
      return [].concat(selected);
    }
    return null;
  };

  return (
    <Select
      isMulti={isMulti}
      className={`${className ? className : ''} ${size ? `react-select--size-xs` : ''}`}
      classNamePrefix="react-select"
      options={options}
      ref={ref}
      value={getValue()}
      isSearchable={isSearchable}
      isClearable={isClearable}
      onChange={(option, actionMeta) => {
        const values = [].concat(option).map((op) => op?.value);
        onChange(isMulti ? values : values[0], actionMeta);
      }}
      components={{
        DropdownIndicator,
        MenuPortal,
        ...components,
      }}
      // TODO: fix any
      styles={defaultReactSelectStyles}
      inputId={selectProps.name}
      {...selectProps}
    />
  );
};

type ForwardRefProps<
  OptionT extends OptionTypeBase = OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionT> = GroupTypeBase<OptionT>,
> = Props<OptionT, IsMulti, GroupType>;

type CompoundSelectComponent = ForwardRefExoticComponent<
  PropsWithoutRef<SelectProps & ForwardRefProps> & RefAttributes<Select>
> & {
  Creatable: typeof CreatableSelect;
};

const CoreSelectField = forwardRef<Select, SelectProps & ForwardRefProps>(Common) as CompoundSelectComponent;

CoreSelectField.Creatable = CreatableSelect;

export default CoreSelectField;
