import {useMemo} from 'react';

import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useQueryCache} from 'shared/hooks';

import {getSimilarNames} from '../utils/getSimilarNames';

export const useGetCashedSubcontractors = (projectId: string) => {
  const {cacheHelper} = useQueryCache();
  return cacheHelper.findActivePagedQuery(QUERY_CACHE_KEYS.projectSubcontractorsWithPaginate(projectId))?.state?.data;
};

export const useSimilarSubcontractors = (inputName: string, projectId: string) => {
  const subcontractors = useGetCashedSubcontractors(projectId);

  const subcontractorNames = useMemo(
    () => getSimilarNames(inputName, subcontractors),
    [inputName, subcontractors?.pages],
  );

  return {subcontractorNames};
};
