import cn from 'classnames';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {SkeletonTheme} from 'react-loading-skeleton';

import {Button, Icon} from 'shared/components';
import {CtrlBooking} from 'shared/components/CoreNewUI';
import {InternalLngSwitcher} from 'shared/components/LanguageSwitcher';
import Logo from 'shared/components/Logo';
import {useAnalyticsService} from 'shared/hooks';

import NavMenu from '../NavMenu';
import UserMenu from '../UserMenu';
import WidgetMenu from '../WidgetMenu';
import WorkspaceSwitcher from '../WorkspaceSwitcher';

import s from './Header.module.scss';
import {useHeaderControls} from './useHeaderControls';

const Header: FunctionComponent = () => {
  const {state, actions, toggleMenu, openHelper, closeHelper} = useHeaderControls();
  const {isMenuOpen, isSwitcherOpen, isSidebarOpen, isSidebarOpenHover, isHelperOpen, isWorkspaceMenuOpen} = state;
  const {setIsMenuOpen, setIsSidebarOpen, setIsSwitcherOpen, setIsSidebarOpenHover} = actions;
  const {t} = useTranslation('left_menu');
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.common;

  return (
    <header
      onMouseEnter={() => setIsSidebarOpenHover(true)}
      onMouseLeave={() => setIsSidebarOpenHover(false)}
      className={cn(s.header, 'header screen__header', {'is-hover': isSidebarOpenHover}, {'is-open': isSidebarOpen})}
    >
      <div className="header__box">
        <div className="header__container">
          <SkeletonTheme baseColor="#2b2727" highlightColor="#444">
            <div className="header__panel">
              <Logo.Crews className="header__logo" />
              <WorkspaceSwitcher menuOpened={isWorkspaceMenuOpen} onOpenMenu={actions.setWorkspaceMenuOpen} />
            </div>
            <NavMenu />
            <WidgetMenu isOpen={isHelperOpen} open={openHelper} close={closeHelper} />
            <CtrlBooking
              className={s.header__booking}
              isCollapse={!isSidebarOpen && !isSidebarOpenHover}
              icon="support"
              url="https://meetings.hubspot.com/gentry-mehringer/crews-by-core-sales"
              text={t('buttons.booking', 'Book a Demo')}
              target="_black"
              onClick={() => mixpanel.track(mixpanelEvents.buttons.bookDemo)}
            />
            <div className="header__actions">
              <UserMenu isOpen={isMenuOpen} onToggle={() => toggleMenu(setIsMenuOpen)} />
              <InternalLngSwitcher isOpen={isSwitcherOpen} onToggle={() => toggleMenu(setIsSwitcherOpen)} />
            </div>
          </SkeletonTheme>
        </div>
        <Button
          iconOnly
          className="ctrl-btn-clear header__button-collapse"
          icon={<Icon className="ctrl-btn-clear__icon" name="chevron-right" colorFill size={24} />}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <span className="ctrl-btn-clear__text">Button text</span>
        </Button>
      </div>
    </header>
  );
};
export default Header;
