import cn from 'classnames';
import {useFormikContext} from 'formik';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {generatePath, useHistory, useParams} from 'react-router';

import {Button, Icon, Loader, Popup} from 'shared/components';
import ProgressBar from 'shared/components/ProgressBar';
import {TasksExportButton} from 'shared/components/TasksExport';
import TasksImport from 'shared/components/TasksImport';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {prepareQueryString} from 'shared/helpers/queryParams';
import {useAnalyticsService, useCompany, useProjectSelector} from 'shared/hooks';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {ProjectModelBase} from 'shared/models/project';
import {TaskFilterQuery} from 'shared/models/task';

import s from './ProjectPreview.module.scss';
import {useCloneProject} from './useCloneProject';
import {useCloneProjectStatus} from './useCloneProjectStatus';

const ProjectPreview = () => {
  const {values} = useFormikContext<Partial<ProjectModelBase>>();
  const routes = useLocalizedRoutes();
  const {id} = useParams<{id}>();
  const {taskCount, name: projectName} = useProjectSelector(values?.id) || {};
  const company = useCompany();
  // Jira: CNA-2721
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const [showImport, setShowImport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [importCounter, setCounter] = useState(0);
  const history = useHistory();
  const {
    mixpanel: {
      events: {project: mixpanelEvents},
      ...mixpanel
    },
  } = useAnalyticsService();
  const isNewProject = id === 'new';
  const mixpanelMeta = useMemo(() => Object.assign({}, {'Project Name': projectName}), [projectName]);
  const {t} = useTranslation(['common', 'project']);
  const {hasAnyAdminRole} = useCompanyWorkerRoles(id);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const onLinkClick = ({
    to,
    query,
    state,
  }: {
    to: string;
    query?: Partial<TaskFilterQuery>;
    state?: {createNewTask: boolean};
  }) => {
    history.push({
      pathname: to,
      search: query ? prepareQueryString({values: query}) : '',
      state,
    });
  };

  const location = useMemo(() => {
    return !!values?.country && [values?.country, values?.state, values?.city].join(', ');
  }, [values?.country, values?.state, values?.city]);

  const noun = useMemo(() => {
    if (isNewProject) return '0' + ' ' + t('common:activity', {count: 0});
    if (!isNewProject && typeof taskCount === 'undefined') {
      return (
        <div>
          <Skeleton width={10} /> {t('common:activity', {count: 10})}
        </div>
      );
    } else return taskCount + ' ' + t('common:activity', {count: taskCount});
  }, [taskCount]);

  const goToTasks = () => {
    if (!isNewProject) {
      history.push(generatePath(routes.tasks, {projectId: id}));
    }
  };

  // FIXME: temp solution.
  const fontSizeStyle = {fontSize: '9px'};
  const {mutate: cloneProject, data: clonedProjectData} = useCloneProject({
    id,
    projectName,
  });

  const {progress, cloneFinished, cloneFailure, cloneInProgress, setStatus, setProgress, setNotified} =
    useCloneProjectStatus({
      projectId: id,
      asyncUploadId: clonedProjectData?.id,
    });

  useEffect(() => {
    if (cloneInProgress || cloneFailure) {
      setIsPopupVisible(true);
    }

    if (cloneFinished) {
      // 🤮 setTimeout need to keep the modal to display progress consistently
      const timeoutId = setTimeout(() => {
        setStatus('idle');
        setProgress(0); // Reset progress to avoid issues with multiple uploads
        setNotified(false);
        setIsPopupVisible(false);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [t, cloneFinished, cloneInProgress, cloneFailure, setNotified, setStatus, setProgress]);

  const handleClone = () => {
    setStatus('running');
    cloneProject();
  };

  const handleCancel = () => {
    setIsPopupVisible(false);
    setStatus('idle');
  };

  return (
    <>
      <div className="project-preview slide-panel__card loader-container">
        {isLoading && <Loader />}
        <div className="project-preview__container">
          <div className="project-preview__actions">
            <div className="project-preview__tasks" style={{cursor: 'pointer'}} onClick={goToTasks}>
              <span className="project-preview__tasks-text">{noun}</span>
              <Icon name={'chevron-right'} className="project-preview__tasks-icon" colorFill size={24} />
            </div>
            <div className="project-preview__time-zone">
              {values?.shortname ? values?.shortname : !isNewProject && <Skeleton width={30} />}
            </div>
          </div>
          <h2 className="project-preview__title">
            <Icon name={'folder_labeled'} className="project-preview__title-icon" colorFill size={24} />
            <span className="project-preview__title-text">
              {values?.name ? values?.name : !isNewProject && <Skeleton width={170} height={24} />}
            </span>
          </h2>
          <div className="project-preview__location">{location}</div>
        </div>
        {!isNewProject && hasAnyAdminRole && (
          <div className="project-preview__container">
            <div className="project-menu">
              <div className="project-menu__grid">
                <button
                  className={cn('project-menu__item', s.projectPreviewCard__button)}
                  type="button"
                  onClick={() => mixpanel.trackWithAction(() => setShowImport(true), mixpanelEvents.import.button)}
                >
                  <Icon name={'upload_on_cloud'} className="project-menu__icon" colorFill size={24} />
                  <span className="project-menu__text" style={fontSizeStyle}>
                    {t('project:preview.buttons.import', 'Import')}
                  </span>
                </button>
                <TasksExportButton
                  company={company}
                  queryParams={{projectId: values?.id}}
                  setIsExporting={setIsLoading}
                  mixpanelEvents={mixpanelEvents.export}
                  buttonStyle={{position: 'relative', margin: 0, minWidth: 0}}
                  dropPanelStyles={{margin: '15px 0 0 0', transform: 'translateX(-38%)'}}
                  button={
                    <button className={cn('project-menu__item', s.projectPreviewCard__button)} type="button">
                      <Icon name={'download_outlined'} className="project-menu__icon" colorFill size={24} />
                      <span className="project-menu__text" style={fontSizeStyle}>
                        {t('project:preview.buttons.export', 'Export')}
                      </span>
                    </button>
                  }
                />
                <button
                  className={cn('project-menu__item', s.projectPreviewCard__button)}
                  type="button"
                  onClick={() => {
                    mixpanel.track(mixpanelEvents.goToTaskList, mixpanelMeta);
                    onLinkClick({to: generatePath(routes.tasks, {projectId: values?.id})});
                  }}
                >
                  <Icon name={'survey'} className="project-menu__icon" colorFill size={24} />
                  <span className="project-menu__text" style={fontSizeStyle}>
                    {t('project:preview.buttons.go_activities', 'Go to Activities')}
                  </span>
                </button>
                <button
                  className={cn('project-menu__item', s.projectPreviewCard__button)}
                  type="button"
                  onClick={handleClone}
                >
                  <Icon name="copy" className="project-menu__icon" colorFill size={24} />
                  <span className="project-menu__text" style={fontSizeStyle}>
                    {t('project:preview.buttons.copy_project')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
        <TasksImport
          key={importCounter}
          visible={showImport}
          onFinish={() =>
            mixpanel.trackWithAction(() => {
              setCounter((counter) => counter + 1);
              setShowImport(false);
            }, mixpanelEvents.import.exitBtn)
          }
          onClose={() => mixpanel.trackWithAction(() => setShowImport(false), mixpanelEvents.import.exitBtn)}
          defaultProjectId={values?.id}
        />
      </div>

      <Popup
        className={s['projectPreviewCard__popup']}
        visible={isPopupVisible}
        // on close is required for other reasons (keyboard events) use void 0 as a workaround
        onClose={() => void 0}
        showCloseIcon={false}
      >
        <Popup.Header>
          {!cloneFailure ? t('project:clone.cloning_project') : t('project:clone.cloning_error')}
        </Popup.Header>
        <Popup.Body>
          <div className={s['projectPreviewCard__progress-bar']}>
            <ProgressBar showProgressComplete progress={progress} />
          </div>
        </Popup.Body>
        {cloneFailure ? (
          <Popup.Footer>
            <div>
              <Button onClick={handleCancel} buttonSize="m" style={{marginRight: 10}} colorStyle="danger">
                {t('project:clone.cancel')}
              </Button>
              <Button buttonSize="m" onClick={handleClone}>
                {t('project:clone.retry')}
              </Button>
            </div>
          </Popup.Footer>
        ) : null}
      </Popup>
    </>
  );
};

export default ProjectPreview;
