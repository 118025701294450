import {GanttStatic} from 'dhtmlx-gantt';

import {GanttPluginBase} from 'modules/Tasks/components/Gantt/plugins/ganttPlugin';
import {GanttEventNameUnion} from 'modules/Tasks/components/Gantt/utils/gantt';

import {EventStore} from '../utils/eventStore';

/* TODO: this class was created to help to implement CNA-1919 task, currently we try more simple version,
    delete it if no need after CNA-1919 will be released
*/
export class CopyPastePlugin extends GanttPluginBase {
  private events: EventStore<GanttEventNameUnion>;
  constructor(gantt: GanttStatic) {
    super(gantt);
    this.events = new EventStore(this.gantt);
  }

  init() {
    this.gantt.copyPlugin = new CopyPlugin(this.gantt);
  }

  clear() {
    delete this.gantt.copyPlugin;
  }
}

export class CopyPlugin {
  private eventName = 'onCopy';
  private selected = new Set<string>();
  constructor(public gantt: GanttStatic) {}

  isSelected(id: string) {
    return this.selected.has(id);
  }

  isActive() {
    return !!this.selected.size;
  }

  notify() {
    this.gantt.callEvent(this.eventName, [this.selected]);
  }
  copy(ids?: string[]): Array<string> {
    this.selected.clear();
    if (ids) {
      ids.forEach((task) => {
        if (this.gantt.isTaskExists(task)) {
          this.selected.add(task);
          if (this.gantt.isSelectedTask(task)) {
            this.gantt.toggleTaskSelection(task);
          }
        }
      });
    } else {
      this.gantt.eachSelectedTask((task) => {
        this.selected.add(task);
        this.gantt.toggleTaskSelection(task);
      });
    }
    this.notify();
    return this.getCopiedIds();
  }
  cancel() {
    this.selected.clear();
    this.notify();
  }
  getCopiedIds(): Array<string> {
    return Array.from(this.selected);
  }
}

declare module 'dhtmlx-gantt' {
  export interface GanttCustomProperties {
    copyPlugin?: CopyPlugin;
  }
}
