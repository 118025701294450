import React, {createRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ExtractRouteParams, useParams} from 'react-router';
import Select from 'react-select';

import TaskAsyncSelect, {TaskOptionType} from 'modules/Tasks/components/SidebarPanel/components/TaskAsyncSelect';
import {Icon} from 'shared/components';
import {CtrlBtnOption, CtrlButton} from 'shared/components/CoreNewUI';
import CtrlDrop from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import {ROUTES} from 'shared/constants/routes';
import {useCompany} from 'shared/hooks';

import {NEW_ACTIVITY_ID} from '../../../constants';
import {SelectTaskOption} from '../ActivitiesList';

import s from './ActivityListItem.module.scss';

type ActivityListItemProps = {
  issueId: string;
  activity: SelectTaskOption;
  tasksIds: string[];
  onDelete: (activityId: string) => void;
  onUpdate: (newActivity: SelectTaskOption, oldActivity: SelectTaskOption, isNew: boolean) => void;
  onBlur: (id: string) => void;
  disabled: boolean;
};

const ActivityListItem = ({disabled, activity, tasksIds, onUpdate, onDelete, onBlur}: ActivityListItemProps) => {
  const [value, setValue] = useState<SelectTaskOption>(activity);

  const excludeActivities = tasksIds;
  const disabledDelete = tasksIds.length === 1 || disabled;
  const taskRef = createRef<Select<TaskOptionType, false>>();
  const {projectId} = useParams<ExtractRouteParams<(typeof ROUTES)['task'], string>>();
  const company = useCompany();
  const {t} = useTranslation(['task', 'common']);
  const isNew = activity.value === NEW_ACTIVITY_ID;

  useEffect(() => {
    if (isNew) {
      taskRef.current?.focus();
    }
  }, [activity, isNew]);

  const onChange = async (newValue: SelectTaskOption) => {
    await onUpdate(newValue, activity, isNew);
    setValue(newValue);
  };

  return (
    <div className={s.activityListItem}>
      <TaskAsyncSelect
        ref={taskRef}
        className={s.activityListItem__name}
        name="name"
        placeholder={t('task:activities_list.predecessor.placeholder', 'Type activity name')}
        projectId={projectId}
        companyId={company.id}
        onChange={(value) => onChange(value)}
        value={value}
        onBlur={() => onBlur(activity.value)}
        exclude={excludeActivities}
        disabled={disabled}
      />
      <div className={s.activityListItem__close}>
        <CtrlDrop
          menuWidth={120}
          viewportPosition="right"
          toggleElement={
            <CtrlButton color="tertiary" icon="more_vertical" iconOnly size="s">
              {t('task:activities_list.menu.title', 'Settings')}
            </CtrlButton>
          }
        >
          <CtrlBtnOption
            size="s"
            onClick={() => onDelete(activity.value)}
            className={s.activityListItem__delete}
            disabled={disabledDelete}
            title={t('task:activities_list.menu.delete', 'Delete')}
            icon={<Icon name="remove_from_trash" colorFill size={24} />}
          />
        </CtrlDrop>
      </div>
    </div>
  );
};

export default ActivityListItem;
