import * as Sentry from '@sentry/browser';
import {type GanttStatic} from 'dhtmlx-gantt';
import equal from 'fast-deep-equal';
import {inject, injectable} from 'inversify';
import {IArrayDidChange, observe, when} from 'mobx';

import {transformLookaheadData} from 'modules/Tasks/components/Gantt/utils/functions';
import {OSKMap} from 'modules/Tasks/components/Gantt/utils/gantt';
import {refreshTask, updateCollapseIcon} from 'modules/Tasks/utils/functions';
import {GanttNames} from 'shared/constants/gantt';
import Gantt from 'shared/models/Gantt';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskModelRawDTO} from 'shared/models/task';
import {TaskDependency} from 'shared/models/TaskDependency';
import {mapDependencyToGanttLink} from 'shared/utils/mapDependencyToGanttLink';

import {type TasksStoreType} from './TasksStore';
import {tasksToGanttTasks} from './utils/transforms';

const ObserverChangeTypes = {
  splice: 'splice',
  update: 'update',
  add: 'add',
  remove: 'remove',
} as const;

export type GanttStoreType = {
  disposers: Array<() => void>;
  filterIds: Set<string | number>;
  ganttInstance: GanttStatic;
  idRownumMap: Record<string, number>;
  rownumIDMap: Record<number, string>;
  setCollapseAccessor: (accessor: () => string[]) => void;

  destroySideEffects: () => void;
  initSideEffects: () => void;
  setFilterIds: (ids: Set<string> | string[]) => void;
  setGanttInstance: (viewMode: string) => void;
  changeTaskId: (oldTaskId: number, newTaskId: string) => void;
};

// refreshTask takes about 2ms and redrawing the whole table takes about 50ms.
// If you're going to redraw more than 25 rows, do it as a batch for efficiency.
const REPAINT_FULL_TABLE_THRESHOLD = 25;

@injectable()
export class GanttStore implements GanttStoreType {
  @inject(IOC_TYPES.TasksStore) private tasksStore: TasksStoreType;

  ganttInstance: GanttStatic | null = null;
  idRownumMap: Record<string, number> = {};
  rownumIDMap: Record<string, string> = {};
  disposers: (() => void)[] = [];
  filterIds: Set<string | number> = new Set();
  collapseAccessor: () => string[];

  constructor() {
    // do nothing
  }

  initSideEffects() {
    try {
      when(
        () => !this.tasksStore.isLoading && this.tasksStore.tasksLoaded,
        () => {
          this.parseTasks();
        },
      );

      this.disposers.push(observe(this.tasksStore.tasks, (change) => this.taskListChanged(change)));
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  setCollapseAccessor(accessor: () => string[]) {
    this.collapseAccessor = accessor;
  }

  setFilterIds(ids: Set<string> | string[]) {
    this.filterIds.clear();
    for (const id of ids) {
      this.filterIds.add(id);
    }
    if (this.ganttInstance) {
      this.parseTasks();
    }
  }

  changeTaskId(oldTaskId: number, newTaskId: string) {
    this.filterIds.delete(oldTaskId);
    this.filterIds.add(newTaskId);
  }

  setGanttInstance(viewMode: string) {
    this.ganttInstance = Gantt.getInstance(GanttNames[viewMode]);
    this.parseTasks();
  }

  destroySideEffects() {
    while (this.disposers.length) {
      const disposer = this.disposers.pop();
      disposer();
    }
  }

  private refreshOneTask(taskModel: TaskModelRawDTO) {
    // Tasks cannot be refreshed and moved in a single operation or gantt
    // will scramble the arrangement of rows.  Extract parent and index,
    // refresh the task, then decide if ganttInstance.move is needed.
    const {parent, index, ...model} = tasksToGanttTasks(
      [taskModel],
      this.tasksStore.tasks.filter((task) => this.filterIds.has(task.id)),
      this.tasksStore.projectId,
    )[0];

    const ganttTask = this.ganttInstance.getTask(model.id);
    let tasksNeedsToMove = false;

    if (ganttTask && (index !== ganttTask?.$local_index || parent !== ganttTask?.parent)) {
      tasksNeedsToMove = true;
    }

    refreshTask(this.ganttInstance, model.id, model);

    if (tasksNeedsToMove) {
      this.ganttInstance.moveTask(ganttTask.id, index, parent);
    }

    // WBS OSK is updated on drag and drop WBS gets new ID and the state of the task store does not have children
    // when the children get updated we need to re-associate the children with their parent
    if (ganttTask && ganttTask?.parent !== this.ganttInstance?.config?.root_id) {
      const taskParent = this.ganttInstance.getTask(parent);
      const taskChildren = this.ganttInstance.getChildren(ganttTask.parent);

      // if an update updates the OSK ensure parent has $has_child is accurate
      if (taskParent.$has_child !== taskChildren.length) {
        refreshTask(this.ganttInstance, taskParent.id, {$has_child: taskChildren.length});
      }
    }

    this.updateRownums();
  }

  private createOrRefreshTask(taskModel: TaskModelRawDTO) {
    if (this.ganttInstance.isTaskExists(taskModel.id)) {
      this.refreshOneTask(taskModel);
    } else {
      const filteredTasks = this.tasksStore.tasks.filter((task) => this.filterIds.has(task.id));
      const model = tasksToGanttTasks([taskModel], filteredTasks, this.tasksStore.projectId)[0];
      this.ganttInstance.addTask(model);
    }
  }

  private createOrRefreshTasks(tasks: TaskModelRawDTO[]) {
    if (tasks.length > REPAINT_FULL_TABLE_THRESHOLD) {
      this.ganttInstance.batchUpdate(() => {
        for (const task of tasks) {
          this.createOrRefreshTask(task);
        }
      });
    } else {
      for (const task of tasks) {
        this.createOrRefreshTask(task);
      }
    }
  }

  private handleRemoveTasks() {
    const gantt = this.ganttInstance;
    const taskIds = gantt.getSelectedTasks();
    const parents: Record<string, number> = {};

    if (taskIds?.length) {
      gantt.batchUpdate(() => {
        taskIds.forEach((id) => {
          if (gantt.isTaskExists(id)) {
            const task = gantt.getTask(id);
            if (task.parent && task.parent !== gantt.config.root_id) {
              parents[task.parent] = !parents?.[task.parent] ? 1 : ++parents[task.parent];
            }
            gantt.unselectTask(id);
            gantt.silent(() => gantt.deleteTask(id));
            gantt.callEvent('bulkDelete', [taskIds]);
          }
        });
      });

      Object.entries(parents).forEach(([id, count]) => {
        updateCollapseIcon(gantt, id, count * -1);
      });
    }

    this.updateRownums();
  }

  private taskListChanged(change: IArrayDidChange<TaskModelRawDTO>) {
    if (this.tasksStore.isLoading || this.tasksStore.silent || this.ganttInstance.name === GanttNames.issues) {
      return;
    }

    // Four cases to handle
    // 1. splice with removed -- pull out of gantt, although some operations may look like
    //    a remove but effectively be an array rearrangement
    // 2. splice with added on first load -- gantt is empty, so just do the full parseTasks
    // 3. splice with added after first load -- normally this would be adding one new row,
    //    but could be caused by array sorting or something where some or all of the tasks
    //    are alredy present in the gantt table.  Transform all the values then add or update
    //    as appropriate
    // 4. update -- one array element was altered.  Transform the value and update the gantt
    if (change.type === ObserverChangeTypes.splice && change.removedCount) {
      for (const task of change.removed) {
        if (this.ganttInstance.isTaskExists(task.id)) {
          this.ganttInstance.silent(() => {
            this.handleRemoveTasks();
          });
        }

        this.ganttInstance.render();
      }
    }

    if (change.type === ObserverChangeTypes.splice && change.addedCount) {
      // Filter the added tasks
      for (const task of change.added) {
        this.filterIds.add(task.id);
      }

      if (this.ganttInstance.getTaskCount() <= 1) {
        // This case will be handled by the when in initSideEffects by calling parseTasks, so that we get
        // correct behavior for both empty task (which doesn't fire arrayChanged) and
        // for non-empty lists.
      } else {
        this.createOrRefreshTasks(change.added);
      }

      this.updateRownums();
    }

    if (change.type === ObserverChangeTypes.update) {
      if (!this.filterIds.has(change.newValue.id)) return;

      this.createOrRefreshTask(change.newValue);
    }
  }

  private linkDependenciesToTasks() {
    const taskDeps = this.tasksStore.dependencies;
    const gantt = this.ganttInstance;
    const rownumbers = this.idRownumMap;
    let needRefresh = false;

    if (Array.isArray(taskDeps) && taskDeps.length) {
      gantt.eachTask((task) => {
        const prevDeps = task.sourceDeps;
        const sourceDeps = taskDeps
          ?.filter(({taskId}) => taskId === task.id)
          .map((dep) => {
            return {...dep, rownum: rownumbers[dep.predTaskId]};
          })
          .sort((a, b) => b.rownum - a.rownum);

        if ((sourceDeps?.length || prevDeps?.length) && !equal(sourceDeps, prevDeps)) {
          needRefresh = true;
          Object.assign(task, {
            sourceDeps: sourceDeps ?? [],
            targetDeps: taskDeps?.filter(({predTaskId}) => predTaskId === task.id) || [],
          });
        }
      });

      if (needRefresh) gantt.refreshData();

      const deps = taskDeps.reduce(
        (agg, cur) => Object.assign(agg, {[cur.id]: cur}),
        {} as {[key: string]: TaskDependency},
      );

      gantt.getLinks().forEach((link) => {
        if (deps[link.id]) {
          const currentLink = gantt.getLink(link.id);
          const update = mapDependencyToGanttLink(gantt, deps[link.id]);
          if (!equal(update, gantt.getLink(link.id))) {
            Object.assign(currentLink, update);
            gantt.silent(() => {
              gantt.refreshLink(link.id);
            });
          }
        } else {
          if (gantt.isTaskExists(link.target) && gantt.isTaskExists(link.source)) {
            gantt.deleteLink(link.id);
          }
        }
      });

      Object.keys(deps).forEach((key) => {
        const dep = deps[key];
        if (!gantt.isLinkExists(key)) {
          gantt.addLink(mapDependencyToGanttLink(gantt, dep));
        }
      });

      this.ganttInstance.render();
    }
  }

  private parseTasks() {
    if (this.ganttInstance.name === GanttNames.issues) {
      return;
    }

    const oskMap: OSKMap = new Map();
    const collapsed: Set<string> = this.collapseAccessor ? new Set(this.collapseAccessor()) : new Set();

    const visibleTasks = this.tasksStore.tasks.filter((task) => this.filterIds.has(task.id));

    const tasksAndLinks = transformLookaheadData({
      tasks: visibleTasks,
      projectId: this.tasksStore.projectId,
      flatList: false,
      oskMap,
      collapsed,
    });

    this.ganttInstance.clearAll();
    this.ganttInstance.parse({tasks: tasksAndLinks?.tasks || [], links: this.tasksStore.dependencies});
    this.linkDependenciesToTasks();
    this.updateRownums();
  }

  private updateRownumMaps() {
    const idToRownum = {};
    const rownumToID = {};
    const sortedTasks = this.tasksStore.tasks?.filter((task) => !!task.outline_sort_key);

    for (let i = 0; i < sortedTasks?.length; i++) {
      idToRownum[sortedTasks[i].id] = i + 1;
      rownumToID[i + 1] = sortedTasks[i].id;
    }

    this.idRownumMap = idToRownum;
    this.rownumIDMap = rownumToID;
  }

  private applyRownums() {
    const gantt = this.ganttInstance;

    // Update the Gantt chart's row number mapping
    gantt.rownumbersMap = this.idRownumMap;

    // No way to be certain how many row numbers will get modified, but can't afford
    // a batchUpdate here because we can't tell in advance how many rows will need to
    // be modified, so no way to judge whether we need to skip batchUpdate to optimize
    // for the common case (added one row at the bottom)
    const modified = [];
    gantt.eachTask((task) => {
      // Check if the task's row number has changed
      if (this.idRownumMap[task.id] && this.idRownumMap[task.id] !== task.rownum) {
        // Update the task's row number
        task.rownum = this.idRownumMap[task.id];

        // Add the task ID to the modified list for efficient updating
        modified.push(task.id);
      }
    });

    if (modified.length > REPAINT_FULL_TABLE_THRESHOLD) {
      // If many tasks were modified, re-render the entire chart
      this.ganttInstance.render();
    } else {
      // If only a few tasks were modified, update them individually
      modified.forEach((id) => {
        // Refresh each modified task without triggering a full re-render
        // The 'false' parameter prevents unnecessary calculations
        gantt.refreshTask(id, false);
      });
    }
  }

  private updateRownums() {
    this.updateRownumMaps();
    this.applyRownums();
  }
}
