import React, {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';

import {Avatar, Tooltip} from 'shared/components';
import WorkerStatus from 'shared/components/WorkerStatus';
import {getCompanyWorkerStatus} from 'shared/helpers/statuses';
import {getNormalizedWorkerTrade} from 'shared/helpers/worker';
import {GroupMemberRole} from 'shared/models/task';

import {EyeIcon, FlagIcon} from '../Icons';
import {AssigneesFilterWorker} from '../types';

type Props = {
  viewOnly: boolean;
  worker: AssigneesFilterWorker;
  isLoading: boolean;
  onAssigned: (member: AssigneesFilterWorker) => void;
};

const WorkerInfo: FC<Props> = ({viewOnly, worker, isLoading, onAssigned}: Props) => {
  const {t} = useTranslation('task');
  const setAsResponsible = () => {
    if (!worker.isResponsible) {
      onAssigned({...worker, memberRole: GroupMemberRole.responsible});
    }
  };
  const setAsWatcher = () => {
    onAssigned({
      ...worker,
      memberRole: worker?.memberRole ? worker.memberRole : GroupMemberRole.assignee,
    });
  };

  return (
    <div className="worker-accepted__info">
      <Avatar
        src={worker?.workerFull?.profilePicUrl}
        className="worker-accepted__info-part worker-accepted__info-part--avatar"
      />
      <div className="worker-accepted__info-part worker-accepted__info-part--text">
        <div className="worker-accepted__name">
          <span>{worker.workerFull.fullName}</span>
        </div>
        <div className="worker-accepted__trade">{getNormalizedWorkerTrade(worker.workerFull.trade, t)}</div>
        <span className="te-worker-status te-worker-status--invited worker-accepted__status">
          <WorkerStatus value={getCompanyWorkerStatus(worker.status, worker.workerFull.status)} />
        </span>
      </div>
      <Tooltip isHide={!worker.tooltip} text={worker.tooltip} placement="bottom-end">
        <label
          className="ctrl-check-worker worker-accepted__info-part worker-accepted__info-part--check"
          title={t('worker_info.title.responsible', 'Assign responsible.')}
          style={{cursor: 'pointer', position: 'relative'}}
        >
          <input
            className="ctrl-check-worker__field"
            type="checkbox"
            name="responsible"
            onChange={setAsResponsible}
            checked={worker.isResponsible}
            disabled={isLoading || viewOnly}
          />
          <FlagIcon />
          <span className="ctrl-check-worker__label" />
        </label>
      </Tooltip>
      <label
        className="ctrl-check-worker worker-accepted__info-part worker-accepted__info-part--check"
        title={t('worker_info.title.observer', 'Assign an observer.')}
        style={{cursor: 'pointer', position: 'relative'}}
      >
        <input
          className="ctrl-check-worker__field"
          type="checkbox"
          onChange={setAsWatcher}
          checked={worker.isWatcher}
          disabled={isLoading || viewOnly || worker.isResponsible}
        />
        <EyeIcon />
        <span className="ctrl-check-worker__label" />
      </label>
    </div>
  );
};
export default memo(WorkerInfo);
