export type ActionModel = {
  id: string;
  name: string;
  endDate: Date;
  minDate: Date;
  responsible: string;
  status: TaskStatus;
  focus?: boolean;
};

import {TaskDetailsModelDTO, TaskObjectType, TaskStatus} from 'shared/models/task';

import {FeedbackRollup} from '../../Gantt/types';

export type FormValues = Partial<
  Pick<
    TaskDetailsModelDTO,
    | 'calendarDays'
    | 'completionAmount'
    | 'completionTarget'
    | 'completionUnit'
    | 'costCode'
    | 'csiCode'
    | 'customCode'
    | 'customFields'
    | 'description'
    | 'duration'
    | 'estLaborHours'
    | 'location'
    | 'name'
    | 'objectType'
    | 'phaseCode'
    | 'projectedLabor'
    | 'projectId'
    | 'responsibleOrgId'
    | 'status'
    | 'type'
  > &
    DateType & {
      parentId?: string;
    } & Partial<FeedbackRollup>
>;

export type DateType = {
  endDate: Date;
  startDate: Date;
  actualStart?: Date;
  actualEnd?: Date;
};

export const getDefaultValues = (values?: Partial<FormValues>): FormValues =>
  Object.assign(
    {
      description: '',
      location: '',
      name: '',
      status: TaskStatus.tba,
      projectId: undefined,
      responsibleOrgId: null,
      responsible: null,
      completionAmount: '',
      completionUnit: '%',
      completionTarget: '100',
      objectType: TaskObjectType.activity,
      duration: 1,
      startDate: null,
      endDate: null,
      actualStart: null,
      actualEnd: null,
      calendarDays: false,
      customFields: [],
      estLaborHours: undefined,
    },
    values || {},
  );
