import {useQuery} from 'react-query';

import {ProjectsApi} from 'api';
import {OptionType} from 'shared/components';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

export function useTaskTypeOptions(projectId: string) {
  return useQuery(
    QUERY_CACHE_KEYS.taskTypes(projectId),
    () => {
      const options: OptionType[] = [];
      return ProjectsApi.getProjectTaskTypes(projectId).then((res) => {
        res?.forEach((label) => {
          if (label.length) options.push({value: label, label: label});
        });
        return options;
      });
    },
    {enabled: !!projectId, refetchOnWindowFocus: false},
  );
}
