import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';

import {WorkerApi} from '../../api';
import {toTitleCase} from '../helpers/common';
import {getNormalizedWorkerTrade} from '../helpers/worker';

export function useLoadWorkerTrades() {
  const {t} = useTranslation(['worker', 'common']);
  const {data: trades} = useQuery('workerTrades', () => {
    return WorkerApi.getTradeOptions().then((res) =>
      res.map((label) => ({
        value: label,
        label: label.toLowerCase() === 'hvac' ? label.toUpperCase() : toTitleCase(label),
      })),
    );
  });
  const localizedTrades = useMemo(
    () => trades?.map((trade) => ({...trade, label: getNormalizedWorkerTrade(trade.value, t)} || [])),
    [trades, t],
  );

  return {localizedTrades, trades};
}
