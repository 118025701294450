import {useTranslation} from 'react-i18next';

import TaskContextMenu from 'modules/Tasks/components/Gantt/components/TaskContextMenu/TaskContextMenu';
import GanttComponent from 'modules/Tasks/components/Gantt/GanttView';
import {configureGanttBase, createDataProcessor, getGanttDefaultLayout} from 'modules/Tasks/components/Gantt/utils';
import {loadActivityIds} from 'modules/Tasks/components/Gantt/utils/load';
import {configureZoomLevels} from 'modules/Tasks/components/Gantt/utils/zoomLevels';
import {TasksViewMode} from 'shared/constants/common';
import {GanttNames} from 'shared/constants/gantt';

import SidebarPanel, {SidebarType} from '../../components/SidebarPanel/SidebarPanel';
import {GanttViewCommonProps} from '../types';

export const GanttDefaultView = ({
  projectId,
  noDataElement,
  isActiveFilter,
  queryParams,
  lookaheadColors,
  hasAdminRole,
}: GanttViewCommonProps) => {
  const {t} = useTranslation(['gantt', 'lookahead']);

  return (
    <GanttComponent
      load={loadActivityIds}
      viewMode={TasksViewMode.gantt}
      name={GanttNames.gantt}
      projectId={projectId}
      noDataElement={noDataElement}
      isActiveFilter={isActiveFilter}
      queryParams={queryParams}
      lookaheadColors={lookaheadColors}
      createDataProcessor={createDataProcessor}
      contextMenu={TaskContextMenu}
      configure={({gantt, cacheHelper}) => {
        configureGanttBase({gantt, t, projectId, cacheHelper, hasAdminRole});
        configureZoomLevels(gantt, projectId);
        gantt.config.placeholder_task = hasAdminRole;
        gantt.config.layout = getGanttDefaultLayout();
      }}
      getPanelActivity={({taskId, onClose, updateActivityDetailsTask, gantt}) => (
        <SidebarPanel
          sidebarType={SidebarType.activity}
          taskId={taskId}
          onClose={onClose}
          updateActivityDetailsTask={updateActivityDetailsTask}
          gantt={gantt}
        />
      )}
      dataCy="gantt-container"
    />
  );
};
