import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Popup from 'shared/components/Popup';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';
import Tooltip from 'shared/components/Tooltip';

import GalleryEmpty from '../../../components/InfoGallery/components/GalleryEmpty/GalleryEmpty';
import galleryStyle from '../../../components/InfoGallery/InfoGallery.module.scss';

import imageGallery from './ImageGallery.module.scss';

type ImageItem = {
  imageUrl: string;
  thumbnailUrl: string;
};

interface ImageGalleryProps {
  className?: string;
  isLoading?: boolean;
  displayGalleryCopy?: boolean;
  imageUrlList: ImageItem[];
}

const ImageGallery = ({
  displayGalleryCopy: displayGallerCopy = true,
  className,
  isLoading,
  imageUrlList,
}: ImageGalleryProps) => {
  const {t} = useTranslation(['task', 'gantt', 'common']);
  const [expandImage, setExpandImage] = useState<string>(null);

  const imagesList = imageUrlList?.map((image, index) => (
    <picture key={`${image.imageUrl}_${index}`} onClick={() => setExpandImage(image.imageUrl)}>
      <img className={cn(galleryStyle.infoGallery__image, imageGallery.baseImage)} src={image.thumbnailUrl} alt="" />
    </picture>
  ));

  const preloadList = useMemo(() => {
    return Array.from({length: 3}).map((_, i) => (
      <div className={galleryStyle.infoGallery__item} key={i}>
        <SkeletonPreloader when className={galleryStyle.infoGallery__image} height="100%" />
      </div>
    ));
  }, []);

  const renderGallery = () => {
    if (isLoading) {
      return preloadList;
    } else if (!!imageUrlList?.length) {
      return imagesList;
    }
    return <GalleryEmpty className={galleryStyle.infoGallery__item} />;
  };

  const galleryLabelContent = isLoading
    ? t('task:task_form.gallery.loading', 'Loading...')
    : imageUrlList
    ? imageUrlList.length
    : t('task:task_form.gallery.empty', 'Empty');

  const copyImageLink = () => {
    navigator.clipboard
      .writeText(expandImage)
      .then(() => toast.success(t('gantt:toast.success.copy', 'Copied!')))
      .catch((err) => {
        Sentry.captureException(err);
        toast.error(t('gantt:toast.error.copy', 'Something went wrong while copying the link.'));
      });
  };

  const openImageInNewTab = () => {
    window.open(expandImage, '_blank');
  };

  return (
    <div className={cn(galleryStyle.infoGallery, className)}>
      <div className={galleryStyle.infoGallery__label}>
        {displayGallerCopy ? (
          <>
            {t('task:task_form.gallery.label', 'Gallery')}
            &nbsp; ({galleryLabelContent})
          </>
        ) : null}
      </div>
      <div className={cn(imageGallery.imageContainer)}>{renderGallery()}</div>
      <Popup
        className={cn(imageGallery.image_popup__item)}
        onClose={() => setExpandImage(null)}
        visible={!!expandImage}
      >
        <Popup.Header>
          <div className={imageGallery.image_popup__share_btn}>
            <Tooltip text={t('common:image_gallery.tooltip.copy_link')} placement="bottom">
              <Button
                iconOnly
                icon={
                  <Icon
                    className={imageGallery.image_popup__share_btn_icon}
                    name="copy"
                    size={22}
                    titleText={t('common:image_gallery.copy_image_address')}
                  />
                }
                onClick={copyImageLink}
              />
            </Tooltip>
            <Tooltip text={t('common:image_gallery.tooltip.open_image')} placement="bottom">
              <Button
                iconOnly
                icon={
                  <Icon
                    className={imageGallery.image_popup__share_btn_icon}
                    name="new-tab"
                    size={22}
                    titleText={t('common:image_gallery.open_image_in_new_tab')}
                  />
                }
                onClick={openImageInNewTab}
              />
            </Tooltip>
          </div>
        </Popup.Header>
        <Popup.Body>
          <img className={imageGallery.image_popup__img} src={expandImage} />
        </Popup.Body>
      </Popup>
    </div>
  );
};

export default ImageGallery;
