import {CSSProperties, ReactElement} from 'react';

type Props = {
  styles?: CSSProperties;
};

function Flag({styles = null}): ReactElement<Props> {
  return (
    <svg
      style={styles}
      className="icon-inline icon-inline--color-fill ctrl-check-worker__icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="12"
      height="12"
    >
      <path d="M20 6.5v9c0 .276-.224.5-.5.5h-5.5c-1.105 0-2-.895-2-2h-6v7.5c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-19c0-.276.224-.5.5-.5h1c.276 0 .5.224.5.5v1.5h6c1.105 0 2 .895 2 2h5.5c.276 0 .5.224.5.5z"></path>
    </svg>
  );
}
export default Flag;
