import {useCallback, useRef, useState} from 'react';

import {OAuthService} from 'api';
import {ExternalServices} from 'shared/constants/common';
import {useUnmount} from 'shared/hooks/core';

import {ProCoreErrors} from './utils/constants';

interface MessageData {
  event: 'success' | 'reject';
  error?: string;
}

export function useProcoreOauth() {
  const popup = useRef<Window>();
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState<string>();
  const closeCheckTimer = useRef<number>(null);

  async function checkAuth() {
    return await OAuthService.check(ExternalServices.Procore);
  }

  const onMessageReceived = useCallback((e: MessageEvent<MessageData>) => {
    if (e.data.event === 'success') {
      setAuthenticated(true);
      setError('');
    } else if (e.data.event === 'reject') {
      e.data.error && setError(error);
    }
    clear();
  }, []);

  const onFocus = useCallback(() => {
    if (popup.current && !popup.current.closed && !error) {
      popup.current.focus();
    }
  }, []);

  function clear() {
    window.removeEventListener('message', onMessageReceived);
    window.removeEventListener('focus', onFocus);
    clearInterval(closeCheckTimer.current);
    popup.current?.close();
  }

  async function init() {
    try {
      if (!(await checkAuth())) {
        const url = await OAuthService.start();
        const authWindow = (popup.current = window.open(url, 'Procore Login', 'left=100,top=100,width=600,height=600'));
        closeCheckTimer.current = window.setInterval(() => {
          if (authWindow.closed) {
            clear();
            setError(ProCoreErrors.abortedAuthorization);
          }
        }, 300);
        window.addEventListener('message', onMessageReceived);
        window.addEventListener('focus', onFocus);
        authWindow.focus();
      } else {
        setAuthenticated(true);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async function check() {
    setAuthenticated(await checkAuth());
  }

  function reset() {
    setError('');
    setAuthenticated(false);
  }

  // to logout from ProCore we just need to call START again
  async function logout() {
    // First need to trigger Procore's logout so cookies on procore.com domain are cleared.
    const logoutUrl = process.env.REACT_APP_PROCORE_LOGOUT_URL;
    const logoutWindow = window.open(logoutUrl, 'Procore logout', 'left=100,top=100,width=400,height=100');

    // I've tried so many things to get definitive knowledge of when the logout screen
    // has finished, but cross-domain security or security measures that prevent the logout
    // page from loading if it's in an iframe blocked all my approaches.  So it just stays up
    // for 4 seconds.
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        logoutWindow.close();
        reset();
        OAuthService.start().then(resolve).catch(reject);
      }, 4000);
    });
  }

  useUnmount(() => {
    clear();
  });

  return {authenticated, init, check, error, reset, logout};
}
