import cn from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {Cell, Row} from 'react-table';

import s from 'shared/components/Table/Table.module.scss';

type RecordableData = Record<string, unknown>;

export type TableColumnProps<D extends RecordableData> = {
  cell: Cell<D> & {row: Row<D> & {onRowExpanded?: (row: Row<D>) => void}};
  columnIndex: number;
  renderProps: Record<string, unknown>;
  className?: string;
  columnId?: string;
};

const DEFAULT_COLUMN_PADDING = 8;

export const TableColumn = <D extends RecordableData>({
  cell,
  renderProps,
  columnIndex,
  className,
  columnId,
}: TableColumnProps<D>): JSX.Element => {
  const cellProps = cell.getCellProps();
  const isFirstColumn = columnIndex === 0;

  function calculateColumnPadding() {
    if (!isFirstColumn) return undefined;
    return DEFAULT_COLUMN_PADDING;
  }

  return (
    <div
      {...cellProps}
      className={cn(
        s.tableWorkers__cell,
        s[`tableWorkers__cell_${columnId}`],
        className,
        cellProps.className,
        cell.column.className,
      )}
      style={{
        ...cellProps.style,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        paddingLeft: calculateColumnPadding(),
      }}
    >
      {renderProps?.loading ? <Skeleton height={42} /> : cell.render('Cell', renderProps)}
    </div>
  );
};
