import {MultiFactorResolver} from '@firebase/auth';
import {PhoneAuthProvider} from 'firebase/auth';
import {ChangeEvent, FC, useLayoutEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import FirebaseService from 'services/Firebase';
import {Button, FormControl, Loader} from 'shared/components/index';
import {getHumanizedFbError, isFirebaseError} from 'shared/constants/firebase';
import useCountDown from 'shared/helpers/hooks/useCountDown';
import {useRecaptcha} from 'shared/helpers/hooks/useRecaptcha';

type Props = {
  multiFactorResolver: MultiFactorResolver;
  resolve?: (value: unknown, closeAfter: boolean) => void;
};

const RECAPTCHA_ID = 'twofactor-auth-recaptcha-box';

const MultiFactorDialog: FC<Props> = ({multiFactorResolver, resolve}: Props) => {
  const {t} = useTranslation('sign_in');
  const [isLoading, setIsLoading] = useState(false);
  const [verificationID, setVerificationID] = useState(null);
  const recaptcha = useRecaptcha();
  const {counter, start, reset} = useCountDown(15);
  const activeResolver = multiFactorResolver.hints[0];
  const phoneNumber = useRef(null);
  const verifyCodeInputRef = useRef(null);

  useLayoutEffect(() => {
    const phoneResolver = multiFactorResolver.hints.find(
      ({factorId}) => factorId === FirebaseService.getPhoneAuthProvider().providerId,
    );
    if (phoneResolver) {
      phoneNumber.current = phoneResolver['phoneNumber'];
    }
  }, [multiFactorResolver]);

  const sendCode = async () => {
    const phoneResolver = multiFactorResolver.hints.find(
      ({factorId}) => factorId === FirebaseService.getPhoneAuthProvider().providerId,
    );
    if (phoneResolver) {
      const recaptchaVerifier = recaptcha.create(RECAPTCHA_ID);
      try {
        const phoneInfoOptions = {
          multiFactorHint: phoneResolver,
          session: multiFactorResolver.session,
        };
        const verifyId = await FirebaseService.getPhoneAuthProvider().verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier,
        );

        setVerificationID(verifyId);
        reset();
        start();
      } catch (e) {
        if (isFirebaseError(e)) {
          toast.error(getHumanizedFbError(e, t));
        }
      } finally {
        recaptcha.destroy(recaptchaVerifier, RECAPTCHA_ID);
        setIsLoading(false);
      }
    }
  };

  const checkCode = async (code: string) => {
    try {
      setIsLoading(true);
      const cred = PhoneAuthProvider.credential(verificationID, code);
      const multiFactorAssertion = FirebaseService.phoneMultiFactorGenerator.assertion(cred);
      const user = await multiFactorResolver.resolveSignIn(multiFactorAssertion);
      resolve(user, true);
    } catch (e) {
      toast.error(getHumanizedFbError(e, t));
    } finally {
      if (verifyCodeInputRef.current) {
        verifyCodeInputRef.current.value = '';
      }
      setIsLoading(false);
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    if (value.length === 6) {
      e.preventDefault();
      verifyCodeInputRef.current.blur();
      verifyCodeInputRef.current.value = '';
      checkCode(value);
    }
  };

  return (
    <div className="loader-container">
      {isLoading && <Loader />}
      <div className="form-onboarding-z__item">
        <FormControl
          className="ctrl-form--button-link"
          name="phoneNumber"
          label={t('fields.phone_number.label', 'Phone Number')}
        >
          <input type="text" value={activeResolver['phoneNumber']} readOnly={true} className="ctrl-textfield" />
        </FormControl>
      </div>
      {verificationID && (
        <div className="form-onboarding-z__item">
          <FormControl
            name="verificationCode"
            label={t('fields.verification_code.label', 'Enter the code we`ve sent to your number')}
          >
            <input
              ref={verifyCodeInputRef}
              className="ctrl-textfield"
              type="number"
              onChange={onInputChange}
              placeholder={t('fields.verification_code.placeholder', 'Enter the code')}
            />
          </FormControl>
        </div>
      )}
      <div className="form-onboarding-z__item form-onboarding-z__item--actions">
        <Button type="button" onClick={sendCode}>
          {!verificationID
            ? t('buttons.send_code', 'Send')
            : !counter
            ? t('buttons.resend_code', 'Resend')
            : `${t('buttons.resend_code_in', 'Resend in')} ${counter}s`}
        </Button>
      </div>
    </div>
  );
};
export default MultiFactorDialog;
