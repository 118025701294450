import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import DownloadAppLink from 'shared/components/DownloadAppLink';
import ExternalLink from 'shared/components/ExternalLink';
import env from 'shared/constants/env';
import {getLocalizedLink} from 'shared/helpers/common';
import {useAnalyticsService} from 'shared/hooks';
import useUserAgent from 'shared/hooks/useUserAgent';

import s from './CtrlAppDetect.module.scss';

type CtrlAppDetectProps = {
  className?: string;
};

const CtrlAppDetect = ({className}: CtrlAppDetectProps) => {
  const {
    t,
    i18n: {language},
  } = useTranslation('header');
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.home;
  const userAgent = useUserAgent();
  const isMobileDevice = userAgent.os.name === 'iOS' || userAgent.os.name === 'Android';
  const mixpanelMeta = {
    OS: userAgent.os.name,
    Device: userAgent.device.vendor,
  };

  return (
    <div className={cn(s.ctrlAppDetect, className)}>
      {isMobileDevice && userAgent.os.name === 'iOS' && (
        <DownloadAppLink.iOS
          className={s.ctrlAppDetect__button}
          onClick={() => mixpanel.track(mixpanelEvents.download.appStore, mixpanelMeta)}
        />
      )}
      {isMobileDevice && userAgent.os.name === 'Android' && (
        <DownloadAppLink.Android
          className={s.ctrlAppDetect__button}
          onClick={() => mixpanel.track(mixpanelEvents.download.googlePlay, mixpanelMeta)}
        />
      )}
      {!isMobileDevice && (
        <ExternalLink
          className={s.ctrlAppDetect__link}
          href={getLocalizedLink(env.legalPages.downloadApp, language)}
          text={t('links.mobile_app_download', 'Mobile App')}
          target="_blank"
        />
      )}
    </div>
  );
};

export default CtrlAppDetect;
