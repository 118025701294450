import dayjs from 'dayjs';
import {useQueries, useQuery} from 'react-query';

import {FeedbackService, TasksApi} from 'api';
import {useFilterContext} from 'modules/Tasks/components/Filters';
import {SortOrder} from 'shared/constants/common';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useProjectWorkers} from 'shared/hooks';
import {SortField, TaskDetailsModelDTO, TaskObjectType, TaskProjection} from 'shared/models/task';

import {TasksWithComments} from './useCurrentDateReports';
import {mergeFeedbackIntoTask} from './utils/mergeFeedback';

function useFetchTasksFeedback(tasks: TaskDetailsModelDTO[]) {
  // Directly pass an array of query objects to useQueries
  const feedbackQueries = useQueries(
    tasks.map((task) => ({
      queryKey: [QUERY_CACHE_KEYS.dailiesTaskFeedback, task.id], // Ensure this matches your caching strategy
      queryFn: () => FeedbackService.getFeedback(task.id),
    })),
  );

  return feedbackQueries;
}

export function useFetchDailiesTasks(projectId: string) {
  const {queryParams} = useFilterContext();
  const {projectWorkers} = useProjectWorkers();
  const dateForReport = dayjs(queryParams.schedEndFirst);
  const startOfDay = dateForReport.startOf('day');
  const endOfDay = dateForReport.endOf('day');
  const schedIntersect: [string, string] = [startOfDay.toISOString(), endOfDay.toISOString()];

  const tasksQuery = useQuery(
    [QUERY_CACHE_KEYS.dailiesReport, projectId, queryParams?.schedEndFirst.toISOString(), queryParams?.q],
    async () => {
      const objectTypeList = [TaskObjectType.activity, TaskObjectType.task];
      const response = await TasksApi.getProjectTasks({
        projection: TaskProjection.taskDetail,
        includeSummaryTasks: true,
        params: {
          ...queryParams,
          projectId,
          objectTypeList,
          schedIntersect,
        },
        sortOrder: SortOrder.ASC,
        sortField: SortField.outlineSortKey,
      });

      return response.data as TaskDetailsModelDTO[];
    },
  );

  // Fetch feedback for each task using `useQueries`
  const feedbackQueries = useFetchTasksFeedback(tasksQuery.data || []);

  // Determine if all feedback queries have either succeeded or there are no tasks
  const allFeedbackQueriesLoaded = feedbackQueries.every((query) => query.isSuccess) || tasksQuery.data?.length === 0;

  // Combine tasks with their feedback once all feedback has been fetched
  const tasksWithFeedback = tasksQuery.data?.map((task, index) => {
    const feedbackQuery = feedbackQueries[index];
    if (feedbackQuery.isSuccess && feedbackQuery.data) {
      const {feedbackByDate, comments} = mergeFeedbackIntoTask(
        task,
        feedbackQuery.data,
        projectWorkers,
        schedIntersect,
      );
      // If feedback for the task is successfully fetched, merge it into the task
      return {
        ...task,
        feedbackByDate,
        comments,
      };
    }
    return task; // Return task as is if feedback not yet available
  });

  // Overall loading state considering both tasks loading and feedback loading
  const isLoading = tasksQuery.isLoading || !allFeedbackQueriesLoaded;

  return {
    ...tasksQuery,
    isLoading, // Override the isLoading state with the combined state
    data: isLoading ? undefined : (tasksWithFeedback as TasksWithComments[]), // Only update the data once everything is loaded
  };
}
