import {GanttStatic} from 'dhtmlx-gantt';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {EventStore} from 'modules/Tasks/components/Gantt/utils/eventStore';
import {GanttEventNameUnion} from 'modules/Tasks/components/Gantt/utils/gantt';
import {debounce} from 'shared/helpers/debounce';
import {useLocalStorage} from 'shared/hooks';

type GanttScrollState = {top: number; left: number; taskId: string; taskOutlineNumber: string};

export function useGanttScrollPosition(
  gantt: GanttStatic,
  projectId: string,
  eventStore: EventStore<GanttEventNameUnion>,
) {
  const [getScrollState, setScrollState] = useLocalStorage<GanttScrollState>({
    key: 'ganttPreferences',
    path: `${gantt.name}.byProject.${projectId}.scrollState`,
    defaultValue: null,
    enabled: !!(gantt.name && projectId),
  });

  function track() {
    const updateScrollState = debounce(setScrollState, 500);
    eventStore.attach('onGanttScroll', (left, top) => {
      const taskIndex = Math.floor(top / gantt.config.row_height);
      const task = gantt.getTaskByIndex(taskIndex);
      updateScrollState({left, top, taskId: task?.id, taskOutlineNumber: task?.outline_sort_key});
    });
  }

  function restore() {
    const scrollState = getScrollState();

    if (scrollState) {
      const {left, taskId, taskOutlineNumber} = scrollState;
      if (taskId && gantt.isTaskExists(taskId)) {
        gantt.scrollTo(left, gantt.getTaskTop(taskId));
      } else if (typeof taskOutlineNumber === 'string') {
        const parts = taskOutlineNumber.split('.');
        let task: GanttTask;
        while (parts.length && !task) {
          task = gantt.getTaskBy((task) => task.outline_sort_key?.includes(parts.join('.')))[0];
          parts.pop();
        }
        if (task) {
          gantt.scrollTo(left, gantt.getTaskTop(task.id));
        }
      }
      return true;
    }
    return false;
  }

  return {getScrollState, trackScrollPosition: track, restoreScrollPosition: restore} as const;
}
