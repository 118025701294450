import * as Sentry from '@sentry/browser';
import {ChangeEvent, FC, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import {string} from 'yup';

import {ProjectsApi} from 'api';
import {TasksViewMode} from 'shared/constants/common';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {getLocalStorageValue, useAnalyticsService} from 'shared/hooks';
import {useMount, useUnmount} from 'shared/hooks/core';
import {CompanyWorker} from 'shared/models/worker';

import {useFilterContext} from '../../../modules/Tasks/components/Filters';
import {isNonHidingColumn} from '../../../modules/Tasks/components/Gantt/utils';
import {GanttNames} from '../../constants/gantt';
import {prepareQueryString} from '../../helpers/queryParams';
import Gantt from '../../models/Gantt';
import {ProjectCollabRequest} from '../../models/project';
import AsyncProjectWorkerSelect from '../CoreForm/Select/AsyncProjectWorkerSelect/AsyncProjectWorkerSelect';
import {CtrlButton} from '../CoreNewUI';
import FieldInline from '../CoreNewUI/FormControlInline/FieldInline/FieldInline';
import Icon from '../Icon';
import {Avatar, Button, Loader} from '../index';
import Popup from '../Popup';

import {isNewAddedWorker, validationSchema} from './helpers';
import s from './ProjectCollabPopup.module.scss';

type Props = {
  visible: boolean;
  onClose: () => void;
};

export type SelectWorkerType = {
  value: ExtendedCompanyWorker;
  label: string;
};

type ExtendedCompanyWorker = {
  email?: string;
  mobileNumber?: string;
} & Partial<CompanyWorker>;

const MAX_NOTE_LENGTH = 128;

function isValidEmail(inputValue: string) {
  try {
    string().email().validateSync(inputValue);
    return true;
  } catch (e) {
    return false;
  }
}

function isValidPhone(inputValue: string) {
  return inputValue.replaceAll(/\D/g, '').search(/^\d{11,12}$/) !== -1;
}

const ProjectCollabPopup: FC<Props> = ({visible, onClose}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {t} = useTranslation('collaboration');
  const {queryParams, viewMode} = useFilterContext();
  const gantt = Gantt.getInstance(GanttNames[viewMode]);

  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.tasks.toolbar.projectCollabPopup;

  const [selectedWorkers, setSelectedWorkers] = useState<SelectWorkerType[]>([]);
  const [note, setNote] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useMount(() => {
    mixpanel.track(mixpanelEvents.open);
  });

  useUnmount(() => {
    mixpanel.track(mixpanelEvents.close);
  });

  useQuery(
    QUERY_CACHE_KEYS.projectMostRecentCollabRequest(projectId),
    () => {
      return ProjectsApi.getMostRecentCollabUsers(projectId)
        .then((res) => {
          const workers = res?.workers || [];
          if (workers.length) {
            setSelectedWorkers((prev) => {
              const preparedWorkers =
                workers.map((w) => {
                  const isSelected = !!selectedWorkers.find(({value}) => value.workerFull.id === w.id);
                  if (!isSelected) {
                    return Object.assign(
                      {},
                      {value: {workerFull: {...w}}, label: w.fullName || w.email || w.mobileNumber},
                    );
                  }
                }) || [];
              return prev.concat(preparedWorkers);
            });
          }
        })
        .catch((err) => {
          // 404s ignored equal to empty response, all other errors thrown.
          if (!err.response?.status || err.response.status !== 404) {
            throw err;
          }
        });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: visible,
      retry: 2,
    },
  );

  const sendCollabRequest = async () => {
    const newAddedWorkers = selectedWorkers.filter(isNewAddedWorker);
    const existedWorkers = selectedWorkers.filter((o) => !isNewAddedWorker(o));
    // getGridColumns returns sorted list of columns
    const visibleGanttColumns = gantt
      .getGridColumns()
      .filter(({name, hide}) => !isNonHidingColumn(name) && !hide)
      .map(({name}) => name);

    const payload: ProjectCollabRequest = {
      message: note.trim(),
      workers: [
        ...existedWorkers.map(({value}) => Object.assign({workerId: value.workerFull.id})),
        ...newAddedWorkers.map(({value: worker}) => {
          const invitedBy = 'email' in worker ? 'email' : 'mobileNumber';
          return Object.assign({[invitedBy]: worker[invitedBy]});
        }),
      ],
      viewFilters:
        prepareQueryString({values: queryParams}) + `&view=${viewMode}&ganttCols=${visibleGanttColumns.join(',')}`,
    };
    if (viewMode === TasksViewMode.ganttVisual) {
      payload.viewFilters += `&documentId=${getLocalStorageValue(
        'mapViewPreferences',
        `byProject.${projectId}.lastDocument`,
      )}`;
    }
    setIsLoading(true);
    try {
      mixpanel.track(mixpanelEvents.sendRequest);
      await ProjectsApi.sendCollabRequest(projectId, payload);
      toast.success('Request successful sent.');
      onClose();
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const selectWorkers = (options: SelectWorkerType[]) => {
    mixpanel.track(mixpanelEvents.selectWorker);
    setSelectedWorkers(options);
  };

  const unselectWorker = useCallback((cworker: CompanyWorker) => {
    setSelectedWorkers((prevState) => {
      return prevState.filter(
        ({
          value: {
            workerFull: {id},
          },
        }) => id !== cworker.workerFull.id,
      );
    });
  }, []);

  const validateInputValue = (inputValue: string): boolean => {
    if (!inputValue) return false;
    if (isValidEmail(inputValue) || isValidPhone(inputValue)) {
      const field = inputValue.includes('@') ? 'email' : 'mobileNumber';
      const isExist = selectedWorkers.find(
        ({value: cw}) => cw[field] === inputValue || cw?.workerFull?.[field] === inputValue,
      );
      if (isExist) return false;
      try {
        validationSchema(t).validateSync({[field]: inputValue});
        return true;
      } catch (e) {
        if (typeof e === 'object' && 'message' in e) {
          toast.error(e.message);
        }
        return false;
      }
    }
    return false;
  };

  const onAddInvitedWorker = (inputValue: string) => {
    const trimmedVal = inputValue.trim();
    if (validateInputValue(trimmedVal)) {
      const invitedBy = trimmedVal.includes('@') ? 'email' : 'mobileNumber';
      const model = {
        workerFull: {
          id: `${Date.now()}`,
          fullName: trimmedVal,
        },
        [invitedBy]: trimmedVal,
      };
      mixpanel.track(mixpanelEvents.addNewWorker, {invitedBy});
      setSelectedWorkers((prev) => {
        return prev.concat([{value: model, label: inputValue} as SelectWorkerType]);
      });
    }
  };

  const onChangeNote = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value.substring(0, MAX_NOTE_LENGTH));
  };

  return (
    <Popup className={s.ProjectCollabPopup} visible={visible} closeOnOutsideClick={false} onClose={onClose}>
      <Popup.Header className={s.ProjectCollabPopup_header}>
        <h3>Collaborate</h3>
      </Popup.Header>
      <Popup.Body>
        <div className={`${s.ProjectCollabPopup_box} loader-container`}>
          {isLoading && <Loader />}
          <div className={s.ProjectCollabPopup_box_selectWorkers}>
            <label htmlFor="members">Select members</label>
            <AsyncProjectWorkerSelect
              isMulti
              isCreatable
              isClearable={false}
              id="members"
              formatCreateLabel={(inputValue) => `Send to "${inputValue}"`}
              isValidNewOption={(inputValue) => validateInputValue(inputValue?.trim())}
              onCreateOption={onAddInvitedWorker}
              closeMenuOnSelect={true}
              controlShouldRenderValue={false}
              loadingPlaceholder="Loading..."
              placeholder="Enter member name..."
              className={`react-select override-menu-height`}
              projectId={projectId}
              onChange={selectWorkers}
              value={selectedWorkers}
            />
          </div>
          <div className={s.selectedWorkers}>
            <div className={s.selectedWorkers__header}>
              <span className={s.selectedWorkers__title}>Send to</span>
            </div>
            <div className={s.selectedWorkers_list}>
              {selectedWorkers.length ? (
                selectedWorkers.map(({value: cw, label}) => (
                  <div key={cw.workerFull.id} className={s.selectedWorkers_list__item}>
                    <Avatar
                      className="avatar worker-accepted__info-part worker-accepted__info-part--avatar"
                      src={cw?.workercard?.profilePicUrl}
                    />
                    <div className={s.selectedWorkers_list__item__userInfo}>
                      <span>{label}</span>
                      {cw?.workerFull.email && (
                        <span className={s['selectedWorkers_list__item__userInfo--email']}>{cw.workerFull.email}</span>
                      )}
                    </div>
                    <div className={s.selectedWorkers_list__item_actions}>
                      <Button
                        iconOnly
                        icon={<Icon size={24} colorFill name="clear" style={{fill: '#7a7a7a'}} />}
                        onClick={() => unselectWorker(cw)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className={s.selectedWorkers__placeholder}>No one selected</div>
              )}
            </div>
          </div>
          <div className={s.collabNote}>
            <span className={s.collabNote__title}>Message</span>
            <FieldInline
              id="collabNote"
              className={s.collabNote__textarea}
              element="textarea"
              placeholder="Enter note..."
              name="note"
              value={note}
              onChange={onChangeNote}
            />
            <span className={s.collabNote__charCounter}>{note.length}/128</span>
          </div>

          <div className={s.ProjectCollabPopup_box_actions}>
            <CtrlButton icon="copy" color="second" disabled={true}>
              Link
            </CtrlButton>
            <CtrlButton color="default" disabled={!selectedWorkers.length} onClick={sendCollabRequest}>
              Send
            </CtrlButton>
          </div>
        </div>
      </Popup.Body>
    </Popup>
  );
};
export default ProjectCollabPopup;
