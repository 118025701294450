import React from 'react';
import {CellProps} from 'react-table';

import {CenteredContainer} from 'shared/components/Table/components/Columns/Centered';
import {CompanyWorkerRole} from 'shared/models/worker';

export const TextArrayColumn = ({value}: CellProps<CompanyWorkerRole[]>) => {
  return (
    <CenteredContainer>
      <span dangerouslySetInnerHTML={{__html: value?.join(', ')}} />
    </CenteredContainer>
  );
};
