import {useMemo} from 'react';
import {useQuery, useQueryClient} from 'react-query';

import {ProjectsApi, TasksApi} from 'api';
import {useMount} from 'shared/hooks/core';

const BLACK_LIST = ['*'];

export interface UseWorkflowStatusesParams {
  getById: string;
  shared?: boolean;
  enabled?: boolean;
}

export function useWorkflowStatuses({getById, enabled = true, shared}: UseWorkflowStatusesParams) {
  const {data: workflow, isLoading} = useQuery(
    [shared ? 'taskWorkflow' : 'workflow', getById],
    () => (shared ? TasksApi.getSharedTaskWorkflow(getById) : ProjectsApi.getWorkflow(getById)),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: !!getById && enabled,
      staleTime: 5 * 60 * 1000,
    },
  );
  const statuses = useMemo(
    () =>
      workflow
        ? Array.from(
            new Set(
              workflow.reduce((acc, cur) => {
                return acc.concat(cur.fromStatus).concat(cur.toStatus);
              }, []),
            ),
          ).filter((status) => !BLACK_LIST.includes(status))
        : [],
    [workflow],
  );
  return [statuses, isLoading] as const;
}

export function usePrefetchWorkFlowStatuses(projectId?: string) {
  const client = useQueryClient();
  useMount(() => {
    client.prefetchQuery(['workflow', projectId], () => ProjectsApi.getWorkflow(projectId));
  });
}
