import * as Sentry from '@sentry/browser';
import {multiFactor, PhoneAuthProvider} from 'firebase/auth';
import {ChangeEvent, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import FirebaseService from 'services/Firebase';
import {Button, FormControl, Icon, Loader, PhoneInput, useAuth, useConfirm} from 'shared/components';
import {ConfirmConfig} from 'shared/components/Confirmation/useConfirm/state';
import CountryCodeSelector from 'shared/components/CoreForm/CountryCodeSelector';
import {CtrlSwitch} from 'shared/components/CoreNewUI';
import {PHONE_MASKS} from 'shared/constants/common';
import {getHumanizedFbError, isAuthRecentLoginError, isFirebaseError} from 'shared/constants/firebase';
import useCountDown from 'shared/helpers/hooks/useCountDown';
import {useRecaptcha} from 'shared/helpers/hooks/useRecaptcha';
import {useUnmount} from 'shared/hooks/core';

import ReAuthUserPopup from '../ReAuthUserPopup';

import s from './TwoFactorAuthSettings.module.scss';

const RECAPTCHA_CONTAINER_ID = 'register-2fa-container';
const CHECK_EMAIL_INTERVAL = 5 * 1000;

const TwoFactorAuthSettings = () => {
  const {user} = useAuth();
  const {t} = useTranslation(['sign_in', 'profile', 'errors']);
  const isEnabled2fa = multiFactor(user).enrolledFactors.length > 0;
  const [toggled, setToggled] = useState(isEnabled2fa);
  const recaptcha = useRecaptcha();

  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState<string>(
    isEnabled2fa ? FirebaseService.getPhoneNumberFromEnrolled2Fa()[0] : PHONE_MASKS[0].code,
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    isEnabled2fa ? FirebaseService.getPhoneNumberFromEnrolled2Fa()[1] : '',
  );
  const [verificationID, setVerificationID] = useState(null);
  const [isEmailLinkSent, setIsEmailLinkSent] = useState(false);
  const isEmailVerified = user.emailVerified;
  const {confirm, accept} = useConfirm();

  useLayoutEffect(() => {
    if (isEnabled2fa) {
      const [code, phone] = FirebaseService.getPhoneNumberFromEnrolled2Fa(true);
      setPhoneNumber(phone);
      setCountryCode(code);
    }
  }, []);

  const {counter: phoneCodeCounter, start: startPhoneCounter, reset: resetPhoneCounter} = useCountDown(30);
  const {counter: emailLinkCounter, start: startEmailLinkCounter, reset: resetEmailLinkCounter} = useCountDown(60);

  const checkEmailInterval = useRef(null);
  const phoneCtrlRef = useRef(null);
  const verifyCodeInputRef = useRef(null);

  useUnmount(() => {
    if (checkEmailInterval.current) {
      clearInterval(checkEmailInterval.current);
    }
  });

  const startIntervalToCheckEmail = () => {
    if (checkEmailInterval.current) {
      clearInterval(checkEmailInterval.current);
    }

    checkEmailInterval.current = setInterval(async () => {
      await user.reload();
      if (user.emailVerified) {
        clearInterval(checkEmailInterval.current);
      }
    }, CHECK_EMAIL_INTERVAL);
  };

  const sendEmailVerificationLink = async () => {
    if (isEmailVerified || (isEmailLinkSent && emailLinkCounter)) return null;
    try {
      await FirebaseService.sendVerificationEmailLink();
      setIsEmailLinkSent(true);

      // timer
      resetEmailLinkCounter();
      startEmailLinkCounter();
      startIntervalToCheckEmail();

      toast.success(t('profile:toast.success.email_link'));
    } catch (e) {
      console.error(e);
      if (isFirebaseError(e)) {
        toast.error(e.message);
      }
    }
  };

  const sendVerificationCode = async () => {
    if (user.emailVerified) {
      setIsLoading(true);
      const fullPhoneNumber = `${countryCode}${phoneNumber}`;
      const recaptchaVerifier = recaptcha.create(RECAPTCHA_CONTAINER_ID, fullPhoneNumber);

      const multiFactorUser = multiFactor(user);
      const multiFactorSession = await multiFactorUser.getSession();
      const phoneInfoOptions = {
        phoneNumber: fullPhoneNumber,
        session: multiFactorSession,
      };
      try {
        const verifyId = await FirebaseService.getPhoneAuthProvider().verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier,
        );
        setVerificationID(verifyId);

        // timer
        resetPhoneCounter();
        startPhoneCounter();

        verifyCodeInputRef.current.focus();
      } catch (e) {
        if (isAuthRecentLoginError(e)) {
          if (await showReAuthDialog()) {
            recaptcha.destroy(recaptchaVerifier, RECAPTCHA_CONTAINER_ID);
            sendVerificationCode();
            return;
          }
        }
        toast.error(getHumanizedFbError(e, t));
        Sentry.captureException(e);
      } finally {
        setIsLoading(false);
        recaptcha.destroy(recaptchaVerifier, RECAPTCHA_CONTAINER_ID);
      }
    } else {
      toast.error(t('profile:toast.error.verify_email'));
    }
  };

  const verifyCode = async (code: string) => {
    try {
      setIsLoading(true);
      const cred = PhoneAuthProvider.credential(verificationID, code);
      const multiFactorAssertion = FirebaseService.phoneMultiFactorGenerator.assertion(cred);
      await multiFactor(user).enroll(multiFactorAssertion, 'My personal phone number');
    } catch (e) {
      toast.error(getHumanizedFbError(e, t));
    } finally {
      verifyCodeInputRef.current.disabled = false;
      verifyCodeInputRef.current.focus();
      setIsLoading(false);
    }
  };

  const getEmailActionText = useMemo(() => {
    if (isEmailVerified) return 'Verified';
    if (!isEmailLinkSent) return 'Send Link';
    return !emailLinkCounter ? `Resend link` : `Resend in ${emailLinkCounter}`;
  }, [isEmailVerified, isEmailLinkSent, emailLinkCounter]);

  const onChangeVerificationCodeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    if (value.length === 6) {
      verifyCode(value);
      e.target.blur();
      e.target.value = '';
      e.target.disabled = true;
    }
  };

  const disable2Fa = async () => {
    const multiFactorUser = multiFactor(user);
    if (multiFactorUser.enrolledFactors.length > 0) {
      const enrolledFactor = multiFactorUser.enrolledFactors[0];
      await multiFactorUser.unenroll(enrolledFactor);
    }
  };

  const toggle2FASwitcher = async () => {
    // it means we should disable
    if (isEnabled2fa && toggled) {
      if (!(await confirm(t('profile:confirm.disable_2fa')))) return;
      try {
        await tryToDisable2Fa();
      } catch (e) {
        if (isAuthRecentLoginError(e)) {
          return (await showReAuthDialog()) ? tryToDisable2Fa() : null;
        }
      } finally {
        setIsLoading(false);
      }
    }
    setToggled((prev) => !prev);
  };

  const tryToDisable2Fa = async () => {
    setIsLoading(true);
    try {
      await disable2Fa();
      toast.success(t('profile:toast.success.2fa_disabled'));
    } catch (e) {
      toast.error(getHumanizedFbError(e, t));
    } finally {
      setIsLoading(false);
    }
  };

  const showReAuthDialog = async (): Promise<boolean> => {
    const payload: ConfirmConfig = {
      component: <ReAuthUserPopup onSuccessAuth={accept} />,
      title: 'Please, reauthenticate to continue',
    };
    return (await confirm(payload)) as boolean;
  };

  return (
    <>
      <div className={s.TwoFactorAuthSettings}>
        <div className={s.TwoFactorAuthSettings__header}>
          <span className={s.TwoFactorAuthSettings__title}>{t('profile:2fa.title', '2FA SMS Authentication')}</span>
          <CtrlSwitch label={toggled ? 'Enabled' : 'Disabled'} checked={toggled} onChange={toggle2FASwitcher} />
        </div>
        {toggled && (
          <div className={`${s.TwoFactorAuthSettings__body} loader-container`}>
            {isLoading && <Loader />}
            <div className="form-onboarding-z__item">
              <FormControl
                label={!isEmailVerified ? t('profile:2fa.must_verify_email') : t('sign_in:fields.email.label')}
                name="email"
                className="ctrl-form--button-link"
              >
                <input value={user.email} className="ctrl-textfield" readOnly={true} />
                <Button
                  className="ctrl-btn-link ctrl-btn-link--size-m ctrl-form__button-link"
                  type="button"
                  style={isEmailVerified ? {color: 'green'} : null}
                  icon={<Icon colorFill name={isEmailVerified ? 'verified' : 'link'} />}
                  onClick={() => !isEmailVerified && sendEmailVerificationLink()}
                >
                  {getEmailActionText}
                </Button>
              </FormControl>
            </div>
            <div className="form-onboarding-z__item">
              <div className="ctrl-phone" ref={phoneCtrlRef}>
                <div className="ctrl-phone__item ctrl-phone__item--code">
                  <FormControl name="countryCode" label={t('sign_in:fields.phone_code.label', 'Mobile Phone Number')}>
                    <CountryCodeSelector
                      disabled={!isEmailVerified || isEnabled2fa}
                      onChange={setCountryCode}
                      name="countryCode"
                      initialValue={countryCode}
                      width={phoneCtrlRef.current?.clientWidth}
                    />
                  </FormControl>
                </div>
                <div className="ctrl-phone__item ctrl-phone__item--number">
                  <FormControl
                    className="ctrl-form--button-link"
                    name="phoneNumber"
                    label={t('sign_in:fields.phone_number.label', 'Phone Number')}
                  >
                    <PhoneInput
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber((e.target as HTMLInputElement).value)}
                      countryCode={countryCode}
                      className={`ctrl-textfield ${
                        !isEmailVerified || isEnabled2fa ? s['TwoFactorAuthSettings__phoneInput--disabled'] : ''
                      }`}
                      disabled={isEnabled2fa}
                      placeholder={t('sign_in:fields.phone_number.placeholder', 'Enter Phone Number')}
                    />

                    <Button
                      className="ctrl-btn-link ctrl-btn-link--size-m ctrl-form__button-link"
                      type="button"
                      style={isEnabled2fa ? {color: 'green'} : null}
                      icon={isEnabled2fa ? <Icon colorFill name="verified" /> : null}
                      onClick={() => !isEnabled2fa && sendVerificationCode()}
                    >
                      {isEnabled2fa
                        ? 'Verified'
                        : !verificationID
                        ? t('sign_in:buttons.send_code', 'Send')
                        : !phoneCodeCounter
                        ? t('sign_in:buttons.resend_code', 'Resend')
                        : `${t('sign_in:buttons.resend_code_in', 'Resend in')} ${phoneCodeCounter}s`}
                    </Button>
                  </FormControl>
                </div>
              </div>
            </div>
            {verificationID && !isEnabled2fa && (
              <div className="form-onboarding-z__item">
                <FormControl
                  name="verificationCode"
                  label={t('sign_in:fields.verification_code.label', 'Enter the code we`ve sent to your number')}
                >
                  <input
                    ref={verifyCodeInputRef}
                    className="ctrl-textfield"
                    type="number"
                    onChange={onChangeVerificationCodeInput}
                    placeholder={t('sign_in:fields.verification_code.placeholder', 'Enter the code')}
                  />
                </FormControl>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default TwoFactorAuthSettings;
