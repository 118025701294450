import * as Sentry from '@sentry/browser';
import axios from 'axios';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';

import {ProjectsApi} from 'api';

type UseCloneProjectProps = {
  id: string;
  projectName: string;
};

export const useCloneProject = ({id, projectName}: UseCloneProjectProps) => {
  const {t} = useTranslation();

  const mutation = useMutation(
    async () => await ProjectsApi.cloneProject(id, t('project:cloning.new_project_name', {projectName})),
    {
      onError: (error) => {
        Sentry.captureException(error);
        if (axios.isAxiosError(error)) {
          toast.error(error.response?.data?.message || error.message);
        }
      },
    },
  );

  return mutation;
};
