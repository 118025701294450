import {TFunction} from 'i18next';

import {TaskObjectType, TaskStatus} from 'shared/models/task';

export enum GANTT_COLUMNS_NAMES {
  activityNames = 'activityNames',
  actualEnd = 'actual_end',
  actualStart = 'actual_start',
  assignmentCount = 'assignment_count',
  averageLaborAbbr = 'average_labor_abbr',
  baselineEnd = 'baseline_end',
  baselineStart = 'baseline_start',
  checkbox = 'custom_checkbox',
  comments = 'comments',
  costCode = 'cost_code',
  costImpact = 'costImpact',
  csiCode = 'csi_code',
  customAdd = 'custom_add',
  customCode = 'custom_code',
  customFields = 'customFields',
  description = 'description',
  doneDate = 'doneDate',
  duration = 'taskDuration',
  endDate = 'end_date',
  estimatedLaborAbbr = 'estimated_labor_abbr',
  estLaborHours = 'est_labor_hours',
  icons = 'icons',
  inprogressDate = 'inprogressDate',
  issueType = 'issue_type',
  location = 'location',
  name = 'name',
  ownership = 'culpable_org_id',
  phaseCode = 'phase_code',
  predecessor = 'predecessor',
  progress = 'progress',
  punchList = 'punchList',
  resolvingCompany = 'responsible_org_id',
  responsible = 'responsible',
  rownumber = 'rownumber',
  severity = 'impact',
  startDate = 'start_date',
  subcontractor = 'responsible_org_id',
  taskStatus = 'task_status',
  trackNumber = 'cost_tracking_number',
  type = 'taskType',
  uniqueId = 'unique_id',
  variance = 'variance',
}

export enum GANTT_COLUMNS_NAMES_EXTRA {
  baselineStart = 'baseline_start',
  baselineEnd = 'baseline_end',
  variance = 'variance',
}

export enum EditActionTypes {
  date = 'date',
  status = 'status',
  subcontractor = 'subcontractor',
  taskType = 'taskType',
  predecessor = 'predecessor',
}

export const SAFE_STATUSES: TaskStatus[] = [
  TaskStatus.approved,
  TaskStatus.done,
  TaskStatus.verified,
  TaskStatus.closed,
];

export const getGanttColumnsLabels = (
  t: TFunction,
): {
  [key in Exclude<
    GANTT_COLUMNS_NAMES | GANTT_COLUMNS_NAMES_EXTRA,
    GANTT_COLUMNS_NAMES.checkbox | GANTT_COLUMNS_NAMES.customAdd | GANTT_COLUMNS_NAMES.customFields
  >]: string;
} => {
  return {
    [GANTT_COLUMNS_NAMES.activityNames]: t('gantt:columns.activity_names'),
    [GANTT_COLUMNS_NAMES.uniqueId]: t('gantt:columns.unique_id', 'Unique ID'),
    [GANTT_COLUMNS_NAMES.rownumber]: t('gantt:columns.row_number', '#'),
    [GANTT_COLUMNS_NAMES.icons]: t('gantt:columns.icons', 'Icons'),
    [GANTT_COLUMNS_NAMES.assignmentCount]: t('gantt:columns.assignees', 'Watchers'),
    [GANTT_COLUMNS_NAMES.punchList]: t('gantt:columns.punch_list', 'Punch list'),
    [GANTT_COLUMNS_NAMES.startDate]: t('gantt:columns.start', 'Start'),
    [GANTT_COLUMNS_NAMES.endDate]: t('gantt:columns.end', 'End'),
    [GANTT_COLUMNS_NAMES.taskStatus]: t('gantt:columns.status', 'Status'),
    [GANTT_COLUMNS_NAMES.name]: t('gantt:columns.gantt.activity', 'Activity'),
    [GANTT_COLUMNS_NAMES.subcontractor]: t('gantt:columns.gantt.subcontractor', 'Company'),
    [GANTT_COLUMNS_NAMES.description]: t('gantt:columns.description', 'Notes'),
    [GANTT_COLUMNS_NAMES.location]: t('gantt:columns.location', 'Location'),
    [GANTT_COLUMNS_NAMES.responsible]: t('gantt:columns.responsible', 'Responsible'),
    [GANTT_COLUMNS_NAMES.type]: t('gantt:columns.type', 'Type'),
    [GANTT_COLUMNS_NAMES.actualStart]: t('gantt:columns.actual_start', 'Actual Start'),
    [GANTT_COLUMNS_NAMES.actualEnd]: t('gantt:columns.actual_end', 'Actual End'),
    [GANTT_COLUMNS_NAMES.duration]: t('gantt:columns.duration', 'Duration'),
    [GANTT_COLUMNS_NAMES.predecessor]: t('gantt:columns.predecessor', 'Predecessor'),
    [GANTT_COLUMNS_NAMES_EXTRA.baselineStart]: t('gantt:columns.baseline_start', 'Baseline Start'),
    [GANTT_COLUMNS_NAMES_EXTRA.baselineEnd]: t('gantt:columns.baseline_end', 'Baseline End'),
    [GANTT_COLUMNS_NAMES_EXTRA.variance]: t('gantt:columns.variance', 'Variance'),
    [GANTT_COLUMNS_NAMES.comments]: t('gantt:columns.comments'),
    [GANTT_COLUMNS_NAMES.estimatedLaborAbbr]: t('gantt:columns.estimated_labor_abbr', 'Estimated Labor'),
    [GANTT_COLUMNS_NAMES.averageLaborAbbr]: t('gantt:columns.average_labor_abbr', 'Average Labor'),
    [GANTT_COLUMNS_NAMES.progress]: t('gantt:columns.progress', 'Progress'),
    [GANTT_COLUMNS_NAMES.csiCode]: t('gantt:columns.csi_code', 'CSI Code'),
    [GANTT_COLUMNS_NAMES.phaseCode]: t('gantt:columns.phase_code', 'Phase Code'),
    [GANTT_COLUMNS_NAMES.costCode]: t('gantt:columns.cost_code', 'Cost Code'),
    [GANTT_COLUMNS_NAMES.customCode]: t('gantt:columns.custom_code', 'Custom Code'),
    [GANTT_COLUMNS_NAMES.ownership]: t('gantt:columns.ownership', 'Liable Company'),
    [GANTT_COLUMNS_NAMES.severity]: t('gantt:columns.impact', 'Time Impact'),
    [GANTT_COLUMNS_NAMES.issueType]: t('gantt:columns.issue_type', 'Type'),
    [GANTT_COLUMNS_NAMES.trackNumber]: t('gantt:columns.cost_tracking_number', 'Cost Track Number'),
    [GANTT_COLUMNS_NAMES.costImpact]: t('gantt:columns.cost_impact', 'Cost Impact'),
    [GANTT_COLUMNS_NAMES.inprogressDate]: t('gantt:columns.in_progress_date', 'In-progress Date'),
    [GANTT_COLUMNS_NAMES.doneDate]: t('gantt:columns.done_date', 'Done Date'),
    [GANTT_COLUMNS_NAMES.estLaborHours]: t('gantt:columns.estimated_labor_hours'),
  };
};

export const SELECTED_GANTT_COLUMNS = 'selectedGanttColumns_v3';
export const GANTT_COLUMNS_SETTINGS = 'ganttColumnsSettings';
export const getActivityName = (t: TFunction) => t('gantt:activity.name', 'New Activity');

export enum DataProcessorAction {
  Inserted = 'inserted',
  Updated = 'updated',
  Deleted = 'deleted',
  Invalid = 'invalid',
  Error = 'error',
}

export const NOT_EDITABLE_COLUMNS: {[key in TaskObjectType]?: GANTT_COLUMNS_NAMES[]} = {
  [TaskObjectType.summary]: [
    GANTT_COLUMNS_NAMES.actualStart,
    GANTT_COLUMNS_NAMES.actualEnd,
    GANTT_COLUMNS_NAMES.startDate,
    GANTT_COLUMNS_NAMES.endDate,
    GANTT_COLUMNS_NAMES.duration,
    GANTT_COLUMNS_NAMES.predecessor,
    GANTT_COLUMNS_NAMES.responsible,
    GANTT_COLUMNS_NAMES.subcontractor,
    GANTT_COLUMNS_NAMES.location,
    GANTT_COLUMNS_NAMES.type,
    GANTT_COLUMNS_NAMES.taskStatus,
    GANTT_COLUMNS_NAMES.inprogressDate,
    GANTT_COLUMNS_NAMES.doneDate,
    GANTT_COLUMNS_NAMES.estimatedLaborAbbr,
    GANTT_COLUMNS_NAMES.averageLaborAbbr,
    GANTT_COLUMNS_NAMES.progress,
    GANTT_COLUMNS_NAMES.comments,
  ],
  [TaskObjectType.milestone]: [GANTT_COLUMNS_NAMES.location, GANTT_COLUMNS_NAMES.subcontractor],
};

export const NON_HIDING_COLUMNS = [
  GANTT_COLUMNS_NAMES.customAdd,
  GANTT_COLUMNS_NAMES.checkbox,
  GANTT_COLUMNS_NAMES.name,
  GANTT_COLUMNS_NAMES.rownumber,
];

export const GANTT_LOCALE_MAP = {
  ja: 'jp',
  es: 'es',
  en: 'en',
  pt: 'pt',
};
export const PERSONAL_PROJECT_NAME = 'Personal Project';

export enum GanttZoomLevels {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}
