import 'reflect-metadata';
import {ErrorBoundary} from '@sentry/react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {Provider} from 'react-redux';
import {Router} from 'react-router';
import {ToastContainer} from 'react-toastify';

import {initApiInterceptors} from 'api/axios';
import {container} from 'IocContainer';
import Routes from 'MainRoutes';
import {initI18n} from 'react-i18next.config';
import 'react-toastify/dist/ReactToastify.css';
import {routerHistory} from 'routerHistory';
import EventManager from 'services/EventManager';
import Firebase from 'services/Firebase';
import {TasksObserverProvider} from 'services/TasksObserver';
import {ConformationProvider} from 'shared/components';
import AuthUserProvider from 'shared/components/AuthUserProvider';
import {initDayJs} from 'shared/helpers/dates';
import {initSentry} from 'shared/helpers/sentry';
import {InjectionProvider} from 'shared/providers/injection';
import store from 'store';
import 'shared/helpers/mobx';

initSentry();
initDayJs();
initI18n();
initApiInterceptors(Firebase);

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (_failureCount, error) => {
        if (typeof error === 'object' && error !== null && 'response' in error && error.response) {
          return ![401, 403, 404].includes(error?.response?.['status']);
        }
        return true;
      },
    },
  },
});

EventManager.initPixel();
EventManager.initMixpanel();

const App = () => {
  return (
    <QueryClientProvider client={reactQueryClient}>
      <InjectionProvider container={container}>
        <TasksObserverProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <Router history={routerHistory}>
            <Provider store={store}>
              <AuthUserProvider>
                <ErrorBoundary showDialog>
                  <ConformationProvider>
                    <Routes />
                  </ConformationProvider>
                </ErrorBoundary>
              </AuthUserProvider>
            </Provider>
            <ToastContainer
              autoClose={4000}
              className="Toastify-core"
              hideProgressBar={false}
              pauseOnFocusLoss={false}
              position="bottom-right"
              style={{zIndex: 99999}}
            />
          </Router>
        </TasksObserverProvider>
      </InjectionProvider>
    </QueryClientProvider>
  );
};

export default App;
