/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/naming-convention */
import * as Sentry from '@sentry/browser';
import {User, UserCredential} from 'firebase/auth';

import {convertKeys} from 'shared/helpers/common';
import {isValidDate, toShortIso} from 'shared/helpers/dates';
import {CompanyModel} from 'shared/models/company';
import {CompanyWorkerSmall, Worker, WorkerCertificate, WorkerExperience} from 'shared/models/worker';

import ApiAxios from './axios';

const BASE_PATH = '/workers';
const SIGNUP_PATH = '/loginOrSignupWorker';
const CHECK_SIGNUP = '/checksignup';
const TRADE_OPTIONS = '/trades';

export type SupportedLoginTypes = 'phone' | 'password' | 'anonymous';

export type TokenLoginResponse = {
  firebaseToken?: string;
  firebaseUid?: string;
  errors?: string[];
  authPrefill?: {
    email?: string;
    phoneNumber?: string;
  };
};

interface LoginOrSignupWorkerParams {
  user: User;
  idToken?: string;
  loginType?: SupportedLoginTypes;
  phoneNumber?: string;
  inviteCode?: string;
}

function mapSignupFields({user, loginType, phoneNumber, inviteCode}: LoginOrSignupWorkerParams) {
  const signupFields = {
    api_key: process.env.REACT_APP_FIREBASE_API_KEY,
    refresh_token: user.refreshToken,
    firebase_uid: user.uid,
    last_login_at: new Date(user.metadata.lastSignInTime).getTime(),
    created_at: new Date(user.metadata.creationTime).getTime(),
    expiration_time: Date.parse(user.metadata.lastSignInTime) + 3600000,
    login_type: loginType === 'password' ? 'email' : loginType,
    source: 'web',
  };
  if (inviteCode) Object.assign(signupFields, {invite_code: inviteCode});
  if (loginType === 'phone') {
    Object.assign(signupFields, {phone_number: user.phoneNumber});
  }
  if (loginType === 'password') {
    Object.assign(signupFields, {email: user.email, phone_number: phoneNumber});
  }

  return signupFields;
}

export function transformWorkerToCamel(workerObject: Worker) {
  let worker = {...workerObject};
  worker = convertKeys('camel', worker);
  return worker;
}

export default {
  getWorkerById(id: string) {
    return ApiAxios.get<Worker>(`${BASE_PATH}/${id}`)
      .then((res) => {
        return transformWorkerToCamel(res.data);
      })
      .catch((res) => Promise.reject(res));
  },

  loginOrSignupWorker({user, idToken, loginType = 'phone', phoneNumber, inviteCode}: LoginOrSignupWorkerParams) {
    return ApiAxios.post<Worker>(
      SIGNUP_PATH,
      mapSignupFields({
        user,
        loginType,
        phoneNumber,
        inviteCode,
      }),
      {
        headers: {
          Authorization: idToken ? `Bearer ${idToken}` : undefined,
        },
      },
    )
      .then((res) => {
        return transformWorkerToCamel(res.data) as Worker;
      })
      .catch((res) => {
        Sentry.setExtra('user', {...user});
        Sentry.captureException(res);
        return Promise.reject(res);
      });
  },

  getTradeOptions(): Promise<string[]> {
    return ApiAxios.get<string[]>(TRADE_OPTIONS).then((res) => res.data);
  },

  loginOrSignupAnonymWorker(signup: UserCredential, idToken: string) {
    return ApiAxios.post<Worker>(
      SIGNUP_PATH,
      mapSignupFields({
        user: signup.user,
        idToken: idToken,
        loginType: 'anonymous',
      }),
    )
      .then((res) => {
        const worker = res.data;
        return convertKeys('camel', worker);
      })
      .catch((res) => Promise.reject(res));
  },

  updateWorkerData(workerResume: Worker, id: string, token?: string) {
    const model = {...workerResume};
    if (model.certifications) {
      model.certifications = model.certifications.map((certificate: WorkerCertificate) => {
        return {
          ...certificate,
          issuedDate: isValidDate(certificate?.issuedDate) ? toShortIso(certificate?.issuedDate) : undefined,
          expirationDate: isValidDate(certificate?.expirationDate)
            ? toShortIso(certificate?.expirationDate)
            : undefined,
        } as any; // provide correct typing later
      });
    }
    if (model.workExperiences) {
      model.workExperiences = model.workExperiences.map((item: WorkerExperience) => {
        return {
          ...item,
          startDate: isValidDate(item.startDate) ? toShortIso(item.startDate) : undefined,
          endDate: isValidDate(item.endDate) ? toShortIso(item.endDate) : undefined,
        } as any; // provide correct typing later
      });
    }

    const converted = convertKeys('snake', model);

    return ApiAxios.post<Worker>(`${BASE_PATH}/${id}`, convertKeys('snake', converted), {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    })
      .then((res) => {
        return transformWorkerToCamel(res.data);
      })
      .catch((errors) => {
        Sentry.setExtra('workerResume', {...workerResume, id});
        Sentry.captureException(errors);
        throw errors;
      });
  },

  uploadWorkerImage(id: string, workerAvatar: File) {
    const payload = new FormData();
    payload.append('profilepic', workerAvatar);
    return ApiAxios.post<Worker>(`${BASE_PATH}/${id}/profilepic`, payload, {
      headers: {'Content-Type': 'multipart/form-data'},
    }).then((res) => res.data);
  },

  closeWorkerAccout(id: string) {
    return ApiAxios.delete(`${BASE_PATH}/${id}`).catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
  },

  checkSignUp(firebaseUID: string): Promise<boolean> {
    return ApiAxios.get(`${CHECK_SIGNUP}/${firebaseUID}`).then((res) => res.data);
  },

  getWorkerCompanies(workerId: string) {
    return ApiAxios.get<CompanyModel[]>(`${BASE_PATH}/${workerId}/companies`).then((res) => res.data);
  },

  getWorkerCompanyWorkers(workerId: string) {
    return ApiAxios.get<CompanyWorkerSmall[]>(`${BASE_PATH}/${workerId}/companyworkers`, {
      params: {
        smallObject: true,
      },
    }).then((res) => res.data);
  },

  loginWithToken(ownerId: string, token: string) {
    return ApiAxios.post<TokenLoginResponse>(`/loginWithToken/${ownerId}/${token}`).then((res) => res.data);
  },

  getSharedLoginToken(workerId: string, taskId: string) {
    return ApiAxios.post<{token: string; url: string}>(`/workers/${workerId}/sharedlogin`, {
      scopes: ['specific_task'],
      taskIds: [taskId],
    }).then((res) => res.data);
  },

  updateWorker(worker: Partial<Worker>) {
    return ApiAxios.post<Worker>(`${BASE_PATH}/${worker.id}`, worker).then((res) => res.data);
  },
};
