import cn from 'classnames';
import {GanttStatic} from 'dhtmlx-gantt';

import SpriteSVG from 'assets/images/svg-sprite.svg';
import s from 'modules/Landing/components/Icon/Icon.module.scss';
import {GanttPluginBase} from 'modules/Tasks/components/Gantt/plugins/ganttPlugin';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {classify} from 'modules/Tasks/DailyRisk/TaskFlagClassifier';

export class StatusIconPluginFact extends GanttPluginBase {
  constructor(gantt: GanttStatic) {
    super(gantt);
  }

  init() {
    this.gantt.statusIcon = new StatusIconPlugin(this.gantt);
  }

  clear() {
    delete this.gantt.statusIcon;
  }
}

export class StatusIconPlugin {
  private gantt: GanttStatic;
  private todayStr: string;

  constructor(gantt: GanttStatic) {
    this.gantt = gantt;
    this.todayStr = new Date().toISOString().split('T')[0];
  }

  getHasFlagsIconHTML(task: GanttTask) {
    const className = cn(s.icon);

    const flags = classify(
      this.gantt.weatherReport?.forecast?.forecastday[0],
      this.gantt.labelCategories,
      task,
      this.todayStr,
      null,
    );
    if (flags.length) {
      return `
        <svg style="margin-bottom: 3px" class="${className} icon--color-fill" >
          <title>Appears on Daily risk report</title>
          <use xlink:href="${SpriteSVG}#warning-outline" />
        </svg>
      `;
    }
    return '';
  }
}

declare module 'dhtmlx-gantt' {
  export interface GanttCustomProperties {
    statusIcon?: StatusIconPlugin;
  }
}
