import cn from 'classnames';
import {cloneElement, isValidElement, ReactNode, useState} from 'react';

import {useEffectAfterMount} from 'shared/hooks/core';

import s from './Collpase.module.scss';

interface CollapseProps {
  children: ReactNode;
  defaultOpen?: boolean;
  toggleElement: (isOpen: boolean, toggle: () => void) => ReactNode;
  className?: string;
  onChange?: (isOpen: boolean) => void;
}

export function Collapse({children, className, defaultOpen = false, toggleElement, onChange}: CollapseProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffectAfterMount(() => {
    onChange?.(isOpen);
  }, [isOpen]);
  return (
    <div className={className}>
      {toggleElement(isOpen, () => setIsOpen((open) => !open))}
      <div className={cn(s.collapse, isOpen && s.isOpen)}>
        {isValidElement<{className: string}>(children) &&
          cloneElement(children, {
            className: cn(children.props.className, s.inner),
          })}
      </div>
    </div>
  );
}
