import {Field, FieldProps, Formik, FormikProps} from 'formik';
import {FC, useRef, useState} from 'react';
import {trackCustom} from 'react-facebook-pixel';
import {useTranslation} from 'react-i18next';
import {batch} from 'react-redux';
import {generatePath, useHistory} from 'react-router';

import EventManager from 'services/EventManager';
import Firebase from 'services/Firebase';
import {FormControl, Geocoder, Loader} from 'shared/components';
import CoreSelectField from 'shared/components/CoreForm/Select/Select';
import ZetOnboardingContainer from 'shared/components/OnboardingLayout/ZetOnboardingContainer';
import {COMPANY_SIZE_OPTIONS, COUNTRY_CODES_MAPPING, getCountryOptions} from 'shared/constants/common';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {CreateCompanyProfileSchema} from 'shared/helpers/validationSchemas';
import {useAnalyticsService} from 'shared/hooks';
import {CompanyCreateRequest, ThirdStepForm} from 'shared/models/onboardingForms';
import {useRootDispatch, useRootSelector} from 'store';
import {createCompany} from 'store/company/actions';
import {profileActions} from 'store/profile';
import {loadWorkerCompanies, loadWorkerCompanyWorkers} from 'store/profile/actions';

import {setSignupInProgress} from '../../../store/onboarding';

const SecondStep: FC = () => {
  const {mixpanel, useZoomInfo} = useAnalyticsService({publicPage: true});
  useZoomInfo();
  const {t} = useTranslation(['signup', 'constants']);
  const dispatch = useRootDispatch();
  const worker = useRootSelector((state) => state.onboarding.worker);
  const company = useRootSelector((state) => state.onboarding.company);
  const formik = useRef<FormikProps<ThirdStepForm>>(null);
  const routes = useLocalizedRoutes();
  const mixpanelEvents = mixpanel.events.signup.steps.third;
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const loadCompaniesAndWorkers = (workerId: string) => {
    return Promise.all([dispatch(loadWorkerCompanies(workerId)), dispatch(loadWorkerCompanyWorkers(workerId))]);
  };

  async function afterSubmit() {
    await loadCompaniesAndWorkers(worker.id);

    batch(() => {
      dispatch(profileActions.setWorker(worker));
      dispatch(profileActions.showCongratsPopup());
      dispatch(setSignupInProgress(false));
    });

    Firebase.setWorkerIdToLS(worker.id);
    trackAnalyticsEvents();

    history.push(generatePath(routes.project, {id: 'new'}));
    setIsLoading(false);
  }

  // use defaultCountry according to phone number area only for US and Brazil numbers
  const defaultCountry = COUNTRY_CODES_MAPPING['+1'];

  const submitForm = async (values: ThirdStepForm) => {
    setIsLoading(true);
    try {
      const preparedValues: CompanyCreateRequest = {
        businessUrl: values.businessUrl,
        companyName: values.companyName,
        pitchText: values.pitchText,
        companyAddress: `${values.companyCity}, ${values.companyState}, ${values.companyCountry}`,
        companySize: values.companySize,
      };
      const res = await dispatch(createCompany({companyObject: preparedValues, workerId: worker.id}));

      if (createCompany.fulfilled.match(res)) {
        await afterSubmit();
        return;
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getFooter = () => {
    return (
      <footer className="form-onboarding-z__footer">
        <div className="form-onboarding-z__footer-actions form-onboarding-z__footer-actions--next">
          <button
            data-cy="signup_step2_submit_btn"
            className="ctrl-btn form-onboarding-z__footer-button"
            onClick={() =>
              mixpanel.trackWithAction(
                !company ? formik.current?.submitForm : afterSubmit,
                mixpanelEvents.buttons.submit,
              )
            }
            disabled={isLoading}
            type="button"
          >
            <span className="ctrl-btn__text">{t('signup:footer.submit_second_step', 'Submit')}</span>
          </button>
        </div>
      </footer>
    );
  };

  const trackAnalyticsEvents = () => {
    trackCustom('Sales_SignUp', {});
    window.lintrk('track', {conversion_id: 4704444});
    EventManager.pushToGtmDataLayer({event: 'c4-onboarding-company'});
  };

  return (
    <ZetOnboardingContainer
      title={!company ? t('signup:second_step.title', "Let's create a Company") : ''}
      description={!company ? t('signup:second_step.description', 'You can add and edit company info later') : ''}
      footer={getFooter()}
    >
      {!company ? (
        <Formik<ThirdStepForm>
          initialValues={{
            businessUrl: '',
            companyName: '',
            companyCountry: defaultCountry,
            companyState: '',
            companyCity: '',
            pitchText: '',
            companySize: '',
          }}
          onSubmit={submitForm}
          validationSchema={CreateCompanyProfileSchema}
          innerRef={formik}
        >
          {({values, setFieldValue}) => (
            <>
              <div className="form-onboarding-z__item">
                <FormControl name="companyName" label={t('signup:fields.companyName.label', 'Company Name')}>
                  <Field
                    data-cy="signup_company_name"
                    name="companyName"
                    className="ctrl-textfield"
                    placeholder={t('signup:fields.companyName.placeholder', 'Enter Company Name')}
                  />
                </FormControl>
              </div>
              <div className="form-onboarding-z__item">
                <FormControl name="businessUrl" label={t('signup:fields.businessUrl.label', 'Company Website')}>
                  <Field
                    data-cy="signup_company_url"
                    name="businessUrl"
                    className="ctrl-textfield"
                    placeholder={t('signup:fields.businessUrl.placeholder', 'Website URL')}
                  />
                </FormControl>
              </div>
              <div className="form-onboarding-z__item form-onboarding-z__item--third">
                <FormControl name="companyCountry" label={t('signup:fields.companyCountry.label', 'Country')}>
                  <Field name="companyCountry">
                    {({field, form}: FieldProps) => (
                      <CoreSelectField
                        {...field}
                        options={getCountryOptions(t)}
                        placeholder={t('signup:fields.companyCountry.placeholder', 'Select Country')}
                        onChange={(value) => {
                          form.setValues({...values, [field.name]: value, companyState: '', companyCity: ''});
                        }}
                      />
                    )}
                  </Field>
                </FormControl>
              </div>
              <div className="form-onboarding-z__item form-onboarding-z__item--third">
                <FormControl
                  name="companyState"
                  data-cy="signup_company_state"
                  label={t('signup:fields.companyState.label', 'State')}
                >
                  <Geocoder
                    onChanged={(item) => {
                      setFieldValue('companyState', item);
                    }}
                    placeholder={t('signup:fields.companyState.placeholder', 'Enter State')}
                    mapboxQueryTypes={['region']}
                    formatItem={(item) => item.text}
                    value={values.companyState}
                    hideOnSelect={true}
                    disableInput={!values.companyCountry}
                    allowCustom={true}
                    selectedCountry={values.companyCountry}
                    limit={3}
                  />
                </FormControl>
              </div>
              <div className="form-onboarding-z__item form-onboarding-z__item--third">
                <FormControl
                  name="companyCity"
                  data-cy="signup_company_city"
                  label={t('signup:fields.companyCity.label', 'City')}
                >
                  <Geocoder
                    onChanged={(item) => {
                      setFieldValue('companyCity', item);
                    }}
                    placeholder={t('signup:fields.companyCity.placeholder', 'Enter City')}
                    mapboxQueryTypes={['place']}
                    formatItem={(item) => item.text}
                    value={values.companyCity}
                    disableInput={!values.companyCountry || !values.companyState}
                    hideOnSelect={true}
                    allowCustom={true}
                    selectedCountry={values.companyCountry}
                    selectedState={values.companyState}
                    limit={3}
                  />
                </FormControl>
              </div>
              <div className="form-onboarding-z__item">
                <FormControl
                  data-cy="signup_company_description"
                  name="pitchText"
                  label={t('signup:fields.companyDescription.label', 'Company Description')}
                >
                  <Field
                    name="pitchText"
                    className="ctrl-textfield"
                    placeholder={t(
                      'signup:fields.companyDescription.placeholder',
                      'Enter a Short Description About Your Company',
                    )}
                  />
                </FormControl>
              </div>
              <div className="form-onboarding-z__item">
                <FormControl
                  name="companySize"
                  data-cy="signup_company_size"
                  label={t('signup:fields.companySize.label', 'Number of Workers')}
                >
                  <Field name="companySize">
                    {({field, form}: FieldProps) => (
                      <CoreSelectField
                        {...field}
                        options={COMPANY_SIZE_OPTIONS}
                        menuPortalTarget={document.body}
                        menuPlacement="top"
                        placeholder={t('signup:fields.companySize.placeholder', 'Select Number of Workers')}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                      />
                    )}
                  </Field>
                </FormControl>
              </div>
            </>
          )}
        </Formik>
      ) : (
        <div className="company-short">
          <header className="company-short__header">
            {company.logoUrl && (
              <picture className="avatar company-short__media">
                <img className="avatar__image" src={company.logoUrl} alt={company.companyName} />
              </picture>
            )}
            <div className="company-short__group">
              <div className="company-short__name">{company.companyName}</div>
              {!!company.currentSeatCount && (
                <div className="company-short__workers">
                  {company.currentSeatCount} {t('signup:second_step.invitation.workerQty', 'active workers')}
                </div>
              )}
            </div>
          </header>
          <div className="company-short__description">
            <p>
              {company.companyName} {t('signup:second_step.invitation.title', 'invited you to join their crew.')}
            </p>
            <p>
              {t(
                'signup:second_step.invitation.description',
                'You can manage and share activities, invite workers, see reports, and more!',
              )}
            </p>
          </div>
          <div className="company-short__actions">
            <button className="ctrl-btn company-short__button" type="button" onClick={afterSubmit}>
              <span className="ctrl-btn__text">{t('signup:second_step.invitation.join_button', 'Join Company')}</span>
            </button>
          </div>
        </div>
      )}
      {isLoading && <Loader />}
    </ZetOnboardingContainer>
  );
};
export default SecondStep;
