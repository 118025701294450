import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useTasksBulkOperations} from 'modules/Tasks/components/TasksActionsBar/useTasksBulkOperations';
import {BulkProps} from 'modules/Tasks/utils/constants';
import {Confirmation} from 'shared/components';
import AsyncProjectSubcontractorSelect from 'shared/components/CoreForm/Select/AsyncProjectSubcontractorSelect';

const BulkSubcontractor = ({onClose, gantt}: BulkProps) => {
  const [selectedSubcontractor, setSelectedSubcontractor] = useState('');
  const {t} = useTranslation('gantt');
  const {bulkUpdate} = useTasksBulkOperations(gantt);

  return (
    <Confirmation
      visible
      title={t('bulk_subcontractor.confirmation.title', 'Subcontractor')}
      description={t('bulk_subcontractor.confirmation.description', 'Subcontractor to Multiple Tasks')}
      cancelButton={t('bulk_assign.confirmation.buttons.cancel', 'Cancel')}
      acceptButton={t('bulk_assign.confirmation.buttons.accept', 'Assign')}
      onAccept={() => bulkUpdate({responsibleOrgId: selectedSubcontractor})}
      onClose={onClose}
      isDisabledAcceptBtn={!selectedSubcontractor}
      field={
        <AsyncProjectSubcontractorSelect
          value={selectedSubcontractor}
          onChange={setSelectedSubcontractor}
          placeholder={t('bulk_subcontractor.confirmation.select.placeholder', 'Select subcontractor')}
          loadingPlaceholder={t('bulk_assign.confirmation.select.loading_placeholder', 'Loading...')}
        />
      }
    />
  );
};

export default BulkSubcontractor;
