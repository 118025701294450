import {FunctionComponent, useMemo} from 'react';

import fourHundredFour from 'assets/images/other/404.svg';
import {Icon, Logo} from 'shared/components';
import env from 'shared/constants/env';
import useUserAgent from 'shared/hooks/useUserAgent';

const NotFound: FunctionComponent = () => {
  const userAgent = useUserAgent();

  const link = useMemo(() => {
    if (userAgent?.os.name == 'iOS') return env.mobileAppLinks.appStore;
    if (userAgent?.os.name == 'Android') return env.mobileAppLinks.googlePlay;

    return env.downloadAppLinkPage;
  }, [userAgent?.os.name]);

  return (
    <>
      <header className="app-header">
        <Logo className="app-header__logo" />
      </header>
      <div className="error-404">
        <div className="box error-404__box">
          <picture className="picture error-404__number">
            <img className="picture__image" src={fourHundredFour} alt="" />
          </picture>
          <h1 className="error-404__title">Page not found</h1>
          <div className="error-404__description">
            <p>We can&rsquo;t find the page you&rsquo;re looking for.</p>
            <p>This task may have been deleted.</p>
          </div>
          <div className="error-404__actions">
            <a className="ctrl-btn-download-app" href={link} target="_blank" rel="noopener noreferrer">
              <picture className="picture ctrl-btn-download-app__icon-app">
                <img className="picture__image" src="/images/logo/icons/icon-app.svg" alt="Crews by Core" />
              </picture>
              <span className="ctrl-btn-download-app__info">
                <span className="ctrl-btn-download-app__name">Get the App</span>
                <span className="ctrl-btn-download-app__description">
                  Unlock the whole toolkit with the free Core App
                </span>
              </span>
              <Icon name="chevron-right" colorFill className="ctrl-btn-download-app__icon" size={24} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
