import {useLayoutEffect, useRef} from 'react';

export interface UseBodyClassNameOptions {
  clearInitialClasses: boolean;
}
export function useClassName(
  element: HTMLElement,
  className: string | string[],
  options: UseBodyClassNameOptions = {clearInitialClasses: false},
) {
  const initialClasses = useRef<string[]>([]);

  useLayoutEffect(() => {
    if (element) {
      const classList = element.classList;
      if (className && className.length) {
        const clear = (list: DOMTokenList) => {
          classList.remove(...Array.from(list));
        };

        if (options.clearInitialClasses) {
          initialClasses.current = Array.from(classList);
          clear(classList);
        }

        classList.add(...[].concat(className));
        return () => {
          classList.remove(...[].concat(className));
          classList.add(...initialClasses.current);
        };
      }
    }
  }, [element, className]);
}
