import {TFunction} from 'i18next';

import {DropdownListItem} from 'modules/Tasks/components/Gantt/components/DropdownList/DropdownList';
import {startOfDay} from 'shared/helpers/dates';
import {UrlParseSchema, UrlSchemaParser} from 'shared/helpers/queryParams';
import {TaskFilterQuery, TaskStates, TaskStatus} from 'shared/models/task';

export const getFilterTabs = (t: TFunction) =>
  [
    {
      state: TaskStates.active,
      title: t('filters:tasks.tabs.active', 'Active'),
    },
    {
      state: TaskStates.deleted,
      title: t('filters:tasks.tabs.deleted', 'Deleted'),
    },
  ] as const;

export const TASK_FILTER_PARAMS_SCHEMA: UrlParseSchema<TaskFilterQuery> = {
  q: 'string',
  locations: 'string[]',
  projectId: 'string',
  schedStartFirst: 'date',
  schedStartSecond: 'date',
  schedEndFirst: 'date',
  schedEndSecond: 'date',
  schedWeeks: 'string',
  responsibleOrgIds: 'string[]',
  responsible: 'string',
  showPastDue: 'boolean',
  status: 'string',
  statusList: 'string[]',
  unassignedOnly: 'boolean',
  types: 'string[]',
  withIssues: 'boolean',
  state: 'string',
  view: 'string',
  milestonesOnly: 'boolean',
  outlineSortKeyPrefixList: 'string[]',
  projectedLabor: 'string',
  actualLabor: 'string',
  costCode: 'string',
  phaseCode: 'string',
  csiCode: 'string',
  customCode: 'string',
  // issue filters
  issueType: 'string[]',
  impact: 'string[]',
  costImpact: 'boolean[]',
  culpableOrgId: 'string[]',
  costTrackingNumber: 'string[]',
  ganttCols: 'string[]',
  documentId: 'string',
  showChat: 'boolean',
};

function getInitialValueBySchemaFieldType(fieldType: UrlSchemaParser) {
  switch (fieldType) {
    case 'boolean':
      return false;
    case 'date':
      return null;
    case 'string':
      return '';
    case 'string[]':
      return [];
    case 'boolean[]':
      return [];
    default:
      throw new Error(`Unnown query parser ${fieldType}`);
  }
}
export function getDefaultSchemaValues<T extends Record<string, unknown>>(schema: UrlParseSchema<T>) {
  return Object.entries(schema).reduce(
    (acc, [key, value]) => Object.assign(acc, {[key]: getInitialValueBySchemaFieldType(value)}),
    {} as T,
  );
}

export const TASK_DEFAULT_FILTER_PARAMS: TaskFilterQuery = {
  q: '',
  schedStartFirst: null,
  schedStartSecond: null,
  schedEndFirst: startOfDay(new Date()).toDate(),
  schedEndSecond: null,
  schedWeeks: null,
  responsibleOrgIds: [],
  responsible: null,
  locations: [],
  types: [],
  showPastDue: false,
  status: null,
  statusList: [],
  unassignedOnly: false,
  withIssues: false,
  state: TaskStates.active,
  milestonesOnly: false,
  outlineSortKeyPrefixList: [],
  projectedLabor: '',
  actualLabor: '',
  customCode: '',
  csiCode: '',
  phaseCode: '',
  costCode: '',
  ganttCols: [],
  // issue filter params
  culpableOrgId: [],
  impact: [],
  issueType: [],
  costImpact: [],
  costTrackingNumber: [],
  showChat: false,
};

type WeekKey = 'OneWeek' | 'ThreeWeeks' | 'FourWeeks' | 'FiveWeeks' | 'SixWeeks' | 'NineWeeks' | 'TwelveWeeks';
export type WeekValue = (typeof WeekMap)[WeekKey];
export const WeekMap: Record<WeekKey, number> = {
  OneWeek: 1,
  ThreeWeeks: 3,
  FourWeeks: 4,
  FiveWeeks: 5,
  SixWeeks: 6,
  NineWeeks: 9,
  TwelveWeeks: 12,
} as const;

type MonthKey = 'SixMonths' | 'NineMonths' | 'OneYear' | 'OnePointFiveYears' | 'TwoYears';
export type MonthValue = (typeof MonthsMap)[MonthKey];

const MonthsMap: Record<MonthKey, number> = {
  SixMonths: 24,
  NineMonths: 36,
  OneYear: 52,
  OnePointFiveYears: 78,
  TwoYears: 104,
} as const;

export type DayValue = typeof OneDay;

const OneDay = 0 as const;

export const LOOKAHEAD_TASK_DEFAULT_FILTER_PARAMS: TaskFilterQuery = {...TASK_DEFAULT_FILTER_PARAMS, schedWeeks: '3'};
export const GANTT_VISUAL_DEFAULT_FILTER_PARAMS: TaskFilterQuery = TASK_DEFAULT_FILTER_PARAMS;
export const DAILIES_TASK_DEFAULT_FILTER_PARAMS: TaskFilterQuery = TASK_DEFAULT_FILTER_PARAMS;
export const GANTT_TASK_DEFAULT_FILTER_PARAMS: TaskFilterQuery = TASK_DEFAULT_FILTER_PARAMS;
export const ISSUES_TASK_DEFAULT_FILTER_PARAMS: TaskFilterQuery = {
  ...TASK_DEFAULT_FILTER_PARAMS,
  statusList: [TaskStatus.tba, TaskStatus.inProgress],
};

export const getCommonWeeksFilter = (t: TFunction): DropdownListItem[] => [
  {title: t('filters:tasks.weeks_options.1_week', '1 week'), value: WeekMap.OneWeek},
  {title: t('filters:tasks.weeks_options.3_weeks', '3 weeks'), value: WeekMap.ThreeWeeks},
  {title: t('filters:tasks.weeks_options.4_weeks', '4 weeks'), value: WeekMap.FourWeeks},
  {title: t('filters:tasks.weeks_options.5_weeks', '5 weeks'), value: WeekMap.FiveWeeks},
  {title: t('filters:tasks.weeks_options.6_weeks', '6 weeks'), value: WeekMap.SixWeeks},
  {title: t('filters:tasks.weeks_options.9_weeks', '9 weeks'), value: WeekMap.NineWeeks},
  {title: t('filters:tasks.weeks_options.12_weeks', '12 weeks'), value: WeekMap.TwelveWeeks},
];

const allWeeksFilter = (t: TFunction): DropdownListItem[] => [
  {title: t('filters:tasks.weeks_options.1_day', '1 day'), value: OneDay},
  ...getCommonWeeksFilter(t),
  {title: t('filters:tasks.weeks_options.6_month', '6 months'), value: MonthsMap.SixMonths},
  {title: t('filters:tasks.weeks_options.9_month', '9 months'), value: MonthsMap.NineMonths},
  {title: t('filters:tasks.weeks_options.1_year', '1 year'), value: MonthsMap.OneYear},
  {title: t('filters:tasks.weeks_options.1_5_year', '1.5 years'), value: MonthsMap.OnePointFiveYears},
  {title: t('filters:tasks.weeks_options.2_year', '2 years'), value: MonthsMap.TwoYears},
];

// TasksViewMode calendar "Weeks Ahead"
export const getLookaheadWeeksFilter = (t: TFunction): DropdownListItem[] => [
  {title: t('filters:tasks.weeks_options.1_day', '1 day'), value: OneDay},
  ...getCommonWeeksFilter(t),
];
export const getGanttWeeksFilter = (t: TFunction): DropdownListItem[] => allWeeksFilter(t);
export const getGanttVisualWeeksFilter = (t: TFunction): DropdownListItem[] => [
  {title: t('filters:tasks.weeks_options.1_day', '1 day'), value: OneDay},
  ...getCommonWeeksFilter(t),
];
