import dayjs, {
  ConfigType,
  Dayjs,
  extend,
  locale,
  ManipulateType,
  OpUnitType,
  QUnitType,
  UnitType,
  WeekdayNames,
} from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/weekday';

export function initDayJs() {
  extend(utc);
  extend(advancedFormat);
  extend(timezone);
  extend(isoWeek);
  extend(quarterOfYear);
  extend(localeData);
  extend(localizedFormat);
  extend(isBetween);
  configureDayjsDefaultLocale();
}

export const ISO_SHORT = 'YYYY-MM-DD';
export const ISO_WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm:SSS';
export const MONTHDAY = 'M/D';

export function setDefaultTimezone(tz?: string) {
  dayjs.tz.setDefault(tz);
}

export function isValidDate(date: any) {
  return dayjs(date).isValid();
}

export function toShortIso(date: ConfigType) {
  return dayjs(date).format(ISO_SHORT);
}

export function toIsoString(date: string | number | Date): string {
  return dayjs(date).toISOString();
}

export function toProjectIso(date: Date | Dayjs, time: string | Date, tz?: string) {
  return setProjectTime(convertLocalDateToTimezone(date, tz), time).format();
}

export function setProjectTime(date: Dayjs | Date, time: string | Date) {
  return dayjs(date).startOf('day').add(parseTimeAtomic(time), 'minutes');
}

/* Parse iso sting and convert to timezone */
export function convertToTimezone(date: ConfigType, tz?: string, keepLocal = false) {
  return dayjs(date).tz(tz, keepLocal);
}

/* Convert local datetime to specific timezone without change time */
export function convertLocalDateToTimezone(date: Date | Dayjs, tz?: string) {
  return convertToTimezone(date, tz, true);
}

/* Convert date sting(ISO) to specific timezone but treat it as local timezone
 * usualy used to convert activity dates from the servert to project datetime
 * @example Convert activity dates to project timezone
 *  // activityDto.schedStartDate = 2022-12-21T00:00:00+00:00, tz = America/Los_Angeles(-08:00)
 *  // returns Date(2022-12-20T16:00:00) in local timezone
 *  activity.schedStartDate = assumeTimezoneDateAsLocal(activityDto.schedStartDate);
 * */
export function assumeTimezoneDateAsLocal(date: string, tz?: string) {
  return assumeDateAsLocal(convertToTimezone(date, tz)).toDate();
}

/* Convert to local timezone without changing time */
export function assumeDateAsLocal(date: Dayjs) {
  return dayjs(dayjs(date).format(ISO_WITHOUT_TIMEZONE));
}

/* Convert date to timezone andd keep local time*/

export function formatToShort(date: Date | Dayjs) {
  return dayjs(date).format(ISO_SHORT);
}

export function formatDate(date: ConfigType, format?: string) {
  return dayjs(date).format(format);
}

export function toTimezoneStringWithFormat(
  date: string | number | Date,
  format: string,
  timezone?: string,
  showTimezoneCode?: boolean,
): string {
  if (!timezone) {
    return dayjs(date)
      .utc()
      .format(format + ' (UTC)');
  }
  return dayjs(date)
    .tz(timezone)
    .format(format + (showTimezoneCode ? ' (z)' : ''));
}

export function safeParseDate(date: string | Date, format?: string): Date | null {
  let res: Date;
  if (!date) return null;
  if (date instanceof Date) {
    res = date;
  } else {
    try {
      res = dayjs(date as string, format).toDate();
    } catch (error) {
      res = new Date(date);
    }
  }
  return !isNaN(res.getTime()) ? res : null;
}

export function safeFormatDate(date: string | Date, formatPattern = 'L', initialFormat?: string) {
  if (!(date instanceof Date)) {
    date = safeParseDate(date, initialFormat);
  }
  return date instanceof Date || typeof date === 'number' ? dayjs(date).format(formatPattern) : '';
}

// This function parse time string in 24h format (eg. 23:55)
export function parseTime(timeString: string | Date): Date {
  if (!timeString) return null;

  if (timeString instanceof Date) return timeString;

  const time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
  if (time == null) return null;

  let hours = parseInt(time[1], 10);
  if (hours == 12 && !time[4]) {
    hours = 0;
  } else {
    hours += hours < 12 && time[4] ? 12 : 0;
  }
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(parseInt(time[3], 10) || 0);
  date.setSeconds(0, 0);
  return date;
}

// Will return minutes from 00:00
export function parseTimeAtomic(timeString: string | Date): number {
  if (!timeString) return null;

  if (timeString instanceof Date) {
    return timeString.getHours() * 60 + timeString.getMinutes();
  }

  const parts = timeString.match(/(\d\d)/g);
  const minutes = parseInt(parts[0]) * 60 + (parseInt(parts[1]) || 0);
  return minutes === 0 ? 0 : minutes;
}

export function startOf(date: ConfigType, unit: OpUnitType = 'day') {
  return dayjs(date).startOf(unit);
}
export function startOfWeek(date: ConfigType): Dayjs {
  return dayjs(date).day(1).startOf('day');
}

export function endOfWeek(date: ConfigType): Dayjs {
  return dayjs(date).day(7).startOf('day');
}

export function startOfMonth(date: ConfigType): Dayjs {
  return dayjs(date).startOf('month');
}

export function endOfMonth(date: ConfigType): Dayjs {
  return dayjs(date).endOf('month');
}

export function startOfYear(date: ConfigType): Dayjs {
  return dayjs(date).startOf('year');
}

export function endOfYear(date: ConfigType): Dayjs {
  return dayjs(date).endOf('year');
}

export function startOfDay(date: ConfigType): Dayjs {
  return dayjs(date).startOf('day');
}

export function subtract(date: ConfigType, value: number, units: ManipulateType = 'day') {
  return dayjs(date).subtract(value, units);
}

export function addDays(date: ConfigType, value: number): Dayjs {
  return dayjs(date).add(value, 'day');
}

export function isSame(first: ConfigType, second: ConfigType, unit: OpUnitType = null) {
  return dayjs(first).isSame(second, unit);
}

export function isSameDay(first: ConfigType, second: ConfigType) {
  return isSame(first, second, 'day');
}

export function diff(first: ConfigType, second: ConfigType, unit: QUnitType | UnitType = 'day') {
  return dayjs(first).diff(second, unit);
}

export function isAfter(first: ConfigType, second: ConfigType, unit: OpUnitType = 'day') {
  return dayjs(first).isAfter(second, unit);
}

export function isBefore(first: ConfigType, second: ConfigType, unit: OpUnitType = 'day') {
  return dayjs(first).isBefore(second, unit);
}
export function isSameOrBefore(first: ConfigType, second: ConfigType, unit: OpUnitType = 'day') {
  const date = dayjs(first);
  return date.isSame(second, unit) || date.isBefore(second, unit);
}
export function getQuarterOfYear(date: ConfigType): number {
  return dayjs(date).quarter();
}

export const configureDayjsDefaultLocale = async () => {
  const loaders = {
    en: () => import('dayjs/locale/en'),
    es: () => import('dayjs/locale/es'),
    ja: () => import('dayjs/locale/ja'),
    ru: () => import('dayjs/locale/ru'),
  };
  if ('navigator' in window) {
    const [browserLang] = navigator.language.split(/-|_/);
    if (loaders[browserLang] && locale() !== browserLang) {
      await loaders[browserLang]();
      locale(browserLang);
    }
  }
};

export function getCustomWeekDay(date: Date) {
  const customWeekdays = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
  return customWeekdays[dayjs(date).weekday()];
}

export function getDateRangeWithWeekdays(first: ConfigType, second: ConfigType, customWeekdays?: WeekdayNames) {
  const defaultWeekdays: WeekdayNames = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];

  function formatRangeDate(date: ConfigType) {
    const dayOfWeek = (customWeekdays || defaultWeekdays)[dayjs(date).weekday()];
    const formattedDate = formatDate(date, MONTHDAY);
    return `${dayOfWeek} ${formattedDate}`;
  }

  if (isSameDay(first, second)) {
    return formatRangeDate(first);
  } else {
    return `${formatRangeDate(first)} - ${formatRangeDate(second)}`;
  }
}
