import {GroupMember, GroupMemberRole, TaskAssignees} from 'shared/models/task';

function getRequiredMemberFields(members: GroupMember[]) {
  return members.map<GroupMember>(({memberId, memberRole}) => ({
    memberId,
    memberRole,
  }));
}

function filterExistingMembers(firstCollection: GroupMember[], secondCollection: GroupMember[]) {
  return firstCollection.filter((sourceItem) => {
    return !secondCollection.find(
      (collectionItem) =>
        collectionItem.memberId === sourceItem.memberId && collectionItem.memberRole === sourceItem.memberRole,
    );
  });
}

export function memberIsOwner(member: GroupMember) {
  return member.memberRole === GroupMemberRole.owner;
}

export function memberIsResponsible(member: GroupMember) {
  return member.memberRole === GroupMemberRole.responsible;
}

export const getMembersForAdd = (members: TaskAssignees[], localMembers: TaskAssignees[]) => {
  const membersToDelete = getRequiredMemberFields(filterExistingMembers(members, localMembers));
  const membersToAdd = getRequiredMemberFields(filterExistingMembers(localMembers, members));
  return {membersToAdd, membersToDelete};
};

export function putResponsibleMemberToBeginning(members = []) {
  const responsible = members.find(memberIsResponsible);
  if (responsible) {
    const restMembers = members.filter((m) => m.memberRole !== GroupMemberRole.responsible) || [];
    return [responsible, ...restMembers];
  }
  return members;
}
