import {TaskLabelCategory} from 'shared/constants/common';
import {TaskLabelCategoryMap} from 'shared/constants/dailyStatus';
import {TaskGanttModel} from 'shared/models/task';
import {TaskObjectType} from 'shared/models/task/const';
import {WeatherForecastDay} from 'shared/models/weather';

export enum TaskStatusFlag {
  quality = 'quality',
  safety = 'safety',
  planning = 'planning',
  startsToday = 'startsToday',
  endsToday = 'endsToday',
  windGeneral = 'windGeneral',
  windActivity = 'windActivity',
  heatIndex = 'heatIndex',
  rain = 'rain',
}

export const classify = (
  forecast: WeatherForecastDay,
  labelCategories: TaskLabelCategoryMap,
  task: TaskGanttModel,
  dayStr: string,
  filterCategory: TaskLabelCategory = null,
): TaskStatusFlag[] => {
  const rainChance = forecast?.day.daily_chance_of_rain || 0;
  const maxWind = forecast?.day.maxwind_mph || 0;

  const flags = new Set<TaskStatusFlag>();
  if (
    task.object_type !== TaskObjectType.activity ||
    task.date_list === undefined ||
    task.date_list.indexOf(dayStr) < 0
  ) {
    return [];
  }

  const startDt = task.date_list[0];
  const endDt = task.date_list[task.date_list.length - 1];

  if (filterCategory === null || filterCategory === TaskLabelCategory.planning) {
    if (dayStr === startDt) {
      flags.add(TaskStatusFlag.startsToday);
    } else if (dayStr === endDt) {
      flags.add(TaskStatusFlag.endsToday);
    }
  }

  if (task?.risk?.labels?.length) {
    for (const tag of task.risk.labels) {
      if (tag.is_deleted) {
        continue;
      }

      const label = tag.label;
      const category = labelCategories[label];
      if (filterCategory && filterCategory !== category) {
        continue;
      }

      if (category === TaskLabelCategory.safety) {
        flags.add(TaskStatusFlag.safety);
      }

      if (category === TaskLabelCategory.quality) {
        flags.add(TaskStatusFlag.quality);
      }

      if (category === TaskLabelCategory.planning) {
        flags.add(TaskStatusFlag.planning);
      }

      if (rainChance > 20 && label === 'rain') {
        flags.add(TaskStatusFlag.rain);
      }

      if (maxWind >= 30 && label === 'wind') {
        flags.add(TaskStatusFlag.windActivity);
      }
    }
  }

  const flagList = Array.from(flags);
  flagList.sort();
  return flagList;
};

type FlagSvgAttributes = {
  iconName: string;
  color: string;
  title: string;
};

export const getFlagSvgAttributes = (flag: TaskStatusFlag): FlagSvgAttributes => {
  let iconName;
  let color = 'blue';
  let title = '';

  if (flag === TaskStatusFlag.quality) {
    title = 'Activity is on critical path';
    iconName = 'circlex';
    color = '#8B120D';
  } else if (flag === TaskStatusFlag.safety) {
    title = 'Activity has a high risk of injury';
    iconName = 'exclamation';
    color = '#8B120D';
  } else if (flag === TaskStatusFlag.planning) {
    title = 'Activity may affect project plan';
    iconName = 'circlex';
    color = '#8B120D';
  } else if (flag === TaskStatusFlag.startsToday) {
    iconName = 'startdate';
    color = '#FCCD3C';
    title = 'New Activity';
  } else if (flag === TaskStatusFlag.endsToday) {
    iconName = 'enddate';
    color = 'black';
    title = 'Activity ending today';
  } else if (flag === TaskStatusFlag.windGeneral) {
    title = 'High winds forecast today';
    iconName = 'wind';
    color = 'black';
  } else if (flag === TaskStatusFlag.windActivity) {
    title = 'High winds forecast today';
    iconName = 'wind';
    color = 'black';
  } else if (flag === TaskStatusFlag.heatIndex) {
    title = 'High heat index advisory';
    iconName = 'exclamation';
    color = '#8B120D';
  } else if (flag === TaskStatusFlag.rain) {
    title = 'High chance of rain forecast today';
    iconName = 'rain';
    color = 'black';
  }

  return {title, iconName, color};
};
