import React, {FC, HTMLAttributes, useMemo, useRef} from 'react';
import InputMask from 'react-input-mask';

import {PHONE_MASKS_BY_CODE} from 'shared/constants/common';
import {useEffectAfterMount} from 'shared/hooks/core';

type PhoneInputProps = {
  value: string;
  countryCode?: string;
  disabled?: boolean;
  onManualChange?: (value: string) => void;
} & HTMLAttributes<HTMLInputElement>;

const PhoneInput: FC<PhoneInputProps> = ({value, countryCode, disabled, onManualChange, ...props}) => {
  const inputRef = useRef<HTMLInputElement>();
  const mask = useMemo(() => {
    const maskConfig = PHONE_MASKS_BY_CODE[countryCode];
    if (!maskConfig) return null;
    return value && maskConfig.alterPredicate && maskConfig.alterPredicate(value.replace(/\D+/g, ''))
      ? maskConfig.alterMask
      : maskConfig.mask;
  }, [countryCode, value]);

  useEffectAfterMount(() => {
    // if we dynamically change mask, input mask will not trigger change/input event and value will be outdated
    if (value !== inputRef.current.value) {
      onManualChange?.(inputRef.current.value);
    }
  }, [mask]);

  return (
    <InputMask
      {...props}
      value={value}
      mask={mask}
      maskChar={null}
      formatChars={{9: '[0-9]', '?': '[0-9 ]'}}
      style={disabled ? {opacity: '.25'} : null}
      disabled={disabled}
    >
      {(inputProps) => <input {...inputProps} ref={inputRef} />}
    </InputMask>
  );
};
export default PhoneInput;
