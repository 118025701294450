import {createContext, useContext} from 'react';

import {TaskAssigneeWorker} from 'shared/models/task';

import {TaskAssigneesExtended} from '../AssigneesTab';
import {AssignmentState} from '../utils/constants';

export interface AssigneesTabContext {
  members: TaskAssigneeWorker[];
  onMemberSelect: (members: TaskAssigneeWorker[]) => void;
  responsible: TaskAssigneesExtended[];
  updateResponsible: (responsible: TaskAssigneesExtended[]) => void;
  watchers: TaskAssigneesExtended[];
  updateWatchers: (watchers: TaskAssigneesExtended[]) => void;
  idsHiddenWatchers: string[];
  setIdsHiddenWatchers: (idsHiddenWatchers: string[]) => void;
  setAssignmentState: (state: AssignmentState) => void;
  prevAssignmentState: AssignmentState;
}
export const AssigneesTabContext = createContext<AssigneesTabContext>({
  members: [],
  onMemberSelect: () => undefined,
  updateResponsible: () => undefined,
  updateWatchers: () => undefined,
  responsible: [],
  watchers: [],
  idsHiddenWatchers: [],
  setIdsHiddenWatchers: () => undefined,
  setAssignmentState: () => undefined,
  prevAssignmentState: AssignmentState.empty,
});
export const useAssigneesTabContext = () => useContext(AssigneesTabContext);
