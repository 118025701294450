import {GanttTaskCustomField} from 'shared/models/task';

import {GanttTask} from '../../types';

export function updateTaskCustomFieldValue(task: GanttTask, {field, value}: {field: string; value: string}): boolean {
  const taskCustomFields = task.custom_fields;
  const fieldIndex = taskCustomFields?.findIndex(({internal_field_name}) => internal_field_name === field) ?? -1;
  const oldFieldValue = fieldIndex !== -1 ? taskCustomFields[fieldIndex].value : null;

  if (oldFieldValue !== value) {
    const updatedModel = {internal_field_name: field, value: value};

    // for showing the new value after exiting from inline editor
    // before receiving a response from the API (task update)
    if (fieldIndex !== -1) {
      task.custom_fields[fieldIndex].value = value;
    } else {
      task.custom_fields.push(updatedModel);
    }

    const prev = (task.lastChangedFields?.customFields?.newValue as GanttTaskCustomField[]) ?? [];
    task.lastChangedFields.customFields = {
      newValue: prev.concat([updatedModel]),
      oldValue: taskCustomFields,
    };
    return true;
  }
  return false;
}
