import cn from 'classnames';
import React, {Children, cloneElement, isValidElement, ReactElement} from 'react';

import s from './CtrlSwitcher.module.scss';

type CtrlSelectorProps = {
  className?: string;
  title?: string;
  children: ReactElement[] | ReactElement;
  view?: string;
  onChange?: (value: string) => void;
};

const CtrlSwitcher = ({className, title, children, view}: CtrlSelectorProps) => {
  return (
    <div className={cn(s.ctrlSelector, view && s[`ctrlSelector_view_${view}`], className)}>
      {title && <span className={s.ctrlSelector__title}>{title}</span>}
      <div className={s.ctrlSelector__container}>
        {Children.toArray(children).map<ReactElement>(
          (child) =>
            isValidElement(child) &&
            cloneElement(child, {...child.props, className: cn(s.ctrlSelector__button, child.props.className)}),
        )}
      </div>
    </div>
  );
};

export default CtrlSwitcher;
