import cs from 'classnames';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import EventManager from 'services/EventManager';
import {Icon} from 'shared/components/index';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {FormattedProPlanTiers} from 'shared/models/checkout';

import {LandingView, TooltipView} from './views';
import UpgradePlanView from './views/UpgradePlanView';

export type PriceTable = {
  tiers?: FormattedProPlanTiers[];
  className?: string;
  classPrfx?: string;
  placement?: 'common' | 'tooltip';
  showHighlight?: boolean;
  highlightedTier?: FormattedProPlanTiers;
};

const dummyOptions = Array.from<undefined>({length: 3});

const TiersPricingTable: FC<PriceTable> & {
  Tooltip: typeof TooltipView;
  Landing: typeof LandingView;
  PickPlan: typeof UpgradePlanView;
} = ({tiers, classPrfx = 'plan-price', className, placement = 'common', showHighlight, highlightedTier}) => {
  const routes = useLocalizedRoutes();
  const {t} = useTranslation('common');
  const getSkeletonFields = () => {
    return dummyOptions.map((_, i) => (
      <tr key={i}>
        <td className={`${classPrfx}__cell`}>
          <Icon name="group_equal" colorFill className={`${classPrfx}-table-icon`} />
          <Skeleton width={150} />
        </td>
        <td className={`${classPrfx}__cell ${classPrfx}__cell--summ`}>
          <Skeleton width={150} />
        </td>
      </tr>
    ));
  };
  return (
    <table className={cs('plan-price', className)}>
      <thead>
        <tr>
          <th className={`${classPrfx}__cell ${classPrfx}__cell--th`}>
            {t('tiers.columns.user_numbers', 'Number of Users')}
          </th>
          <th className={`${classPrfx}__cell ${classPrfx}__cell--th plan-price__cell--summ`}></th>
        </tr>
      </thead>
      <tbody>
        {!tiers?.length
          ? getSkeletonFields()
          : tiers.map((tier, i) => (
              <tr
                key={i}
                className={cs(
                  'plan-price__row',
                  showHighlight && +highlightedTier?.upTo === +tier?.upTo && 'is-active',
                )}
              >
                <td className={`${classPrfx}__cell`}>
                  {placement !== 'tooltip' && <Icon name="group_equal" colorFill className={`${classPrfx}__icon`} />}
                  <span>{tier.seatRange}</span>
                </td>
                <td className={`${classPrfx}__cell ${classPrfx}__cell--summ`}>
                  <span>{tier.formattedFlatPrice}</span>
                </td>
              </tr>
            ))}
        <tr>
          <td className={`${classPrfx}__cell ${classPrfx}__cell--summ ${classPrfx}__cell--contacts`}>
            <a
              className={`${classPrfx}__link`}
              onClick={() => {
                EventManager.pushToGtmDataLayer({event: 'c4-contacts-enterprise'});
              }}
              href={routes.enterpriseContact}
              target="_blank"
              rel="noreferrer"
            >
              {t('contact_sales', 'Contact Sales for custom bundle')}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

TiersPricingTable.Tooltip = TooltipView;
TiersPricingTable.Landing = LandingView;
TiersPricingTable.PickPlan = UpgradePlanView;
export default TiersPricingTable;
