import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useTasksBulkOperations} from 'modules/Tasks/components/TasksActionsBar/useTasksBulkOperations';
import {BulkProps} from 'modules/Tasks/utils/constants';
import {Confirmation} from 'shared/components';
import AsyncProjectWorkerSelect from 'shared/components/CoreForm/Select/AsyncProjectWorkerSelect';
import {CompanyWorker} from 'shared/models/worker';

const BulkResponsible = ({onClose, gantt, projectId}: BulkProps) => {
  const [selectedMember, setSelectedMember] = useState<CompanyWorker[]>(null);
  const {t} = useTranslation('gantt');
  const {assignResponsible} = useTasksBulkOperations(gantt);

  return (
    <Confirmation
      visible
      title={t('bulk_responsible.confirmation.title', 'Assignee')}
      description={t('bulk_responsible.confirmation.description', 'Responsible to Multiple Tasks')}
      cancelButton={t('bulk_assign.confirmation.buttons.cancel', 'Cancel')}
      acceptButton={t('bulk_assign.confirmation.buttons.accept', 'Assign')}
      onAccept={() => {
        assignResponsible(selectedMember[0]);
      }}
      onClose={onClose}
      isDisabledAcceptBtn={!selectedMember}
      field={
        <AsyncProjectWorkerSelect
          projectId={projectId}
          onSelectChange={setSelectedMember}
          placeholder={t('bulk_responsible.confirmation.select.placeholder', 'Select assignee')}
          loadingPlaceholder={t('bulk_assign.confirmation.select.loading_placeholder', 'Loading...')}
        />
      }
    />
  );
};

export default BulkResponsible;
