import React, {FC} from 'react';

import StatusElement from 'shared/components/StatusElement';
import {toTitleCase} from 'shared/helpers/common';

type Props = {value: string; label?: string};

const WorkerStatus: FC<Props> = ({value, label}) => {
  return <StatusElement label={!!label ? label : toTitleCase(value)} className={`status-element--worker-${value}`} />;
};
export default WorkerStatus;
