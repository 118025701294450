import {useState} from 'react';

import {useFilterContext} from 'modules/Tasks/components/Filters';

export function useGanttZoom(gantt) {
  const {queryParams, reset} = useFilterContext();
  const [zoomLevel, setZoomLevel] = useState<number>(gantt.ext?.zoom?.getCurrentLevel() || 0);

  const zoomInOrOut = (zoom: 'in' | 'out') => {
    const nextLevel = zoom === 'in' ? zoomLevel - 1 : zoomLevel + 1;
    gantt.ext.zoom.setLevel(nextLevel);
    setZoomLevel(nextLevel);
    if (queryParams.schedWeeks || queryParams.schedEndFirst) {
      reset({newState: {schedWeeks: null, schedEndFirst: null}});
    }
  };

  const isDisabledZoomIn = zoomLevel === 0;
  const isDisabledZoomOut = gantt.ext?.zoom.getLevels()?.length - 1 === zoomLevel;

  return {
    zoomInOrOut,
    isDisabledZoomIn,
    isDisabledZoomOut,
  };
}
