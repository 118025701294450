import {GroupMember} from 'shared/models/task';
import {CompanyWorker} from 'shared/models/worker';

import ApiAxios from './axios';

class GroupService {
  private getBasePath(groupId: string) {
    return `/groups/${groupId}`;
  }

  addMembers(members: GroupMember[]) {
    return ApiAxios.post<CompanyWorker[]>(this.getBasePath(members[0].groupId) + '/add_members', members).then(
      (res) => res.data,
    );
  }
  removeMembers(members: GroupMember[]) {
    return ApiAxios.post<CompanyWorker[]>(this.getBasePath(members[0].groupId) + '/remove_members', members).then(
      (res) => res.data,
    );
  }
}

export default new GroupService();
