import {useQuery} from 'react-query';

import {TasksApi} from 'api';

export type TaskLabelCategories = {[key: string]: string[]};

export type AppConstantsResponse = {
  locales: {
    uS: {
      lookaheadColors: {
        [key: string]: {fill: string; outline: string; userSelectable: boolean};
      };
      taskRiskLabelByCategory: TaskLabelCategories;
      matrix: {homeserver: string};
    };
  };
};

export function useAppConstants() {
  const {data} = useQuery(
    'responsibleOrgColors',
    async () => {
      return (await TasksApi.getAppConstants()) as AppConstantsResponse;
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  return data;
}
