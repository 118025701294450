import cn from 'classnames';
import {Children, createContext, ReactElement, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {CtrlButton, Dropdown} from 'shared/components/CoreNewUI';
import {AllowedViewPortPosition} from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import {IconsMap} from 'shared/constants/icons';
import {throttle} from 'shared/helpers/throttle';
import ResizeObserver from 'shared/utils/resizeObserver';

import styles from './Toolbar.module.scss';
import {ToolbarItem} from './ToolbarItem';
import ToolbarMoreActions from './ToolbarMoreActions';
import {ToolbarSection, ToolbarSectionProps} from './ToolbarSection';

interface ToolbarContextType {
  hiddenSeparatorIndices: Set<number>;
}

interface ToolbarProps {
  children: ReactNode;
  className?: string;
  onStaticItemsWrappedChange?: (isWrapped: boolean) => void;
  staticItems?: ReactNode;
  readonly?: boolean;
  viewportPosition?: AllowedViewPortPosition;
  useItemWidth?: boolean;
}

export const ToolbarContext = createContext<ToolbarContextType | undefined>(undefined);

export const Toolbar = ({
  children,
  className,
  onStaticItemsWrappedChange,
  staticItems,
  readonly,
  viewportPosition,
  useItemWidth,
}: ToolbarProps) => {
  const {t} = useTranslation('gantt');
  const toolbarRef = useRef<HTMLDivElement>(null);
  const staticItemsRef = useRef<HTMLDivElement>(null);
  const [visibleItems, setVisibleItems] = useState<ReactNode>(children);
  const [overflowItems, setOverflowItems] = useState<ReactElement<ToolbarSectionProps>[]>([]);
  const [hiddenSeparatorIndices, setHiddenSeparatorIndices] = useState<Set<number>>(new Set());
  const [hasStaticItemsWrapped, setHasStaticItemsWrapped] = useState(false);

  // calculates which toolbar items should be visible and which should overflow based on the toolbar's width.
  const calculateVisibleAndOverflowItems = useCallback(() => {
    if (!toolbarRef.current) {
      return;
    }

    const toolbar = toolbarRef.current;
    const toolbarWidth = toolbar.offsetWidth;

    const safeBreakpointThreshhold = 100;
    let totalWidth = 0;
    const visibleItemsList = [];
    const overflowItemsList = [];
    const separatorIndices = new Set<number>();
    const sectionWidths = Array.from(toolbar.children).map((child: Element) => (child as HTMLElement).offsetWidth);

    Children.forEach(children, (section, index) => {
      const isLastItem = index === Children.count(children) - 1;
      const sectionWidth = sectionWidths[index];
      totalWidth += useItemWidth ? sectionWidth + 10 : safeBreakpointThreshhold;

      // If a section element is excluded it is always visible
      if (totalWidth > toolbarWidth) {
        overflowItemsList.push(section);
        // hides the separator when added to overflow list
        if (!isLastItem) {
          separatorIndices.add(index);
        }
      } else {
        visibleItemsList.push(section);
      }
    });

    if (staticItems) {
      visibleItemsList.push(
        <Toolbar.Section
          className={cn(styles['static-items-section'], {[styles.has_wrapped]: hasStaticItemsWrapped})}
          contentClassName={styles['static-items-content']}
          key="staticItems"
          ref={staticItemsRef}
        >
          {staticItems}
        </Toolbar.Section>,
      );
    }

    setVisibleItems(visibleItemsList.filter(Boolean));
    setOverflowItems(overflowItemsList.filter(Boolean));
    setHiddenSeparatorIndices(separatorIndices);

    // Computes when the static items breaks to the next line.
    if (staticItemsRef.current) {
      const toolbarTopMargin = parseInt(window.getComputedStyle(toolbarRef.current).paddingTop);
      const staticItemRect = staticItemsRef.current.getBoundingClientRect().top - toolbarTopMargin;
      const toolbarRect = toolbar.getBoundingClientRect().top;
      const isWrapped = staticItemRect > toolbarRect;
      if (isWrapped !== hasStaticItemsWrapped) {
        setHasStaticItemsWrapped(isWrapped);
      }
    }
  }, [children, hasStaticItemsWrapped, staticItems, useItemWidth]);

  useEffect(() => {
    const throttledCalculate = throttle(calculateVisibleAndOverflowItems, 100);
    const resizeObserver = new ResizeObserver(throttledCalculate);
    const currentToolbarRef = toolbarRef.current;
    if (currentToolbarRef) {
      resizeObserver.observe(currentToolbarRef);
    }

    throttledCalculate();

    return () => {
      if (currentToolbarRef) {
        resizeObserver.unobserve(currentToolbarRef);
      }
    };
  }, [calculateVisibleAndOverflowItems, children]);

  useEffect(() => {
    if (onStaticItemsWrappedChange) {
      onStaticItemsWrappedChange(hasStaticItemsWrapped);
    }
  }, [hasStaticItemsWrapped, onStaticItemsWrappedChange]);

  return (
    <ToolbarContext.Provider value={{hiddenSeparatorIndices}}>
      {readonly ? (
        <div className={styles['toolbar__read_only-container']}>
          <div className={styles['toolbar__read_only-chip']}>{t('contextMenu.read_only')}</div>
        </div>
      ) : null}
      <div className={cn(styles.toolbar, className)} ref={toolbarRef}>
        {!!overflowItems.length ? (
          <Dropdown
            className={styles.dropdown}
            toggleElement={
              <CtrlButton color="action" size="xs" icon={IconsMap.more_vertical} iconOnly={true}>
                More
              </CtrlButton>
            }
            viewportPosition={viewportPosition}
          >
            {overflowItems}
          </Dropdown>
        ) : null}
        {visibleItems}
      </div>
    </ToolbarContext.Provider>
  );
};

Toolbar.Section = ToolbarSection;
Toolbar.Item = ToolbarItem;
Toolbar.MoreActions = ToolbarMoreActions;
