import {GanttStatic} from 'dhtmlx-gantt';
import equal from 'fast-deep-equal';
import {useEffect, useCallback, useState} from 'react';
import {useQuery} from 'react-query';

import {ProjectsApi} from 'api';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {GanttEventNameUnion} from 'modules/Tasks/components/Gantt/utils';
import {GanttNames} from 'shared/constants/gantt';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useDebounce} from 'shared/hooks/core';
import {ActivitiesIdRowMap} from 'shared/models/project';
import {GanttLinkModel} from 'shared/models/task';
import {TaskDependency, TaskDependencyDto} from 'shared/models/TaskDependency';
import {mapDependencyToGanttLink} from 'shared/utils/mapDependencyToGanttLink';

export function useLoadRowNumbersAndLinks(gantt: GanttStatic, projectId: string) {
  const [taskDeps, setTaskDeps] = useState([]);

  const {data: rownumbers, refetch: refetchRowNumbers} = useQuery(
    QUERY_CACHE_KEYS.projectRowMap(projectId),
    async () => ProjectsApi.getRowNumberMap(projectId),
    {enabled: !!projectId, refetchOnWindowFocus: false},
  );

  const refreshDepsForTaskIds = useCallback(
    async (taskIds: string[]) => {
      const res = await ProjectsApi.getTaskDependencies(projectId, taskIds);
      const tidSet = new Set(taskIds);
      // strip out any possibly-stale dependencies
      const nextDeps = taskDeps.filter((dep) => {
        return !tidSet.has(dep.taskId);
      });
      // Append deps fetched for taskIds list
      nextDeps.push(...res.dependencies);
      setTaskDeps(nextDeps);
    },
    [projectId, taskDeps, setTaskDeps],
  );

  const {refetch: refetchDependencies} = useQuery(
    QUERY_CACHE_KEYS.projectDependencies(projectId, gantt.name),
    async () => {
      const tasks = gantt.getTaskByTime();
      // filter out placeholder task and new created tasks for wich we dont know real id yet
      const tasksIds = tasks
        .filter(({type, id}) => type !== 'placeholder' && typeof id === 'string')
        .map((task) => task.id);
      if (tasksIds.length) {
        await refreshDepsForTaskIds(tasksIds);
      }
      return [];
    },
    {enabled: !!projectId, refetchOnWindowFocus: false, refetchOnMount: false, structuralSharing: false},
  );

  const addRowNumberData = useDebounce((rownumbers: ActivitiesIdRowMap) => {
    rownumbers;
    return;
  }, 100);

  const refetchData = useCallback(async () => {
    await refetchDependencies();
  }, [refetchRowNumbers, refetchDependencies]);

  const refetchDataForTaskIds = useCallback(
    async (taskIds: string[]) => {
      await refreshDepsForTaskIds(taskIds);
    },
    [refetchRowNumbers, refreshDepsForTaskIds],
  );

  useEffect(() => {
    const events = [];
    events.push(
      gantt.attachEvent(
        'DataLoaded' as GanttEventNameUnion,
        () => {
          addRowNumberData(rownumbers);
          refetchDependencies();
        },
        undefined,
      ),
    );
    events.push(
      gantt.attachEvent(
        'afterTasksImport' as GanttEventNameUnion,
        () => {
          refetchData();
        },
        undefined,
      ),
    );
    events.push(
      gantt.attachEvent(
        'afterTasksClone' as GanttEventNameUnion,
        (taskIds: string[]) => {
          refreshDepsForTaskIds(taskIds);
        },
        undefined,
      ),
    );

    return () => events.forEach((eventId) => gantt.detachEvent(eventId));
  }, [addRowNumberData, gantt, refetchData, refetchDependencies, refreshDepsForTaskIds, refetchRowNumbers, rownumbers]);

  const addDepsData = useDebounce(() => {
    if (Array.isArray(taskDeps)) {
      let needRefresh = false;
      gantt.eachTask((task: GanttTask) => {
        const prevDeps = task.sourceDeps;
        const sourceDeps = taskDeps
          ?.filter(({taskId}) => taskId === task.id)
          .map((dep: TaskDependencyDto) => {
            return {...dep, rownum: rownumbers?.[dep.predTaskId] as number};
          })
          .sort((a, b) => b.rownum - a.rownum);
        if ((sourceDeps?.length || prevDeps?.length) && !equal(sourceDeps, prevDeps)) {
          needRefresh = true;
          Object.assign(task, {
            sourceDeps: sourceDeps ?? [],
            targetDeps: taskDeps?.filter(({predTaskId}) => predTaskId === task.id) || [],
          });
        }
      });
      needRefresh && gantt.refreshData();
      if (gantt.name === GanttNames.gantt) {
        const deps = taskDeps.reduce(
          (agg, cur) => Object.assign(agg, {[cur.id]: cur}),
          {} as {[key: string]: TaskDependency},
        );
        gantt.getLinks().forEach((link: GanttLinkModel) => {
          if (deps[link.id]) {
            const currentLink = gantt.getLink(link.id);
            const update = mapDependencyToGanttLink(gantt, deps[link.id]);
            if (!equal(update, gantt.getLink(link.id))) {
              Object.assign(currentLink, update);
              gantt.refreshLink(link.id);
            }
          } else {
            if (gantt.isTaskExists(link.target) && gantt.isTaskExists(link.source)) {
              link.deletedAt = +new Date(); // need to ignore dataProcessor action
              gantt.deleteLink(link.id);
            }
          }
        });
        const missing = taskDeps.filter((dep) => !gantt.isLinkExists(dep.id));
        if (missing.length) {
          gantt.parse({
            data: [],
            links: missing.map((dep) => mapDependencyToGanttLink(gantt, dep)),
          });
        }
      }
    }
  }, 100);

  useEffect(() => {
    rownumbers && addRowNumberData(rownumbers);
  }, [addRowNumberData, rownumbers, gantt]);

  useEffect(() => {
    addDepsData();
  }, [taskDeps, addDepsData, rownumbers, gantt]);

  return {
    refetchData,
    refetchDataForTaskIds,
  };
}
