import React, {CSSProperties, FC, ReactElement} from 'react';

type ScreenGreedProps = {
  content?: ReactElement;
  aside?: ReactElement;
  children?: ReactElement;
  asideStyles?: CSSProperties;
};

const ScreenGreed: FC<ScreenGreedProps> = ({content, aside, children, asideStyles}) => {
  return (
    <div className="screen-grid screen__grid">
      <div className="screen-grid__content" style={{position: 'relative'}}>
        {content || children}
      </div>
      {aside && (
        <div className="screen-grid__aside" style={asideStyles || {}}>
          {aside}
        </div>
      )}
    </div>
  );
};
export default ScreenGreed;
