import * as Sentry from '@sentry/react';
import {AxiosResponse} from 'axios';
import {GanttStatic} from 'dhtmlx-gantt';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {TasksApi} from 'api';
import {container} from 'IocContainer';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {deselectAll, getTaskByRowNumber} from 'modules/Tasks/components/Gantt/utils/gantt';
import {TaskUpdateFifoQueue} from 'services/TaskUpdateFifoQueue';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {extractAxiosError, fetchAllBlocking} from 'shared/helpers/axios';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskModelRawDTO, TaskProjection} from 'shared/models/task';
import {TaskObjectType} from 'shared/models/task/const';
import {IndentOutdentError, expandIndent, expandOutdent} from 'shared/prediction/expander';
import {useInjectStore} from 'shared/providers/injection';
import {TasksStoreType} from 'shared/stores/TasksStore';
import {UIStoreType} from 'shared/stores/UIStore';
import {applyChangeset} from 'shared/stores/utils/transforms';
import {normalizeI18Key} from 'shared/utils/normalizeI18Key';

/**
 * Determine if this indentation will result in converting to WBS an activity
 * that is actualized
 * @param {GanttStatic} gantt - source of indentation request
 * @param {GanttTask[]} selectedTasks - tasks to indent
 * @return {boolean} True if this indent will cause invalid WBS
 */
export function causesActualizedWBS(gantt: GanttStatic, selectedTasks: GanttTask[]): boolean {
  let prevRownum = selectedTasks[0]?.rownum;
  const firstsOfContiguous = [prevRownum];
  for (let i = 1; i < selectedTasks.length; i++) {
    if (selectedTasks[i].rownum > prevRownum + 1) {
      firstsOfContiguous.push(selectedTasks[i].rownum);
    }
    prevRownum = selectedTasks[i].rownum;
  }

  for (const rownum of firstsOfContiguous) {
    const task = getTaskByRowNumber(gantt, rownum);
    const prior = getTaskByRowNumber(gantt, rownum - 1);
    // Prior row is an activity
    if (prior && (prior.object_type === TaskObjectType.activity || prior.object_type === TaskObjectType.milestone)) {
      // Prior row is within same WBS structure (or both top level)
      if (task.outline_sort_key?.slice(0, -10) === prior.outline_sort_key?.slice(0, -10)) {
        // Prior is actualized, can't be converted to WBS
        if (prior.actual_start || prior.actual_end) {
          return true;
        }
      }
    }
  }

  return false;
}

export function useTasksActions(gantt: GanttStatic) {
  const {t} = useTranslation(['common', 'gantt', 'task']);
  const tasksStore = useInjectStore<TasksStoreType>(IOC_TYPES.TasksStore);
  const updateFifoQueue = useInjectStore<TaskUpdateFifoQueue>(IOC_TYPES.TaskUpdateFifoQueue);
  const {confirm} = useConfirm();

  async function indentSelectedTasks() {
    const selected = gantt.getSelectedTasks();
    const taskIds = selected.length ? selected : [gantt.contextMenu?.context?.id];

    /*
     *  # 2 Task A
     *  # 3 Task B
     *    Indenting Task A or Task A + Task B rejected because prior row number 1 not in list
     *  # 1 Task A
     *  # 3 Task C
     *    Indenting Task C rejected because prior row isn’t “2”, meaning Task B is filtered
     *  # 1 Task A
     *  # 2 Task B
     *  # 4  Task D
     *    Indenting Task B & D rejected because there’s a row number gap between 2 & 4
     */
    const selectedTasks = gantt.getTaskBy((task: GanttTask) => taskIds.includes(task.id)) || [];
    if (causesActualizedWBS(gantt, selectedTasks)) {
      toast.warning(
        t(
          'gantt:toast.error.indent_actualized_wbs',
          'These activities cannot be indented because actualized tasks cannot be converted to WBS',
        ),
      );
      return;
    }
    const exist = selectedTasks.every((task: GanttTask) => !!getTaskByRowNumber(gantt, task.rownum - 1));

    if (!exist) {
      // TODO: need to add translation for all indent/outdent errors
      await confirm({
        description: t(
          'gantt:toast.error.indent.out_of_sequence',
          'Filtering hides some affected tasks, indent cancelled.',
        ),
        acceptButton: t('common:button_names.ok', 'OK'),
        cancelButton: null,
      });
      return;
    }
    try {
      const changeSet = expandIndent(tasksStore.tasks, taskIds);
      applyChangeset(tasksStore, changeSet);
      for (const [id, changes] of Object.entries(changeSet)) {
        if (changes.object_type === TaskObjectType.summary) {
          gantt.open(id);
        }
      }
    } catch (e) {
      /**
       * t('gantt:toast.error.indent.invalid_sibling', 'Activities and Actions cannot be siblings');
       * t('gantt:toast.error.indent.no_destination_parent', 'There is no parent for it to be under.');
       * t('gantt:toast.error.indent.excess_indent', "A task can't be indented two levels more than its parent.");
       * t('gantt:toast.error.indent.out_of_sequence', 'Rows hidden by filters would cause unexpected results.  Indent operation cancelled.');
       */
      if (e instanceof IndentOutdentError) {
        const errorCode = e.validationErrors[0].errorCode;
        const key = `gantt:toast.error.indent.${errorCode}`;
        toast.error(t(normalizeI18Key(key)));
        return;
      }
    }

    deselectAll(gantt);

    updateFifoQueue.enqueue(async (idMap: Map<number, string>) => {
      try {
        const permIds = taskIds.map((id: number | string) => (typeof id === 'number' ? idMap.get(id) : id));
        const response = await TasksApi.indentOrOutdentTasks(tasksStore.projectId, permIds, 'indent');
        // TODO make a 2.0.0 version of indent that reports all changes at once
        const affectedTasks = await fetchAllBlocking((offset: number, take: number) => {
          return TasksApi.getProjectTasks({
            params: {ids: response.affectedTaskIds, projectId: tasksStore.projectId},
            offset,
            limit: take,
            projection: TaskProjection.task,
          }) as Promise<AxiosResponse<TaskModelRawDTO[]>>;
        });
        tasksStore.updateTasks(affectedTasks);

        toast.success(
          t('gantt:indent_success', '$t(common:activity, {"count": {{count}} }) indented.', {count: permIds.length}),
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    });
  }

  async function outdentSelectedTasks() {
    const selected = gantt.getSelectedTasks();
    const taskIds = selected.length ? selected : [gantt.contextMenu?.context?.id];

    try {
      const changeSet = expandOutdent(tasksStore.tasks, taskIds);
      applyChangeset(tasksStore, changeSet);
    } catch (e) {
      if (e instanceof IndentOutdentError) {
        const errorKey = (e as IndentOutdentError).validationErrors[0].errorCode;
        /**
         * t('gantt:toast.error.outdent.excess_outdent', "Task can't be outdented. It's already top level.");
         */
        const key = `gantt:toast.error.outdent.${errorKey}`;
        toast.error(t(normalizeI18Key(key)));
        return;
      }
    }

    deselectAll(gantt);

    updateFifoQueue.enqueue(async (idMap) => {
      try {
        const permIds = taskIds.map((id: number | string) => (typeof id === 'number' ? idMap.get(id) : id));
        const response = await TasksApi.indentOrOutdentTasks(tasksStore.projectId, permIds, 'outdent');
        // TODO make a 2.0.0 version of outdent that reports all changes at once
        const affectedTasks = await fetchAllBlocking((offset: number, take: number) => {
          return TasksApi.getProjectTasks({
            params: {ids: response.affectedTaskIds, projectId: tasksStore.projectId},
            offset,
            limit: take,
            projection: TaskProjection.task,
          }) as Promise<AxiosResponse<TaskModelRawDTO[]>>;
        });
        tasksStore.updateTasks(affectedTasks);

        toast.success(
          t('gantt:outdent_success', '$t(common:activity, {"count": {{count}} }) outdented.', {count: permIds.length}),
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    });
  }

  function includeSubtaskIds(taskIds: string[]): string[] {
    const oskMap: Map<string, string> = new Map();
    const sortedTasks = tasksStore.getSortedTasksCopy();
    sortedTasks.forEach((task) => {
      oskMap.set(task.outline_sort_key, task.id);
    });

    const fullTaskIds: Set<string> = new Set(taskIds);
    sortedTasks.forEach((task) => {
      const parentKey = task.outline_sort_key.slice(0, -11);
      if (fullTaskIds.has(oskMap.get(parentKey))) {
        fullTaskIds.add(task.id);
      }
    });

    return Array.from(fullTaskIds);
  }

  async function deleteSelectedTasks(projectId: string) {
    const selectedTasks = gantt.getSelectedTasks();
    const taskIds = includeSubtaskIds(selectedTasks);
    const onlyOneTaskSelected = selectedTasks.length === 1;
    const tasksStore = container.get<TasksStoreType>(IOC_TYPES.TasksStore);
    const uiStore = container.get<UIStoreType>(IOC_TYPES.UIStore);
    const selectedTask = tasksStore.tasks.find((t) => t.id === selectedTasks?.[0]);
    const isSelectedTaskWBS = selectedTask?.object_type === TaskObjectType.summary;
    let confirmDelete = true;

    if (onlyOneTaskSelected && isSelectedTaskWBS) {
      confirmDelete = (await confirm({
        title: t('task:confirmation.delete.title', {activityName: selectedTask.name}),
        description: t(
          'task:confirmation.delete_with_child.text',
          'This is a parent activity. Deleting this activity will also delete all its sub-activities. Are you sure?',
        ),
        acceptButton: {
          title: t('task:confirmation.delete.accept_button', 'Delete'),
          type: 'danger',
        },
      })) as boolean;
    }

    if (taskIds.length && confirmDelete) {
      uiStore.setLoading(true);

      await updateFifoQueue.enqueue(async (idMap: Map<number, string>) => {
        try {
          const permIds = taskIds.map((id: number | string) => (typeof id === 'number' ? idMap.get(id) : id));
          return TasksApi.deleteTasks(projectId, permIds).then(({deletedTaskIds}) => {
            tasksStore.removeTasks(deletedTaskIds);
          });
        } catch (e) {
          Sentry.captureException(e);
          const message = extractAxiosError(e);
          if (typeof e === 'string') toast.error(message);
          tasksStore.loadTasks();
          uiStore.setLoading(false);
        }
      });

      uiStore.setLoading(false);
    }
  }

  return {indentSelectedTasks, outdentSelectedTasks, deleteSelectedTasks};
}
