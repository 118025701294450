import * as Sentry from '@sentry/react';
import cn from 'classnames';
import {GanttStatic} from 'dhtmlx-gantt';
import React, {ChangeEvent, FC, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';

import {TasksApi} from 'api';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {getTaskOpenIssuesIds, refreshTask} from 'modules/Tasks/utils/functions';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {safeFormatDate} from 'shared/helpers/dates';
import {AddIssueDayPayload, IssueModelDTO} from 'shared/models/task';

import {toGanttTaskModel} from '../../../mapping/task';
import Radios from '../../CoreForm/Radios';
import {Button, Icon, Loader, RadiosControl} from '../../index';
import CtrlButton from '../CtrlButton';

import s from './IssueUpdatesPopup.module.scss';

type Props = {
  gantt: GanttStatic;
  parent: GanttTask;
  date?: Date | null;
  closePopup: () => void;
};

const IssueUpdatesPopup: FC<Props> = ({gantt, parent, date, closePopup}: Props) => {
  const taskId = parent.id;
  const {t} = useTranslation(['gantt']);
  const taskOpenedIssueIds = getTaskOpenIssuesIds(parent.status_issue_task_ids_pairs);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState(false);
  const [issueDayType, setIssueDayType] = useState<AddIssueDayPayload['issueDayType']>('move');

  const {data: taskIssues, isLoading} = useQuery(
    ['taskIssues', taskId],
    async () => {
      return taskOpenedIssueIds.length ? await TasksApi.getTaskIssuesById(parent.projectId, taskOpenedIssueIds) : [];
    },
    {
      enabled: !!taskId && !!parent.projectId,
      refetchOnWindowFocus: false,
    },
  );

  const switchIssue = (dir: 'next' | 'prev') => {
    setActiveIndex((prev) => {
      if (dir === 'next') {
        return prev !== taskIssues.length - 1 ? ++prev : prev;
      }
      return prev === 0 ? prev : --prev;
    });
  };

  const isAllowAddIssueDay = useMemo(() => {
    const formattedDate = safeFormatDate(date, 'YYYY-MM-DD');
    if (!Object.keys(parent.per_date_override?.[formattedDate] ?? {}).length) {
      return true;
    }
    return !parent.per_date_override[formattedDate]['issue_day_type'];
  }, [parent, date]);

  const addIssueDay = async () => {
    if (isDisabledSubmitBtn && isAllowAddIssueDay) return;
    setIsDisabledSubmitBtn(true);
    try {
      const res = await TasksApi.addIssueDay(taskId, {
        affectedDate: safeFormatDate(date, 'YYYY-MM-DD'),
        issueDayType: issueDayType,
        issueIds: getTaskOpenIssuesIds(parent.status_issue_task_ids_pairs),
      });

      gantt.batchUpdate(() => {
        const {affectedTasks, task} = res;
        // for some reason the API does not return the "parent_task" field in affected tasks
        // so we use this method instead of updateGanttTask() to prevent task moving, because parent !== old_parent
        (affectedTasks || []).concat([task]).forEach((task) => {
          const {id, meta, parent, outline_sort_key, ...updates} = toGanttTaskModel(task);
          refreshTask(gantt, id, updates);
        });
      });

      toast.success('Task updated');
      closePopup();
      gantt.dRender();
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast.error(isAxiosError(e) ? extractAxiosError(e) : 'An error occurred while sending request.');
    } finally {
      setIsDisabledSubmitBtn(false);
    }
  };

  const onChangeIssueDayType = (e: ChangeEvent<HTMLInputElement>) => {
    setIssueDayType(e.target.value as AddIssueDayPayload['issueDayType']);
  };

  const issue: IssueModelDTO = taskIssues?.[activeIndex];

  if (isLoading) {
    return (
      <div className="loader-container" style={{width: '100%'}}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={s.issueUpdate}>
      <Button
        className={s.issueUpdate__closeButton}
        iconOnly
        icon={<Icon size={24} fill={false} colorFill name="clear" />}
        onClick={() => closePopup()}
      />
      <div className={s.issueUpdate__header}>
        <Button
          disabled={activeIndex === 0}
          onClick={() => switchIssue('prev')}
          className={s.issueUpdate__button}
          icon={<Icon size={24} fill={false} colorFill name="chevron-left" />}
          iconOnly
        />
        <h1 className={s.issueUpdate__titleBox}>
          <span className={cn(s.issueUpdate__title, s['issueUpdate__title--small'])}>Issue Name:</span>
          <span className={s.issueUpdate__title}>{issue?.name}</span>
        </h1>
        <Button
          iconOnly
          onClick={() => switchIssue('next')}
          disabled={activeIndex === taskIssues?.length - 1}
          className={s.issueUpdate__button}
          icon={<Icon size={24} fill={false} colorFill name="chevron-right" />}
        />
      </div>
      <div className={s.issueUpdate__body}>
        <span className={cn(s.issueUpdate__text, s['issueUpdate__text--block'])}>
          {t('gantt:contextMenu.create_issue_day.issue_assignee', 'Assignee')}: {issue?.responsible[0].memberName}
        </span>
        <span className={cn(s.issueUpdate__text, s['issueUpdate__text--block'])}>
          {t('gantt:contextMenu.create_issue_day.issue_description', 'Description')}: {issue?.description}
        </span>
        {isAllowAddIssueDay && (
          <>
            <span className={cn(s.issueUpdate__text, s['issueUpdate__text--center'])}>
              {t('gantt:contextMenu.create_issue_day.issue_day_type', 'Issue Day Type')}
            </span>
            <RadiosControl>
              <Radios.Field
                name="issueDayType"
                label={t('gantt:contextMenu.create_issue_day.move_issue_daytype', 'Move')}
              >
                <input
                  disabled={isDisabledSubmitBtn || !isAllowAddIssueDay}
                  type="radio"
                  value="move"
                  checked={issueDayType === 'move'}
                  onChange={onChangeIssueDayType}
                />
              </Radios.Field>
              <Radios.Field
                name="issueDayType"
                label={t('gantt:contextMenu.create_issue_day.overlap_issue_daytype', 'Overlap')}
              >
                <input
                  disabled={isDisabledSubmitBtn || !isAllowAddIssueDay}
                  type="radio"
                  value="overlap"
                  checked={issueDayType === 'overlap'}
                  onChange={onChangeIssueDayType}
                />
              </Radios.Field>
            </RadiosControl>
          </>
        )}
      </div>
      <CtrlButton
        className={s.issueUpdate__button}
        onClick={addIssueDay}
        disabled={isDisabledSubmitBtn || !isAllowAddIssueDay}
      >
        {t('gantt:contextMenu.create_issue_day.submit_btn', 'Add Issue Day')}
      </CtrlButton>
    </div>
  );
};
export default IssueUpdatesPopup;
