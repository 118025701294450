import React from 'react';
import {useTranslation} from 'react-i18next';

import {SkeletonFieldPreloader} from 'shared/components';
import CompletionAmountSlider from 'shared/components/CompletionAmountSlider/CompletionAmountSlider';
import {safeFormatDate} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks';
import {TaskDetailsModelDTO} from 'shared/models/task';

type Props = {
  task: TaskDetailsModelDTO;
  updateTask: (task: Partial<TaskDetailsModelDTO>) => void;
};

const AdditionalInfoPart = ({task, updateTask}: Props) => {
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.share;
  const {t} = useTranslation('shared_task');

  return (
    <div className="app-task-info__additional">
      <h3 className="app-task-info__additional-title">{t('additional.title', 'Additional info')}</h3>
      <div className="app-task-info__additional-group">
        <SkeletonFieldPreloader when={!task}>
          {!!task?.completionTarget && task?.completionUnit && (
            <div className="app-task-info__additional-item app-task-info__additional-item--progress">
              <span className="app-task-info__additional-label">{t('additional.progress', 'Current Progress')}</span>
              <span className="app-task-info__additional-value">{`${task?.completionAmount || 0}${
                task?.completionUnit
              }`}</span>
              <CompletionAmountSlider
                inline
                min={0}
                step={1}
                max={parseInt(task.completionTarget)}
                unit={task.completionUnit}
                value={parseInt(task.completionAmount) || 0}
                onChange={(value) => {
                  mixpanel.trackWithAction(
                    () => updateTask({id: task.id, completionAmount: String(value)}),
                    mixpanelEvents.updatesCompletionProgress,
                  );
                }}
              />
            </div>
          )}
        </SkeletonFieldPreloader>
        <SkeletonFieldPreloader when={!task}>
          <div className="app-task-info__additional-item">
            <span className="app-task-info__additional-label">{t('additional.start', 'Actual start date')}</span>
            <span className="app-task-info__additional-value">
              {task?.actualStartDate ? safeFormatDate(task?.actualStartDate, 'll') : t('not_applicable', 'N/A')}
            </span>
          </div>
        </SkeletonFieldPreloader>
        <SkeletonFieldPreloader when={!task}>
          <div className="app-task-info__additional-item">
            <span className="app-task-info__additional-label">{t('additional.end', 'Actual end date')}</span>
            <span className="app-task-info__additional-value">
              {task?.actualEndDate ? safeFormatDate(task?.actualEndDate, 'll') : t('additional.not_applicable', 'N/A')}
            </span>
          </div>
        </SkeletonFieldPreloader>
        <SkeletonFieldPreloader when={!task}>
          <div className="app-task-info__additional-item">
            <span className="app-task-info__additional-label">{t('additional.subcontractor', 'Subcontractor')}</span>
            <span className="app-task-info__additional-value">
              {task?.responsibleOrg?.group?.name || t('additional.not_applicable', 'N/A')}
            </span>
          </div>
        </SkeletonFieldPreloader>
        <SkeletonFieldPreloader when={!task}>
          <div className="app-task-info__additional-item">
            <span className="app-task-info__additional-label">{t('additional.location', 'Location')}</span>
            <span className="app-task-info__additional-value">
              {task?.location || t('additional.not_applicable', 'N/A')}
            </span>
          </div>
        </SkeletonFieldPreloader>
        <SkeletonFieldPreloader when={!task}>
          <div className="app-task-info__additional-item">
            <span className="app-task-info__additional-label">{t('additional.type', 'Task Type')}</span>
            <span className="app-task-info__additional-value">
              {task?.type || t('additional.not_applicable', 'N/A')}
            </span>
          </div>
        </SkeletonFieldPreloader>
      </div>
    </div>
  );
};
export default AdditionalInfoPart;
