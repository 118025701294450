/* eslint-disable @typescript-eslint/naming-convention */
export type TaskDependencyType = 'start_start' | 'start_finish' | 'finish_start' | 'finish_finish';

export interface TaskDependencyDto {
  id: string;
  delay: number;
  delayUnit: 'days' | 'hours';
  depTaskId: string;
  depType: TaskDependencyType;
  lagDays: number;
  predTaskId: string;
  taskId: string;
}

export const TaskDependencyToGanttLinkType: Record<TaskDependencyType, string> = {
  start_start: 'start_to_start',
  finish_finish: 'finish_to_finish',
  finish_start: 'finish_to_start',
  start_finish: 'start_to_finish',
};

export const GanttLinkTypeToTaskDependency: Record<string, TaskDependencyType> = {
  start_to_start: 'start_start',
  finish_to_finish: 'finish_finish',
  finish_to_start: 'finish_start',
  start_to_finish: 'start_finish',
};

export const TaskDependencyTypeTitle: Record<TaskDependencyType, string> = {
  finish_start: 'Finish to Start',
  start_start: 'Start to Start',
  start_finish: 'Start to Finish',
  finish_finish: 'Finish to Finish',
};

export const TaskDependencyTypeAbbr: Record<TaskDependencyType, string> = {
  finish_start: 'FS',
  start_start: 'SS',
  start_finish: 'SF',
  finish_finish: 'FF',
};

export const TaskDependencyTypeByAbbr: Record<string, TaskDependencyType> = Object.fromEntries(
  (Object.entries(TaskDependencyTypeAbbr) as [TaskDependencyType, string][]).map(
    ([k, v]: [TaskDependencyType, string]) => [v, k],
  ),
);

/*
 * t('task:dependency.types.finish_start', 'Finish to Start (F-S)')
 * t('task:dependency.types.start_start', 'Start to Start (S-S)')
 * t('task:dependency.types.start_finish', 'Start to Finish (S-F)')
 * t('task:dependency.types.finish_finish', 'Finish to Finish (F-F)')
 *
 * */
export const DEPENDENCIES_TYPES = [
  {label: 'finish_start', value: 'finish_start'},
  {label: 'start_start', value: 'start_start'},
  {
    label: 'start_finish',
    value: 'start_finish',
  },
  {
    label: 'finish_finish',
    value: 'finish_finish',
  },
] as {label: string; value: TaskDependencyType}[];

export enum DependencyMode {
  LagTime = 'lag_time',
  LeadTime = 'lead_time',
}

/*
 * t('task:dependency.modes.lag_time', 'Lag time (+)')
 * t('task:dependency.modes.lead_time', 'Lead Time (-)')
 * */
export const DEPENDENCIES_MODES = [
  {label: DependencyMode.LagTime, value: DependencyMode.LagTime},
  {label: DependencyMode.LeadTime, value: DependencyMode.LeadTime},
];

export interface TaskDependency extends TaskDependencyDto {
  rownum?: number;
}
