import cn from 'classnames';
import {Formik} from 'formik';
import {FormikProps} from 'formik/dist/types';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ExtractRouteParams, useParams} from 'react-router';
import {number, object, string, StringSchema, TypeOf} from 'yup';

import {TaskExtendedDependency} from 'modules/Tasks/components/SidebarPanel/components/DependenciesList/types';
import {FormikChangeWatcher, Loader} from 'shared/components';
import {CtrlBtnOption, CtrlButton, CtrlCard, FormControl} from 'shared/components/CoreNewUI';
import CtrlDrop from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import Icon from 'shared/components/Icon';
import {ROUTES} from 'shared/constants/routes';
import {DEFAULT_REQUIRED_MESSAGE} from 'shared/helpers/validationSchemas';
import {useCompany} from 'shared/hooks';
import {useEffectOnce} from 'shared/hooks/core';
import {DependencyMode, TaskDependencyType} from 'shared/models/TaskDependency';

import TaskAsyncSelect from '../../TaskAsyncSelect';
import DependencyForm from '../DependencyForm/DependencyForm';

import s from './Dependency.module.scss';

type DependencyProps = {
  className?: string;
  dependency?: Partial<TaskExtendedDependency>;
  onChange?: (values: FormValues) => Promise<void>;
  onDelete: () => Promise<void> | void;
  excludeTasks?: string[];
  disabled?: boolean;
};

const schema = object({
  predTask: object({value: string(), label: string(), uniqId: string().optional()})
    .required(DEFAULT_REQUIRED_MESSAGE)
    .nullable(),
  depType: string().required(DEFAULT_REQUIRED_MESSAGE).nullable() as StringSchema<TaskDependencyType>,
  mode: string().required(DEFAULT_REQUIRED_MESSAGE).nullable(),
  lagDays: number().required(DEFAULT_REQUIRED_MESSAGE),
});

const defaultFormValues = {
  predTask: null,
  depType: null,
  mode: DependencyMode.LagTime as string,
  lagDays: 0,
};

type FormValues = TypeOf<typeof schema>;

const Dependency = ({className, dependency, disabled, onChange, onDelete, excludeTasks}: DependencyProps) => {
  const company = useCompany();
  const formik = useRef<FormikProps<FormValues>>();
  const {projectId} = useParams<ExtractRouteParams<(typeof ROUTES)['task'], string>>();
  const [loading, setLoading] = useState(false);
  const container = useRef<HTMLDivElement>();
  const {t} = useTranslation(['task']);
  const hasId = !!dependency?.id;

  useEffectOnce(
    () => {
      container.current.scrollIntoView({behavior: 'smooth', block: 'center'});
    },
    [dependency],
    !dependency,
  );

  useEffect(() => {
    if (dependency) {
      formik.current.resetForm(
        typeof dependency === 'string'
          ? {values: defaultFormValues}
          : {
              values: {
                predTask: {
                  value: dependency.predTaskId,
                  label: dependency.title,
                  uniqId: dependency.uniqId,
                },
                mode: dependency.lagDays < 0 ? DependencyMode.LeadTime : DependencyMode.LagTime,
                lagDays: Math.abs(dependency.lagDays),
                depType: dependency.depType,
              },
            },
      );
    }
  }, [dependency]);

  const handleValuesChange = async (values: FormValues) => {
    if (formik.current.dirty && formik.current.isValid) {
      setLoading(true);
      try {
        await onChange(values);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await onDelete();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={defaultFormValues}
      onSubmit={() => undefined}
      innerRef={formik}
      validationSchema={schema}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {() => (
        <CtrlCard
          ref={container}
          className={cn(className, 'loader-container', s.dependency)}
          badge={dependency?.uniqId}
          titleField={
            <FormControl.Formik
              name="predTask"
              error={
                hasId && !disabled
                  ? t(
                      'task:dependencies.predecessor.validation.have_to_remove',
                      'Have to remove the current dependency',
                    )
                  : null
              }
              onClick={() => {
                if (hasId) {
                  formik.current.setFieldTouched('predTask', true, false);
                }
              }}
            >
              {({field, form}) => (
                <TaskAsyncSelect
                  className={s.predecessor__select}
                  name={field.name}
                  placeholder={t('task:dependencies.predecessor.placeholder', 'Type activity name')}
                  projectId={projectId}
                  companyId={company.id}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                  value={field.value}
                  onBlur={field.onBlur}
                  exclude={excludeTasks}
                  disabled={disabled || hasId}
                ></TaskAsyncSelect>
              )}
            </FormControl.Formik>
          }
          actions={
            disabled ? null : (
              <CtrlDrop
                menuWidth={120}
                viewportPosition="right"
                toggleElement={
                  <CtrlButton color="tertiary" icon="more_vertical" iconOnly size="s">
                    {t('task:dependencies.menu.title', 'Settings')}
                  </CtrlButton>
                }
              >
                <CtrlBtnOption
                  size="s"
                  onClick={handleDelete}
                  title={t('task:dependencies.menu.delete', 'Delete')}
                  icon={<Icon name="remove_from_trash" colorFill size={24} />}
                />
              </CtrlDrop>
            )
          }
        >
          <DependencyForm disabled={disabled} />
          <FormikChangeWatcher<FormValues> onChange={handleValuesChange} debounce={500} />
          {loading && <Loader />}
        </CtrlCard>
      )}
    </Formik>
  );
};

export default Dependency;
