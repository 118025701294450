import classNames from 'classnames';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Icon} from 'shared/components/index';

import {DEFAULT_COUNTRY_CODES} from '../../../constants/common';
import {CoreSelect, FormControl, PhoneInput} from '../index';

import css from './phoneControl.module.scss';

interface PhoneControlValue {
  code: string;
  phone: string;
}

type PhoneControlProps = {
  disabled: boolean;
  onChange: (value: PhoneControlValue) => void;
  value: PhoneControlValue;
  codeName?: string;
  phoneName?: string;
  children?: React.ReactNode;
  showTooltip?: boolean;
  className?: string;
};
function PhoneControl({
  disabled,
  onChange,
  value,
  codeName,
  phoneName,
  className,
  children,
  showTooltip = false,
}: PhoneControlProps) {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const getFormatOptionLabel = useCallback(({label, value}, {context}) => {
    return context === 'menu' ? `${value} ${label}` : value;
  }, []);

  const toggleTooltip = () => setTooltipVisible((prev) => !prev);

  const {t} = useTranslation('signup');

  const getTooltip = () => {
    if (tooltipVisible) {
      return (
        <div
          className="react-popper react-popper--help react-popper--with-button-close"
          style={{position: 'absolute', bottom: '100%', right: 0}}
        >
          <div className="react-popper__title">Verification code</div>
          <div className="react-popper__text">We&lsquo;ll send a verification code to your phone.</div>
          <Button
            className="react-popper__button-close"
            iconOnly
            icon={<Icon name="clear" onClick={toggleTooltip} className="ctrl-btn-clear__icon" colorFill size={24} />}
          >
            <span className="ctrl-btn-clear__text">Close help</span>
          </Button>
        </div>
      );
    }
  };

  return (
    <div className="ctrl-phone">
      <div className="ctrl-phone__item ctrl-phone__item--code">
        <FormControl label="Code" name={codeName || 'phoneCode'}>
          <CoreSelect
            className={className}
            options={DEFAULT_COUNTRY_CODES}
            isDisabled={disabled}
            value={value.code}
            onChange={(code) => onChange({code, phone: value.phone})}
            formatOptionLabel={getFormatOptionLabel}
          />
        </FormControl>
      </div>
      <div className="ctrl-phone__item ctrl-phone__item--number">
        <FormControl
          label="Phone Number"
          name={phoneName || 'phoneNumber'}
          {...(showTooltip && {
            iconHelpName: 'help',
            onHelpClick: toggleTooltip,
            tooltip: getTooltip(),
          })}
        >
          <PhoneInput
            countryCode={value.code}
            className={classNames('ctrl-textfield', {[css.ctrlTextField_disabled]: disabled})}
            value={value.phone}
            onChange={(e) => onChange({code: value.code, phone: (e.target as HTMLInputElement)?.value})}
            placeholder={t('field.phoneNumber.placeholder', 'Enter phone')}
            disabled={disabled}
          />
        </FormControl>
      </div>
      {children}
    </div>
  );
}
export default PhoneControl;
