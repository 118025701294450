import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect, useState} from 'react';

import {useDebounce} from 'shared/hooks/core';

export function useBulkActions<T>({gantt, projectId}: {gantt: GanttStatic; projectId: string}) {
  const [selected, setSelected] = useState(gantt?.getSelectedTasks ? gantt.getSelectedTasks() : []);
  const [bulkType, setBulkType] = useState<T>(null);

  const closeBulkModal = () => setBulkType(null);
  const openBulkModal = (type: T) => setBulkType(type);

  const updateSelections = useDebounce((gantt: GanttStatic) => {
    setSelected(gantt.getSelectedTasks());
  }, 100);

  const ganttSelectedTasksLength = gantt?.getSelectedTasks ? gantt.getSelectedTasks().length : 0;
  if (ganttSelectedTasksLength !== selected.length) {
    updateSelections(gantt);
  }

  useEffect(() => {
    if (gantt) {
      setSelected([]);
      const readyId = gantt.attachEvent(
        'onGanttReady',
        () => {
          updateSelections(gantt);
          gantt.detachEvent(readyId);
        },
        undefined,
      );
      const onSelectId = gantt.attachEvent(
        'onTaskMultiSelect',
        () => {
          updateSelections(gantt);
        },
        undefined,
      );
      return () => {
        setSelected([]);
        gantt.detachEvent(onSelectId);
        gantt.detachEvent(readyId);
      };
    }
  }, [gantt, projectId, updateSelections]);

  const clearSelection = () => {
    gantt.batchUpdate(() => {
      gantt.eachSelectedTask((task) => {
        gantt.unselectTask(task);
      });
    });
  };

  return {
    bulkType,
    setBulkType,
    selected,
    setSelected,
    clearSelection,
    openBulkModal,
    closeBulkModal,
  };
}
