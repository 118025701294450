import {useQuery} from 'react-query';

import {ProjectsApi} from 'api';

export const useGetSubcontractor = (projectId: string, subId: string) => {
  const {isLoading, data: projectSub} = useQuery(
    ['projectorgs', projectId, subId],
    () => {
      return ProjectsApi.getProjectSub(projectId, subId).then((res) => res);
    },
    {
      enabled: !!projectId && !!subId && subId !== 'new',
      refetchOnWindowFocus: false,
    },
  );

  return {isLoading, projectSub};
};
