import React, {FC} from 'react';

import {SkeletonPreloader} from 'shared/components';
import {CompanyInfoType} from 'shared/models/worker';

type Props = {
  companyInfo: CompanyInfoType;
};

const CompanyInfo: FC<Props> = ({companyInfo}: Props) => (
  <div className="company-short company-short--size-s company-short--view-card form-onboarding-z__company">
    <div className="company-short__container">
      <header className="company-short__header">
        <picture className="avatar company-short__media">
          <SkeletonPreloader when={!companyInfo} width="100%" height="100%" circle>
            <img
              className="avatar__image"
              src={companyInfo?.logoUrl || '/images-temp/avatars/avatar-company-0-256x256@1x.png'}
              alt="Name"
            />
          </SkeletonPreloader>
        </picture>

        <div className="company-short__group" style={{flexGrow: 1}}>
          <div className="company-short__name" style={{wordBreak: 'break-all'}}>
            <SkeletonPreloader when={!companyInfo}>{companyInfo?.companyName}</SkeletonPreloader>
          </div>
        </div>
      </header>
    </div>
  </div>
);

export default CompanyInfo;
