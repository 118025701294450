import {ObserverAction, ObserverActionSource} from 'services/TasksObserver/const';
import {TaskDetailsModelDTO, TaskGanttModelDTO, TaskProjection} from 'shared/models/task';

export function isLoadEvent(e: unknown): e is ObserverLoadEvent {
  return e && typeof e === 'object' && 'ids' in e;
}

export function isLoadEvents(events: ObserverBaseEvent[]): events is ObserverLoadEvent[] {
  return isLoadEvent(events[0]);
}

export type ObserverLoadEvent = {
  ids: string[];
} & ObserverBaseEvent;

export type ObserverData = TaskDetailsModelDTO[];
export type ObserverDataUnion = (Partial<TaskDetailsModelDTO> | Partial<TaskGanttModelDTO>)[];
export type ObserverUpdateEvent = {
  projection: TaskProjection;
  data?: Partial<TaskDetailsModelDTO>;
} & ObserverBaseEvent;

export type ObserverEventsDescription = {
  action?: ObserverAction;
  projectId: string;
  source?: ObserverActionSource;
  sourceName?: string;
  refetchRowNumbers?: boolean;
  meta?: {
    create?: {
      isPristine: boolean;
      index: number;
    };
    move?: {
      parent: string;
      index: number;
    };
  };
};

export type CreateEvent = {
  isPristine?: boolean;
  index?: number;
};

export type MoveEventParams = {
  parent: string;
  index: number;
};

export type CommonEventParams = {
  action?: ObserverAction;
};

export type EventParams = {
  [ObserverAction.create]: CreateEvent;
  [ObserverAction.move]: MoveEventParams;
  [ObserverAction.remove]: null;
  [ObserverAction.restore]: null;
  [ObserverAction.update]: null;
  [ObserverAction.archive]: null;
};

export type Events = {
  [Key in ObserverAction]: {action: Key} & (EventParams[Key] extends null | undefined
    ? {} /* eslint-disable-line @typescript-eslint/ban-types */
    : EventParams[Key]);
}[ObserverAction];

type ObserverBaseEvent = {
  action?: ObserverAction;
  projectId: string;
  source?: ObserverActionSource;
  sourceName?: string;
  refetchRowNumbers?: boolean;
  meta?: {
    create?: {
      isPristine: boolean;
      index: number;
    };
    move?: {
      parent: string;
      index: number;
    };
  };
};

export type ObserverEvent = ObserverLoadEvent | ObserverUpdateEvent;
