import cn from 'classnames';
import dayjs from 'dayjs';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import CtrlDates from 'modules/Tasks/components/ActionsBar/components/CtrlDates/CtrlDates';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {DatePicker} from 'shared/components';
import {CtrlButton, Dropdown} from 'shared/components/CoreNewUI';
import {IconsMap} from 'shared/constants/icons';
import {RouteParams} from 'shared/constants/routes';
import {formatDate, isAfter} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useProject} from 'shared/hooks/useProject';
import {WorkDaysEnum} from 'shared/models/project';

import styles from './DateDropdown.module.scss';

type DailieCalendarProps = {
  className?: string;
  active?: boolean;
};

export const DirectionMap = {
  forward: 'forward',
  backward: 'backward',
} as const;

export type Direction = keyof typeof DirectionMap;

const DailiesDateDropdown = ({className}: DailieCalendarProps) => {
  const {projectId} = useParams<RouteParams['tasks']>();
  const {queryParams, updateSearchParams, reset, viewMode} = useFilterContext();
  const {project} = useProject(projectId);
  const {t} = useTranslation(['filters', 'gantt']);
  const {isProjectAdmin} = useCompanyWorkerRoles(projectId);
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, isReadOnlyMode: !isProjectAdmin, viewMode}});
  const mixpanelEvents = mixpanel.events.tasks.toolbar;

  const alreadyToday = () => {
    const currentSchedEndFirst = dayjs(queryParams.schedEndFirst).startOf('day');
    const today = dayjs().startOf('day');
    return currentSchedEndFirst.isSame(today, 'day');
  };

  const selectedDate = useMemo(
    () => (queryParams.schedEndFirst ? new Date(queryParams.schedEndFirst) : null),
    [queryParams.schedEndFirst],
  );

  const date = formatDate(queryParams.schedEndFirst, 'MMMM D');

  const onChangeDate = (newDate: Date) => {
    const newSearchParams = {...queryParams, schedEndFirst: newDate};
    updateSearchParams(newSearchParams);
    mixpanel.track(mixpanelEvents.dateDropdown.selectDate);
  };

  const isDateInFuture = (nextDate: Date) => {
    // get today's date at the start of day to compare with `nextDate`
    const today = dayjs().startOf('day').toDate();

    return isAfter(nextDate, today);
  };

  const getNextDate = (direction: Direction) => {
    let dateDirection = dayjs(queryParams.schedEndFirst);

    do {
      // Based on the direction move the date by one day, either forward or backward
      dateDirection =
        direction === DirectionMap.forward ? dateDirection.add(1, 'day') : dateDirection.subtract(1, 'day');

      // Continue to shift the day until an enabled date is found
      // an enabled date is a working day that isn't an exception
    } while (!isDayEnabled(dateDirection.toDate()));

    return dateDirection;
  };

  const nextDateNavigation = () => {
    const nextDate = getNextDate(DirectionMap.forward);
    if (!isDateInFuture(nextDate.toDate())) {
      updateSearchParams({...queryParams, schedEndFirst: nextDate.toDate()});
      mixpanel.track(mixpanelEvents.dateDropdown.nextDay);
    }
  };

  const prevDateNavigation = () => {
    const prevDate = getNextDate(DirectionMap.backward);
    if (!isDateInFuture(prevDate.toDate())) {
      updateSearchParams({...queryParams, schedEndFirst: prevDate.toDate()});
      mixpanel.track(mixpanelEvents.dateDropdown.prevDay);
    }
  };

  const gotoToday = () => reset({newState: {schedWeeks: null, schedEndFirst: null}});

  const isDayEnabled = (date: Date) => {
    const exceptions = {};
    project.calendar.exceptions.forEach((exception) => {
      exceptions[exception.date] = exception.working;
    });

    const dayjsDate = dayjs(date);
    const day = dayjsDate.day();
    const dateString = dayjsDate.format('YYYY-MM-DD');

    // JavaScript's getDay() function returns: 0 (for Sunday) to 6 (for Saturday)
    // for dayjs, day() function also returns 0 (for Sunday) to 6 (for Saturday)

    const isWorkDay = project.calendar.workDays.includes(
      ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][day] as WorkDaysEnum,
    );

    const isException = exceptions[dateString] === false;
    return isWorkDay && !isException;
  };

  const footer = (
    <CtrlButton
      className={cn(styles.dateDropdownButton, styles.dateDropdownButton_reset)}
      disabled={alreadyToday()}
      onClick={gotoToday}
    >
      {t('filters:dropdown.goto_today')}
    </CtrlButton>
  );

  const renderPrevButton = (
    <CtrlButton
      color="clear"
      icon={IconsMap['chevron-left']}
      iconOnly={true}
      onClick={prevDateNavigation}
      size="xs"
      title={t('filters:dropdown.prev_button')}
    >
      {t('filters:dropdown.prev_button')}
    </CtrlButton>
  );

  const renderNextButton = (
    <CtrlButton
      color="clear"
      icon={IconsMap['chevron-right']}
      iconOnly={true}
      onClick={nextDateNavigation}
      size="xs"
      title={t('filters:dropdown.next_button')}
      disabled={isDateInFuture(getNextDate(DirectionMap.forward).toDate())}
    >
      {t('filters:dropdown.next_button')}
    </CtrlButton>
  );

  const handleOpenDateFilter = () => {
    mixpanel.track(mixpanel.events.dailies.clickDailiesDatePicker);
  };

  const toggleElement = (
    <CtrlDates nextButton={renderNextButton} onClick={handleOpenDateFilter} prevButton={renderPrevButton} text={date} />
  );

  return (
    <Dropdown
      className={cn(styles.dateDropdown, className)}
      color="second"
      footer={footer}
      toggleElement={toggleElement}
    >
      <DatePicker
        maxDate={dayjs().startOf('day').toDate()}
        inline
        isClearable={false}
        dateFormat="dd/MM/yy"
        onChange={onChangeDate}
        selected={selectedDate}
        filterDate={isDayEnabled}
      />
    </Dropdown>
  );
};

export default DailiesDateDropdown;
