export const MAX_ABBREV_LENGTH = 3;

export const flatSubs = (subsArr) => subsArr?.reduce((prev, cur) => prev.concat(cur), []) || [];

export type SubcontractorFormValues = {
  name: string;
  abbrev?: string;
  lookaheadColor?: string;
  contactWorkerId?: string;
};
