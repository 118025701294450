import React from 'react';
import {CellProps, Renderer} from 'react-table';

import {CompanyWorker} from 'shared/models/worker';

type CenteredColumnProps = {
  nbWorkers: number;
  onClick: () => void;
};

export const CenteredContainer = ({children, ...props}) => {
  const centerStyles = {display: 'flex', alignItems: 'center', height: '100%'};
  return (
    <div style={centerStyles} {...props}>
      {children}
    </div>
  );
};

export const CenteredColumn: Renderer<CellProps<CompanyWorker, unknown>> = ({value}) => {
  return <CenteredContainer>{value}</CenteredContainer>;
};

export const CenteredColumnSubcontractors: Renderer<CellProps<CompanyWorker, unknown>> = ({
  value: {nbWorkers = 0, onClick},
}: CellProps<CenteredColumnProps>) => {
  return <CenteredContainer onClick={onClick}>{nbWorkers}</CenteredContainer>;
};
