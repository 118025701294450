/* eslint-disable no-invalid-this */
import React, {SyntheticEvent, Component, createRef, ReactNode} from 'react';

type SliderProps = {
  min: number;
  max: number;
  value: number;
  change(value: number): void;
  step?: number;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
};

export function isNotTouchEvent(e: React.TouchEvent) {
  return e.touches.length > 1 || (e.type.toLowerCase() === 'touchend' && e.touches.length > 0);
}

class Slider extends Component<SliderProps> {
  static defaultProps = {
    step: 0.1,
  };
  drag = createRef<HTMLSpanElement>();
  line = createRef<HTMLDivElement>();

  get offset() {
    return (this.props.value / this.props.max) * 100;
  }

  get currentStepNumber() {
    return Math.round(this.props.value / this.props.step);
  }

  get size() {
    return Math.floor((this.props.max - this.props.min) / this.props.step);
  }

  onClick = (value: number) => {
    if (value >= this.props.min && value <= this.props.max) {
      this.props.change(value);
    } else {
      this.props.change(value < this.props.min ? this.props.min : this.props.max);
    }
  };

  goToStepNumber(stestepNumber: number) {
    if (stestepNumber !== this.currentStepNumber) {
      if (stestepNumber === 0) {
        return this.props.change(0);
      }
      this.props.change(stestepNumber * this.props.step);
    }
  }

  handlePosition(coordinate: number) {
    const {left, width} = this.line.current.getBoundingClientRect();
    const diff = coordinate - left;
    if (diff >= 0 && diff <= width) {
      const stepSize = width / this.size;
      const stepNumber = Math.round(diff / stepSize);
      this.goToStepNumber(stepNumber);
    }
    if (diff < 0) {
      this.goToStepNumber(0);
    }
    if (diff > width) {
      this.goToStepNumber(this.size);
    }
  }

  handleMousePosition = (e: MouseEvent | React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.handlePosition(e.clientX);
  };

  handleTouchPositon = (e: TouchEvent | React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.handlePosition(e.touches[0].clientX);
  };

  onMouseDown = (e: SyntheticEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
    document.addEventListener('mousemove', this.handleMousePosition);
    document.addEventListener('mouseup', this.clear);
  };

  onTouchStart = (e: React.TouchEvent) => {
    if (isNotTouchEvent(e)) return;

    e.preventDefault();
    e.stopPropagation();

    document.addEventListener('touchmove', this.handleTouchPositon);
    document.addEventListener('touchend', this.clear);
  };

  clear = () => {
    document.removeEventListener('mousemove', this.handleMousePosition);
    document.removeEventListener('touchmove', this.handleTouchPositon);
    document.removeEventListener('mouseup', this.clear);
    document.removeEventListener('touchend', this.clear);
  };

  componentWillUnmount() {
    this.clear();
  }

  render() {
    const {value, step, iconLeft, iconRight} = this.props;
    const {onMouseDown, onTouchStart, onClick, line, drag, offset, handleMousePosition} = this;
    const leftPosition = offset + '%';
    return (
      <div className="ctrl-crop__group ctrl-crop__group--zoom">
        <button
          className="ctrl-btn-clear ctrl-crop__button ctrl-crop__button--zoom"
          type="button"
          onClick={() => onClick(value - step)}
        >
          <span className="ctrl-btn-clear__text">Zoom Out</span>
          {iconLeft}
        </button>
        <div ref={line} className="ctrl-slider ctrl-crop__slider" onClick={handleMousePosition}>
          <span className="ctrl-slider__progress" style={{width: leftPosition}}></span>
          <span
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            ref={drag}
            className="ctrl-slider__drag"
            style={{left: leftPosition}}
          ></span>
        </div>
        <button
          className="ctrl-btn-clear ctrl-crop__button ctrl-crop__button--zoom"
          type="button"
          onClick={() => onClick(value + step)}
        >
          <span className="ctrl-btn-clear__text">Zoom In</span>
          {iconRight}
        </button>
      </div>
    );
  }
}
export default Slider;
