import equal from 'fast-deep-equal';
import {Formik, FormikProps} from 'formik';
import React, {useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {Icon} from 'shared/components';
import {Autocomplete, CtrlBtnOption, CtrlButton, CtrlSwitcher, Dropdown} from 'shared/components/CoreNewUI';
import {IconsMap} from 'shared/constants/icons';
import {RouteParams} from 'shared/constants/routes';
import {toTitleCase} from 'shared/helpers/common';
import {useAnalyticsService, useTasksUrlState} from 'shared/hooks';
import {useDebounce} from 'shared/hooks/core';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {TaskFilterQuery} from 'shared/models/task';

import styles from '../ActionsBar/components/FilterDropdown/FilterDropdown.module.scss';
import {useProjectSubcontractorOptions} from '../ActionsBar/components/FilterDropdown/useProjectSubcontractorOptions';
import {useResponsibleOptions} from '../ActionsBar/components/FilterDropdown/useResponsibleOptions';
import {WBSAutocomplete} from '../ActionsBar/components/FilterDropdown/WBSAutocomplete';
import SearchField from '../ActionsBar/components/SearchField/SearchField';
import {getFilterTabs, useFilterContext} from '../Filters';

type DailiesFilterDropdownProps = {
  className?: string;
  active?: boolean;
  sideContentDirection?: 'left' | 'right';
};

const DailiesFilterDropdown = ({className, active, sideContentDirection = 'left'}: DailiesFilterDropdownProps) => {
  const tasksState = useTasksUrlState();
  const {projectId} = useParams<RouteParams['tasks']>();
  const {t} = useTranslation(['filters', 'gantt']);
  const {updateSearchParams, onSelectState, reset, queryParams, taskState, filtersState} = useFilterContext();
  const {viewMode} = useFilterContext();
  const {isProjectAdmin} = useCompanyWorkerRoles(projectId);
  const responsibleOptions = useResponsibleOptions(projectId);
  const tabs = useMemo(() => getFilterTabs(t), [t]);
  const {data: subcontractorOptions, isLoading: isLoadingSubcontractors} = useProjectSubcontractorOptions(projectId);

  const {
    mixpanel: {events, ...mixpanel},
  } = useAnalyticsService({extraMeta: {projectId, isReadOnlyMode: !isProjectAdmin, viewMode}});
  const formik = useRef<FormikProps<TaskFilterQuery>>(null);

  const syncFormikStateWithQuery = useDebounce(() => {
    if (!equal(formik.current?.values, queryParams)) {
      formik.current?.setValues(queryParams);
    }
  }, 500);

  useEffect(() => {
    syncFormikStateWithQuery();
  }, [queryParams]);

  const onSearchInputChange = (q: string) => {
    updateSearchParams({...queryParams, q});
  };

  const applyFilters = (values: TaskFilterQuery) => {
    if (!equal(queryParams, values)) {
      updateSearchParams({...queryParams, ...values});
    }
  };

  return (
    <Dropdown
      viewportPosition="right"
      className={className}
      toggleElement={
        <SearchField
          name="filterSearch"
          onChange={onSearchInputChange}
          value={queryParams.q}
          active={active}
          className={styles.filterDropdown__input}
          inputClassName={styles.filterDropdown__input_size}
        />
      }
      toggleElementId="toolbarFiltersToggle"
      header={{
        title: t('filters:dropdown.header', 'Filters'),
        button: {
          title: t('filters:dropdown.reset', 'Reset all'),
          onClick: () => reset({exclude: ['q', 'schedWeeks', 'schedEndFirst', 'showPastDue']}),
        },
      }}
    >
      <CtrlSwitcher view="block">
        {tabs.map((tab) => {
          return (
            <CtrlButton
              key={tab.state}
              color="tertiary"
              view="selector"
              selected={tasksState === tab.state}
              onClick={() =>
                mixpanel.trackWithAction(() => onSelectState(tab.state), events.tasks.taskList(toTitleCase(tab.state)))
              }
            >
              {tab.title}
            </CtrlButton>
          );
        })}
      </CtrlSwitcher>
      <Formik<TaskFilterQuery>
        innerRef={formik}
        onSubmit={applyFilters}
        initialValues={filtersState.current[viewMode][taskState]}
      >
        {({handleSubmit, values, setFieldValue}) => {
          return (
            <>
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.responsible', 'Responsible')}
                countSelected={values.responsible ? 1 : 0}
                iconRight={
                  values.responsible ? (
                    <Icon name={IconsMap.clear} colorFill onClick={() => setFieldValue('responsible', null)} />
                  ) : null
                }
                icon={<Icon colorFill name={IconsMap.assign} />}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    items={responsibleOptions}
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(
                        () => setFieldValue(name, value),
                        events.gantt.filterDropdown.responsible,
                      )
                    }
                    selected={values.responsible}
                    name="responsible"
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.subcontractor', 'Subcontractor')}
                countSelected={values.responsibleOrgIds.length}
                icon={<Icon colorFill name={IconsMap.subcontractor} />}
                iconRight={values.responsibleOrgIds.length ? <Icon name="clear" colorFill /> : null}
                onClick={() => setFieldValue('responsibleOrgIds', [])}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    items={subcontractorOptions}
                    name="responsibleOrgIds"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(
                        () => setFieldValue(name, value),
                        events.gantt.filterDropdown.subcontractor,
                      )
                    }
                    selected={values.responsibleOrgIds}
                    disabled={isLoadingSubcontractors}
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.wbs', 'WBS')}
                countSelected={values.outlineSortKeyPrefixList.length}
                icon={<Icon colorFill name={IconsMap.parenttask} />}
                iconRight={
                  values.outlineSortKeyPrefixList.length ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('outlineSortKeyPrefixList', [])} />
                  ) : null
                }
                nestedContentPlacement={sideContentDirection}
                nested={
                  <WBSAutocomplete
                    projectId={projectId}
                    taskState={tasksState}
                    isMulti
                    onSelect={setFieldValue}
                    selected={values.outlineSortKeyPrefixList}
                    name="outlineSortKeyPrefixList"
                  />
                }
              />
              <CtrlButton
                style={{width: '100%', marginTop: '15px'}}
                type="submit"
                onClick={() => mixpanel.trackWithAction(handleSubmit, events.gantt.filterDropdown.applyButton)}
              >
                {t('filters:dropdown.apply', 'Apply')}
              </CtrlButton>
            </>
          );
        }}
      </Formik>
    </Dropdown>
  );
};

export default DailiesFilterDropdown;
