import dayjs from 'dayjs';
import {Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters';
import ImageGallery from 'modules/Tasks/components/SidebarPanel/IssuePanel/components/ImageGallery/ImageGallery';
import {Icon, Tooltip} from 'shared/components';
import {IconsMap} from 'shared/constants/icons';
import {useDistinctProjectWorkers} from 'shared/hooks';

import {DailiesReportCard} from '../DailiesReportCard';
import {DailyCardSeparator} from '../DailiesReportCard/DailyCardSeparator';
import {useDailiesGeneralComments} from '../hooks/useDailiesGeneralComments';

export function DailiesGeneralComments() {
  const {projectId} = useParams<{projectId: string}>();
  const {t} = useTranslation('dailies');
  const {data, isLoading} = useDailiesGeneralComments();
  const {queryParams} = useFilterContext();
  const {projectWorkers} = useDistinctProjectWorkers(projectId);

  if (!data?.length || isLoading) {
    return null;
  }

  return (
    <>
      <h3 className="daily-report-report-title">
        {t('dailies_report.general_comments.title', {date: dayjs(queryParams.schedEndFirst).format('MMMM D, YYYY')})}
      </h3>
      <DailiesReportCard>
        {data.map((commentGroup) => {
          const worker = projectWorkers.find(
            (worker) => worker.workerFull.id === commentGroup.comment.workerId || commentGroup.pdf.workerId,
          );
          const formattedTimeCreated = dayjs(commentGroup.comment.timeCreated).format('h:mm A');
          const workerFullName = worker?.workerFull?.fullName || worker?.workerFull?.email;
          const isPdf = !!commentGroup.pdf.id;
          const icon = isPdf ? (
            <Tooltip text={t('dailies_report.comments.snapshot.tooltip')}>
              <span>
                <Icon
                  className="dailies-card-comments-comment-header--icon"
                  name={IconsMap.info_outlined}
                  height={12}
                  width={12}
                />
              </span>
            </Tooltip>
          ) : (
            <span>
              <Icon
                className="dailies-card-comments-comment-header--icon"
                name={IconsMap['comment-vs']}
                height={12}
                width={12}
              />
            </span>
          );
          return (
            <Fragment key={commentGroup.key}>
              <aside className="dailies-card-comments">
                <div>
                  <div className="dailies-card-comments-comment-header">
                    <span className="dailies-card-comments-comment-header--heading">
                      {isPdf ? t('dailies_report.comments.snapshot.title') : t('dailies_report.comments.comment')}
                    </span>
                    {icon}
                  </div>
                  <div>
                    <p>{commentGroup.comment.body}</p>
                    {isPdf ? (
                      <a href={commentGroup.pdf.url} target="_blank" rel="noreferrer">
                        {commentGroup.pdf.body}
                      </a>
                    ) : null}
                  </div>
                  <div className="dailies-card-comments-issue--submitter">
                    <p>
                      <Trans
                        i18nKey="dailies:dailies_report.comments.comments.submitter"
                        components={{
                          span: <span className="daily-report-card__report-metrics-submitter-name" />,
                        }}
                        values={{formattedTimeCreated, workerFullName}}
                      />
                    </p>
                  </div>
                  {commentGroup.images && commentGroup.images.length > 0 && (
                    <ImageGallery
                      className="dailies-card-comments-image-thumbnails"
                      displayGalleryCopy={false}
                      imageUrlList={commentGroup.images}
                      isLoading={false}
                    />
                  )}
                </div>
              </aside>
              <DailyCardSeparator />
            </Fragment>
          );
        })}
      </DailiesReportCard>
    </>
  );
}
