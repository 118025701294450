import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {getContactContentByKey} from '../contentHelper';

type HeroContactSectionProps = {
  type: string;
};

const HeroContactSection: FC<HeroContactSectionProps> = ({type}) => {
  const {t} = useTranslation('contacts');
  const content = getContactContentByKey(type, t);
  return (
    <section className="hero hero--contacts">
      <div className="box">
        <div className="hero__container">
          <h1 className="hero__title">{content.title}</h1>
          <div className="hero__description">{content.description}</div>
        </div>
      </div>
    </section>
  );
};
export default HeroContactSection;
