import {Formik} from 'formik';
import React, {FC, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useHistory, useParams} from 'react-router';

import {ProjectsApi, WorkerApi} from 'api';
import {isFilterFieldsDirty} from 'modules/Tasks/components/Filters';
import {DEFAULT_FILTER_PARAMS, EXCLUDED_FIELDS_FROM_FILTER} from 'modules/Workers/utils/constants';
import {FormikChangeWatcher, Icon, OptionType} from 'shared/components';
import {Autocomplete, CtrlBtnOption, CtrlButton, Dropdown} from 'shared/components/CoreNewUI';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {getCompanyWorkersStatuses} from 'shared/constants/statuses';
import {toTitleCase} from 'shared/helpers/common';
import {prepareQueryString} from 'shared/helpers/queryParams';
import {getNormalizedWorkerTrade, prepareWorkerFiltersForUrl} from 'shared/helpers/worker';
import {useAnalyticsService} from 'shared/hooks';
import {usePrevious} from 'shared/hooks/core';
import {CompanyWorkerFilterParams} from 'shared/models/worker';
import {getWorkerMixpanelLabel} from 'shared/utils/inviteWorker';

type Props = {
  queryParams: CompanyWorkerFilterParams;
  isProjectWorkers: boolean;
};

const FilterDropdown: FC<Props> = ({queryParams, isProjectWorkers}: Props) => {
  const history = useHistory();
  const {projectId} = useParams<{projectId: string}>();
  const prevProjectId = usePrevious(projectId);
  const formik = useRef(null);
  const {t} = useTranslation(['filters', 'common']);
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const mixpanelEvents = mixpanel.events.workers.filterDropdown;
  const isDirty = isFilterFieldsDirty({
    queryParams: queryParams,
    initialValues: DEFAULT_FILTER_PARAMS,
  });
  useEffect(() => {
    if (prevProjectId && projectId !== prevProjectId) {
      formik.current.setValues(DEFAULT_FILTER_PARAMS);
    }
  }, [projectId, prevProjectId, DEFAULT_FILTER_PARAMS]);

  const {data: tradesOptions} = useQuery('workerTrades', () => {
    return WorkerApi.getTradeOptions().then((res) =>
      res.map((label) => ({value: label, label: label === 'HVAC' ? label : toTitleCase(label)})),
    );
  });

  const translatedTradeOptions = useMemo(() => {
    return (
      tradesOptions?.map((option) => {
        return {
          ...option,
          label: getNormalizedWorkerTrade(option.value, t),
        };
      }) || []
    );
  }, [tradesOptions, t]);

  const {data: orgsOptions} = useQuery(
    QUERY_CACHE_KEYS.projectSubcontractors(projectId),
    () => {
      return ProjectsApi.getOrgs(projectId);
    },
    {
      enabled: !!projectId,
      select: (orgs) => {
        return orgs.map((org) => ({value: org.group.name, label: org.group.name, id: org.id} as OptionType));
      },
    },
  );

  const updateSearchParams = (values: CompanyWorkerFilterParams) => {
    history.push({
      pathname: location.pathname,
      search: prepareQueryString({
        values: {
          ...values,
          wildcard: queryParams.wildcard,
        },
        prepareFunction: prepareWorkerFiltersForUrl,
      }),
      // TODO: delete state within the task: https://journey-builders.atlassian.net/browse/CNA-3017
      state: history.location.state,
    });
  };

  const resetFilters = () => {
    formik.current.setValues(DEFAULT_FILTER_PARAMS);
  };

  const onChangeFormField = (fieldName: string, value: string | string[]) => {
    const capitalizedField = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    mixpanelEvents.selected(getWorkerMixpanelLabel(!!projectId), `${capitalizedField}`);
    formik.current.setFieldValue(fieldName, value);
  };

  const isActive = (): boolean => {
    const fieldsToCheck = Object.keys(DEFAULT_FILTER_PARAMS).filter((k) => !EXCLUDED_FIELDS_FROM_FILTER.includes(k));
    for (const field of fieldsToCheck) {
      if (queryParams[field] !== DEFAULT_FILTER_PARAMS[field]) {
        return true;
      }
    }
    return false;
  };

  return (
    <Formik<CompanyWorkerFilterParams> initialValues={queryParams} onSubmit={() => null} innerRef={formik}>
      <>
        <FormikChangeWatcher onChange={updateSearchParams} debounce={500} />
        <Dropdown
          toggleElement={
            <CtrlButton
              color="second"
              icon="filter"
              active={isActive()}
              activeDot={isDirty}
              onClick={() => mixpanel.track(mixpanelEvents.button(getWorkerMixpanelLabel(!!projectId)))}
            >
              {t('filters:dropdown.title')}
            </CtrlButton>
          }
          header={{
            title: t('filters:dropdown.header'),
            button: {title: t('filters:dropdown.reset'), onClick: resetFilters},
          }}
        >
          <CtrlBtnOption
            openOnHover
            title={t('filters:workers.options.trade')}
            countSelected={queryParams.trade ? 1 : 0}
            icon={<Icon colorFill name="subcontructor" />}
            nested={
              <Autocomplete
                items={translatedTradeOptions}
                name="trade"
                onSelect={onChangeFormField}
                selected={queryParams.trade}
              />
            }
          />
          <CtrlBtnOption
            openOnHover
            title={t('filters:workers.options.status')}
            countSelected={queryParams.blendedStatus ? 1 : 0}
            icon={<Icon colorFill name="status" />}
            nested={
              <Autocomplete
                items={getCompanyWorkersStatuses(t)}
                name="blendedStatus"
                onSelect={onChangeFormField}
                selected={queryParams.blendedStatus as string}
              />
            }
          />
          {isProjectWorkers && (
            <CtrlBtnOption
              openOnHover
              title={t('filters:workers.options.organization')}
              countSelected={queryParams.orgList.length}
              icon={<Icon colorFill name="company" />}
              nested={
                <Autocomplete
                  items={orgsOptions}
                  name="orgList"
                  onSelect={onChangeFormField}
                  selected={queryParams.orgList[0]}
                />
              }
            />
          )}
        </Dropdown>
      </>
    </Formik>
  );
};
export default FilterDropdown;
