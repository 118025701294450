import './styles.scss';

import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters';
import {Button, Icon, TaskStatus, Tooltip} from 'shared/components';
import {HSpacer} from 'shared/components/Spacer';
import {TasksViewMode} from 'shared/constants/common';
import {IconsMap} from 'shared/constants/icons';
import {diff} from 'shared/helpers/dates';
import {TaskActiveTab} from 'shared/models/task';

import {useFetchDailiesIssues} from './hooks/useFetchDailiesIssues';

export interface IssuesTableHeader {
  id: string;
  name: string;
  tooltip?: string;
}

export function DailiesReportIssues() {
  const {queryParams} = useFilterContext();
  const {issues, isLoading} = useFetchDailiesIssues();
  const {t} = useTranslation('dailies');
  const history = useHistory();

  const rowMap = {
    actions: t('dailies_report.issues.table.actions', 'Actions'),
    activitiesImpacted: t('dailies_report.issues.table.activities_impacted', 'Activities Impacted'),
    addActivity: t('dailies_report.issues.table.add_activity', 'Activity'),
    description: t('dailies_report.issues.table.description', 'Description'),
    name: t('dailies_report.issues.table.name', 'Name'),
    status: t('dailies_report.issues.table.status'),
    totalIssueDays: t('dailies_report.issues.table.total_issue_days', 'Total Issue Days'),
    tooltip: t('dailies_report.issues.table.total_issue_days_tooltip'),
  } as const;

  const tableHeaderRow: IssuesTableHeader[] = [
    {
      name: rowMap.name,
      id: 'name',
    },
    {
      name: rowMap.description,
      id: 'description',
    },
    {
      name: rowMap.totalIssueDays,
      id: 'totalIssueDays',
      tooltip: rowMap.tooltip,
    },
    {
      name: rowMap.status,
      id: 'status',
    },
    {
      name: rowMap.activitiesImpacted,
      id: 'activitiesImpacted',
    },
    {
      name: rowMap.actions,
      id: 'addActivity',
    },
  ];

  const handleAddActivity = (taskId: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set('view', TasksViewMode.issues);
    history.push({
      pathname: history.location.pathname,
      search: `?${searchParams.toString()}`,
      state: {taskId, activeTab: TaskActiveTab.info},
    });
  };

  const total = issues.filter((issue) => {
    const startDate = dayjs(issue.startDate).startOf('day');
    const selectedDay = dayjs(queryParams.schedEndFirst).startOf('day');
    const totalIssueDays = diff(selectedDay, startDate);
    return totalIssueDays >= 0;
  }).length;

  if (!total || isLoading) {
    return null;
  }

  return (
    <>
      <header className="issues-table-header">
        <h3>{t('dailies_report.issues.title')}</h3>
      </header>
      <div className="tables-container">
        {/* Open Issues */}
        <div className="issues-table-card">
          <table className="issues-table">
            <caption>{t('dailies_report.issues.open_issues_table_title', {total})}</caption>
            <thead>
              <tr>
                {tableHeaderRow.map((row) => (
                  <th key={row.id}>
                    <div className={row.id}>
                      <p>{row.name}</p>
                      {row.tooltip ? (
                        <>
                          <HSpacer />
                          <Tooltip placement="right" text={row.tooltip}>
                            <Button iconOnly icon={<Icon name={IconsMap.help_outlined} />} />
                          </Tooltip>
                        </>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {issues?.map((issue) => {
                const startDate = dayjs(issue.startDate).startOf('day');
                const selectedDay = dayjs(queryParams.schedEndFirst).startOf('day');
                const totalIssueDays = diff(selectedDay, startDate);
                if (totalIssueDays < 0) {
                  return null;
                }

                return (
                  <tr key={issue.id}>
                    <td data-cell={rowMap.name}>{issue.name}</td>
                    <td data-cell={rowMap.description}>{issue.description}</td>
                    <td data-cell={rowMap.totalIssueDays}>{totalIssueDays}</td>
                    <td data-cell={rowMap.status}>
                      <TaskStatus value={issue.status} />
                    </td>
                    <td data-cell={rowMap.activitiesImpacted}>{issue.taskIds?.length || 0}</td>
                    {issue.taskIds?.length === 0 ? (
                      <td data-cell="add-activity">
                        <Button
                          buttonSize="xs"
                          className="add_activity_btn"
                          colorStyle="light"
                          onClick={() => handleAddActivity(issue.id)}
                          rightAddon={<Icon className="add_activity_btn-icon" name={IconsMap['add-plus']} size={12} />}
                          suffix="add_activity_btn"
                        >
                          {rowMap.addActivity}
                        </Button>
                      </td>
                    ) : (
                      <td>&nbsp;</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Future suggested issues */}
        {/* <div className="issues-table-card">
          <table className="issues-table">
            <caption>Issues in play: {issues?.length || 0}</caption>
            <thead>
              <tr>
                {tableHeaderRow.map((row) => (
                  <th key={row.id}>
                    <div className={row.id}>
                      <p>{row.name}</p>
                      {row.tooltip ? (
                        <>
                          <HSpacer />
                          <Tooltip placement="right" text={row.tooltip}>
                            <Button iconOnly icon={<Icon name={IconsMap.help_outlined} />} />
                          </Tooltip>
                        </>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {issues?.map((issue) => {
                const startDate = dayjs(issue.startDate).startOf('day');
                const selectedDay = dayjs(queryParams.schedEndFirst).startOf('day');
                const totalIssueDays = diff(selectedDay, startDate);
                return (
                  <tr key={issue.id}>
                    <td data-cell={t('dailies_report.issues.table.name')}>{issue.name}</td>
                    <td data-cell={t('dailies_report.issues.table.description')}>{issue.description}</td>
                    <td data-cell={t('dailies_report.issues.table.total_issue_days')}>{totalIssueDays}</td>
                    <td data-cell={t('dailies_report.issues.table.activities_impacted')}>
                      {issue.taskIds?.length || 0}
                    </td>
                    {issue.taskIds?.length === 0 ? (
                      <td data-cell="add-activity">
                        <Button
                          buttonSize="xs"
                          className="add_activity_btn"
                          colorStyle="light"
                          icon={<Icon name={IconsMap['add-plus']} size={12} />}
                          onClick={() => handleAddActivity(issue.id)}
                          suffix="add_activity_btn"
                        >
                          {t('dailies_report.issues.table.add_activity')}
                        </Button>
                      </td>
                    ) : (
                      <td>&nbsp;</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
}
