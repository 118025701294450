import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import {TaskStatus} from 'shared/models/task';

import {AssigneesFilterWorker} from './types';

type TaskLoadProps = {
  worker: AssigneesFilterWorker;
  isFetching: boolean;
};

enum TaskGroup {
  completed = 'completed',
  issues = 'issues',
  tbaAndAssignees = 'tbaAndAssignees',
  inProgress = 'inProgress',
  pastDue = 'pastDue',
}

const taskStatusGroup: {[key in keyof AssigneesFilterWorker['taskLoad']]: TaskGroup | null} = {
  [TaskStatus.unblocked]: TaskGroup.tbaAndAssignees,
  [TaskStatus.tba]: TaskGroup.tbaAndAssignees,
  [TaskStatus.assigned]: TaskGroup.tbaAndAssignees,
  [TaskStatus.inProgress]: TaskGroup.inProgress,
  [TaskStatus.blocked]: TaskGroup.issues,
  [TaskStatus.rework]: TaskGroup.issues,
  [TaskStatus.notDone]: TaskGroup.issues,
  [TaskStatus.done]: TaskGroup.completed,
  [TaskStatus.verified]: TaskGroup.completed,
  [TaskStatus.approved]: TaskGroup.completed,
  inProgress: TaskGroup.inProgress,
  pastDue: TaskGroup.pastDue,
  closed: null,
  archived: null,
};

const TaskLoad: FC<TaskLoadProps> = ({worker, isFetching}) => {
  const {taskLoad} = worker;
  const {t} = useTranslation('task');

  const tasksAmount = useMemo(() => {
    const taskLoadArray = Object.entries(taskLoad || {});
    const total = taskLoadArray.reduce((acc, [_, value]) => (acc += value), 0);
    return taskLoadArray.reduce(
      (acc, [key, value]) => {
        const name = taskStatusGroup[key] as TaskGroup;
        if (name) {
          acc[name].amount += value;
          acc[name].percent = acc[name].amount > 0 ? (acc[name].amount / acc.total) * 100 : 0;
        }
        return acc;
      },
      {
        total,
        completed: {amount: 0, percent: 0},
        issues: {amount: 0, percent: 0},
        tbaAndAssignees: {amount: 0, percent: 0},
        inProgress: {amount: 0, percent: 0},
        pastDue: {amount: 0, percent: 0},
      },
    );
  }, [taskLoad]);

  return (
    <div className="report-qty-tasks worker-accepted__tasks-info">
      <div className="report-qty-tasks__text">
        {isFetching && <Skeleton width={90} height={10} />}
        {!isFetching && (
          <>
            <span className="report-qty-tasks__text-number">
              {tasksAmount.total} {t('task_load.title.activities_count', 'Activities')}
            </span>
            <span className="report-qty-tasks__text-number report-qty-tasks__text-number--due">
              {tasksAmount.pastDue.amount} {t('task_load.title.past_due', 'Past Due')}
            </span>
          </>
        )}
      </div>
      {isFetching && <Skeleton width={264} height={8} />}
      {!isFetching && (
        <div className="report-qty-tasks__line">
          {!!tasksAmount.completed.amount && (
            <span
              className="report-qty-tasks__line-item report-qty-tasks__line-item--completed"
              style={{width: `${tasksAmount.completed.percent}%`}}
            />
          )}
          {!!tasksAmount?.inProgress.amount && (
            <span
              className="report-qty-tasks__line-item report-qty-tasks__line-item--inprogress"
              style={{width: `${tasksAmount.inProgress.percent}%`}}
            />
          )}
          {!!tasksAmount.issues.amount && (
            <span
              className="report-qty-tasks__line-item report-qty-tasks__line-item--issues"
              style={{width: `${tasksAmount.issues.percent}%`}}
            />
          )}
          {!!tasksAmount.pastDue.amount && (
            <span
              className="report-qty-tasks__line-item report-qty-tasks__line-item--due"
              style={{width: `${tasksAmount.pastDue.percent}%`}}
            />
          )}
          {!!tasksAmount.tbaAndAssignees.amount && (
            <span
              className="report-qty-tasks__line-item"
              style={{width: `${tasksAmount.tbaAndAssignees.percent}%`, backgroundColor: '#DEDEDE'}}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TaskLoad;
