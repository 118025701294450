import classnames from 'classnames';
import React, {FC} from 'react';
import {HeaderGroup} from 'react-table';

import {Icon} from 'shared/components/index';

type TableSortIndicatorProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: HeaderGroup<any>;
};

export const TableSortIndicator: FC<TableSortIndicatorProps> = ({column}) => {
  return (
    <div className="table-sorter table-main__sorter">
      <Icon
        colorFill
        name="chevron-top"
        className={classnames('table-sorter__icon table-sorter__icon--top', {'is-active': !column.isSortedDesc})}
        size={24}
      />
      <Icon
        colorFill
        name="chevron-bottom"
        className={classnames('table-sorter__icon table-sorter__icon--bottom', {'is-active': column.isSortedDesc})}
        size={24}
      />
    </div>
  );
};
