import {forwardRef, PropsWithoutRef, useMemo} from 'react';
import {GroupTypeBase, OptionTypeBase, Props} from 'react-select';
// eslint-disable-next-line import/no-named-as-default
import Creatable from 'react-select/creatable';

import {SelectProps} from 'shared/components';

import DropdownIndicator from '../DropdownIndicator';
import {defaultReactSelectStyles} from '../styles';
import {OptionType} from '../types';

type ForwardRefProps<
  OptionT extends OptionTypeBase = OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionT> = GroupTypeBase<OptionT>,
> = Props<OptionT, IsMulti, GroupType>;

const CreatableS = forwardRef<
  PropsWithoutRef<Creatable<typeof Creatable>>,
  PropsWithoutRef<SelectProps & ForwardRefProps>
>(({value, onChange, options, components, className, size, ...selectProps}, ref) => {
  const selected = useMemo(() => {
    if (selectProps.isMulti) {
      // TODO!! not consistent, fix
      // value for isMulti should be array of stings and we need convert it to objects here
      // and transform back from array of object to array of strings in onChange callback
      return value;
    }
    const selected = options?.find((option) => option.value === value);
    return value ? selected || {label: value, value} : null;
  }, [value, options]);
  return (
    <Creatable
      ref={ref}
      className={`${className ? className : ''} ${size ? `react-select--size-xs` : ''}`}
      classNamePrefix="react-select"
      options={options}
      value={selected}
      onChange={(option, actionMeta) => {
        onChange(Array.isArray(option) ? option : option?.value, actionMeta);
      }}
      components={{DropdownIndicator, ...components}}
      styles={defaultReactSelectStyles}
      {...selectProps}
    />
  );
});
CreatableS.displayName = 'CreatableS';

export default CreatableS;
