export interface ProSubscription {
  creatorId: string;
  expireTime: string;
  id: string;
  seatCount: number;
  status: string;
  subscriptionStartTime: string;
  timeCreated: string;
  type: ProSubscriptionTypes;
  stripeSubscriptionInfo?: ProStripeSubscriptionInfo;
}

export enum ProSubscriptionTypes {
  FOREMAN_FREE = 'foreman_free',
  PRO = 'pro',
}

export interface ProStripeSubscriptionInfo {
  amount: number;
  amountDecimal: string;
  currency: string;
  customerId: string;
  nextInvoice: string;
  plan: string;
  priceId: string;
  productId: string;
  quantity: number;
  status: string;
  subscriptionId: string;
}

export interface CheckoutSessionRequestPayload {
  priceid: string;
  quantity: string;
  cancelUrl: string;
  successUrl: string;
}

export enum ProPlanInterval {
  Month = 'month',
  Year = 'year',
  Week = 'week',
  Day = 'week',
}

export interface ProPlanRecurring {
  aggregateUsage: string;
  interval: ProPlanInterval;
  intervalCount: 1;
  trialPeriodDays: number;
  usageType: string;
}

export interface ProPlanTier {
  flatAmount: string;
  flatAmountDecimal: number;
  unitAmount: string;
  unitAmountDecimal: number;
  upTo: string;
}

/** Deprecated interface. Will delete soon **/
export interface DepProPlan {
  priceId: string;
  currency: string;
  recurring: ProPlanRecurring;
  nickname: string;
  seatCount: string;
  unitAmount: number;
  unitAmountDecimal: string;
}

export interface ProPlan {
  priceId: string;
  currency: string;
  interval: ProPlanInterval;
  intervalCount: number;
  tiers: ProPlanTier[];
}

export interface FormattedProPlanTiers extends ProPlanTier {
  seatRange?: string;
  formattedUnitPrice?: string;
  formattedFlatPrice?: string;
}

export interface FormattedProPlan extends ProPlan {
  tiers: FormattedProPlanTiers[];
}

export interface FormattedDepProPlan extends DepProPlan {
  seatRange: string;
  formattedPrice: string;
}
