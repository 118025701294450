import {createAsyncThunk} from '@reduxjs/toolkit';

import {CompanyApi} from 'api';
import {CompanyModel} from 'shared/models/company';
import {CompanyCreateRequest} from 'shared/models/onboardingForms';
import {serializeError} from 'store/utils/serializeError';

type CompanyCreatePayload = {
  workerId: string;
  companyObject: CompanyCreateRequest;
};

export const createCompany = createAsyncThunk(
  'companies/createCompany',
  async ({companyObject, workerId}: CompanyCreatePayload) => {
    try {
      return await CompanyApi.createCompany(companyObject, workerId);
    } catch (error) {
      throw error;
    }
  },
  {serializeError},
);

export const updateCompany = createAsyncThunk(
  'company/update',
  async ({company, workerId}: {company: Partial<CompanyModel> & Pick<CompanyModel, 'id'>; workerId: string}) => {
    return CompanyApi.updateCompany(company, workerId);
  },
);
