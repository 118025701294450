import cn from 'classnames';
import React, {useState} from 'react';

import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import {useAnalyticsService} from 'shared/hooks';

import {onInput} from '../../utils/helpers';

import classes from './InlineFormElements.module.scss';

interface InlineInputProps<T extends string | number> {
  allowNegative?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  event?: string;
  icon?: string;
  label: string;
  onChange: (value: T) => void;
  type: 'number' | 'string';
  value: T;
}

const InlineInput = <T extends string | number>({
  disabled,
  errorMessage,
  event,
  icon = 'edit-pencil',
  label,
  onChange,
  type,
  value,
}: InlineInputProps<T>) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    mixpanel: {trackWithAction},
  } = useAnalyticsService();

  const toggleEdit = () => trackWithAction(() => setIsEditing(!isEditing), event);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((typeof value === 'number' ? Number(event.target.value) : event.target.value) as T);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onChange((typeof value === 'number' ? Number(value) : value) as T);
      setIsEditing(false);
    }
  };

  const inputValue = value === undefined || value === null ? (typeof value === 'number' ? '0' : '') : value;

  const button = !disabled ? (
    <Button className={classes.button} icon={<Icon name={icon} size={16} colorFill />} iconOnly onClick={toggleEdit} />
  ) : null;

  const containerStyle = cn({
    [classes.container]: true,
    [classes.container_disabled]: disabled,
  });

  const inputStyle = cn({
    [classes.input]: true,
    [classes.input_error]: !!errorMessage,
  });

  const valueStyle = cn({
    [classes.value]: true,
    [classes.value_error]: !!errorMessage,
  });

  return (
    <div className={containerStyle}>
      <span className={classes.label} onClick={toggleEdit}>
        {label}:
      </span>
      {isEditing && !disabled ? (
        <input
          autoFocus
          className={inputStyle}
          onBlur={toggleEdit}
          onChange={handleChange}
          onFocus={(e) => e.target.select()}
          onInput={type === 'number' ? onInput : undefined}
          onKeyDown={handleKeyDown}
          type={type}
          value={inputValue}
        />
      ) : (
        <p className={classes.text}>
          <span className={valueStyle} onClick={toggleEdit}>
            {value}
          </span>
        </p>
      )}
      {button}
      {errorMessage ? <span className={classes.error}>{errorMessage}</span> : null}
    </div>
  );
};

export default InlineInput;
