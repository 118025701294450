import classNames from 'classnames';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import {Avatar, Icon} from 'shared/components';
import {useAppIsReady} from 'shared/hooks/useAppIsReady';
import {useCompany} from 'shared/hooks/useCompany';
import {useIsCompanyAdmin} from 'shared/hooks/useIsCompanyAdmin';
import {useRootDispatch} from 'store';
import {fullLogout} from 'store/ducks/auth/actions';

// import './user-menu-animation.scss';
import {profileActions} from 'store/profile';

import {useProfile} from '../../../hooks';
import {HeaderControls} from '../Header/useHeaderControls';

const UserMenu: FC<HeaderControls> = ({isOpen, onToggle}) => {
  const ready = useAppIsReady();
  const profile = useProfile();
  const isCompanyAdmin = useIsCompanyAdmin();
  const company = useCompany();
  const dispatch = useRootDispatch();
  const {t} = useTranslation('left_menu');

  const logout = () => dispatch(fullLogout());

  const personName = profile.fullName || profile.email || profile.mobileNumber;
  return (
    <div className="header__actions-item header__actions-item--user">
      <div className={classNames('ctrl-dropmenu header__menu', {'is-active': isOpen})}>
        <button className="ctrl-dropmenu__button ctrl-dropmenu__button--toggle is-active" onClick={onToggle}>
          <span className="ctrl-dropmenu__button-content">
            <div className="person-preview">
              {ready ? (
                <Avatar
                  className="person-preview__avatar"
                  src={profile.profilePicUrl}
                  style={{backgroundColor: 'white'}}
                />
              ) : (
                <div className="avatar person-preview__avatar">
                  <Skeleton circle={true} width={36} height={36} />
                </div>
              )}
              <div className="person-preview__info">
                <div data-cy="user_menu_name" className="person-preview__name">
                  {ready ? personName : <Skeleton width={100} />}
                </div>
                <div className="person-preview__trade">{ready ? company?.companyName : <Skeleton width={50} />}</div>
              </div>
            </div>
          </span>
          {ready && <Icon name="chevron-top" colorFill className="ctrl-dropmenu__button-icon" />}
        </button>
        <div className="ctrl-dropmenu__viewport">
          <ul className="ctrl-dropmenu__list">
            {profile.status === 'open' && (
              <li className="ctrl-dropmenu__list-item">
                <button
                  className="ctrl-dropmenu__button"
                  type="button"
                  onClick={() => dispatch(profileActions.setMyProfileOpened(true))}
                >
                  {t('profile.buttons.open_profile', 'Your profile')}
                </button>
              </li>
            )}
            {isCompanyAdmin(company?.id) && (
              <li className="ctrl-dropmenu__list-item">
                <button
                  className="ctrl-dropmenu__button"
                  type="button"
                  onClick={() => dispatch(profileActions.setCompanyProfileOpened(true))}
                >
                  {t('profile.buttons.open_company_profile', 'Company profile')}
                </button>
              </li>
            )}
            <li className="ctrl-dropmenu__list-item">
              <button
                onClick={logout}
                className="ctrl-dropmenu__button ctrl-dropmenu__button--color-second"
                type="button"
              >
                {t('profile.buttons.logout', 'Log Out')}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default UserMenu;
