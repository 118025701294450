import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useTasksBulkOperations} from 'modules/Tasks/components/TasksActionsBar/useTasksBulkOperations';
import {BulkProps, TasksBulkTypes} from 'modules/Tasks/utils/constants';
import {Confirmation, CoreStatusSelect} from 'shared/components';

const BulkStatus = ({onClose, gantt, projectId}: BulkProps) => {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const {t} = useTranslation('gantt');
  const {bulkUpdate} = useTasksBulkOperations(gantt);

  return (
    <Confirmation
      visible
      title={t('bulk_status.confirmation.title', 'Status')}
      description={t('bulk_status.confirmation.description', 'Set status for multiple activities')}
      cancelButton={t('bulk_assign.confirmation.buttons.cancel', 'Cancel')}
      acceptButton={t('bulk_assign.confirmation.buttons.accept', 'Submit')}
      onAccept={async () => bulkUpdate({status: selectedStatus})}
      onClose={onClose}
      isDisabledAcceptBtn={!selectedStatus}
      field={
        <CoreStatusSelect.Task
          onChange={setSelectedStatus}
          projectId={projectId}
          value={selectedStatus}
          name={TasksBulkTypes.status}
          placeholder={t('bulk_status.confirmation.title', 'Select Status')}
          menuPosition="fixed"
        />
      }
    />
  );
};

export default BulkStatus;
