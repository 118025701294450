import {FeedbackModel} from 'shared/models/task/comments';

export function getUniqueTags(commentContentList: FeedbackModel[][]): string[] {
  const tagSet = new Set<string>();
  const allTags = commentContentList.flatMap((list) => (list || []).flatMap((item) => item.tags.flat()));

  allTags.forEach((tag: string) => {
    if (!tagSet.has(tag)) {
      tagSet.add(tag);
    }
  });

  return Array.from(tagSet);
}
