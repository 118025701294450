import {CSSProperties, ReactElement} from 'react';

type Props = {
  styles?: CSSProperties;
};

function Eye({styles = null}: Props): ReactElement<Props> {
  return (
    <svg
      style={styles}
      className="icon-inline icon-inline--color-fill ctrl-check-worker__icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="12"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.994 5.001c4.394-.045 8.364 2.616 9.994 6.7.016.099.016.201 0 .3.003.113-.017.225-.06.33-1.631 4.051-5.569 6.695-9.934 6.67-4.37.022-8.309-2.631-9.934-6.69-.04-.098-.06-.204-.06-.31.001-.113.022-.224.06-.33 1.631-4.051 5.569-6.695 9.934-6.67zm-7.925 7c1.465 3.05 4.544 4.992 7.925 5 3.382-.008 6.461-1.95 7.925-5-1.46-3.054-4.542-4.998-7.925-4.998s-6.466 1.944-7.925 4.998zm7.925-2c1.104 0 1.999.895 1.999 2 0 1.104-.895 2-1.999 2s-1.999-.896-1.999-2c0-1.105.895-2 1.999-2zm-3.998 2c0-2.209 1.79-4 3.998-4s3.998 1.791 3.998 4-1.79 4-3.998 4-3.998-1.791-3.998-4z"
      />
    </svg>
  );
}
export default Eye;
