import {useTranslation} from 'react-i18next';
import {generatePath, useHistory} from 'react-router';

import {CtrlButton} from 'shared/components/CoreNewUI';

import env from '../../constants/env';
import {useLocalizedRoutes} from '../../constants/routes';
import {getLocalizedLink} from '../../helpers/common';

import s from './LaptopActions.module.scss';

const LaptopActions = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation('signup');
  const routes = useLocalizedRoutes();
  const history = useHistory();

  const onComputerStart = () => {
    history.push(generatePath(routes.computerStart));
  };

  const onCreateProject = () => {
    history.push(generatePath(routes.project, {id: 'new'}));
  };
  const onDownloadApp = () => {
    window.open(getLocalizedLink(env.legalPages.downloadApp, language));
  };

  return (
    <div className={s.laptopActions}>
      <h2 className={s.laptopActions__title}>{t('mobile_account_complete.title', 'Your account is set!')}</h2>
      <div className={s.laptopActions__actions}>
        <CtrlButton className={s.laptopActions__button} onClick={onComputerStart}>
          {t('mobile_account_complete.buttonStart', 'Start on your computer')}
        </CtrlButton>
        <CtrlButton className={s.laptopActions__button} color="second" onClick={onDownloadApp}>
          {t('mobile_account_complete.buttonDownload', 'Download the App')}
        </CtrlButton>
      </div>
      <div className={s.laptopActions__more}>
        <span className={s.laptopActions__moreText}>or</span>
        <CtrlButton view="link" onClick={onCreateProject}>
          {t('mobile_account_complete.buttonContinue', 'Continue on mobile')}
        </CtrlButton>
      </div>
    </div>
  );
};

export default LaptopActions;
