import {GanttStatic} from 'dhtmlx-gantt';
import React from 'react';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {costImpactTypes} from 'shared/constants/statuses';

import SelectEditor from './SelectEditor';

type CostImpactEditorProps = {
  gantt: GanttStatic;
  task: GanttTask;
  name: string;
};

const CostImpactEditor = (props: CostImpactEditorProps) => {
  return <SelectEditor options={costImpactTypes} {...props} />;
};
export default CostImpactEditor;
