import {GanttStatic} from 'dhtmlx-gantt';

import {startOfDay} from 'shared/helpers/dates';

import {GetOneDayFilterOptions} from './functions';

export function updateTodayMarker(gantt: GanttStatic) {
  const marker = gantt.getMarker('today');
  marker.start_date = new Date();
  marker.title = gantt.date.date_to_str(gantt.config.task_date)(new Date());
  return gantt.updateMarker('today');
}

export function addTodayMarker(gantt: GanttStatic) {
  return gantt.addMarker({
    id: 'today',
    start_date: new Date(),
    css: 'today',
    title: gantt.date.date_to_str(gantt.config.task_date)(new Date()),
  });
}

export function addOrUpdateTodayMarker(gantt: GanttStatic) {
  if (gantt.getMarker('today')) {
    updateTodayMarker(gantt);
  } else {
    addTodayMarker(gantt);
  }
}

export function addOneDayFilterMarker(gantt: GanttStatic, getOneDayFilterOptions: GetOneDayFilterOptions) {
  const {filterDate, enableOneDayFilter} = getOneDayFilterOptions();

  if (enableOneDayFilter) {
    gantt.addMarker({
      id: 'oneDayFilter',
      start_date: startOfDay(filterDate).toDate(),
      css: 'one-day-filter-area',
    });
  }
}
