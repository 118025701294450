import {
  DashboardReportRequest,
  DashboardReportResults,
  Report,
  ReportFilters,
  ReportRaw,
  ReportResults,
} from 'shared/models/reports';

import ApiAxios from './axios';

class ReportsService {
  private getBasePath(companyId: string) {
    return `/companies/${companyId}/reportresults`;
  }

  private getDashboardPath(projectId: string) {
    return `/projects/${projectId}/dashboard`;
  }

  getReport<T extends ReportResults>(companyId: string, params: ReportFilters) {
    return ApiAxios.get<ReportRaw[]>(this.getBasePath(companyId), {params}).then((res) => {
      const reports = res.data;
      return reports.map<Report<T>>(({results, ...rest}) => {
        try {
          const parsed = JSON.parse(results);
          return {...rest, results: parsed};
        } catch (error) {
          return {...rest, results: []};
        }
      });
    });
  }

  getDashbordReport(projectId: string, params: DashboardReportRequest) {
    return ApiAxios.get<DashboardReportResults>(this.getDashboardPath(projectId), {params}).then((res) => {
      return res.data;
    });
  }
}

export default new ReportsService();
