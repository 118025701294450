import * as Sentry from '@sentry/browser';

import {ProjectsApi} from 'api';
import {FeedbackMessage, FeedbackImage} from 'shared/components/ProgressReport/hooks/useHandleSubmitProgressReport';
import {uploadImagesToMatrix} from 'shared/components/ProgressReport/utils/uploadImagesToMatrix';
import {toShortIso} from 'shared/helpers/dates';
import {Feedback} from 'shared/models/feedback';
import {MatrixSessionResponse} from 'shared/models/task/chat';

export interface GeneralCommentUpdates {
  comment: FeedbackMessage;
  image: FeedbackImage;
}

export async function handlePostGeneralComments(
  updates: GeneralCommentUpdates,
  accessToken: MatrixSessionResponse['accessToken'],
  projectId: string,
) {
  const {comment, image} = updates;
  const payload: Feedback[] = [];
  if (comment.comment) {
    payload.push({
      feedback_type: comment.feedbackType,
      date: toShortIso(comment.dateTag),
      payload: {
        [comment.feedbackType]: {
          content: {
            msgtype: 'm.text',
            body: comment.comment.trim(),
            format: 'org.matrix.custom.html',
            formatted_body: `<p>${comment.comment.trim()}</p>`,
          },
        },
      },
    });
  }

  if (image?.images?.length) {
    const results = await uploadImagesToMatrix(image, accessToken, comment.dateTag);
    if (results.length) {
      payload.push(...results);
    }
  }

  try {
    return ProjectsApi.postProjectFeedback(projectId, payload);
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        tag: 'handlePostGeneralComments',
      },
    });
  }
}
