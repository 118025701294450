import cn from 'classnames';
import {FC, useCallback, useEffect, useRef, useState} from 'react';

import {Button, Icon} from 'shared/components';
import {useCompany, useProfile} from 'shared/hooks';
import {useOutsideClick} from 'shared/hooks/core';

type HelpProps = {
  buttonAction: () => void;
  title: string;
  text: string;
  iconName: string;
  buttonText: string;
  onClick?: () => void;
};

const USERS_THAT_OPENED_PROJECTS_PAGE = 'usersThatOpenedProjectsPage';

const HelpModal: FC<HelpProps> = ({buttonAction, buttonText, title, text, iconName, onClick}) => {
  const [isActive, setIsActive] = useState(false);
  const modalHelp = useRef<HTMLDivElement>();
  const profile = useProfile();
  const company = useCompany();

  const onOutsideClick = useCallback(() => {
    setIsActive(false);
  }, [setIsActive]);

  useOutsideClick({ref: modalHelp, callback: onOutsideClick});

  const hideHelp = () => setIsActive(false);

  const showHelp = () => setIsActive(true);

  useEffect(() => {
    const userId = profile?.id;
    if (userId && company?.id) {
      try {
        const collection = JSON.parse(localStorage.getItem(USERS_THAT_OPENED_PROJECTS_PAGE)) || {};
        const userCompanies = collection[userId]?.companies || [];
        const userOpenedPages = collection[userId]?.pages || [];
        if (!userCompanies?.includes(company.id) || !userOpenedPages?.includes(title)) {
          setIsActive(true);
          const companies = userCompanies.includes(company.id) ? userCompanies : userCompanies.concat(company.id);
          const pages = userOpenedPages.includes(title) ? userOpenedPages : userOpenedPages.concat(title);
          localStorage.setItem(
            USERS_THAT_OPENED_PROJECTS_PAGE,
            JSON.stringify(Object.assign(collection, {[userId]: {companies, pages}})),
          );
        }
      } catch (error) {
        console.warn('Cannot set user information to localStorage');
        console.warn(error);
      }
    }
  }, [company, profile, title]);

  return (
    <>
      <div
        className={cn('help', {'is-active': isActive})}
        ref={modalHelp}
        onClick={(e) => {
          e.preventDefault();
          showHelp();
          if (onClick) {
            onClick();
          }
        }}
      >
        <Button className="help__button-toggle" iconOnly icon={<Icon name="help_outlined" colorFill size={24} />} />
        <div className="help__container">
          <div className="help__body">
            <h2 className="help__title">{title}</h2>
            <div className="help__description">
              <p>{text}</p>
            </div>
            <div className="help__actions">
              <Button
                className="ctrl-btn--color-light help__button-main"
                onClick={buttonAction}
                icon={<Icon name={iconName} colorFill size={24} />}
              >
                {buttonText}
              </Button>
            </div>
          </div>
          <Button
            className="help__button-close"
            onClick={(e) => {
              e.stopPropagation();
              hideHelp();
            }}
            iconOnly
            icon={<Icon name="clear" colorFill size={24} />}
          />
        </div>
      </div>
    </>
  );
};

export default HelpModal;
