import dayjs from 'dayjs';
import {useMemo} from 'react';

import {formatDate} from 'shared/helpers/dates';
import {TaskDetailsModelDTO, TaskObjectType} from 'shared/models/task';
import {FeedbackGrouped} from 'shared/models/task/comments';

import {DailyTask} from '../types';

export type TasksWithComments = TaskDetailsModelDTO & {
  comments: FeedbackGrouped[];
};

type CurrentDateReports = {
  tasks: TasksWithComments[];
  startOfDay: dayjs.Dayjs;
  endOfDay: dayjs.Dayjs;
  oskMap: React.MutableRefObject<{[osk: string]: TaskDetailsModelDTO}>;
};

export function useCurrentDateReports({tasks, startOfDay, endOfDay, oskMap}: CurrentDateReports): DailyTask[] | null {
  return useMemo(() => {
    if (!tasks) return null;

    const filteredReportsByCurrentDate: DailyTask[] = tasks
      .filter((task) => task.dateList.some((date) => dayjs(date).isSame(endOfDay, 'day')))
      .map((task) => {
        const feedbackByDate = task.feedbackByDate.map((task) => {
          const filteredFeedbackByDate = task.feedbackByDate.filter((feedBackReport) => {
            const reportDate = dayjs(feedBackReport.date);
            const isSameDay = !reportDate.isBefore(startOfDay) && !reportDate.isAfter(endOfDay);
            return isSameDay;
          });

          return {...task, feedbackByDate: filteredFeedbackByDate};
        });

        if (task.objectType === TaskObjectType.summary) {
          oskMap.current[task.outlineSortKey] = task;
        }

        return {
          completionAmount: task.completionAmount,
          completionTarget: task.completionTarget,
          completionUnit: task.completionUnit,
          comments: task?.comments ?? [],
          dateList: task.dateList,
          feedbackByDate: feedbackByDate,
          id: task.id,
          name: task.name,
          outlineSortKey: task.outlineSortKey,
          responsibleOrg: task.responsibleOrg,
          formatedStartDate: formatDate(task.startDate, 'MMMM D, YYYY'),
          formatedEndDate: formatDate(task.endDate, 'MMMM D, YYYY'),
          projectedLabor: task.projectedLabor,
          objectType: task.objectType,
        };
      });

    return filteredReportsByCurrentDate;
  }, [endOfDay, oskMap, startOfDay, tasks]);
}
