import {TaskModelRawDTO, TaskObjectType} from 'shared/models/task';

import {ChangeSet, ProjectCalendar, TaskDates} from './types';

/**
 * Convert a single changed date attribute into a minimally necessary set of date attributes
 * and call calculateDates. Example: if start_date changes, include duration so that the
 * task gets rescheduled to a new starting date but keeps the original duration.
 * @param {ProjectCalendar} calendar Project calendar so holidays / override weekends can be observed
 * @param {TaskModelRawDTO} task Full task attributes
 * @param {Partial<TaskDates>} fields The field that changed
 * @return {ChangeSet} set of attributes that must will change as a result of the date change
 */
export function expandDates(
  calendar: ProjectCalendar,
  task: Partial<TaskModelRawDTO>,
  fields: Partial<TaskModelRawDTO>,
): ChangeSet {
  const minimalFields: Partial<TaskDates> = {};

  // Based on which single attribute changed, determine what other attribute will be included
  // to calculate authoritative set of 4 values
  if (fields.date_list) {
    // date list is authoritative, proceed
    minimalFields.date_list = fields.date_list;
  } else if (fields.start_date) {
    // changing start date will just reschedule with same number of work days
    minimalFields.start_date = fields.start_date;
    minimalFields.duration = task.duration;
  } else if (fields.end_date) {
    if (task.object_type === TaskObjectType.milestone) {
      // For milestones, start date == end date, with duration 0.
      minimalFields.start_date = fields.end_date;
      minimalFields.duration = 0;
    } else {
      // reschedule in a way that changes duration.
      minimalFields.start_date = task.start_date;
      minimalFields.end_date = fields.end_date;
    }
  } else if (fields.duration !== undefined) {
    // might be 0
    minimalFields.start_date = task.start_date;
    minimalFields.duration = fields.duration;
  }

  if (Object.keys(minimalFields).length === 0) {
    return {};
  }

  // Once date attributes are determined, check if per-date overrides must be altered
  const dtUpdates = calculateDates(calendar, task, minimalFields);
  if (Object.keys(dtUpdates).length) {
    return {[task.id]: dtUpdates};
  }
  return {};
}

/**
 * Based on minimally necessary fields, provide authoritative set of 4 values.
 * Possible valid combinations: start_date + duration, start_date + end_date, or date_list
 * @param {ProjectCalendar} calendar Project calendar so holidays / override weekends can be observed
 * @param {TaskModelRawDTO} task Full task attributes
 * @param {Partial<TaskDates>} fields Minimal set of fields that changed
 * @return {ChangeSet} set of attributes that must be updated
 */
export function calculateDates(
  calendar: ProjectCalendar,
  task: Partial<TaskModelRawDTO>,
  fields: Partial<TaskDates>,
): TaskDates {
  if (fields.start_date && fields.end_date) {
    return calculateStartEnd(calendar, task, fields.start_date, fields.end_date);
  } else if (fields.start_date && fields.duration !== undefined) {
    return calculateStartDuration(calendar, task, fields.start_date, fields.duration);
  } else if (fields.date_list?.length) {
    return calculateDateList(fields.date_list);
  }
  throw new Error('No valid combination of date fields provided');
}

// By setting a time of 00:00:00, it will interpret it as midnight in the current TZ,
// rather than GMT, which can have date wrapping problems.
// Example: new Date('2020-07-04').getDay() === 5, but it should be 6 for Saturday because
// it is parsed as GMT and translated to Fri Jul 03 2020 17:00:00 GMT-0700
const MIDNIGHT = 'T00:00:00';

function calculateStartEnd(
  calendar: ProjectCalendar,
  task: Partial<TaskModelRawDTO>,
  startDate: string,
  endDate: string,
): TaskDates {
  const dateList: string[] = [];
  const prevDateSet = new Set(task.date_list || []);
  const currDt = new Date(startDate + MIDNIGHT);
  const endDt = new Date(endDate + MIDNIGHT);

  while (currDt <= endDt) {
    const currISO = currDt.toISOString().slice(0, 10);
    // defined as a weekend or holiday by project calendar
    if (task.calendar_days) {
      dateList.push(currISO);
    } else if (
      // Respect specified start day as a work day even if it's not normally a working day
      currISO == startDate ||
      // Respect specified end day as a work day even if it's not normally a working day
      currISO == endDate ||
      // Keep any days previously set as working days even if they're weekends / holidays
      prevDateSet.has(currISO) ||
      // Only add working days
      isBusinessDay(calendar, currDt, currISO)
    ) {
      dateList.push(currISO);
    }
    currDt.setDate(currDt.getDate() + 1);
  }

  return calculateDateList(dateList);
}

function calculateStartDuration(
  calendar: ProjectCalendar,
  task: Partial<TaskModelRawDTO>,
  startDate: string,
  duration: number,
): TaskDates {
  if (duration === 0) {
    // Special case for milestones.
    return {
      start_date: startDate,
      end_date: startDate,
      duration: 0,
      date_list: [startDate],
    };
  }

  const dateList: string[] = [];
  const prevDateSet = new Set(task.date_list || []);
  const currDt = new Date(startDate + MIDNIGHT);
  while (true) {
    const currISO = currDt.toISOString().slice(0, 10);
    if (task.calendar_days) {
      dateList.push(currISO);
    } else {
      if (prevDateSet.has(currISO) || currISO === startDate || isBusinessDay(calendar, currDt, currISO)) {
        dateList.push(currISO);
      }
    }
    if (dateList.length === duration) {
      break;
    }
    currDt.setDate(currDt.getDate() + 1);
  }

  return calculateDateList(dateList);
}

function calculateDateList(dateList: string[]): TaskDates {
  return {
    start_date: dateList[0],
    end_date: dateList[dateList.length - 1],
    duration: dateList.length,
    date_list: dateList,
  } as TaskDates;
}

export function isBusinessDay(calendar: ProjectCalendar, currDt: Date, currISO: string = null) {
  if (currISO === null) {
    currISO = currDt.toISOString().slice(0, 10);
  }
  if (calendar.exceptions[currISO] !== undefined) {
    return calendar.exceptions[currISO];
  }
  return calendar.work_days.has(currDt.getDay());
}
