import React, {FC, MouseEventHandler} from 'react';
import {useTranslation} from 'react-i18next';

import {safeFormatDate as formatDate} from 'shared/helpers/dates';
import {ProjectModel} from 'shared/models/project';
import {TaskDetailsModelDTO} from 'shared/models/task';

import {SkeletonPreloader, TaskStatus} from '..';
import {usePreferDateFormat} from '../../hooks/usePreferDateFormat';

type TaskCardProps = {
  className?: string;
  task?: Partial<TaskDetailsModelDTO>;
  project?: ProjectModel;
  isNewTask?: boolean;
  loading?: boolean;
  hideEmpty?: boolean;
  onClick?: MouseEventHandler<HTMLScriptElement>;
  initialDateFormat?: string;
};

const TaskCard: FC<TaskCardProps> = ({
  task,
  loading,
  project,
  hideEmpty = false,
  className,
  onClick,
  initialDateFormat,
}) => {
  const prefDateFormat = usePreferDateFormat();
  const {t} = useTranslation('task');

  return (
    <section className={`task-card ${className}`} onClick={onClick}>
      <div className="task-card__header">
        <div className="task-card__id">{task?.uniqueId || '—'}</div>
        <TaskStatus value={task?.status} className="task-card__status" />
      </div>
      <h2 className="task-card__name">
        <SkeletonPreloader when={loading}>{task?.name || '—'}</SkeletonPreloader>
      </h2>
      <div className="task-card__dates">
        <span className="task-card__date">
          <SkeletonPreloader when={loading} width={50}>
            {t('task_card.due', 'Due')}
            {formatDate(task?.schedEndDate, prefDateFormat, initialDateFormat ? initialDateFormat : '')}
          </SkeletonPreloader>
        </span>
        <span className="task-card__date">
          <SkeletonPreloader when={loading} width={50}>
            {t('task_card.start', 'Start')}
            {formatDate(task?.schedStartDate, prefDateFormat, initialDateFormat ? initialDateFormat : '')}
          </SkeletonPreloader>
        </span>
      </div>
      <div className="task-card__body">
        {hideEmpty && !project?.name ? null : (
          <div className="info-line task-card__info-line">
            <span className="info-line__label">{t('task_card.label.project', 'Project')}</span>
            <span className="info-line__value">
              <SkeletonPreloader when={loading} width={50}>
                {project?.name || '—'}
              </SkeletonPreloader>
            </span>
          </div>
        )}
        {hideEmpty && !task?.responsibleParty ? null : (
          <div className="info-line task-card__info-line">
            <span className="info-line__label">{t('task_card.label.subcontractor', 'Subcontractor')}</span>
            <span className="info-line__value">
              <SkeletonPreloader when={loading} width={50}>
                {task?.responsibleParty || '—'}
              </SkeletonPreloader>
            </span>
          </div>
        )}
        {hideEmpty && !task?.schedStartDate ? null : (
          <div className="info-line task-card__info-line">
            <span className="info-line__label">{t('task_card.label.start_date', 'Start Date')}</span>
            <span className="info-line__value">
              <SkeletonPreloader when={loading} width={50}>
                {formatDate(task?.schedStartDate || '—', prefDateFormat, initialDateFormat ? initialDateFormat : '') ||
                  '—'}
              </SkeletonPreloader>
            </span>
          </div>
        )}
        {hideEmpty && !task?.schedEndDate ? null : (
          <div className="info-line task-card__info-line">
            <span className="info-line__label">{t('task_card.label.due_date', 'Due Date')}</span>
            <span className="info-line__value">
              <SkeletonPreloader when={loading} width={50}>
                {formatDate(task?.schedEndDate || '—', prefDateFormat, initialDateFormat ? initialDateFormat : '') ||
                  '—'}
              </SkeletonPreloader>
            </span>
          </div>
        )}
        {hideEmpty && !task?.location ? null : (
          <div className="info-line task-card__info-line">
            <span className="info-line__label">{t('task_card.label.location', 'Location')}</span>
            <span className="info-line__value">
              <SkeletonPreloader when={loading} width={50}>
                {task?.location || '—'}
              </SkeletonPreloader>
            </span>
          </div>
        )}
        {hideEmpty && !task?.outlineCode ? null : (
          <div className="info-line task-card__info-line">
            <span className="info-line__label">{t('task_card.label.outline', 'Outline')}</span>
            <span className="info-line__value">
              <SkeletonPreloader when={loading} width={50}>
                {task?.outlineCode || '—'}
              </SkeletonPreloader>
            </span>
          </div>
        )}
      </div>
    </section>
  );
};
export default TaskCard;
