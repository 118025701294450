import cn from 'classnames';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {Icon} from 'shared/components';
import {useAnalyticsService} from 'shared/hooks';
import {useCompany} from 'shared/hooks/useCompany';
import {useIsCompanyAdmin} from 'shared/hooks/useIsCompanyAdmin';
import {ProjectModel} from 'shared/models/project';

import ProjectItem from '../ProjectItem';

import s from './ProjectsList.module.scss';

type ProjectsListProps = {
  projects: ProjectModel[];
  openProject: (id: string) => void;
  openNewProject: () => void;
};

const ProjectsList: FC<ProjectsListProps> = ({projects, openProject, openNewProject}) => {
  const {mixpanel} = useAnalyticsService();
  const {t} = useTranslation('projects');
  const isCompanyAdmin = useIsCompanyAdmin();
  const mixpanelEvents = mixpanel.events.projects;
  const activeCompany = useCompany();

  return (
    <div className={cn('projects-list', s.projectsList)}>
      <div className="projects-list__grid">
        {projects.map((project) => (
          <ProjectItem key={project.id} project={project} openProject={openProject} />
        ))}
        {isCompanyAdmin(activeCompany?.id) && (
          <button
            onClick={() => mixpanel.trackWithAction(openNewProject, mixpanelEvents.buttons.newProjectCard)}
            className="project-add projects-list__item"
            type="button"
          >
            <span className="project-add__container">
              <Icon name="add_circle" className="project-add__icon" colorFill size={24} />
              <span className="project-add__text">{t('buttons.add', 'Add New Project')}</span>
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ProjectsList;
