import {useMemo} from 'react';

import {useParsedQuery} from 'shared/hooks/useParsedQuery';
import {TaskDetailsModelDTO, TaskStatus} from 'shared/models/task';

export function useTaskState(task?: TaskDetailsModelDTO) {
  const {state: requestedTaskState} = useParsedQuery<{state: string}>({
    defaultParams: {state: null},
    schema: {state: 'string'},
  });
  return useMemo(() => {
    if (requestedTaskState) return requestedTaskState;
    if (!task?.id) return 'active';
    if (task.timeRemoved) return 'deleted';
    if (task.status === TaskStatus.archived) return 'archived';
    return 'active';
  }, [task, requestedTaskState]);
}
