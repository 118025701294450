import cs from 'classnames';
import React, {FC, ReactElement, useCallback, useRef, MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';

import './slide-panel-animation.scss';
import {Icon} from 'shared/components';
import {usePopupScrollHandler} from 'shared/hooks/usePopupScrollHandler';

import s from './SlidePanel.module.scss';
import SlidePanelBody from './SlidePanelBody';
import SlidePanelHeader from './SlidePanelHeader';

type SlidePanelProps = {
  onClose: () => void;
  closeOnBackdropClick?: boolean;
  content: ReactElement | ((props: {close: () => void}) => ReactElement);
  aside?: ReactElement | ReactElement[];
  className?: string;
  size?: 'default' | 'm' | 'xs';
  showCloseBtn?: boolean;
  disablePaddings?: boolean;
};

const SlidePanel: FC<SlidePanelProps> & {
  Header: typeof SlidePanelHeader;
  Body: typeof SlidePanelBody;
} = ({
  onClose,
  closeOnBackdropClick = true,
  content,
  aside,
  className,
  size = 'default',
  showCloseBtn = true,
  disablePaddings = false,
}) => {
  const backdrop = useRef<HTMLDivElement>();
  const {t} = useTranslation('task');

  const handleBackdropClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (closeOnBackdropClick && e.target === backdrop.current) {
        onClose();
      }
    },
    [closeOnBackdropClick, onClose],
  );

  usePopupScrollHandler();

  return (
    <div
      className={cs('slide-panel', className, {[`slide-panel--size-${size}`]: size !== 'default'})}
      ref={backdrop}
      onMouseDown={handleBackdropClick}
    >
      <div className="slide-panel__item">
        <div
          className="slide-panel__content slide-panel__content--main"
          style={{display: 'flex', flexDirection: 'column'}}
        >
          {typeof content === 'function' ? content({close: onClose}) : content}
        </div>
        {aside && (
          <div
            className={`slide-panel__content ${disablePaddings && s.slidePanel__content} slide-panel__content--aside`}
            id="slide-panel__content--aside"
          >
            {aside}
          </div>
        )}
        {showCloseBtn && (
          <button
            data-cy="close_edit_menu_bt"
            className="ctrl-btn-clear slide-panel__button-close"
            type="button"
            onClick={onClose}
          >
            <span className="ctrl-btn-clear__text">{t('slide_panel.buttons.close', 'close')}</span>
            <Icon className="ctrl-btn-clear__icon" colorFill size={24} name="clear" />
          </button>
        )}
      </div>
    </div>
  );
};
SlidePanel.Header = SlidePanelHeader;
SlidePanel.Body = SlidePanelBody;
export default SlidePanel;
