import {decamelizeKeys} from 'humps';

import {FeedbackRollup, TaskAssigneesGantt} from 'modules/Tasks/components/Gantt/types';
import {addDays, diff, safeParseDate, startOf, toShortIso} from 'shared/helpers/dates';
import {
  GanttCompanyOrgs,
  GanttTaskCustomField,
  IssueModelDTO,
  IssueStatusTaskIDsPair,
  PunchList,
  PunchListCountModel,
  TaskDetailsModelDTO,
  TaskHistoryRawType,
  TaskModelDTO,
  TaskModelRawDTO,
  TaskGanttModel,
  TaskObjectSubType,
  TaskObjectType,
  TaskOutlineModelDTO,
  TaskPerDateOverrideColor,
  TaskRiskModelRawDTO,
  TaskWithAffectedResponse,
  TaskStatus,
  TaskHistoryType,
} from 'shared/models/task';
import {TaskAssignees} from 'shared/models/task/member';

export const getPunchListCountModel = (punchlist: PunchList = {items: []}): PunchListCountModel => {
  const checkedItems = punchlist?.items?.reduce((acc, item) => (item.complete ? acc + 1 : acc), 0) || 0;
  return {checked: checkedItems, total: punchlist?.items?.length};
};

export function prepareDateForGantt(date: string | Date): null | Date {
  const parsed = safeParseDate(date);
  return parsed && startOf(parsed, 'day')?.toDate();
}

export function prepareEndDateForGantt(date: string | Date) {
  const prepared = prepareDateForGantt(date);
  return prepared && addDays(prepared, 1).toDate();
}

export const prepareAssignees = (assignees: TaskAssigneesGantt[]): TaskAssignees[] => {
  return assignees?.map((assignee) => {
    const model: TaskAssignees = {
      memberId: assignee.member_id,
      memberName: assignee.member_name,
    };
    return model;
  });
};

export const prepareAssigneesForGantt = (assignees: TaskAssignees[]): TaskAssigneesGantt[] => {
  return (
    assignees?.map((assignee) => {
      const model: TaskAssigneesGantt = {
        member_id: assignee.memberId,
        member_name: assignee.memberName,
      };
      return model;
    }) ?? []
  );
};

const getStatusDate = (statusHistory: TaskHistoryType[], status: TaskStatus): string | undefined => {
  return statusHistory.find((statusChange) => statusChange.status === status)?.timeUpdated;
};

export function toOutlineTask(task: TaskDetailsModelDTO): TaskOutlineModelDTO {
  return {
    outlineSortKey: task.outlineSortKey,
    id: task.id,
    actualEndDate: task.actualEndDate,
    actualStartDate: task.actualStartDate,
    schedEndDate: task.schedEndDate,
    schedStartDate: task.schedStartDate,
    assigneeCount: task.assignmentCount,
    name: task.name,
    location: task.location,
    outlineCode: task.outlineCode,
    outlineIsLeaf: task.outlineIsLeaf,
    type: task.type,
    responsibleParty: task.responsibleParty,
    status: task.status,
    uniqueId: task.uniqueId,
    punchlist: task.punchlist,
    projectId: task.projectId,
  };
}

/**
 * Convert to GanttTask omitting any properties not present in task argument
 * @param {TaskDetailsModelDTO} task Task properties
 * @return {Partial<TaskGanttModel>} Values from task mapped to TaskGanttModel
 */
export function toPartialGanttTaskModel(
  task: Partial<TaskDetailsModelDTO> | Partial<IssueModelDTO>,
): Partial<TaskGanttModel> {
  const ganttTask = toGanttTaskModel(task);
  return Object.fromEntries(Object.entries(ganttTask).filter(([k, _]) => task.hasOwnProperty(k)));
}

export function taskWithAffectedToArray(response: TaskWithAffectedResponse): TaskModelRawDTO[] {
  const tasks = [response.task];
  if (response.affected_tasks) {
    tasks.push(...response.affected_tasks);
  }
  return tasks;
}

export function toTaskModelRawDTO(task: Partial<TaskDetailsModelDTO> | Partial<TaskModelDTO>): TaskModelRawDTO {
  return {
    abbrev: task.abbrev,
    actual_end: task.actualEnd,
    actual_labor: task.actualLabor,
    actual_start: task.actualStart,
    assignment_count: task.assignmentCount,
    calendar_days: task.calendarDays,
    completion_amount: task.completionAmount,
    completion_target: task.completionTarget,
    completion_unit: task.completionUnit,
    cost_code: task.costCode,
    created_by: task.createdBy,
    created_using: task.createdUsing,
    csi_code: task.csiCode,
    custom_code: task.customCode,
    custom_fields: decamelizeKeys(task.customFields) as GanttTaskCustomField[],
    date_list: task.dateList,
    description: task.description,
    duration: task.duration,
    end_date: task.endDate,
    estimated_labor: task.projectedLabor,
    est_labor_hours: task.estLaborHours,
    feedback_by_date: task.feedbackByDate,
    feedback_rollup: decamelizeKeys(task.feedbackRollup) as FeedbackRollup,
    id: task.id,
    issue_task_ids: task.issueTaskIds,
    location: task.location,
    lookahead_color: task.lookaheadColor,
    name: task.name,
    object_subtype: task.objectSubtype,
    object_type: task.objectType,
    outline_sort_key: task.outlineSortKey,
    per_date_override: task.perDateOverride,
    phase_code: task.phaseCode,
    progress: task.completionAmount,
    project_id: task.projectId,
    projected_labor: task.projectedLabor,
    punchlist: task.punchlist,
    responsible: decamelizeKeys(task.responsible) as TaskAssigneesGantt[],
    responsible_org: decamelizeKeys(task.responsibleOrg) as GanttCompanyOrgs,
    responsible_org_id: task.responsibleOrgId,
    risk: decamelizeKeys(task.risk) as TaskRiskModelRawDTO,
    start_date: task.startDate,
    status: task.status,
    status_history: decamelizeKeys(task.statusHistory) as TaskHistoryRawType[],
    status_issue_task_ids_pairs: decamelizeKeys(task.statusIssueTaskIdsPairs) as IssueStatusTaskIDsPair[],
    time_created: task.timeCreated,
    time_removed: task.timeRemoved,
    time_updated: task.timeUpdated,
    type: task.type,
    unique_id: task.uniqueId,
    keywords: '', // unused in web-admin
    outline_is_leaf: false, // unused in web-admin
    actual_start_date: '', // unused in web-admin
    actual_end_date: '', // unused in web-admin
    sched_start_date: '', // unused in web-admin
    sched_end_date: '', // unused in web-admin,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function toGanttTaskModel(task: Partial<TaskDetailsModelDTO> | Partial<IssueModelDTO>): TaskGanttModel {
  const startDate = prepareDateForGantt(task.startDate);
  const endDate = prepareEndDateForGantt(task.endDate);
  const model: Partial<TaskGanttModel> = {
    assignment_count: task.assignmentCount,
    completion_amount: task.completionAmount || '',
    completion_target: task.completionTarget,
    completion_unit: task.completionUnit,
    created_by: task.createdBy,
    created_using: task.createdUsing,
    date_list: task.dateList,
    description: task.description,
    duration: diff(task.endDate, task.startDate) + 1,
    start_date: startDate,
    start_date_real: startDate,
    end_date: endDate,
    end_date_real: endDate,
    actual_start: prepareDateForGantt(task.actualStart),
    actual_end: prepareEndDateForGantt(task.actualEnd),
    id: task.id,
    keywords: task.keywords,
    location: task.location,
    meta: {
      loading: false,
    },
    name: task.name,
    object_type: task.objectType,
    object_subtype: task.objectSubtype,
    open: 0,
    outline_sort_key: task.outlineSortKey,
    parent: task.parentTask?.id ?? '0',
    projectId: task.projectId,
    project_id: task.projectId,
    punchList: getPunchListCountModel(task.punchlist), // TODO duplicate?
    punchlist: task.punchlist,
    responsible: prepareAssigneesForGantt(task.responsible),
    responsible_org_id: task.responsibleOrgId,
    responsible_org: decamelizeKeys(task.responsibleOrg) as GanttCompanyOrgs,
    risk: decamelizeKeys(task.risk) as TaskRiskModelRawDTO,
    status: task.status, // reserved property?
    status_history: decamelizeKeys(task.statusHistory) as TaskHistoryRawType[],
    taskDuration: task.duration,
    taskStatus: task.status,
    time_created: task.timeCreated,
    time_updated: task.timeUpdated,
    unique_id: task.uniqueId,
    lastChangedFields: {},
    taskType: task.type,
    custom_fields: decamelizeKeys(task.customFields) as GanttTaskCustomField[],
  };

  if (task.statusHistory?.some((statHist) => statHist.status === TaskStatus.inProgress)) {
    // status history is provided newest to oldest, but looking for first in-progress
    model.inprogressDate = prepareDateForGantt(
      getStatusDate(task.statusHistory.slice().reverse(), TaskStatus.inProgress),
    );
    model.doneDate = prepareDateForGantt(getStatusDate(task.statusHistory, TaskStatus.done));
  }

  // TODO: separate mapping funcitons

  if ('subtaskCount' in task) {
    Object.assign<Partial<TaskGanttModel>, Partial<TaskGanttModel>>(model, {
      $has_child: [TaskObjectType.activity, TaskObjectType.milestone].includes(task.objectType) ? 0 : task.subtaskCount,
      has_child: task.subtaskCount,
      issue_task_ids: task.issueTaskIds,
      status_issue_task_ids_pairs:
        task.statusIssueTaskIdsPairs?.map((pair) => decamelizeKeys(pair) as IssueStatusTaskIDsPair) ?? [],
      abbrev: task.abbrev,
      lookahead_color: task.lookaheadColor,
      subtask_count: task.subtaskCount,
      time_removed: task.timeRemoved,
      projected_labor: task.projectedLabor,
      actual_labor: task.actualLabor,
      cost_code: task.costCode,
      csi_code: task.csiCode,
      phase_code: task.phaseCode,
      custom_code: task.customCode,
      per_date_override: decamelizeKeys(task.perDateOverride) as TaskPerDateOverrideColor,
    });
  }

  if ('culpableOrgId' in task) {
    Object.assign<Partial<TaskGanttModel>, Partial<TaskGanttModel>>(model, {
      cost_days: task.costDays,
      cost_tracking_number: task.costTrackingNumber,
      culpable_org_id: task.culpableOrgId,
      culpable_org: decamelizeKeys(task.culpableOrg) as GanttCompanyOrgs,
      impact: task.impact,
      issue_type: task.issueType,
      task_ids: task.taskIds,
    });
  }

  if ('activities' in task && (task.activities satisfies Partial<IssueModelDTO['activities']>)) {
    model.activities = task.activities;
  }

  // TODO: move in mapping to issue gantt model
  if ('costImpact' in task) {
    model.cost_impact = task.costImpact;
  }

  if (task.objectType === TaskObjectType.milestone) {
    model.type = TaskObjectType.milestone;
    if (task.objectSubtype === TaskObjectSubType.end) {
      model.start_date = model.end_date;
    }
  } else {
    model.type = '';
  }
  return model as TaskGanttModel;
}

export function ganttToTaskModelRawDTO(task: TaskGanttModel): Partial<TaskModelRawDTO> {
  return {
    assignment_count: task.assignment_count,
    completion_amount: task.completion_amount,
    completion_target: task.completion_target,
    completion_unit: task.completion_unit,
    created_by: task.created_by,
    created_using: task.created_using,
    date_list: task.date_list,
    description: task.description,
    start_date: toShortIso(task.start_date),
    end_date: toShortIso(task.end_date),
    est_labor_hours: task.est_labor_hours,
    actual_start: toShortIso(task.actual_start),
    actual_end: toShortIso(task.actual_end),
    id: task.id,
    keywords: task.keywords,
    location: task.location,
    name: task.name,
    object_type: task.object_type,
    object_subtype: task.object_subtype,
    outline_sort_key: task.outline_sort_key,
    project_id: task.project_id,
    punchlist: task.punchlist,
    responsible_org_id: task.responsible_org_id,
    risk: task.risk,
    status_history: task.status_history,
    duration: task.duration,
    status: task.status,
    unique_id: task.unique_id,
    type: task.type,
  };
}
