import dayjs from 'dayjs';

import {IssueModel} from 'shared/models/task';

export const getPreparedFormValues = (issue: IssueModel) => {
  return {
    costImpact: typeof issue.costImpact === 'boolean' ? issue.costImpact : undefined,
    projectId: issue.projectId,
    name: issue.name,
    description: issue.description,
    startDate: issue.startDate ? dayjs(issue.startDate).toDate() : null,
    endDate: issue.endDate ? dayjs(issue.endDate).toDate() : null,
    responsible: issue.responsible[0].memberId,
    status: issue.status,
    issueType: issue.issueType,
    responsibleOrgId: issue.responsibleOrgId || undefined,
    culpableOrgId: issue.culpableOrgId || undefined,
    impact: issue.impact,
  };
};
