import {nanoid} from 'nanoid';

import {TasksApi} from 'api';
import {ObserverJobStatus} from 'services/TasksObserver/const';
import {
  isLoadEvent,
  ObserverEvent,
  ObserverLoadEvent,
  ObserverUpdateEvent,
} from 'services/TasksObserver/TasksObserverEvent';
import {ObserverJob} from 'services/TasksObserver/TasksObserverJob';
import {ObserverUnsubscribe, Subscription, SubscriptionConfig} from 'services/TasksObserver/TasksObserverSubscription';
import {SortOrder} from 'shared/constants/common';
import {GanttNames} from 'shared/constants/gantt';
import {chunk} from 'shared/helpers/common';
import {debounce} from 'shared/helpers/debounce';
import {TaskDetailsModelDTO, TaskProjection} from 'shared/models/task';

const REQUEST_LIMIT = 5;

const instances = new Map<string, TasksObserver>();

export class TasksObserver {
  private retry: 1;
  private subscribers = new Map<string, Subscription>();
  private queue: ObserverJob[] = [];
  private running: ObserverJob[] = [];
  private events: ObserverLoadEvent[] = [];

  static getInstance(name = GanttNames.gantt) {
    let inst = instances.get(name);
    if (!inst) {
      inst = new TasksObserver();
      instances.set(name, inst);
    }
    return inst;
  }

  private queueJobs = debounce(() => {
    const events = this.events.splice(0);
    const subscribers = Array.from(this.subscribers.values());
    /* TODO: handle project change
      if there is situation when load tasks will be ran to multiple projects at once
      we need to group that tasks in different jobs
      const projects = new Set();
      const projections = new Set();
    */
    const targetProjections = new Set<TaskProjection>(
      subscribers.reduce((acc, cur) => acc.concat(cur.config.projection), [] as TaskProjection[]),
    );

    targetProjections.forEach((projection) => {
      chunk(this.getIdsToLoad(events), 50).forEach((ids) => {
        const job: ObserverJob = {
          id: nanoid(),
          created: new Date(),
          started: null,
          status: ObserverJobStatus.queue,
          events: [...events],
          idsToLoad: ids,
          staleIds: [],
          projection,
          retryAttempts: 0,
        };
        this.queue.unshift(job);
      });
    });
    this.runJobs();
  }, 50).bind(this);

  private getIdsToLoad(events: ObserverLoadEvent[]) {
    return Array.from(new Set(events.reduce((acc, cur) => acc.concat(cur.ids), [])));
  }

  private runJobs() {
    while (this.running.length < REQUEST_LIMIT && this.queue.length) {
      this.runJob(this.queue.at(-1));
    }
  }

  private findStaleIds() {
    const alreadyLoading = new Set();
    [...this.running]
      .sort((jobA, jobB) => +jobB.started - +jobA.started)
      .forEach((job) => {
        job.idsToLoad.forEach((id) => {
          if (!alreadyLoading.has(id)) {
            alreadyLoading.add(id);
          } else {
            job.staleIds.push(id);
          }
        });
      });
  }

  private async runJob(job: ObserverJob) {
    this.running.push(this.queue.pop());
    job.started = new Date();
    job.status = ObserverJobStatus.running;
    try {
      job.status = ObserverJobStatus.success;
      // todo make generic base on projection
      job.result = (await this.processJob(job)) as TaskDetailsModelDTO[];
      this.findStaleIds();
      this.notifyOnJobComplete(job);
    } catch (e) {
      job.status = ObserverJobStatus.error;
      job.error = e as Error;
      if (job.retryAttempts < this.retry) {
        this.queue.push(job);
      }
    }
    this.running = this.running.filter((running) => running.id !== job.id);
    this.runJobs();
  }

  private async processJob(job: ObserverJob) {
    const events = job.events;
    return TasksApi.getProjectTasks({
      projection: job.projection,
      limit: job.idsToLoad.length,
      sortOrder: SortOrder.ASC,
      sortField: 'outline_sort_key',
      params: {
        projectId: events[0].projectId,
        ids: job.idsToLoad,
      },
    }).then((res) => res.data);
  }

  load(events: ObserverLoadEvent | ObserverLoadEvent[]) {
    this.events.push(...[].concat(events));
    this.queueJobs();
  }

  emit(events: Pick<ObserverUpdateEvent, 'data' | 'meta'>[], params: Omit<ObserverUpdateEvent, 'data' | 'meta'>) {
    this.notify(([] as ObserverUpdateEvent[]).concat(events.map((data) => Object.assign(data, params))));
  }

  subscribe(callback: Subscription['callback'], config: SubscriptionConfig, id?: string): ObserverUnsubscribe {
    const subscriptionId = id || nanoid();
    this.subscribers.set(subscriptionId, {callback, config, id: subscriptionId});
    const unsub = (() => this.unsubscribe(subscriptionId)) as ObserverUnsubscribe;
    unsub.id = subscriptionId;
    return unsub;
  }

  unsubscribe(id: string) {
    if (this.subscribers.has(id)) {
      this.subscribers.delete(id);
    } else {
      console.warn(`Attempt to unsubscribe with not exist id: ${id}`);
    }
  }

  private getEventsForSubscription<E extends ObserverEvent>(events: E[], subscription: Subscription) {
    return events.filter(
      (event) =>
        (!subscription.config.triggerSource || subscription.config.triggerSource.includes(event.source)) &&
        (!subscription.config.triggerType || subscription.config.triggerType.includes(event.action)) &&
        (isLoadEvent(event) ? true : subscription.config.projection.includes(event.projection)),
    );
  }

  private notifyOnJobComplete(job: ObserverJob) {
    if (job) {
      // need to optimize
      this.subscribers.forEach((s) => {
        if (s.config.projection.includes(job.projection)) {
          const eventsToEmit = this.getEventsForSubscription(job.events, s);
          const ids = this.getIdsToLoad(eventsToEmit).filter((id) => !job.staleIds.includes(id));
          // TODO: optimize
          const res = job.result
            .filter((res) => ids.includes(res.id))
            .map((res) => ({
              event: job.events.find((event) => event.ids.includes(res.id)),
              data: res,
            }));
          res.length &&
            s.callback({
              type: 'load',
              payload: res,
            });
        }
      });
    }
  }

  private notify(events: ObserverUpdateEvent | ObserverUpdateEvent[]) {
    const eventsArray = [].concat(events) as ObserverUpdateEvent[];
    this.subscribers.forEach((s) => {
      const eventsForSub = this.getEventsForSubscription(eventsArray, s);
      if (eventsForSub.length) {
        s.callback({
          type: 'cache',
          payload: eventsForSub,
        });
      }
    });
  }
}
