import classnames from 'classnames';
import React, {ComponentType, FC} from 'react';

import AppStore from 'assets/images/downloads/download-app-store.svg';
import GooglePlay from 'assets/images/downloads/download-google-play.svg';

type DownloadAppLinkProps = {
  onClick?: () => void;
  imageSrc: string;
  href: string;
  className?: string;
  trackAnalyticsEvents?: boolean;
  baseClassName?: string;
};

type WrappedDownloadAppLinks = Pick<
DownloadAppLinkProps,
'className' | 'trackAnalyticsEvents' | 'onClick' | 'baseClassName'
>;

type DownloadAppComponent = FC<DownloadAppLinkProps> & {
  Android?: ComponentType<WrappedDownloadAppLinks>;
  iOS?: ComponentType<WrappedDownloadAppLinks>;
};

const DownloadAppLink: DownloadAppComponent = ({
  href,
  imageSrc,
  className,
  baseClassName = 'ctrl-btn-app',
  children,
  onClick,
}) => {
  return (
    <a
      className={classnames(baseClassName, className)}
      href={href}
      onClick={onClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span className={`${baseClassName}__text`}>{children}</span>
      <img className={`${baseClassName}__image`} src={imageSrc} alt="Download on the App Store" />
    </a>
  );
};

const createAndroidAppLink = (imageSrc: string) => {
  const wrappedAndroidAppLink = function (props: WrappedDownloadAppLinks) {
    return (
      <DownloadAppLink href={process.env.REACT_APP_GOOGLE_PLAY_LINK} imageSrc={imageSrc} {...props}>
        Get it on Google Play
      </DownloadAppLink>
    );
  };
  return wrappedAndroidAppLink;
};

const createIosAppLink = (imageSrc: string) => {
  const wrappedIosAppLink = function (props: WrappedDownloadAppLinks) {
    return (
      <DownloadAppLink href={process.env.REACT_APP_APPLE_STORE_LINK} imageSrc={imageSrc} {...props}>
        Download on the App Store
      </DownloadAppLink>
    );
  };
  return wrappedIosAppLink;
};

DownloadAppLink.Android = createAndroidAppLink(GooglePlay);
DownloadAppLink.iOS = createIosAppLink(AppStore);

export default DownloadAppLink;
