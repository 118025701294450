import {AsyncJobChatHistoryStatus, TaskChatEvent, TaskChatEventTypes} from 'shared/models/task';

export function getTaskStatusFromEventType(type: TaskChatEventTypes): string {
  const [, , , status] = type.split('.');
  return status;
}

export function isReplacedMessage(event: TaskChatEvent) {
  return event?.content?.['m.relatesTo']?.['relType'] === 'm.replace';
}

export function getReplyMessageText(formattedBody: string): string {
  if (formattedBody) {
    const [, text] = decodeHTMLEntities(formattedBody).split('</mx-reply>');
    return text;
  }
  return '';
}

export function getRepliedMessageTextFrom(formattedBody: string): string {
  if (formattedBody) {
    const parts = decodeHTMLEntities(formattedBody).split('<blockquote>');
    if (parts.length === 2) {
      const replyParts = parts[1].split('</blockquote>');
      if (replyParts.length === 2) {
        const infoParts = replyParts[0].split('</p>');
        if (infoParts.length === 3) {
          // text message
          return infoParts[1].replaceAll('<p>', '').trim();
        }
      }
    }
  }
  return '';
}

export function decodeHTMLEntities(encoded: string): string {
  const entitiesRegexp = /&(nbsp|amp|quot|lt|gt);/g;
  const entitiesMap = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
  };
  return encoded
    .replace(entitiesRegexp, (match, entity) => entitiesMap[entity])
    .replace(/&#(\d+);/gi, (match, numStr) => String.fromCharCode(parseInt(numStr, 10)));
}

export function checkIsDeletedMessage(event: TaskChatEvent) {
  return !event.content?.body && event?.redactedBecause?.content?.reason === 'Deleted by author';
}

export function isAsyncJobInProgress(status: AsyncJobChatHistoryStatus): boolean {
  return status === undefined || [AsyncJobChatHistoryStatus.queued, AsyncJobChatHistoryStatus.running].includes(status);
}

export function isAsyncJobFinished(status: AsyncJobChatHistoryStatus): boolean {
  return status === AsyncJobChatHistoryStatus.finished;
}

export function isAsyncJobFailed(status: AsyncJobChatHistoryStatus): boolean {
  return status === AsyncJobChatHistoryStatus.failed;
}
