import cn from 'classnames';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {object, string, ValidationError} from 'yup';

import {ContactApi} from 'api';
import Firebase from 'services/Firebase/Firebase';
import {useAuth} from 'shared/components';
import {isAxiosError} from 'shared/helpers/axios';
import {useAnalyticsService} from 'shared/hooks';

import CtrlButton from '../CtrlButton/CtrlButton';
import CtrlForm from '../CtrlForm/CtrlForm';
import CtrlTextfield from '../CtrlTextfield/CtrlTextfield';

import s from './Subscribe.module.scss';

type SubscribeProps = {
  className?: string;
};

const Subscribe = ({className}: SubscribeProps) => {
  const {t} = useTranslation(['landing2']);
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.home;
  const {user} = useAuth();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validationSchema = object().shape({
    email: string()
      .email(t('landing2:validation.email.valid', 'Must be a valid email'))
      .required(t('landing2:validation.email.required', 'This field is required')),
  });

  const validate = async () => {
    try {
      await validationSchema.validate({email});
      return true;
    } catch (e) {
      const message = (e as ValidationError).message;
      message && setError(message);

      return false;
    }
  };

  const submitForm = async () => {
    const isValidate = await validate();
    if (!isValidate) return;
    if (!user) {
      await Firebase.signInAnonymously();
    }
    try {
      await ContactApi.sendContactRequest({
        emailAddress: email,
        message: email,
        name: email,
      });
      toast.success(t('landing2:toast.success_send_email', 'We’ll contact you soon.'));
      setEmail('');
      if (error) setError('');
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err.message);
      }
    }
  };

  return (
    <section className={cn(s.subscribe, className)}>
      <h2 className={s.subscribe__title}>{t('landing2:subscribe.title', "Let's Talk")}</h2>
      <div className={s.subscribe__description}>
        {t(
          'landing2:subscribe.description',
          "We're here to help. Send us a message and we'll respond as soon as possible.",
        )}
      </div>
      <div className={s.subscribe__body}>
        <CtrlForm
          label={t('landing2:subscribe.label', 'Email address')}
          labelIsHidden
          id="subscribeEmail"
          className={s.subscribe__field}
          field={
            <>
              <CtrlTextfield
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('landing2:subscribe.placeholder', 'Enter your email')}
              />
              {error ? <div className="ctrl-form__validation">{error}</div> : null}
            </>
          }
        />
        <CtrlButton
          type="submit"
          className={s.subscribe__button}
          text={t('landing2:subscribe.button', 'Contact me')}
          onClick={() => mixpanel.trackWithAction(submitForm, mixpanelEvents.letsTalk)}
        />
      </div>
    </section>
  );
};

export default Subscribe;
