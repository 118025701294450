import React, {FC, useCallback, useState} from 'react';

import {MonthlyPaymentCalculator} from 'shared/components';
import TiersPricingTable from 'shared/components/ProPlanPriceTable/index';
import {CalcMonthlyPaymentResult} from 'shared/helpers/subscriptions';
import {FormattedProPlanTiers} from 'shared/models/checkout';

type Props = {
  tiers: FormattedProPlanTiers[];
};

const LandingView: FC<Props> = ({tiers}) => {
  const [calcResult, setCalcResult] = useState<CalcMonthlyPaymentResult>(null);

  const onCalcResultChange = useCallback((value: CalcMonthlyPaymentResult) => {
    return setCalcResult(value);
  }, []);

  return (
    <>
      <TiersPricingTable
        tiers={tiers}
        className="plan-price--view-landing plan__price-table"
        showHighlight={true}
        highlightedTier={calcResult?.matched}
      />
      <MonthlyPaymentCalculator tiers={tiers} className="plan__calc" onResultChange={onCalcResultChange} />
    </>
  );
};
export default LandingView;
