import 'react-loading-skeleton/dist/skeleton.css';
import 'assets/stylesheets/common.min.css';
import 'cropperjs/dist/cropper.css';
import 'assets/stylesheets/tmp_import_preview.css';
import 'assets/scripts/modernizr/modernizr-custom.js';

import React, {Suspense} from 'react';
import {render} from 'react-dom';

// breaks application if sort order is applied 😢
// eslint-disable-next-line import/order
import App from './App';

import smoothscroll from 'smoothscroll-polyfill';

import {Loader} from 'shared/components';

smoothscroll.polyfill();

render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
