import {useMemo} from 'react';

import {useRootSelector} from 'store';
import {getProjectResponsibleParties, getProjectLocations} from 'store/projects/selectors';

import {OptionType} from '../components';

export function useOptionsForSelect(projectId: string) {
  const responsibleParties = useRootSelector((state) => getProjectResponsibleParties(state, projectId));
  const projectLocations = useRootSelector((state) => getProjectLocations(state, projectId));

  const responsibleOptions = useMemo(
    () =>
      responsibleParties?.reduce((res, v) => {
        return res.concat({value: v, label: v});
      }, [] as OptionType[]) ?? [],
    [responsibleParties],
  );

  const locationOptions = useMemo(
    () =>
      projectLocations
        .filter((value) => !!value)
        .reduce((res, v) => {
          return res.concat({value: v, label: v});
        }, [] as OptionType[])
        .sort((a, b) => a.label?.localeCompare(b.label)),
    [projectLocations],
  );

  return {responsibleOptions, locationOptions};
}
