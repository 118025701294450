import {cloneElement} from 'react';

import {Icon} from 'shared/components';
import {CtrlBtnOption, CtrlButton, Dropdown} from 'shared/components/CoreNewUI';
import {IconsMap} from 'shared/constants/icons';

import {ActionItem} from '../ActionsBar/types';

import s from './Toolbar.module.scss';

function ToolbarMoreActions({
  actions,
  selected,
  canEdit,
}: {
  actions: ActionItem[];
  selected: string[];
  canEdit?: boolean;
}) {
  return (
    <Dropdown
      dataCy="btnMore"
      className={s.moreActions}
      toggleElement={
        <CtrlButton color="action" size="xs" icon={IconsMap.more_horizontal} iconOnly={true}>
          More
        </CtrlButton>
      }
      viewportPosition={canEdit ? 'right' : 'left'}
    >
      {actions
        .filter((action) => !action.hide)
        .map(({name, text, icon, customButton, onClick, disabled, dataCy}, i) =>
          customButton ? (
            cloneElement(customButton, {key: `${name}_${i}`})
          ) : (
            <CtrlBtnOption
              data-cy={dataCy}
              key={`${name}_${i}`}
              title={text}
              onClick={onClick}
              disabled={disabled?.(selected)}
              icon={<Icon name={icon} colorFill />}
            />
          ),
        )}
    </Dropdown>
  );
}

export default ToolbarMoreActions;
