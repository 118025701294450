import {TFunction} from 'i18next';
import React from 'react';

import EmptyGridBubble from 'shared/components/EmptyGridBubble';
import {toTitleCase} from 'shared/helpers/common';

interface NoDataBubbleProps {
  hasFilters: boolean;
  isGanttView: boolean;
  status: string;
  openNewTask: () => void;
  t: TFunction;
  asLink?: boolean;
}

export default function NoDataBubble({hasFilters, openNewTask, status, isGanttView, t}: NoDataBubbleProps) {
  const getText = () => {
    if (hasFilters) {
      return `No results found. Please try again or reset filters.`;
    }

    if (isGanttView) {
      return `You have no ${status} activities yet.`;
    }

    return status === 'active'
      ? 'You have no active Activities yet. Would you like to create one?'
      : `No ${toTitleCase(status)} Activities`;
  };

  const buttonProps =
    !hasFilters && !isGanttView && status === 'active'
      ? {cardText: 'Create a activity', buttonText: 'New Activity', buttonAction: openNewTask}
      : {};

  return <EmptyGridBubble text={getText()} {...buttonProps} t={t} />;
}
