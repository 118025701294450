import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect} from 'react';

import sprite from 'assets/images/svg-sprite.svg';
import {GANTT_COLUMNS_NAMES} from 'modules/Tasks/components/Gantt/utils';
import {useGanttEventStore} from 'modules/Tasks/components/Gantt/utils/eventStore';
import {GanttEventNameUnion, toggleOpen} from 'modules/Tasks/components/Gantt/utils/gantt';
import {GANTT_PREFERENCES_KEY} from 'shared/constants/common';
import {useLocalStorage} from 'shared/hooks';
import {useDebounce} from 'shared/hooks/core';

export function handleCollapseClick(e: Event, gantt: GanttStatic) {
  let button: HTMLElement;
  if (e.target instanceof Element && (button = e.target.closest('#gantt-collapse-button'))) {
    const allCollapsed: boolean = button.dataset.allCollapsed === 'true';
    const detach = gantt.attachEvent(
      'onGanttRender',
      () => {
        update(gantt, !allCollapsed);
        gantt.detachEvent(detach);
      },
      undefined,
    );
    toggleOpen(gantt, allCollapsed);
  }
}

const getButton = () => {
  return document.querySelector<HTMLButtonElement>('#gantt-collapse-button');
};

function updateToggleButton(allCollapsed: boolean) {
  const button = getButton();
  if (button) {
    button.dataset.allCollapsed = `${allCollapsed}`;
    button.innerHTML = getToggleIcon(allCollapsed);
  }
}

function updateColumnTitle(gantt: GanttStatic) {
  const innerHTML = document.querySelector(`.gantt_grid_head_${GANTT_COLUMNS_NAMES.name}`)?.innerHTML;
  const column = gantt.getGridColumn(GANTT_COLUMNS_NAMES.name);
  if (column && innerHTML) {
    column.label = innerHTML;
  }
}

function update(gantt: GanttStatic, allCollapsed: boolean) {
  gantt.allCollapsed = allCollapsed;
  updateToggleButton(allCollapsed);
  updateColumnTitle(gantt);
}

export function getToggleIcon(allCollapsed: boolean) {
  return `<svg width="24" height="24"><use href="${sprite}#${getIconName(allCollapsed)}"/></svg>`;
}

export function getIconName(allCollapsed: boolean) {
  return allCollapsed ? 'expand_all' : 'collapse_all';
}

export function isAllCollapsed(gantt: GanttStatic) {
  function someChildrenExpanded(parent: string) {
    for (const childId of gantt.getChildren(parent)) {
      if (gantt.hasChild(childId)) {
        if (gantt.getTask(childId).$open || someChildrenExpanded(childId)) {
          return true;
        }
      }
    }
    return false;
  }

  return !someChildrenExpanded(gantt.config.root_id as string);
}

export function useRowsOpenState(
  gantt: GanttStatic,
  projectId: string,
  strategy: 'expanded' | 'collapsed' = 'expanded',
) {
  const [getExpanded, setExpanded] = useLocalStorage<string[]>({
    key: GANTT_PREFERENCES_KEY,
    path: `${gantt.name}.byProject.${projectId}.${strategy}`,
    defaultValue: [],
    enabled: !!(gantt.name && projectId),
  });
  const eventStore = useGanttEventStore<GanttEventNameUnion>(gantt);

  const recalculateExpanded = useDebounce(() => {
    if (gantt.getTaskCount()) {
      update(gantt, isAllCollapsed(gantt));
    }
  }, 250);

  useEffect(() => {
    const saveExpanded = strategy === 'expanded';
    function addToStore(taskId: string) {
      setExpanded(Array.from(new Set(getExpanded().concat(taskId))));
    }
    function removeFromStore(taskId: string) {
      setExpanded(getExpanded().filter((id) => id !== taskId));
    }

    eventStore.attach('onGanttRender', recalculateExpanded);
    eventStore.attach('onDataRender', recalculateExpanded);
    eventStore.attach('onGridHeaderClick', function (name, e) {
      handleCollapseClick(e, gantt);
    });
    eventStore.attach('onTaskOpened', function (taskId) {
      saveExpanded ? addToStore(taskId) : removeFromStore(taskId);
      update(gantt, false);
    });
    eventStore.attach('onTaskClosed', function (taskId) {
      saveExpanded ? removeFromStore(taskId) : addToStore(taskId);
      recalculateExpanded();
    });
    return () => eventStore.detachAll();
  }, [strategy, gantt, projectId, eventStore]);

  return [getExpanded, setExpanded] as const;
}
