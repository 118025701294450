import {useCallback, useRef, useState} from 'react';
import {toast} from 'react-toastify';

import {SubscriptionApi} from 'api';
import {useMountedState, useUnmount} from 'shared/hooks/core';

import {SubscriptionEventTypes} from '../utils/constants';

export function useUpdateBillingInfo(companyId: string, subscriptionId: string) {
  const [inProgress, setInProgress] = useState(false);
  const popupRef = useRef<Window>();
  const timer = useRef<number | null>();
  const isMounted = useMountedState();

  const clear = useCallback(
    (listener: (e: MessageEvent) => void) => {
      if (popupRef.current) {
        isMounted() && setInProgress(false);
        if (timer.current) {
          clearInterval(timer.current);
          timer.current = null;
        }
        window.removeEventListener('message', listener);
        popupRef.current.close();
        popupRef.current = null;
      }
    },
    [setInProgress, isMounted],
  );

  const onMessage = useCallback(
    (e: MessageEvent) => {
      if (e.data.type === SubscriptionEventTypes.billingInfoRedirect) {
        popupRef.current?.close();
        clear(onMessage);
      }
    },
    [clear],
  );

  const edit = async () => {
    if (popupRef.current) {
      popupRef.current.focus();
      return;
    }
    setInProgress(true);
    try {
      const url = await SubscriptionApi.generatePortalUrl(
        companyId,
        subscriptionId,
        `${window.location.origin}/billing-info-redirect.html`,
      );
      const popup = (popupRef.current = window.open(url, '_blank', 'width: 800, height: 800'));
      timer.current = window.setInterval(() => {
        if (popup.closed) {
          clear(onMessage);
        }
      }, 500);
      window.addEventListener('message', onMessage);
    } catch (error) {
      clear(onMessage);
      toast.error('Failed to generate a link to stripe');
    }
  };

  useUnmount(() => clear(onMessage));

  return {inProcess: inProgress, edit} as const;
}
