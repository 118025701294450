import cn from 'classnames';
import {components} from 'react-select';

import s from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkWatcher/BulkWatcher.module.scss';
import {Avatar} from 'shared/components';

export const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="worker-accepted">
        {props.data?.abbrev ? (
          <div className={cn(s.subcontractorInfo)}>
            <div className={cn(s.subcontractorInfo__preview)} style={{backgroundColor: props.data?.lookaheadColor}}>
              {props.data?.abbrev}
            </div>
            <span className={s.subcontractorInfo__text}>{props.data?.label}</span>
            <span className={s.subcontractorInfo__numbers}>&nbsp;({props.data.nbWorkers})</span>
          </div>
        ) : (
          <div className="worker-accepted__info">
            <Avatar
              className="avatar worker-accepted__info-part worker-accepted__info-part--avatar"
              src={props.data?.profilePicUrl}
            />
            <div className="worker-accepted__info-part worker-accepted__info-part--text">
              <div className="worker-accepted__name">
                <span>{props.data?.label}</span>
              </div>
              <div className="worker-accepted__trade">
                <span>{props.data?.trade}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </components.Option>
  );
};

export const CustomSingleValue = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      <>
        <div className={cn(s.selectLabel)}>
          <div className={cn(s.selectLabel__name)}>{props.data?.label}</div>
          {typeof props.data?.nbWorkers === 'number' && (
            <div className={cn(s.selectLabel__numbers)}>&nbsp;({props.data.nbWorkers})</div>
          )}
        </div>
      </>
    </components.MultiValueLabel>
  );
};
