import {TFunction} from 'i18next';

import {OptionType} from 'shared/components';
import {toTitleCase} from 'shared/helpers/common';
import {CompanyModel} from 'shared/models/company';
import {CompanyWorker, CompanyWorkerFilterParams, CompanyWorkerRole} from 'shared/models/worker';

import env from '../constants/env';
import {ProSubscriptionTypes} from '../models/checkout';

/*
 * worker company roles
 * t('common:worker_roles.project_admin', 'Project Admin');
 * t('common:worker_roles.company_admin', 'Admin');
 * t('common:worker_roles.worker', 'Worker');
 * t('common:worker_roles.foreman', 'Foreman');
 *
 *  worker mapped statuses
 * t('common:worker_status.active', 'Active');
 * t('common:worker_status.removed', 'Removed');
 * t('common:worker_status.invited', 'Invited');
 * t('common:worker_status.closed', 'Closed');
 *
 * worker trades
 * t('common:worker_trades.ALC'),
 * t('common:worker_trades.BIM Coordinator'),
 * t('common:worker_trades.HVAC Control Engineer'),
 * t('common:worker_trades.HVAC'),
 * t('common:worker_trades.administration'),
 * t('common:worker_trades.app developer'),
 * t('common:worker_trades.architect'),
 * t('common:worker_trades.business development manager'),
 * t('common:worker_trades.carpenter'),
 * t('common:worker_trades.concrete finisher'),
 * t('common:worker_trades.developer'),
 * t('common:worker_trades.digital fabrication specialist'),
 * t('common:worker_trades.draftsman'),
 * t('common:worker_trades.driver'),
 * t('common:worker_trades.drywall finisher'),
 * t('common:worker_trades.drywall'),
 * t('common:worker_trades.electrician'),
 * t('common:worker_trades.engineer'),
 * t('common:worker_trades.equipment operator'),
 * t('common:worker_trades.fire alarm'),
 * t('common:worker_trades.fireproofing'),
 * t('common:worker_trades.flooring'),
 * t('common:worker_trades.foreman'),
 * t('common:worker_trades.general contractor'),
 * t('common:worker_trades.glazier'),
 * t('common:worker_trades.handyman'),
 * t('common:worker_trades.human resources specialist'),
 * t('common:worker_trades.innovation engineer'),
 * t('common:worker_trades.insulator'),
 * t('common:worker_trades.ironwork'),
 * t('common:worker_trades.laborer'),
 * t('common:worker_trades.landscaper'),
 * t('common:worker_trades.manager'),
 * t('common:worker_trades.manufacturing engineer'),
 * t('common:worker_trades.mason'),
 * t('common:worker_trades.metal framer'),
 * t('common:worker_trades.millwork'),
 * t('common:worker_trades.millwright'),
 * t('common:worker_trades.owner'),
 * t('common:worker_trades.painter'),
 * t('common:worker_trades.pipefitter'),
 * t('common:worker_trades.plastering'),
 * t('common:worker_trades.plumber'),
 * t('common:worker_trades.production operator'),
 * t('common:worker_trades.program manager'),
 * t('common:worker_trades.project manager'),
 * t('common:worker_trades.purchase agent'),
 * t('common:worker_trades.quality control inspector'),
 * t('common:worker_trades.receptionist'),
 * t('common:worker_trades.reinforcing ironworker'),
 * t('common:worker_trades.rigger'),
 * t('common:worker_trades.roofer'),
 * t('common:worker_trades.scaffolding'),
 * t('common:worker_trades.security'),
 * t('common:worker_trades.sheet metal worker'),
 * t('common:worker_trades.site supervisor'),
 * t('common:worker_trades.structural ironworker'),
 * t('common:worker_trades.structured cabling'),
 * t('common:worker_trades.subcontractor'),
 * t('common:worker_trades.superintendent'),
 * t('common:worker_trades.supplier'),
 * t('common:worker_trades.unspecified'),
 * t('common:worker_trades.waterproofer'),
 * t('common:worker_trades.welder'),
 * // old
 * t('common:worker_trades.roofer', 'Roofer')
 * */

export function hasAdminAccess(companyWorkers: CompanyWorker[]) {
  /* ignore companies with hirer role , because it's the marketplace company, and it should not be visible in C4*/
  return companyWorkers.some((w) => !!w.roles && w.roles.includes('company_admin') && !w.roles.includes('hirer'));
}

// TODO это специфичная функция, она не нужен нигде кроме страницы воркеров. вынести в фичу воркеров
// TODO This is a specific function, it is not needed anywhere except the workers page. Move it to the workers feature.
export const prepareWorkerFiltersForUrl = (filters: CompanyWorkerFilterParams): {[key: string]: string} => {
  const {wildcard, blendedStatus, trade, orgList, workerIdList, companyLevel, ...restParams} = filters;
  const preparedFilters = {};

  if (wildcard != null) {
    Object.assign(preparedFilters, {wildcard});
  }
  if (blendedStatus) {
    Object.assign(preparedFilters, {blendedStatus});
  }
  if (trade) {
    Object.assign(preparedFilters, {trade});
  }
  if (orgList) {
    Object.assign(preparedFilters, {orgList: typeof orgList === 'object' ? orgList.join(',') : orgList.split(',')});
  }
  if (workerIdList) {
    Object.assign(preparedFilters, {workerIdList: workerIdList.join(',')});
  }

  if (companyLevel) {
    Object.assign(preparedFilters, {companyLevel: 'true'});
  }

  return {...restParams, ...preparedFilters};
};

export function getWorkerFlyerPath(workerId: string) {
  const origin = env.byCoreBaseLink || 'https://bycore.com';
  const flyerPath = '/workercard/:id'.replace(':id', workerId) || '';
  return origin + flyerPath;
}

export function getNormalizedWorkerRoles(roles: string[], t: TFunction): string[] {
  return roles?.map((role) => t(`common:worker_roles.${role}`)) || roles;
}

export const getProperPhoneNumber = (formattedPhoneNumber: string): string => {
  const numberWithoutAnyCharacters = formattedPhoneNumber.replace(/[^\d]/g, '');
  return `+${numberWithoutAnyCharacters}`;
};

export function sortWorkerRoles(roles: CompanyWorkerRole[]) {
  const rolesWeight: Record<CompanyWorkerRole, number> = {
    worker: 1,
    hirer: 2,
    foreman: 3,
    company_admin: 4,
    project_admin: 5,
  };

  return [...roles].sort((roleA, roleB) => rolesWeight[roleB] - rolesWeight[roleA]);
}

export function getNormalizedWorkerTrade(trade: string, t: TFunction) {
  trade = t(`common:worker_trades.${trade}` || trade);
  if (!trade) return trade;

  // for example HVAC
  if (trade === trade.toUpperCase()) return trade;

  return toTitleCase(trade);
}

export function filterAdminCompanies(companies: CompanyModel[], workers: CompanyWorker[]): CompanyModel[] {
  const companiesWithAdminAccess = workers
    .filter((worker) =>
      worker.roles
        ? (worker.roles.includes('company_admin') || worker.roles.includes('project_admin')) &&
          !worker.roles.includes('hirer')
        : false,
    )
    .map((worker) => worker.companyId);
  return companies.filter(
    (company) =>
      companiesWithAdminAccess.includes(company.id) && company.subscriptionLevel !== ProSubscriptionTypes.FOREMAN_FREE,
  );
}

export function mapOrgsIds(orgsId: string[], orgsOptions: OptionType[]) {
  if (orgsId && orgsOptions) {
    return orgsId.reduce((acc, cur) => {
      const selectedOrg = orgsOptions.find((opt) => opt.id === cur);
      if (selectedOrg) acc.push(selectedOrg.label);
      return acc;
    }, []);
  }
}

// TODO: need check, is it possible replaced with filterDuplicatedProjectWorkers?
export function filterDuplicatedCompanyWorkers(cworkers: CompanyWorker[]) {
  if (!cworkers) return [];
  return cworkers.reduce((acc, cur) => {
    if (acc.find((w) => w.workerId === cur.workerId)) {
      return acc;
    }
    acc.push(cur);
    return acc;
  }, [] as CompanyWorker[]);
}

export function filterDuplicatedProjectWorkers(workers: CompanyWorker[]) {
  if (!workers) return [];
  return workers.reduce((acc, cur) => {
    if (!acc.find((w) => w.workerId === cur.workerId)) {
      acc.push(cur);
    }
    return acc;
  }, [] as CompanyWorker[]);
}
