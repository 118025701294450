import cn from 'classnames';
import {CellProps} from 'react-table';

import {Avatar} from 'shared/components';
import {CompanyWorker, Worker} from 'shared/models/worker';

import s from './NameColumn.module.scss';

export const NameColumn = ({value}: CellProps<CompanyWorker, Worker>) => {
  return (
    <div className={cn(s.nameColumn)}>
      <Avatar className={s.nameColumn__photo} src={value?.profilePicUrl} />
      <div className={s.nameColumn__name}>{value?.fullName || value?.email}</div>
    </div>
  );
};
