import React from 'react';
import {useTranslation} from 'react-i18next';

import {TutorialPopup} from 'shared/components/TutorialPopup';
import {useRootDispatch, useRootSelector} from 'store';
import {profileActions} from 'store/profile';

export function TasksTutorialPopup() {
  const dispatch = useRootDispatch();
  const {displayTutorialPopup} = useRootSelector((state) => ({
    displayTutorialPopup: state.profile.showNewProjectLookaheadTutorial,
  }));
  const {t} = useTranslation(['lookahead']);

  const hideTutorialPopup = () => dispatch(profileActions.showTutorialPopup(false));
  return (
    <TutorialPopup
      visible={displayTutorialPopup}
      onClose={hideTutorialPopup}
      header={t('lookahead:tutorial.header', 'Build Your Field Schedule - Quick & Easy!')}
      description={t(
        'lookahead:tutorial.description',
        'You can easily create your activities in Lookahead view and assign team members to stay on track!',
      )}
      buttonText={t('lookahead:tutorial.buttonText', 'Start Building')}
    >
      <div className="video">
        <video className="video__frame" controls muted autoPlay loop playsInline>
          <source src="/videos/lookahead/lookahead_tutorial.mp4" type="video/mp4" />
          <source src="/videos/lookahead/lookahead_tutorial.webm" type="video/webm" />
        </video>
      </div>
    </TutorialPopup>
  );
}
