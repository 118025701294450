import memoize from 'lodash/memoize';

import {toShortIso} from 'shared/helpers/dates';
import {ProjectModel, WorkDaysEnum, ProjectCalendarException} from 'shared/models/project';
import {TaskGanttLastChangedFields, TaskModelRawDTO} from 'shared/models/task';

import {ProjectCalendar} from './types';

/**
 * Transform gantt last changed fields into a format that can be used by the expander.
 * @param {TaskGanttLastChangedFields} fields Modified fields.
 * @return {object} Transformed fields compatible with prediction module.
 */
export function prepareLastChangedFields(fields: TaskGanttLastChangedFields): Partial<TaskModelRawDTO> {
  const result = {};
  for (const [key, change] of Object.entries(fields)) {
    if (key === 'taskDuration') {
      result['duration'] = typeof change.newValue === 'string' ? Number(change.newValue) : change.newValue;
    } else {
      if (change.newValue instanceof Date) {
        result[key] = toShortIso(change.newValue);
      } else {
        result[key] = change.newValue;
      }
    }
  }
  return result;
}

export const getMemoizedCalendar = memoize((project: ProjectModel): ProjectCalendar => {
  const dayNumbers = {sunday: 0, monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6};
  return {
    work_days: project.calendar.workDays.reduce((acc: Set<number>, day: WorkDaysEnum) => {
      acc.add(dayNumbers[day]);
      return acc;
    }, new Set<number>()),
    exceptions: project.calendar.exceptions.reduce(
      (acc: {[key: string]: boolean}, exception: ProjectCalendarException) => {
        acc[exception.date] = exception.working;
        return acc;
      },
      {},
    ),
  };
});
