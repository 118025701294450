import {TFunction} from 'i18next';

import {META_KEYWORDS} from 'shared/constants/common';

export type ContactContent = {
  title: string;
  description: string;
  messagePlaceholder: string;
  tag: string;
  metaDescription?: string;
  metaKeywords?: string;
};

export const getContactData = (t: TFunction): Record<string, ContactContent> => ({
  '/enterprise-contact': {
    title: t('contacts:enterprise_contact.title', 'Contact Sales'),
    description: t(
      'contacts:enterprise_contact.description',
      "We're here to answer any questions you have about Crews by Core Enterprise. Fill out our form and include any questions. We'll get back to you ASAP!",
    ),
    messagePlaceholder: t('contacts:enterprise_contact.messagePlaceholder', 'Any questions? Send us a message.'),
    metaDescription: t(
      'contacts:enterprise_contact.metaDescription',
      'We look forward to working with you! Reduce rework and cut chaos with collaborative task management for the whole team.',
    ),
    metaKeywords: META_KEYWORDS,
    tag: 'enterprise_sales',
  },
  '/demo-contact': {
    title: t('contacts:demo_contact.title', 'Request Demo'),
    description: t(
      'contacts:demo_contact.description',
      "We're here to answer any questions you have about Crews by Core Pro. Fill out our form to Schedule a time for Demo. We'll get back to you ASAP!",
    ),
    messagePlaceholder: t(
      'contacts:demo_contact.messagePlaceholder',
      'What dates / times work for you? Any questions?',
    ),
    metaDescription: t(
      'contacts:demo_contact.metaDescription',
      'Dive in to Crews by Core PRO: explore features, see how it works and how it can reduce rework and improve communications for your whole team.',
    ),
    metaKeywords: META_KEYWORDS,
    tag: 'demo_request',
  },
});

export const getContactContentByKey = (key: string, t) => {
  return getContactData(t)[key];
};
