import React, {FC, ReactElement, ReactNode, useEffect, useRef, useState} from 'react';

type DragAndDropProps = {
  showOnDrag: ReactElement;
  onDropHandle: (fileList: FileList) => void;
  children: ReactNode;
};

const DragAndDrop: FC<DragAndDropProps> = ({children, showOnDrag, onDropHandle}) => {
  const dropRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [dragCount, setDragCount] = useState<number>(0);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
    setDragCount((prev) => prev + 1);
  };

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCount((prev) => prev - 1);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onDropHandle(e.dataTransfer.files);
      e.dataTransfer.clearData();
      setDragCount(0);
    }
  };

  useEffect(() => {
    setDragging(dragCount > 0);
  }, [dragCount]);

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  }, []);

  return (
    <div ref={dropRef} style={{cursor: dragging ? 'copy' : 'auto'}}>
      {dragging ? showOnDrag : children}
    </div>
  );
};
export default DragAndDrop;
