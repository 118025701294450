import classNames from 'classnames';
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Icon, SkeletonPreloader} from 'shared/components';
import env from 'shared/constants/env';
import {useFormattedProPlanTiers} from 'shared/hooks';
import {ProSubscription, ProSubscriptionTypes} from 'shared/models/checkout';
import {CompanyModel} from 'shared/models/company';
import {useRootDispatch, useRootSelector} from 'store';
import {profileActions, selectCurrentSubscription} from 'store/profile';

import SubscriptionModal from '../SubscriptionModal';

import s from './CompanyPreviewUpsell.module.scss';

type CompanyPreviewProps = {
  values: CompanyModel;
  onEdit: () => void;
  className?: string;
};

const CompanyPreview: FC<CompanyPreviewProps> = ({values, onEdit, className}) => {
  const {t} = useTranslation('company');
  const loading = !values;
  const dispatch = useRootDispatch();

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const {subscription, proPlan} = useRootSelector((state) => ({
    subscription: selectCurrentSubscription(state),
    subscriptionLoaded: !!state.profile.companySubscriptions,
    proPlan: state.profile.proPlan,
  }));
  const proPlanTiers = useFormattedProPlanTiers(proPlan, 'short');

  const isSubscribed = subscription?.type === ProSubscriptionTypes.PRO && subscription?.status === 'active';
  const manageSubscription = async () => {
    setShowSubscriptionModal(true);
  };
  const onSubscriptionCanceled = () => {
    dispatch(profileActions.cancelSubscription(subscription.id));
  };

  const onSubscriptionUpdated = (sub: ProSubscription) => {
    dispatch(profileActions.updateSubscription([sub]));
  };

  return (
    <section className={classNames('company-preview', className)}>
      <div className="company-preview__container">
        <div className="ctrl-upload ctrl-upload--disabled">
          <picture className="avatar ctrl-upload__avatar">
            <SkeletonPreloader when={loading} width="100%" height="100%" circle>
              <source src={values?.logoUrl || '/images-temp/avatars/avatar-company-0-256x256@1x.png'} />
              <img
                className="avatar__image"
                src={values?.logoUrl || '/images-temp/avatars/avatar-company-0-256x256@1x.png'}
                alt="company logo"
              />
              <button className="ctrl-btn-clear avatar__addition" type="button" onClick={onEdit}>
                <span className="ctrl-btn-clear__text">{t('preview.avatar.edit', 'Edit Avatar')}</span>
                <svg className="icon icon--color-fill ctrl-btn-clear__icon" width={24} height={24}>
                  <use xlinkHref="/images/svg-sprite.svg#edit" />
                </svg>
              </button>
            </SkeletonPreloader>
          </picture>
        </div>
        <div className="company-preview__name">
          <SkeletonPreloader width={200} when={loading}>
            {values?.companyName}
          </SkeletonPreloader>
        </div>
        <div className="company-preview__info">
          <SkeletonPreloader when={loading} className="company-preview__info-item" width={300}>
            {!!values?.businessUrl && (
              <a
                className="company-preview__info-item company-preview__info-item--link"
                target="_blank"
                href={values.businessUrl}
                rel="noopener noreferrer"
              >
                <Icon colorFill company-preview__info-icon name="link" />
                <span className="company-preview__info-text">{values.businessUrl}</span>
              </a>
            )}
            {!!values?.currentSeatCount && (
              <div className="company-preview__info-item">
                <Icon name="group_equal" colorFill className="company-preview__info-icon" />
                <span className="company-preview__info-text">
                  {values.currentSeatCount}{' '}
                  {values.currentSeatCount > 1
                    ? t('preview.seat_count.plural', 'workers')
                    : t('preview.seat_count.single', 'worker')}
                </span>
              </div>
            )}
            {!!values?.companyAddress && (
              <div className="company-preview__info-item">
                <Icon colorFill company-preview__info-icon name="poi" />
                <span className="company-preview__info-text">{values.companyAddress}</span>
              </div>
            )}
          </SkeletonPreloader>
        </div>
        <div className="company-preview__description">
          <SkeletonPreloader count={3} when={loading}>
            {values?.pitchText}
          </SkeletonPreloader>
        </div>
        {!isSubscribed && (
          <button className={s.upsell__upsell_ctrl_btn} type="button" onClick={manageSubscription}>
            <span className="ctrl-btn__text">
              {!isSubscribed
                ? t('license.see_options', 'See Payment Options')
                : t('license.active_subscription', 'Manage subscription')}
            </span>
          </button>
        )}
        {!isSubscribed && env.stripePublishableKey ? (
          <SubscriptionModal
            company={values}
            subscription={subscription}
            plan={{...proPlan, tiers: proPlanTiers}}
            visible={showSubscriptionModal}
            onVisibleChange={setShowSubscriptionModal}
            onSubscriptionCanceled={onSubscriptionCanceled}
            onSubscriptionUpdated={onSubscriptionUpdated}
          />
        ) : null}
        <div className={s.upsell__upsell_container}>
          <div className={s.upsell__upsell_title}>{t('company.help.tools_to_help', 'Tools to Help Your Company')}</div>
          <video
            autoPlay={true}
            muted={true}
            className={s.upsell__upsell_image}
            src="/images/other/schedule-made-easy.mp4"
          />
          <div className={s.upsell__upsell_container}>
            <span className={s.upsell__upsell_image_title}>
              {t('company.help.scheduling_made_easy', 'Scheduling Made Easy')}
            </span>
            <ul>
              <li className={s.upsell__upsell_image_title_li}>
                {t('company.help.create_lookaheads', 'Create Lookaheads')}
              </li>
              <li className={s.upsell__upsell_image_title_li}>
                {t('company.help.easy_milestones_baselines', 'Easily see Milestones and Compare Baselines')}
              </li>
            </ul>
          </div>
          <div className={s.upsell__upsell_container}>
            <span className={s.upsell__upsell_image_title}>
              {t('company.help.visualize_schedules', 'Visualize Schedules on Your Floorplans')}
            </span>
            <ul>
              <li className={s.upsell__upsell_image_title_li}>
                {t('company.help.helpful_videos.check_out_our', 'Check out our')}{' '}
                <a href={env.corePricingHelpfulVideos} rel="noreferrer" target="_blank">
                  {t('company.help.helpful_videos.helpful_videos', 'Helpful Videos')}
                </a>{' '}
                {t('company.help.to_get_started', 'to get Started')}
              </li>
            </ul>
          </div>
          <div className={s.upsell__upsell_container}>
            <span className={s.upsell__upsell_image_title}>
              {t('company.help.figure_out_plans', 'Figure out what Plans work best for your company')}
            </span>
            <ul>
              <li className={s.upsell__upsell_image_title_li}>
                {t('company.help.comparison.check_out_our', 'Check out our')}{' '}
                <a href={env.corePricing} rel="noreferrer" target="_blank">
                  {t('company.help.comparison.comparison_page', 'Comparison page')}
                </a>{' '}
                {t('company.help.comparison.to_see_features', 'to see the features by plan')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CompanyPreview;
