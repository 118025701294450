import Compressor from 'compressorjs';
import CropperJs from 'cropperjs';
import React, {ChangeEvent, Component, createRef, RefObject} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';

import {DragMode} from 'modules/Tasks/components/Gantt/types';
import {throttle} from 'shared/helpers/throttle';

import {Icon} from '..';
import Loader from '../Loader';
import Slider from '../Slider';

import {AvatarCropModes} from './AvatarCropModes';
import {NoAvatar} from './NoAvatar';

type RotateType = unknown & {detail?: {rotate?: number}};

type AvatarCropProps = {
  image: string; // original image src that will be cropped
  previewImg: string;
  edit: boolean;
  classNames?: string;
  fileType?: string; // output file type
  zoom?: number;
  rotate?: number;
  onImageChange(image: File | null): void;
  onCropped(blob: Blob);
  onEdit?(editing: boolean): void;
  onZoom(zoom: number): void;
  onRotate(angle: number): void;
  maxWidth?: number;
  throttle?: number;
  imageBrowseEvent?: string;
};
type AvatarCropState = {
  ready: boolean;
  mode?: AvatarCropModes;
};

class AvatarCrop extends Component<AvatarCropProps & WithTranslation, AvatarCropState> {
  private cropper!: CropperJs;
  private image: RefObject<HTMLImageElement> = createRef();
  private input: RefObject<HTMLInputElement> = createRef();
  static defaultProps = {
    zoom: 0,
    rotate: 0,
  };

  constructor(props) {
    super(props);
    this.state = {ready: false};
    this.imageChange = this.imageChange.bind(this);
    this.getZoom = this.getZoom.bind(this);
    this.getCroppedImage = this.getCroppedImage.bind(this);
    this.compressOriginalImage = this.compressOriginalImage.bind(this);
  }

  imageChange(event?: ChangeEvent<HTMLInputElement>) {
    const image = event ? event.currentTarget.files[0] : null;
    if (image) {
      this.setState({ready: false});
      new Compressor(image, {
        quality: 0.8,
        maxWidth: 1920,
        maxHeight: 1080,
        success: (blob: Blob) => {
          this.props.onImageChange(new File([blob], image.name, {type: blob.type}));
          this.setState({ready: true});
        },
        error: () => {
          this.props.onImageChange(image);
          this.setState({ready: true});
        },
      });
    } else {
      this.props.onImageChange(null);
    }
  }

  componentDidMount() {
    if (this.props.image && this.props.edit) {
      this.initCropper();
    }
  }

  componentDidUpdate(prevProps: AvatarCropProps) {
    if (!this.props.image && this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }
    if (this.props.image && this.props.edit) {
      if (!this.cropper) {
        this.initCropper();
      } else if (this.props.image !== prevProps.image) {
        this.cropper.replace(this.props.image);
      }
    }
    if (!this.props.image) {
      this.input.current.value = '';
    }
  }

  getZoom() {
    if (!this.cropper) {
      return 0;
    }
    const data = this.cropper.getCanvasData();
    return data.width / data.naturalWidth;
  }

  getRoundedCanvas() {
    const sourceCanvas = this.cropper.getCroppedCanvas({width: 256, height: 256});
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;
    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();
    return canvas;
  }

  getCroppedImage(): Promise<Blob> {
    return new Promise((res) => {
      if (this.cropper) {
        return this.cropper.getCroppedCanvas({width: 256}).toBlob(res, this.props.fileType, 0.8);
      }
      res(null);
    });
  }

  compressOriginalImage() {
    if (this.cropper) {
      this.cropper.getCroppedCanvas({maxWidth: this.props.maxWidth || 1920}).toBlob(
        (blob) => {
          const a = document.createElement('a');
          a.download = 'optimized';
          a.href = URL.createObjectURL(blob);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
        this.props.fileType,
        0.8,
      );
    }
  }

  initCropper() {
    this.cropper = new CropperJs(this.image.current, {
      viewMode: 3,
      aspectRatio: 1,
      autoCropArea: 1,
      center: false,
      guides: false,
      highlight: false,
      modal: false,
      dragMode: DragMode.move,
      cropBoxMovable: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      checkOrientation: false,
      minContainerWidth: 48,
      minContainerHeight: 48,
      zoom: (event) => {
        if (event.detail.ratio >= 0 && event.detail.ratio <= 1) {
          this.props.onZoom(event.detail.ratio);
        } else {
          event.preventDefault();
          event.stopPropagation();
        }
      },
      crop: throttle((e) => {
        this.getCroppedImage().then(this.props.onCropped);
        this.props.onRotate((e as RotateType).detail.rotate);
      }, this.props.throttle || 200),
      ready: () => {
        const zoom = this.props.zoom || this.getZoom();
        this.props.onZoom(zoom);
        this.cropper.zoomTo(zoom);
        this.props.rotate && this.cropper.rotateTo(this.props.rotate);
        this.setState({ready: true});
      },
    });
  }

  throttledZoom = throttle((scale: number) => {
    // eslint-disable-next-line no-invalid-this
    this.cropper.zoomTo(scale);
  }, 50);

  componentWillUnmount() {
    this.cropper && this.cropper.destroy();
    this.cropper = null;
  }

  editPhoto() {
    const {image, onImageChange} = this.props;
    return (
      <>
        <picture className="avatar ctrl-upload__avatar">
          <img crossOrigin="anonymous" ref={this.image} className="avatar__image" src={image} alt="" />
        </picture>
        <div className="ctrl-crop ctrl-upload__body">
          <div className="ctrl-crop__header">
            <div className="ctrl-crop__title">{this.props.t('cropper.actions.adjust', 'Adjust a Photo')}</div>
            <button
              className="ctrl-btn-clear ctrl-crop__button-remove"
              type="button"
              onClick={() => onImageChange(null)}
            >
              <span className="ctrl-btn-clear__text">
                {this.props.t('cropper.actions.remove', 'Remove this photo')}
              </span>
              <Icon name="remove_from_trash" colorFill={true} size={24} className="ctrl-btn-clear__icon" />
            </button>
          </div>
          <div className="ctrl-crop__body">
            <div className="ctrl-crop__group">
              <button
                className="ctrl-btn-clear ctrl-crop__button"
                type="button"
                onClick={() => this.cropper.rotate(-15)}
              >
                <span className="ctrl-btn-clear__text">
                  {this.props.t('cropper.actions.rotateLeft', 'Rotate to left')}
                </span>
                <Icon name="rotate-left" colorFill={true} size={24} className="ctrl-btn-clear__icon" />
              </button>

              <button
                className="ctrl-btn-clear ctrl-crop__button"
                type="button"
                onClick={() => this.cropper.rotate(15)}
              >
                <span className="ctrl-btn-clear__text">
                  {this.props.t('cropper.actions.rotateRigth', 'Rotate to right')}
                </span>
                <Icon name="rotate-right" colorFill={true} size={24} className="ctrl-btn-clear__icon" />
              </button>
            </div>
            <Slider
              value={this.props.zoom}
              change={this.throttledZoom}
              step={0.05}
              min={0}
              max={1}
              iconLeft={<Icon name="zoom-out" colorFill={true} size={24} className="ctrl-btn-clear__icon" />}
              iconRight={<Icon name="zoom-in" colorFill={true} size={24} className="ctrl-btn-clear__icon" />}
            />
          </div>
        </div>
        {!this.state.ready && <Loader />}
      </>
    );
  }

  previewPhoto() {
    const {previewImg, onEdit} = this.props;
    return (
      <div className="ctrl-upload__container">
        <div className="avatar ctrl-upload__avatar">
          <div className="avatar__container">
            <div className="image-box avatar__image">
              <img className="image-box__image" src={previewImg} alt="" />
            </div>
          </div>
        </div>
        <div className="ctrl-upload__info">
          <div className="ctrl-upload__header">
            <p className="ctrl-upload__title">{this.props.t('cropper.title', 'Your photo')}</p>
            <div className="ctrl-upload__actions">
              <button
                className="ctrl-button ctrl-button--icon-only ctrl-button--color-clear ctrl-button--size-xs ctrl-button--shadow-off"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onEdit && onEdit(true);
                }}
              >
                <span className="ctrl-button__container">
                  <span className="ctrl-button__text">{this.props.t('cropper.editTitle', 'Edit photo')}</span>
                  <Icon name="edit" size={24} className="ctrl-button__icon"></Icon>
                </span>
              </button>
              <button
                className="ctrl-button ctrl-button--icon-only ctrl-button--color-clear ctrl-button--size-xs ctrl-button--shadow-off"
                type="button"
                onClick={() => this.props.onImageChange(null)}
              >
                <span className="ctrl-button__container">
                  <span className="ctrl-button__text">
                    {this.props.t('cropper.actions.remove', 'Remove this photo')}
                  </span>
                  <Icon name="trash" size={24} className="ctrl-button__icon"></Icon>
                </span>
              </button>
            </div>
          </div>
          <p className="ctrl-upload__description">
            {this.props.t(
              'cropper.hint',
              'Resumes with photos increase your chance to get a job and build a Core Card',
            )}
          </p>
        </div>
      </div>
    );
  }

  render(): JSX.Element {
    const {image, previewImg, edit} = this.props;
    const showPreview = !edit && !!previewImg;
    const showCropper = edit && !!image;
    return (
      <div className="ctrl-upload" style={{position: 'relative'}}>
        {showPreview ? (
          this.previewPhoto()
        ) : showCropper ? (
          this.editPhoto()
        ) : (
          <NoAvatar
            ref={this.input}
            onImageChange={this.imageChange}
            browseEvent={this.props.imageBrowseEvent}
            t={this.props.t}
          />
        )}
      </div>
    );
  }
}

export default withTranslation('common')(AvatarCrop);
