import {AxiosRequestConfig} from 'axios';

import {MatrixConnectionToken, MatrixSessionBody, MatrixSessionResponse} from 'shared/models/task';

import ApiAxios from './axios';
import {createMatrixAxiosInstance} from './matrix';

export class ChatService {
  private async getMatrixConnectionToken(workerId: string) {
    const res = await ApiAxios.get<MatrixConnectionToken>(`/workers/${workerId}/chatauth`);
    return res.data;
  }

  async getMatrixServerConfig(accessToken: string) {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const ChatServiceAxios = await createMatrixAxiosInstance();
    const res = await ChatServiceAxios.get<{'m.upload.size': number}>('/_matrix/media/v3/config', config);
    return res.data;
  }

  async initializeMatrixSession(workerId: string) {
    const credentials = await this.getMatrixConnectionToken(workerId);
    const body: MatrixSessionBody = {
      type: 'm.login.password',
      identifier: {
        type: 'm.id.user',
        user: credentials.id,
      },
      password: credentials.password,
    };
    const ChatServiceAxios = await createMatrixAxiosInstance();
    const res = await ChatServiceAxios.post<MatrixSessionResponse>(`/_matrix/client/v3/login`, body);
    return res.data;
  }

  async uploadAssetToMatrix(accessToken: string, file: File) {
    const body = await file.arrayBuffer();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': file.type,
      },
    };
    const ChatServiceAxios = await createMatrixAxiosInstance();
    const res = await ChatServiceAxios.post<{contentUri: string}>(
      `/_matrix/media/v3/upload?filename=${file.name}`,
      body,
      config,
    );
    return res.data;
  }
}

export default new ChatService();
