import cn from 'classnames';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {SkeletonPreloader} from 'shared/components';
import {CtrlButton} from 'shared/components/CoreNewUI';
import {useTabsContext} from 'shared/components/Tabs/TabsContext';

import {useLoadTaskChatHistory} from '../../hooks/useLoadTaskChatHistory';
import {isAsyncJobInProgress} from '../CommentsTab/utils/helpers';
import GalleryEmpty from '../InfoGallery/components/GalleryEmpty/GalleryEmpty';

import s from './InfoGallery.module.scss';

type InfoGalleryProps = {
  className?: string;
  taskId: string;
};

const CHAT_TAB_INDEX = 2;
export const MAX_QUANTITY_IMAGES_IN_PREVIEW = 3;

const InfoGallery = ({className, taskId}: InfoGalleryProps) => {
  const {setActiveTab} = useTabsContext();
  const {t} = useTranslation('task');
  const {chatEvents, jobStatus} = useLoadTaskChatHistory(taskId);

  const imagesUrl = useMemo(() => {
    const result: string[] = [];
    if (chatEvents?.length) {
      chatEvents.forEach((event) => {
        if (!!event.content?.attachments) {
          event.content?.attachments.forEach((attachment) => {
            if (attachment.url) {
              result.push(attachment.url);
            }
          });
        }
      });
    }
    return result;
  }, [chatEvents]);

  const imagesList =
    imagesUrl &&
    imagesUrl.slice(0, MAX_QUANTITY_IMAGES_IN_PREVIEW).map((image, index) => {
      return (
        <picture className={s.infoGallery__item} key={`${image}_${index}`}>
          <img className={s.infoGallery__image} src={image} alt="" />
        </picture>
      );
    });

  const preloadList = useMemo(() => {
    return Array.from({length: 3}).map((_, i) => (
      <div className={s.infoGallery__item} key={i}>
        <SkeletonPreloader when className={s.infoGallery__image} height="100%" />
      </div>
    ));
  }, []);

  const isLoading = isAsyncJobInProgress(jobStatus) || !taskId;

  function openChat() {
    setActiveTab(CHAT_TAB_INDEX);
  }

  return (
    <div className={cn(s.infoGallery, className)}>
      <div className={s.infoGallery__label}>
        {t('task_form.gallery.label', 'Gallery')} (
        {isLoading
          ? t('task_form.gallery.loading', 'Loading...')
          : imagesUrl
          ? imagesUrl.length
          : t('task_form.gallery.empty', 'Empty')}
        )
      </div>
      <div className={s.infoGallery__body}>
        <div className={s.infoGallery__grid}>
          {isLoading ? (
            preloadList
          ) : imagesList.length ? (
            imagesList
          ) : (
            <GalleryEmpty className={s.infoGallery__item} onCLick={openChat}></GalleryEmpty>
          )}
        </div>
      </div>
      {imagesList?.length > MAX_QUANTITY_IMAGES_IN_PREVIEW ? (
        <div className={s.infoGallery__moreActions}>
          <CtrlButton onClick={openChat} view="link">
            {t('task_form.gallery.show_more', 'Show more')}
          </CtrlButton>
        </div>
      ) : null}
    </div>
  );
};

export default InfoGallery;
