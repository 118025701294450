import cn from 'classnames';
import {isNaN} from 'formik';
import {FC, SyntheticEvent, useEffect, useRef} from 'react';

import {useDebounce, useUnmount} from 'shared/hooks/core';

import {Point} from '../../../modules/Tasks/components/ActionsBar/components/BaselineDropdown/components/DateControl/DateSlider';

import s from './CompletionAmountSlider.module.scss';

type Props = {
  disabled?: boolean;
  unit?: string;
  step: number;
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  legend?: boolean;
  inline?: boolean;
  points?: {label: string; subPointsNumbers: number}[];
  legendStart?: string;
  legendEnd?: string;
};

export function isNotTouchEvent(e: React.TouchEvent) {
  return e.touches.length > 1 || (e.type.toLowerCase() === 'touchend' && e.touches.length > 0);
}

const CompletionAmountSlider: FC<Props> = ({
  disabled,
  unit,
  min,
  max,
  step,
  onChange,
  legend,
  value,
  inline,
  points,
  legendEnd,
  legendStart,
}: Props) => {
  const lineRef = useRef<HTMLDivElement>();
  const bar = useRef<HTMLDivElement>();
  const parsedMax = isNaN(max) ? 100 : max ?? 100;
  const parsedMin = isNaN(min) ? 0 : min ?? 0;

  const stepsAmount = () => {
    return Math.floor(parsedMax - parsedMin) / step;
  };

  useEffect(() => {
    getPositionStep(bar.current?.clientWidth);
  }, [points]);

  const updateValue = useDebounce(onChange, 100);

  useEffect(() => {
    handleValueChange(value);
  }, [value, parsedMax, parsedMin]);

  const handleValueChange = (value: number | string) => {
    const parsed = Number(value);
    if (!isNaN(parsed)) {
      const offset = (parsed / Math.floor(parsedMax - parsedMin)) * lineRef.current.clientWidth;
      lineRef.current.style.setProperty('--offset', `${Math.min(offset, lineRef.current.clientWidth) ?? 1}px`);
    }
  };

  const handlePositionChange = (stepNumber: number) => {
    const newValue = stepNumber * step;
    const offset = Math.min((lineRef.current.clientWidth / stepsAmount()) * stepNumber, lineRef.current.clientWidth);
    lineRef.current.style.setProperty('--offset', `${offset || 1}px`);
    return newValue;
  };

  const getPositionStep = (coordinate: number) => {
    const {left, width} = lineRef.current.getBoundingClientRect();
    const diff = coordinate - left;
    let positionStep = 0;

    if (diff > 0 && diff <= width) {
      const stepSize = width / stepsAmount();
      const stepNumber = Math.round(diff / stepSize);
      positionStep = handlePositionChange(stepNumber);
    }

    if (diff <= 0) {
      positionStep = handlePositionChange(0);
    }

    if (diff > width) {
      positionStep = handlePositionChange(stepsAmount());
    }

    return positionStep;
  };

  const handleMousePosition = (e: MouseEvent | React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      updateValue(getPositionStep(e.clientX));
    }
  };

  const handleTouchPosition = (e: TouchEvent | React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      updateValue(getPositionStep(e.touches[0].clientX));
    }
  };

  const onTouchStart = (e: React.TouchEvent) => {
    if (isNotTouchEvent(e)) return;

    e.preventDefault();
    e.stopPropagation();

    if (!disabled) {
      document.addEventListener('touchmove', handleTouchPosition, {passive: false});
      document.addEventListener('touchend', clearListeners, {passive: false});
    }
  };

  const onMouseDown = (e: SyntheticEvent<HTMLDivElement | HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!disabled) {
      document.addEventListener('mousemove', handleMousePosition);
      document.addEventListener('mouseup', clearListeners);
    }
  };

  const clearListeners = () => {
    document.removeEventListener('mousemove', handleMousePosition);
    document.removeEventListener('touchmove', handleTouchPosition);
    document.removeEventListener('mouseup', clearListeners);
    document.removeEventListener('touchend', clearListeners);
  };

  useUnmount(() => {
    clearListeners();
  });

  const getPointStyle = (point: Point) => {
    let marginRight = 0;
    if (lineRef.current) {
      marginRight = Math.floor(
        (lineRef.current?.clientWidth / max) * (point.subPointsNumbers < max ? point.subPointsNumbers : max),
      );
    }
    return {
      marginRight: marginRight,
      maxWidth: 0,
      opacity: point.label ? 1 : 0,
    };
  };

  return (
    <div
      className={cn(
        'app-task-progress app-task-info__additional-progress',
        s.completionAmountSlider,
        inline && s.completionAmountSlider_inline,
      )}
      ref={lineRef}
    >
      <div
        ref={bar}
        className={cn('app-task-progress__bar', s.completionAmountSlider__bar)}
        onClick={handleMousePosition}
      ></div>
      <div
        className={cn('app-task-progress__dot', s.completionAmountSlider__dot)}
        onTouchStart={onTouchStart}
        onMouseDown={onMouseDown}
        style={{cursor: 'pointer'}}
      />
      <span
        className={cn('app-task-progress__number', s.completionAmountSlider__number)}
        onTouchStart={onTouchStart}
        onMouseDown={onMouseDown}
        style={{cursor: 'pointer'}}
      >{`${value}${unit}`}</span>
      {legend && (
        <>
          <span className="app-task-progress__min_number">{parsedMin}</span>
          <span className="app-task-progress__min_text">{legendStart || 'Start'}</span>
          <span className="app-task-progress__max_number">{parsedMax}</span>
          <span className="app-task-progress__max_text">{legendEnd || 'Target'}</span>
        </>
      )}
      {points && (
        <div className="app-task-progress__points">
          {points.map((point, index) => (
            <span
              className={cn('app-task-progress__point', {'app-task-progress__point_single': points.length === 1})}
              style={getPointStyle(point)}
              key={`${point.label}_${index}`}
            >
              <span className="app-task-progress__pointName">{point.label}</span>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
export default CompletionAmountSlider;
