import {useMemo} from 'react';

import {SearchResultOptionsType} from 'shared/components/CoreNewUI/Autocomplete/Autocomplete';
import {useOptionsForSelect} from 'shared/hooks';

export function useLocationsOptions(projectId: string, selectedLocations: string[]) {
  const {locationOptions} = useOptionsForSelect(projectId);
  return useMemo(() => {
    if (!selectedLocations.length && selectedLocations.length) {
      return selectedLocations.map((loc) => ({value: loc, label: loc}));
    }
    return locationOptions as SearchResultOptionsType[];
  }, [locationOptions, selectedLocations]);
}
