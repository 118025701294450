import * as Sentry from '@sentry/browser';
import {GanttStatic} from 'dhtmlx-gantt';
import {camelizeKeys} from 'humps';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import {TasksApi} from 'api';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {TasksBulkTypes} from 'modules/Tasks/utils/constants';
import {batchUpdateSelectedTasks} from 'modules/Tasks/utils/utils';
import {useTasksObserver} from 'services/TasksObserver';
import {RouteParams} from 'shared/constants/routes';
import {useAnalyticsService, useProjectSelector} from 'shared/hooks';
import {GroupMember, GroupMemberRole, TaskAssignees, TaskDetailsModelDTO, TaskObjectType} from 'shared/models/task';
import {CompanyWorker} from 'shared/models/worker';
import {useRootDispatch, useRootSelector} from 'store';
import {getProject} from 'store/projects/actions';
import {getProjectsLocations} from 'store/projects/selectors';

export type SelectedTaskMembers = {
  taskId: string;
  memberIds: string[];
};

export const useTasksBulkOperations = (gantt: GanttStatic) => {
  const {projectId} = useParams<RouteParams['tasks']>();
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.tasks.toolbar.bulkActions;
  const project = useProjectSelector(projectId);
  const {t} = useTranslation('gantt');
  const observer = useTasksObserver();
  const dispatch = useRootDispatch();
  const projectLocations = useRootSelector(getProjectsLocations);

  const getPreparedMembers = (workerIds: string[], memberRole): TaskAssignees[] => {
    return workerIds.map((id) => ({
      memberId: id,
      memberRole: memberRole,
    }));
  };

  const addMembers = async (
    members: SelectedTaskMembers[],
    memberRole: GroupMemberRole.responsible | GroupMemberRole.assignee,
  ) => {
    const bulkType = memberRole === GroupMemberRole.assignee ? TasksBulkTypes.watcher : TasksBulkTypes.responsible;
    mixpanel.track(mixpanelEvents[TasksBulkTypes[bulkType]]);
    await handleBulkActions<TaskDetailsModelDTO>(
      members.map(({taskId, memberIds}) => TasksApi.addAssignees(taskId, getPreparedMembers(memberIds, memberRole))),
    );
  };

  const assignResponsible = async (member: CompanyWorker) => {
    await addMembers(
      getSelectedTasks()
        .filter((task) => {
          const activeResponsible = camelizeKeys(task.responsible[0]) as GroupMember;
          return activeResponsible?.memberId !== member.workerId || task.object_type === TaskObjectType.milestone;
        })
        .map<SelectedTaskMembers>((task) => ({taskId: task.id, memberIds: [member.workerId]})),
      GroupMemberRole.responsible,
    );
  };

  const assignWatchers = async (members: CompanyWorker[]) => {
    const memberIds = members.map(({workerId}) => workerId);
    await addMembers(
      getSelectedTasks().reduce((acc, task) => {
        if (task.object_type === TaskObjectType.milestone) return acc;
        // exclude responsibles of task to prevent next operation override member role to 'member'
        const withoutResponsibles = memberIds.filter(
          // TODO: fix typing for TaskGantt, responsible should have TaskAssigneeGantt type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (memberId) => !task.responsible?.find((r) => (r as any).member_id === memberId),
        );
        return withoutResponsibles.length
          ? acc.concat({
              taskId: task.id,
              memberIds: withoutResponsibles,
            })
          : acc;
      }, [] as SelectedTaskMembers[]),
      GroupMemberRole.assignee,
    );
  };

  const bulkUpdate = async (updates: Partial<TaskDetailsModelDTO>) => {
    const updatedKey = Object.keys(updates)[0];
    mixpanel.track(mixpanelEvents?.[updatedKey]);
    await handleBulkActions<TaskDetailsModelDTO>(
      getSelectedTasks().map(({id}) => TasksApi.updateTask({id, ...updates})),
    );
    updateProjectLocation(updates);
  };

  const updateProjectLocation = (updates: Partial<TaskDetailsModelDTO>) => {
    if (updates?.location && !projectLocations.includes(updates?.location)) {
      dispatch(getProject(projectId));
    }
  };

  const getSelectedTasks = () => {
    return (gantt.getSelectedTasks().map((t) => gantt.getTask(t)) as GanttTask[]).filter(
      (task) => task.object_type !== TaskObjectType.summary,
    );
  };

  const handleBulkActions = async <T extends TaskDetailsModelDTO>(promises: Promise<T>[]) => {
    const res = await Promise.allSettled(promises).then((res) => {
      return res.reduce(
        (all, cur) => {
          if (cur.status === 'fulfilled') all.fulfilled.push(cur);
          else all.rejected.push(cur);
          return all;
        },
        {fulfilled: [] as PromiseFulfilledResult<T>[], rejected: [] as PromiseRejectedResult[]},
      );
    });
    if (res.fulfilled.length) {
      batchUpdateSelectedTasks(gantt, res.fulfilled, project, observer);
      toast.success(t('toast.success.bulk_assign', 'The changes has been saved.'));
    }
    if (res.rejected.length) {
      const error = res.rejected[0].reason;
      console.error(error);
      Sentry.captureException(error);
    }
  };

  return {assignWatcher: assignWatchers, assignResponsible, bulkUpdate};
};
