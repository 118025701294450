import cn from 'classnames';
import {ChangeEvent, CSSProperties, useEffect, useState} from 'react';

import {CtrlButton, FormControl} from 'shared/components/CoreNewUI/index';

import CtrlColor from './components/CtrlColor/CtrlColor';
import s from './CtrlColorPicker.module.scss';

type CtrlColorPickerProps = {
  className?: string;
  onSave?: (abbrev: string, color: string) => void;
  onReset?: () => void;
  colors?: string[];
  disabled?: boolean;
  initialValues?: {
    abbrev: string;
    color: string;
  };
  style?: CSSProperties;
};
const MAX_ABBREV_LENGTH = 3;

const CtrlColorPicker = ({
  className,
  onSave,
  onReset,
  colors = [],
  initialValues,
  disabled,
  style,
}: CtrlColorPickerProps) => {
  const [activeColor, setActiveColor] = useState(null);
  const [abbrev, setAbbrev] = useState('');

  useEffect(() => {
    setAbbrev(initialValues.abbrev || '');
    setActiveColor(initialValues.color || '');
  }, [initialValues]);

  const onChangeAbbrev = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    if (value.length > MAX_ABBREV_LENGTH) {
      e.preventDefault();
    } else {
      setAbbrev(value);
    }
  };

  const onClickResetBtn = () => {
    if (abbrev || activeColor) {
      setAbbrev('');
      setActiveColor(null);
      onReset();
    }
  };

  return (
    <div className={cn(s.ctrlColorPicker, className)} style={style}>
      <div className={s.ctrlColorPicker__header}>
        <FormControl>
          <input type="text" value={abbrev} placeholder="Abbreviation" onChange={onChangeAbbrev} />
        </FormControl>
        <CtrlButton
          disabled={disabled || (!abbrev && !activeColor)}
          icon="check"
          color="second"
          onClick={() => onSave(abbrev, activeColor)}
          className={s.ctrlColorPicker__buttonSave}
        >
          Save
        </CtrlButton>
      </div>
      <div className={s.ctrlColorPicker__body}>
        {colors.map((color) => (
          <CtrlColor key={color} onClick={() => setActiveColor(color)} color={color} isActive={activeColor === color} />
        ))}
      </div>
      <div className={s.ctrlColorPicker__footer}>
        <CtrlColor onClick={onClickResetBtn} />
      </div>
    </div>
  );
};

export default CtrlColorPicker;
