import React, {FC} from 'react';

import Member from '../../../Member/Member';
import MembersList from '../../../MembersList/MembersList';
import s from '../../../MembersList/MembersList.module.scss';
import {useAssigneesTabContext} from '../AssigneesTabContext';

type Props = {
  addWatchers: () => void;
  disabled?: boolean;
};
const SelectedWatchersList: FC<Props> = ({addWatchers, disabled}: Props) => {
  const {watchers} = useAssigneesTabContext();

  return (
    <MembersList disabled={disabled} isMemberList membersCount={watchers.length} addWatchers={addWatchers}>
      <>
        {watchers.map((watcher) => (
          <Member
            key={watcher.memberId}
            className={s.MembersList__item}
            memberName={watcher.memberName}
            memberProfilePicUrl={watcher.memberProfilePicUrl}
          />
        ))}
      </>
    </MembersList>
  );
};

export default SelectedWatchersList;
