import {ChangeEvent, FC, memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import {ExportApi} from 'api';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {ExportToCSV} from 'services/ExportToCSV';
import {Icon} from 'shared/components';
import {CtrlBtnOption, CtrlChip, CtrlChipGroup} from 'shared/components/CoreNewUI';
import {addStatusToFilters} from 'shared/helpers/tasks';
import {useCompany, useTasksUrlState} from 'shared/hooks';

import s from './ExportDropdown.module.scss';

const availableDateFormats = ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD'] as const;

const ExportButton: FC = () => {
  const {t} = useTranslation('gantt');
  const {queryParams} = useFilterContext();
  const {projectId} = useParams<{projectId: string}>();
  const [isExporting, setIsExporting] = useState(false);
  const [exportDateFormat, setExportDateFormat] = useState<string>(availableDateFormats[0]);
  const company = useCompany();
  const tasksState = useTasksUrlState();

  const onChangeExportDateFormat = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setExportDateFormat(value);
  };

  const startExport = async () => {
    const res = await ExportApi.exportTasks(company.id, {
      dateFormat: exportDateFormat,
      params: addStatusToFilters(tasksState, {...queryParams, projectId}),
    });
    setIsExporting(true);
    const exportData = await ExportApi.pollExportTasksResults(company.id, res.data.id, 200);
    if (exportData.status === 'finished') {
      if (exportData.result.errors) {
        toast.error(exportData.result.errors[0].code);
      } else if (exportData.result.error) {
        toast.error(t('toast.error.unexpected', 'Unexpected server error'));
      } else {
        const csvExport = new ExportToCSV();
        csvExport.download(company.companyName + '_' + new Date().toLocaleString(), '', exportData.resultFileUrl);
      }
    }
    setIsExporting(false);
  };

  return (
    <CtrlBtnOption
      disabled={isExporting || !company?.id}
      title={
        isExporting ? t('toolbar.actions.exporting', 'Exporting...') : t('toolbar.actions.export', 'Export to CSV')
      }
      icon={<Icon name="export" colorFill className={s.iconColor} />}
      onClick={startExport}
    >
      <CtrlChipGroup title={t('toolbar.actions.chooseDate', 'Choose date format for Export')}>
        {availableDateFormats.map((format, index) => {
          return (
            <CtrlChip
              key={`${format}|${index}`}
              label={format}
              value={format}
              name="dateFormat"
              checked={exportDateFormat === format}
              onChange={onChangeExportDateFormat}
            />
          );
        })}
      </CtrlChipGroup>
    </CtrlBtnOption>
  );
};
export default memo(ExportButton);
