import cn from 'classnames';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {NavigationId} from 'modules/Home/Home';
import {scrollToService} from 'services/ScrollToService';
import {Logo} from 'shared/components';
import DownloadAppLink from 'shared/components/DownloadAppLink';
import ExternalLink from 'shared/components/ExternalLink';
import LandingLngSwitcher from 'shared/components/LanguageSwitcher/LandingLngSwitcher';
import env from 'shared/constants/env';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {getLocalizedLink} from 'shared/helpers/common';
import {useAnalyticsService} from 'shared/hooks';
import useUserAgent from 'shared/hooks/useUserAgent';

type HeaderProps = {
  navigationList?: NavigationId[];
  className?: string;
  clear?: boolean;
};

const Header: FC<HeaderProps> = ({navigationList, clear = true}) => {
  const {mixpanel} = useAnalyticsService({waitCompany: false, waitAuth: false});
  const {
    t,
    i18n: {language},
  } = useTranslation('header');
  const routes = useLocalizedRoutes();
  const mixpanelEvents = mixpanel.events.home.download;
  const userAgent = useUserAgent();
  const isMobileDevice = userAgent.os.name === 'iOS' || userAgent.os.name === 'Android';
  const mixpanelMeta = {
    OS: userAgent.os.name,
    Device: userAgent.device.vendor,
  };

  return (
    <header className={cn('landing-header', clear && 'landing-header--clear')}>
      <div className="landing-header__container">
        <Logo className="landing-header__logo" />
        {navigationList && (
          <>
            <ul className="landing-header__nav">
              {navigationList.map(({title, scrollTo}) => {
                return (
                  <li className="landing-header__nav-item" key={`nav-item-${title.toLowerCase()}`}>
                    <button
                      className="landing-header__nav-button"
                      type="button"
                      onClick={() => scrollToService.scrollToElement(document.querySelector(scrollTo))}
                    >
                      {title}
                    </button>
                  </li>
                );
              })}
            </ul>
            <div
              className={cn(
                'landing-header__download',
                `landing-header__download--detect-${isMobileDevice ? 'on' : 'off'}`,
              )}
            >
              {(!isMobileDevice || userAgent.os.name === 'iOS') && (
                <DownloadAppLink.iOS
                  className="landing-header__button-download"
                  onClick={() => mixpanel.track(mixpanelEvents.appStore, mixpanelMeta)}
                />
              )}
              {(!isMobileDevice || userAgent.os.name === 'Android') && (
                <DownloadAppLink.Android
                  className="landing-header__button-download"
                  onClick={() => mixpanel.track(mixpanelEvents.googlePlay, mixpanelMeta)}
                />
              )}
              <ExternalLink
                className="landing-header__button-login"
                href={getLocalizedLink(env.legalPages.downloadApp, language)}
                text={t('links.mobile_app_download', 'Mobile App')}
                target="_blank"
              />
            </div>
            <Link className="landing-header__button-login" to={routes.logIn}>
              {t('links.login', 'Log in')}
            </Link>
          </>
        )}
        <LandingLngSwitcher className="landing-header__langs" />
      </div>
    </header>
  );
};
export default Header;
