import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {TFunction} from 'i18next';

import {GanttTask} from '../../../Gantt/types';
import {GANTT_COLUMNS_NAMES} from '../../../Gantt/utils';
import {GANTT_COLUMNS_NAMES_EXTRA} from '../../../Gantt/utils/constants';

export const BASELINE_EXTRA_COLUMNS_NAMES = [
  GANTT_COLUMNS_NAMES_EXTRA.baselineStart,
  GANTT_COLUMNS_NAMES_EXTRA.baselineEnd,
  GANTT_COLUMNS_NAMES_EXTRA.variance,
];

export type BaselineSettings = {cutoff: string; activeSnapshotId: string};

export const BASELINE_SETTINGS = 'baselineSettings';

export const isExistExtraColumns = (gantt: GanttStatic) =>
  BASELINE_EXTRA_COLUMNS_NAMES.every((name) => {
    return !!gantt.config.columns.find((column) => name === column.name);
  });

export const addBaselineExtraColumns = (gantt: GanttStatic, t: TFunction) => {
  const labels = gantt.getColumnLabels(t);

  if (isExistExtraColumns(gantt)) return;
  const extraColumns = BASELINE_EXTRA_COLUMNS_NAMES.map((name) => {
    const common = {name, label: labels[name], width: 120};
    if (name === GANTT_COLUMNS_NAMES_EXTRA.variance) {
      Object.assign(common, {
        template: (task: GanttTask) => (task?.meta?.variance ? 'Loading...' : task.variance),
      });
    }
    return common;
  });
  gantt.config.columns = gantt.config.columns.reduce((acc, column) => {
    acc.push(column);
    if (column.name === GANTT_COLUMNS_NAMES.endDate) acc.push(...extraColumns);
    return acc;
  }, []);
  gantt.dRender();
};

export const removeBaselineExtraColumns = (gantt: GanttStatic) => {
  gantt.config.columns = gantt.config.columns.filter((column) => !BASELINE_EXTRA_COLUMNS_NAMES.includes(column.name));
};

export const removeFromLocalstorage = (key: string) => {
  localStorage.removeItem(key);
};

export const addToLocalstorage = (key: string, settings: BaselineSettings) => {
  localStorage.setItem(key, JSON.stringify(settings));
};

export const getPreparedName = (date: string | Date) => `${dayjs(date).format('ll')} - Baseline`;
