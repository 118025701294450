import cn from 'classnames';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';

import {useFilterContext} from 'modules/Tasks/components/Filters';
import {Button, Icon, Tooltip} from 'shared/components';
import {HSpacer, VSpacer} from 'shared/components/Spacer';
import {IconsMap} from 'shared/constants/icons';
import {diff} from 'shared/helpers/dates';

import {IssuesTableHeader} from '../DailiesReportIssues';
import {useFetchDailiesIssues} from '../hooks/useFetchDailiesIssues';
import {DailiesDisplayMode} from '../types';

import {DailiesReportCard} from './DailiesReportCard';

export default function ActivityIssues({displayMode, taskId}: {displayMode: DailiesDisplayMode; taskId: string}) {
  const {t} = useTranslation('dailies');
  const {queryParams} = useFilterContext();
  const {issues} = useFetchDailiesIssues();

  const activityIssues = issues?.filter((issue) => {
    if (!issue.taskIds?.includes(taskId)) {
      return false;
    }
    const startDate = dayjs(issue.startDate).startOf('day');
    const selectedDay = dayjs(queryParams.schedEndFirst).startOf('day');
    const totalIssueDays = diff(selectedDay, startDate);
    return totalIssueDays > 0;
  });

  const rowMap = {
    description: t('dailies_report.issues.table.description', 'Description'),
    name: t('dailies_report.issues.table.name', 'Name'),
    tooltip: t(
      'dailies_report.issues.table.total_issue_days_tooltip',
      'Total Issue Days are the number of days the issue has occurred up until the day of this report',
    ),
    totalIssueDays: t('dailies_report.issues.table.total_issue_days', 'Total Issue Days'),
  } as const;

  const tableHeaderRow: IssuesTableHeader[] = [
    {
      name: rowMap.name,
      id: 'name',
    },
    {
      name: rowMap.description,
      id: 'description',
    },
    {
      name: rowMap.totalIssueDays,
      id: 'totalIssueDays',
      tooltip: rowMap.tooltip,
    },
  ];

  if (!activityIssues.length) {
    return null;
  }

  return (
    <>
      <header className="issues-table-header">
        <p className={cn('issues-table-header_title', displayMode)}>{t('dailies_report.issues.title')}</p>
      </header>
      <table className="issues-table">
        <caption className="activity_caption">
          {t('dailies_report.issues.open_issues_table_title', {total: activityIssues?.length || 0})}
        </caption>
        <thead>
          <tr>
            {tableHeaderRow.map((row) => (
              <th key={row.id}>
                <div className={row.id}>
                  <p>{row.name}</p>
                  {row.tooltip ? (
                    <>
                      <HSpacer />
                      <Tooltip placement="right" text={row.tooltip}>
                        <Button iconOnly icon={<Icon name={IconsMap.help_outlined} />} />
                      </Tooltip>
                    </>
                  ) : null}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {activityIssues?.map((issue) => {
            const startDate = dayjs(issue.startDate).startOf('day');
            const selectedDay = dayjs(queryParams.schedEndFirst).startOf('day');
            const totalIssueDays = diff(selectedDay, startDate);

            return (
              <tr key={issue.id}>
                <td data-cell={rowMap.name}>{issue.name}</td>
                <td data-cell={rowMap.description}>{issue.description}</td>
                <td data-cell={rowMap.totalIssueDays}>{totalIssueDays}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <VSpacer size="5" />
      <DailiesReportCard.Separator />
    </>
  );
}
