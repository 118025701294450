import {useMutation} from 'react-query';

import {TaskDetailsModelDTO, TaskWithAffectedResponse} from 'shared/models/task';

import {DailyCardUpdates} from '../components/DailyCard/DailyCard';
import {handleSubmitProgressReport} from '../utils/apiHelpers';

import {useInitializeMatrixSession} from './useInitializeMatrixSession';

export interface FeedbackMessage {
  comment: string;
  dateTag: Date;
  feedbackType: 'm_message';
  flagAsPotentialIssue: boolean;
}

export interface FeedbackImage {
  dateTag: Date;
  feedbackType: 'm_image';
  images: File[];
}

export type ProgressReportUpdates = {
  task: Partial<TaskDetailsModelDTO>;
  feedback: DailyCardUpdates[];
  initialFeedBack: DailyCardUpdates[];
  comment: FeedbackMessage;
  image: FeedbackImage;
};

export type MatrixValues = {
  workerId: string;
  homeServer: string;
};

export type ProgressReportResponse = {
  comment_count: number;
  taskUpdateResponse?: TaskWithAffectedResponse;
};

export function useHandleSubmitProgressReport({
  onSuccess,
  onError,
}: {
  onSuccess: (data: ProgressReportResponse) => void;
  onError: (err: unknown) => void;
}) {
  const {data} = useInitializeMatrixSession();
  const {mutate: onSubmit} = useMutation(
    async (updates: ProgressReportUpdates) => handleSubmitProgressReport(updates, data?.accessToken),
    {onSuccess, onError},
  );
  return onSubmit;
}
