import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import {TFunction} from 'i18next';
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {ImportApi} from 'api';
import {
  Button,
  CoreSelect,
  FormControl,
  FormikChangeWatcher,
  Icon,
  OptionType,
  Popup,
  Switcher,
  TaskCard,
} from 'shared/components';
import {useTasksImportContext} from 'shared/components/TasksImport/TasksImportContext';
import {
  TaskImportClientMapping,
  TaskImportField,
  TaskImportPrepResponse,
  TasksImportConfig,
} from 'shared/components/TasksImport/utils/types';
import env from 'shared/constants/env';
import {assumeTimezoneDateAsLocal, toIsoString} from 'shared/helpers/dates';
import {useAnalyticsService, useCompany, useProjectSelector, useQueryCache} from 'shared/hooks';
import {TaskDetailsModelDTO, TaskStatus} from 'shared/models/task';
import {useRootDispatch, useRootSelector} from 'store';
import {selectAllProjects} from 'store/projects';
import {updateProject} from 'store/projects/actions';

import {TaskImportStep, TasksImportSourceType} from '../../utils/constants';
import {isMppFile} from '../../utils/functions';

import {getDefaultValues, getMapping, schema} from './schema';

import './style.css';

type TasksImportMappingProps = {
  fields: TaskImportField[];
  parseResult: TaskImportPrepResponse;
  fileHeaders: {label: string; value: string}[];
  onSubmit: (asyncUploadId: string, values: TasksImportConfig) => void;
  importPercentage: number;
  defaultProjectId?: string;
  mixpanelEvents: {[name: string]: string};
};

const getDateFormatOptions = (t: TFunction): OptionType[] => [
  {label: t('import:mapping.date_options.month_first', 'MM/DD/YYYY'), value: 'MM/DD/YYYY'},
  {label: t('import:mapping.date_options.day_first', 'DD/MM/YYYY'), value: 'DD/MM/YYYY'},
  {label: t('import:mapping.date_options.year_first', 'YYYY-MM-DD'), value: 'YYYY-MM-DD'},
];

const TasksImportMapping: FC<TasksImportMappingProps> = ({
  fields,
  onSubmit,
  parseResult,
  fileHeaders,
  defaultProjectId,
  mixpanelEvents,
}) => {
  const dispatch = useRootDispatch();
  const {companyName} = useCompany();
  const projects = useRootSelector(selectAllProjects);
  const [isParsing, setParsing] = useState(false);
  const [tasksIsLoading, setTasksIsLoading] = useState(false);
  const [taskPreviewModel, setTaskPreviewModel] = useState<Partial<TaskDetailsModelDTO>>();
  const [mappingValues, setMappingValues] = useState<TaskImportClientMapping | Record<string, unknown>>({});
  const {t} = useTranslation('import');

  const [{sourceType, procoreProjects, file, asyncUploadId, config}, actions] = useTasksImportContext();

  const [selectedProject, setSelectedProject] = useState<string>(defaultProjectId);
  const [selectedProcoreProject, setSelectedProcoreProject] = useState<string>(procoreProjects?.[0]?.value);
  const formik = useRef<FormikProps<TasksImportConfig>>();
  const {cacheHelper} = useQueryCache();
  const {mixpanel} = useAnalyticsService();
  const project = useProjectSelector(selectedProject);
  const mixpanelMeta = useMemo(() => Object.assign({}, {'Project Name': project?.name}), [project]);
  const dateFormatOptions = useMemo(() => getDateFormatOptions(t), []);

  const isLoading = !parseResult || isParsing || (sourceType === TasksImportSourceType.proCore && !procoreProjects);

  const mailto = `${t(
    'mapping.mailto.start',
    'mailto:c4@bycore.com?subject=Help with Import Activities File&body=',
  )}${companyName}${t(
    'mapping.mailto.end',
    ' would like help with Importing Activities File. Attach the file you are trying to import',
  )}`;

  const fieldsToSelect = useMemo(() => {
    if (file && !isMppFile(file.name)) return fields;
    // we are automatically mapping for the outlineCode field
    // if file is mpp and hasHierarchy = true
    return fields.filter(({key}) => key !== 'outlineCode');
  }, [fields, parseResult?.result?.hasHierarchy]);

  const onFormikValueChanged = (values: TasksImportConfig) => {
    if (parseResult?.result?.samples?.length) {
      const taskSamples = parseResult.result.samples;
      const relevantTaskSample =
        taskSamples.find((sample) => Object.values(sample).every((value) => value !== null)) || taskSamples[0];
      setTaskPreviewModel({
        uniqueId: relevantTaskSample[values.mapping.uniqueId],
        status: TaskStatus.tba,
        name: relevantTaskSample[values.mapping.name],
        schedStartDate: toIsoString(
          assumeTimezoneDateAsLocal(relevantTaskSample[values.mapping.schedStartDate], project?.timezone),
        ),
        schedEndDate: toIsoString(
          assumeTimezoneDateAsLocal(relevantTaskSample[values.mapping.schedEndDate], project?.timezone),
        ),
        location: relevantTaskSample[values.mapping.location],
        responsibleParty: relevantTaskSample[values.mapping.subcontractor],
        outlineCode: relevantTaskSample[values.mapping.outlineCode],
      });
    }
    setMappingValues(values.mapping);
  };

  const taskIdOptions = useMemo(
    () =>
      fileHeaders
        .concat({label: t('fields.auto_assign.label', 'Auto-Assign Task ID'), value: 'auto'})
        .sort((a, b) => a.label.localeCompare(b.label, 'en', {sensitivity: 'base'})),
    [fileHeaders],
  );

  const projectsOptions = useMemo(
    () => projects.map((project) => ({label: project.name, value: project.id})),
    [projects],
  );

  const startFileImport = async (defaultProject: string, selectedFile: File) => {
    setParsing(true);
    const {
      data: {id, putUrl},
    } = await ImportApi.startImport(defaultProject, selectedFile.name);
    actions.asyncUploadId(id);

    await ImportApi.uploadFileToS3(
      selectedFile,
      process.env.NODE_ENV === 'development'
        ? putUrl.replace('https://journey-builders-staging.s3.amazonaws.com/', '/s3/')
        : putUrl,
    );
    await startPrep(defaultProject, id);
    setParsing(false);
  };

  const startProcoreImport = async (projectId: string, procoreProjectId: string) => {
    setParsing(true);
    const res = await ImportApi.startImport(projectId);
    actions.asyncUploadId(res.data.id);
    await ImportApi.extract(selectedProject, res.data.id, procoreProjectId);
    await ImportApi.pollExtractResult(selectedProject, res.data.id);
    await startPrep(projectId, res.data.id);
    setParsing(false);
  };

  const startPrep = async (projectId: string, importId: string) => {
    await ImportApi.prepRun(projectId, importId);
    const prepData = await ImportApi.pollPrepResults(projectId, importId, 100);
    handleStatusResult(prepData);
  };

  const handleStatusResult = (rs: TaskImportPrepResponse) => {
    if (rs.status === 'finished') {
      if (rs.result.errors) {
        actions.setErrorCode(rs.result.errors[0].code);
      } else if (rs.result.error) {
        actions.setErrorCode('500');
      } else {
        const tmp = Object.assign(rs, {
          result: {
            ...rs.result,
            headers: rs.result.headers.filter(Boolean).sort(new Intl.Collator('en', {sensitivity: 'base'}).compare),
          },
        });
        actions.setParseResult(tmp);
      }
    }
  };

  const saveMapping = async (values: TasksImportConfig) => {
    const updatingProject = projects.find((project) => project.id === values.defaultProject);
    const {mapping} = values;

    if (file && isMppFile(file.name)) {
      if (values.importWithHierarchy) {
        mapping.outlineCode = 'outline_code';
      } else {
        delete mapping.outlineCode;
      }
    }

    const preparedMapping = JSON.stringify({mapping: mapping, dateFormat: values.dateFormat});
    if (updatingProject.importSettings === preparedMapping) {
      return;
    }
    const res = await dispatch(
      updateProject({
        ...updatingProject,
        importSettings: preparedMapping,
      }),
    );
    if (updateProject.rejected.match(res)) {
      toast.error(res.error.message);
    } else {
      actions.setConfig(values);
      toast.success(t('toast.success.save', 'Project mapping saved!'));
      cacheHelper.findRecentPagedQuery('project')?.setData((data) => cacheHelper.updatePagedData(data, res.payload));
    }
  };

  useEffect(() => {
    if (projects.length) {
      formik.current.setFieldValue('defaultProject', defaultProjectId ? defaultProjectId : projects[0].id);
    }
  }, [projects]);

  const handleSubmit = async (values: TasksImportConfig) => {
    await saveMapping(values);
    setTasksIsLoading(true);
    await onSubmit(asyncUploadId, values);
    setTasksIsLoading(false);
  };

  useEffect(() => {
    if (!parseResult) return;
    if (config) {
      formik.current.setValues({...config});
      return;
    }
    try {
      const object = projects.find((project) => project.id === formik.current.values.defaultProject);
      if (object) {
        const importSettings = JSON.parse(object?.importSettings);
        formik.current.setValues({
          ...formik.current.values,
          mapping: getMapping(formik.current.values.mapping, importSettings.mapping),
          dateFormat: importSettings.dateFormat,
        });
        setMappingValues(importSettings.mapping);
      }
    } catch (error) {
      if (env.NODE_ENV === 'development') {
        console.warn(error);
      }
    }
  }, [parseResult, config, projects]);

  useEffect(() => {
    if (sourceType === TasksImportSourceType.file) {
      startFileImport(selectedProject, file);
    } else if (selectedProcoreProject) {
      startProcoreImport(selectedProject, selectedProcoreProject);
    }
  }, [sourceType, selectedProcoreProject]);

  useEffect(() => {
    if (!selectedProcoreProject && procoreProjects) {
      setSelectedProcoreProject(procoreProjects[0]?.value);
    }
  }, [procoreProjects, selectedProcoreProject]);

  useEffect(() => {
    if (parseResult?.result?.hasHierarchy) {
      const values = formik.current.values;
      formik.current.setValues({
        ...values,
        importWithHierarchy: true,
        mapping: {...values.mapping, outlineCode: 'outline_code'},
      });
    }
  }, [parseResult?.result?.hasHierarchy]);

  return (
    <Formik<TasksImportConfig>
      onSubmit={handleSubmit}
      innerRef={formik}
      initialValues={getDefaultValues(dateFormatOptions[0].value)}
      validationSchema={schema}
    >
      {({setFieldValue, submitForm}) => (
        <>
          <FormikChangeWatcher debounce={200} onChange={onFormikValueChanged} />
          <Popup.Body>
            <div className="compare-grid">
              <div className="compare-grid__content">
                <Form className="form-compare">
                  {parseResult?.result?.hasHierarchy && (
                    <div className="form-default__item form-default__item--full">
                      <Field name="importWithHierarchy">
                        {({field}: FieldProps) => (
                          <Switcher
                            {...field}
                            onChange={(value) =>
                              mixpanel.trackWithAction(
                                () => setFieldValue(field.name, value),
                                mixpanelEvents.notifyEmail,
                              )
                            }
                            disabled={isLoading}
                            label={t('mapping.form.hierarchy.label', 'Import With Hierarchy (WBS)')}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                  <div className="form-compare__default">
                    <div className="form-compare__item form-compare__item--default">
                      <FormControl
                        name="dateFormat"
                        label={t('mapping.form.date_format.label', 'Select Date Format You Use')}
                      >
                        <Field>
                          {({field}: FieldProps) => (
                            <CoreSelect
                              options={dateFormatOptions}
                              value={field.value}
                              onChange={(value) =>
                                mixpanel.trackWithAction(
                                  () => setFieldValue(field.name, value),
                                  mixpanelEvents.selectDateFormat,
                                  mixpanelMeta,
                                )
                              }
                            />
                          )}
                        </Field>
                      </FormControl>
                    </div>
                    <div className="form-compare__item form-compare__item--default">
                      <FormControl
                        name="defaultProject"
                        label={t('mapping.form.default_project.label', 'Default Project')}
                      >
                        <Field>
                          {({field}: FieldProps) => (
                            <CoreSelect
                              options={projectsOptions}
                              value={field.value}
                              onChange={(value) =>
                                mixpanel.trackWithAction(
                                  () => {
                                    setFieldValue(field.name, value);
                                    setSelectedProject(value);
                                  },
                                  mixpanelEvents.selectProject,
                                  {'Project Name': value},
                                )
                              }
                            />
                          )}
                        </Field>
                      </FormControl>
                    </div>
                    {sourceType === TasksImportSourceType.proCore && (
                      <div className="form-compare__item form-compare__item--default">
                        <FormControl
                          name="procoreProject"
                          label={t('mapping.form.procore_project.label', 'Procore Project')}
                        >
                          <CoreSelect
                            options={procoreProjects}
                            isLoading={!procoreProjects}
                            loadingMessage={() => t('mapping.form.procore_project.loading', 'Loading...')}
                            value={selectedProcoreProject}
                            onChange={(value) =>
                              mixpanel.trackWithAction(
                                () => {
                                  setSelectedProcoreProject(value);
                                },
                                mixpanelEvents.selectProject,
                                {'Project Name': value},
                              )
                            }
                          />
                        </FormControl>
                      </div>
                    )}
                  </div>
                  <div className="form-compare__body">
                    {fieldsToSelect.map((importField) => (
                      <div key={importField.key} className="form-compare__control">
                        <span className="form-compare__name">
                          {importField.label}{' '}
                          {!!importField.required && <span>*{t('mapping.form.symbol.required', '*')}</span>}
                        </span>
                        <Icon colorFill className="form-compare__icon-compare" size={24} name="arrow_forward" />
                        <div className="form-compare__item form-compare__item--compare">
                          <FormControl name={`mapping.${importField.key}`} labelHidden label={importField.label}>
                            <Field>
                              {({field}: FieldProps) => (
                                <CoreSelect
                                  placeholder={
                                    isLoading
                                      ? t('mapping.form.select.placeholder.loading', 'Preparing data...')
                                      : t('mapping.form.select.placeholder.default', 'Select...')
                                  }
                                  isSearchable
                                  isDisabled={!parseResult}
                                  isLoading={isLoading}
                                  options={importField.key === 'uniqueId' ? taskIdOptions : fileHeaders}
                                  isOptionDisabled={(option) => Object.values(mappingValues).includes(option.value)}
                                  value={field.value}
                                  onChange={(value) => setFieldValue(field.name, value)}
                                  menuPlacement="auto"
                                  isClearable
                                />
                              )}
                            </Field>
                          </FormControl>
                        </div>
                      </div>
                    ))}
                  </div>
                </Form>
              </div>
              <div className="compare-grid__aside">
                <h2 className="compare-preview__title">{t('mapping.preview.title', 'Preview')}</h2>
                <div className="compare-preview__description">
                  {t('mapping.preview.description', 'This is how your single activity will look like')}
                </div>
                <TaskCard
                  task={taskPreviewModel}
                  initialDateFormat={formik?.current?.values.dateFormat}
                  project={projects.find((proj) => proj.id === formik.current?.values?.defaultProject)}
                  className="compare-preview__card"
                />
                <a
                  className="ctrl-btn ctrl-btn--view-border compare-grid__button-help"
                  href={mailto}
                  onClick={() => mixpanel.track(mixpanelEvents.needHelpBtn, mixpanelMeta)}
                >
                  <Icon colorFill className="ctrl-btn__icon" name="help" />
                  <span className="ctrl-btn__text">{t('mapping.preview.help', 'Need help importing activities?')}</span>
                </a>
              </div>
            </div>
          </Popup.Body>
          <Popup.Footer>
            <Button
              disabled={isLoading || tasksIsLoading}
              icon={tasksIsLoading ? <Icon colorFill className="ctrl-btn__icon" name="autorenew" /> : null}
              className={`popup__button ${tasksIsLoading ? 'is-processing' : ''}`}
              type="button"
              onClick={() =>
                mixpanel.trackWithAction(
                  () => actions.setCurrentStep(TaskImportStep.SelectSource),
                  mixpanelEvents.nextBtn,
                  mixpanelMeta,
                  !tasksIsLoading,
                )
              }
            >
              {tasksIsLoading ? t('mapping.buttons.loading', 'Preparing...') : t('mapping.buttons.prev', 'Prev')}
            </Button>
            <Button
              data-cy="btnImportNext"
              disabled={isLoading || tasksIsLoading}
              icon={tasksIsLoading ? <Icon colorFill className="ctrl-btn__icon" name="autorenew" /> : null}
              className={`popup__button ${tasksIsLoading ? 'is-processing' : ''}`}
              type="button"
              onClick={() =>
                mixpanel.trackWithAction(submitForm, mixpanelEvents.nextBtn, mixpanelMeta, !tasksIsLoading)
              }
            >
              {tasksIsLoading ? t('mapping.buttons.loading', 'Preparing...') : t('mapping.buttons.next', 'Next')}
            </Button>
          </Popup.Footer>
        </>
      )}
    </Formik>
  );
};
export default TasksImportMapping;
