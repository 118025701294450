import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory} from 'react-router';

import EventManager from 'services/EventManager';
import {Button} from 'shared/components';
import ZetOnboardingContainer from 'shared/components/OnboardingLayout/ZetOnboardingContainer';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks';
import {useEffectOnce} from 'shared/hooks/core';

const ThirdStep: FC = () => {
  const {mixpanel, useZoomInfo} = useAnalyticsService({publicPage: true});
  useZoomInfo();
  const {t} = useTranslation('signup');
  const history = useHistory();
  const routes = useLocalizedRoutes();
  const mixpanelEvents = mixpanel.events.signup.steps.third;

  useEffectOnce(() => {
    mixpanel.track(mixpanelEvents.page);
  });

  const onFinishBtnHandler = async () => {
    trackAnalyticsEvents();
    history.push(generatePath(routes.project, {id: 'new'}));
  };

  const trackAnalyticsEvents = () => {
    mixpanel.track(mixpanelEvents.buttons.finish);
    EventManager.pushToGtmDataLayer({event: 'c4-onboarding-complete'});
  };

  return (
    <ZetOnboardingContainer
      title={t('third_step.title', "We're all set!")}
      description={
        <>
          {t('third_step.description.1', 'Create, manage and delegate activities on the')}{' '}
          <b>{t('third_step.description.2', 'Crews by Core')}</b> {t('third_step.description.3', 'platform.')}
        </>
      }
      footer={
        <footer className="form-onboarding-z__footer">
          <div className="form-onboarding-z__footer-actions form-onboarding-z__footer-actions--next">
            <Button className="ctrl-btn--color-success form-onboarding-z__footer-button" onClick={onFinishBtnHandler}>
              {t('footer.submit_third_step', 'Finish!')}
            </Button>
          </div>
        </footer>
      }
    >
      <div className="form-onboarding-z__item">
        <div className="onboarding-finish">
          <div className="onboarding-finish__container">
            <picture className="picture onboarding-finish__media">
              <source srcSet="/images/screenshots/screenshot-1@1x.jpg 1x, /images/screenshots/screenshot-1@2x.jpg 2x" />
              <img className="picture__image" src="/images/screenshots/screenshot-1@1x.jpg" alt="" />
            </picture>
          </div>
        </div>
      </div>
    </ZetOnboardingContainer>
  );
};
export default ThirdStep;
