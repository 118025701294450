import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {useAssigneesTabContext} from '../AssigneesTabContext';
import SearchWorkers from '../SearchWorkers';

type Props = {
  onClose: () => void;
};

const AddNewWatchers: FC<Props> = ({onClose}: Props) => {
  const {t} = useTranslation('task');
  const {watchers, onMemberSelect, updateWatchers} = useAssigneesTabContext();

  return (
    <SearchWorkers
      isMemberList
      title={t('responsible_panel.watchers_adding ', 'Adding Watchers')}
      taskAssignees={watchers}
      updateSelectedWorkers={updateWatchers}
      onClose={onClose}
      onMemberSelect={onMemberSelect}
    />
  );
};

export default AddNewWatchers;
