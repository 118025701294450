import {GanttStatic} from 'dhtmlx-gantt';

import {ObserverAction, ObserverActionSource, TasksObserver} from 'services/TasksObserver';
import {ProjectModel} from 'shared/models/project';
import {TaskDetailsModelDTO, TaskProjection} from 'shared/models/task';

export const batchUpdateSelectedTasks = (
  gantt: GanttStatic,
  data: PromiseFulfilledResult<TaskDetailsModelDTO>[],
  project: ProjectModel,
  observer: TasksObserver,
) => {
  gantt.batchUpdate(function () {
    gantt.eachSelectedTask(function (taskId: string) {
      const updatedTask = data.find((promise) => promise.value.id === taskId)?.value;
      if (updatedTask) {
        updateTasksCache(observer, updatedTask);
      }
    });
  });
};

const updateTasksCache = (observer: TasksObserver, task: TaskDetailsModelDTO) => {
  observer.emit([{data: task}], {
    action: ObserverAction.update,
    projectId: task.projectId,
    projection: TaskProjection.taskDetail,
    source: ObserverActionSource.bulk,
  });
};
