import {GanttStatic} from 'dhtmlx-gantt';
import {History} from 'history';
import {TFunction} from 'i18next';

import {getActivityName} from 'modules/Tasks/components/Gantt/utils';
import {checkEmptyPersonalProject} from 'modules/Tasks/components/Gantt/utils/gantt';
import {TasksLocationState} from 'modules/Tasks/types/location';
import {ProjectModel} from 'shared/models/project';
import {RootDispatch} from 'store';
import {profileActions} from 'store/profile';

export function handleInitialStateParams({
  history,
  dispatch,
  project,
  gantt,
  t,
}: {
  history: History<TasksLocationState>;
  dispatch?: RootDispatch;
  project: ProjectModel;
  gantt: GanttStatic;
  t: TFunction;
}) {
  if (history.location.state) {
    const locationState = {...history.location.state};
    if (dispatch && history.location.state?.showTutorial) {
      dispatch(profileActions.showTutorialPopup(true));
      delete locationState.showTutorial;
    }
    if (history.location.state?.createNewTask || checkEmptyPersonalProject(project)) {
      gantt.createTask({name: getActivityName(t), autoCreated: true, objectType: 'activity'}, '0');
      delete locationState.createNewTask;
    }
    history.replace({
      ...history.location,
      state: locationState,
    });
  }
}
