import {GanttStatic} from 'dhtmlx-gantt';
import {camelizeKeys} from 'humps';
import {TFunction} from 'i18next';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {configureGanttBase, configureTimeline, getGanttLookaheadLayout} from 'modules/Tasks/components/Gantt/utils';
import {getPrettyTaskStatuses} from 'shared/constants/statuses';
import {QueryCacheHelper} from 'shared/hooks/useQueryCache/QueryCacheHelper';

export interface ConfigureGanttLookaheadViewParams {
  gantt: GanttStatic;
  t: TFunction;
  projectId: string;
  startDate?: Date;
  endDate?: Date;
  cacheHelper: QueryCacheHelper;
  hasAdminRole?: boolean;
}

export const configureProjectSpecificSettings = (gantt: GanttStatic, t: TFunction, projectId: string) => {
  configureTimeline(gantt, projectId, (task) => {
    return task.date_list?.length && task.object_type !== 'summary' ? 'lookahead_task_cell' : '';
  });
};

export default function configure({
  gantt,
  t,
  projectId,
  startDate,
  endDate,
  cacheHelper,
  hasAdminRole = true,
}: ConfigureGanttLookaheadViewParams) {
  configureGanttBase({gantt, t, projectId, cacheHelper, hasAdminRole});
  configureTemplates(gantt, t);
  startDate && (gantt.config.start_date = startDate);
  endDate && (gantt.config.end_date = endDate);
  gantt.config.drag_links = false;
  gantt.config.drag_move = false;
  gantt.config.drag_resize = false;
  gantt.config.drag_multiple = false;
  gantt.config.drag_progress = false;
  gantt.config.smart_scales = false; // disable because of custom layout on lookahead
  gantt.config.show_links = false;
  gantt.config.layout = getGanttLookaheadLayout();

  configureTooltip(gantt, t);
}

function configureTooltip(gantt: GanttStatic, t: TFunction) {
  const eventId = gantt.attachEvent(
    'onGanttReady',
    () => {
      gantt.ext.tooltips?.detach('[' + gantt.config.task_attribute + ']:not(.gantt_task_row)');
      gantt.ext.tooltips?.tooltipFor({
        selector: '.lookahead_container .lookahead_element_filled',
        html: (event) => {
          if (gantt.config.touch && !gantt.config.touch_tooltip) {
            return;
          }
          const targetTaskId = gantt.locate(event);
          if (gantt.isTaskExists(targetTaskId)) {
            const task = gantt.getTask(targetTaskId);
            return gantt.templates.tooltip_text(task.start_date, task.end_date, task);
          }
          return null;
        },
      });
      gantt.detachEvent(eventId);
    },
    undefined,
  );
  gantt.templates.tooltip_text = (start, end, task) => {
    if (!task) return null;
    // TODO: remove camelize
    return `
      <div class="lookahead-tooltip">
        <div class="lookahead_tooltip_title">${task.name}</div>
        <div class="lookahead-tooltip_items">
          <div class="lookahead-tooltip_item">
            <span>${t('lookahead:tooltip.subcontractor', 'Subcontractor:')}</span>
            <span class="lookahead-tooltip_item_value">${
              task.responsible_org?.group?.name || t('gantt:labels.not_assigned', 'N/A')
            }</span>
          </div>
          <div class="lookahead-tooltip_item">
            <span>${t('lookahead:tooltip.responsible', 'Responsible:')}</span>
            <span class="lookahead-tooltip_item_value">
              ${
                camelizeKeys((task?.responsible || {}) as GanttTask)?.[0]?.memberName ||
                t('gantt:labels.not_assigned', 'N/A')
              }
            </span>
          </div>
          <div class="lookahead-tooltip_item">
            <span>${t('lookahead:tooltip.status', 'Status:')}</span>
            <span class="lookahead-tooltip_item_value">${
              getPrettyTaskStatuses(t)[task.status] || t('gantt:labels.not_assigned', 'N/A')
            }</span>
          </div>
        </div>
      </div>`;
  };
}

export function configureTemplates(gantt: GanttStatic, t: TFunction) {
  gantt.templates.tooltip_text = (start, end, task) => {
    if (!task) return null;
    return `
      <div class="lookahead-tooltip">
        <div class="lookahead_tooltip_title" style="color: ${gantt.taskColors[task.color]};">${task.name}</div>
        <div class="lookahead-tooltip_items">
          <div class="lookahead-tooltip_item">
            <span>Subcontractor:</span>
            <span class="lookahead-tooltip_item_value">${task.responsible_org?.group?.name || 'N/A'}</spancla>
          </div>
          <div class="lookahead-tooltip_item">
            <span>Responsible:</span>
            <span class="lookahead-tooltip_item_value">${task.responsible?.[0]?.memberName || 'N/A'}</span>
          </div>
          <div class="lookahead-tooltip_item">
            <span>Status:</span>
            <span class="lookahead-tooltip_item_value">${getPrettyTaskStatuses(t)[task.status] || 'N/A'}</span>
          </div>
        </div>
      </div>`;
  };
}
