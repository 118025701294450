import {GanttStatic} from 'dhtmlx-gantt';
import {toast} from 'react-toastify';

import {TasksApi} from 'api';
import {ObserverAction, ObserverActionSource, useTasksObserver} from 'services/TasksObserver';
import {extractAxiosError} from 'shared/helpers/axios';

export const useDeleteOneSelectedIssue = (gantt: GanttStatic) => {
  const observer = useTasksObserver();

  return async (projectId: string) => {
    const taskIds = gantt.getSelectedTasks();
    if (taskIds.length === 1) {
      const linkedActivityId = gantt.getTask(taskIds[0]).task_ids[0];
      try {
        await TasksApi.deleteIssue(taskIds[0], projectId);
        gantt.deleteTask(taskIds[0]);
        observer.load({
          ids: [linkedActivityId],
          projectId: projectId,
          action: ObserverAction.update,
          source: ObserverActionSource.taskDetails,
        });
      } catch (err) {
        toast.error(extractAxiosError);
      }
    }
  };
};
