import {useMemo} from 'react';

import {useTaskStatusOptions} from 'shared/hooks';
import {TaskStatus} from 'shared/models/task';

const EXCLUDED_STATUSES = [TaskStatus.tba, TaskStatus.archived, TaskStatus.closed];

export function useSharedTaskStatusOptions(taskId: string) {
  const {options, workflowStatuses} = useTaskStatusOptions({getById: taskId, shared: true});
  const visibleOptions = useMemo(
    () =>
      options.filter(
        (option) => !EXCLUDED_STATUSES.includes(option.value as TaskStatus) && workflowStatuses.includes(option.value),
      ),
    [status, EXCLUDED_STATUSES, workflowStatuses],
  );
  return [options, visibleOptions] as const;
}
