import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import {useRef, useEffect, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {TasksLocationState} from 'modules/Tasks/types/location';
import {Loader} from 'shared/components';

import ChatTimelinePlaceholder from '../../ChatTimeline/EmptyChatTimeline/ChatTimelinePlaceholder';

import {CommentCard, CommentCardBody, CommentCardSeparator} from './CommentCard';
import {useCommentsFeed} from './hooks/useCommentsFeed';

export const CommentsList = observer(function CommentList({taskId}: {taskId: string}) {
  const history = useHistory<TasksLocationState>();
  const {t} = useTranslation('comments');
  const {comments, isLoading: commentsLoading, isFetching, hasNoComments} = useCommentsFeed(taskId);
  const endOfList = useRef(null);
  let currentDate = null;

  useEffect(() => {
    if (history.location.state?.dailyReportDate) {
      const el = document.getElementById(history.location.state?.dailyReportDate);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    } else {
      endOfList.current?.scrollIntoView();
    }
  }, [comments, history.location.state?.dailyReportDate]);

  if (commentsLoading || isFetching) {
    return <Loader />;
  }

  if (hasNoComments) {
    return (
      <ChatTimelinePlaceholder
        title={t('comments_list.comments.empty.title')}
        description={t('comments_list.comments.empty.description')}
      />
    );
  }

  return (
    <>
      {comments?.map((commentContent, index) => {
        const commentItemsDateFormatted = dayjs(commentContent.date).format('MMMM D, YYYY');
        const showDateSeparator = commentItemsDateFormatted !== currentDate;
        currentDate = commentItemsDateFormatted;
        const dayValue = dayjs(commentItemsDateFormatted).valueOf().toString();

        return (
          <Fragment key={commentContent.key}>
            {showDateSeparator ? (
              <>
                {currentDate}
                <CommentCardSeparator forceDisplay />
              </>
            ) : null}
            <CommentCard key={commentContent.key + commentContent.date} day={dayValue}>
              <CommentCardBody commentContent={commentContent} taskId={taskId} />
            </CommentCard>
            {/* keep list scrolled to the bottom when opening the list of comments when not navigating from dailies view */}
            {index === comments.length - 1 ? <div ref={endOfList} /> : null}
          </Fragment>
        );
      })}
    </>
  );
});
