import {useProjectWorkers} from 'shared/hooks';
import {BaselineSnapshot} from 'shared/models/task';

import Snapshot from '../Snapshot/Snapshot';

import s from './SnapshotsList.module.scss';
import {memo} from 'react';

type Props = {
  snapshots: BaselineSnapshot[];
  deleteSnapshot: (id: string) => void;
  onChangeSnapshotId: (id: string) => void;
  activeId: string;
  disabled?: boolean;
};

const SnapshotsList = ({snapshots, deleteSnapshot, disabled, onChangeSnapshotId, activeId}: Props) => {
  const {projectWorkers} = useProjectWorkers();

  const getAuthor = (workerId: string) => {
    return projectWorkers?.find((w) => w.workerId === workerId) || null;
  };

  return (
    <div className={s.snapshotsList}>
      {snapshots.map((item, i) => (
        <Snapshot
          disabled={disabled}
          key={`${item.id}_${i}`}
          snapshot={item}
          isActive={activeId === item.id}
          onClick={onChangeSnapshotId}
          worker={getAuthor(item.createdBy)}
          deleteSnapshot={deleteSnapshot}
        />
      ))}
    </div>
  );
};

export default memo(SnapshotsList);
