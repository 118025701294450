import {decamelizeKeys} from 'humps';

import {TasksViewMode} from 'shared/constants/common';
import {TaskFilterQuery, TaskStatus, TaskStatusQuery} from 'shared/models/task';

import {clearEmptinessFromObject, getTimestampString} from './common';

export const prepareTaskFilters = (filters: TaskFilterQuery & TaskStatusQuery) => {
  const {
    schedStartFirst,
    schedStartSecond,
    schedEndFirst,
    schedEndSecond,
    showPastDue,
    unassignedOnly,
    withIssues,
    state,
    statusList,
    ...otherFilters
  } = filters;
  if (unassignedOnly) {
    Object.assign(otherFilters, {assignmentCount: 0});
  }

  if (withIssues) {
    Object.assign(otherFilters, {hasIssues: true});
  }
  if (showPastDue) {
    Object.assign(otherFilters, {
      pastDue: showPastDue,
    });
  }
  if (statusList?.length) {
    delete otherFilters.status;
    Object.assign(otherFilters, {statusList: statusList.join(',')});
  }

  return decamelizeKeys(clearEmptinessFromObject(otherFilters));
};

export const prepareValuesForUrl = (filters: TaskFilterQuery): {[key: string]: string} => {
  const {schedEndFirst, schedWeeks, view} = filters;
  const validFields = [
    'q',
    'projectId',
    'locations',
    'responsibleOrgIds',
    'responsible',
    'unassignedOnly',
    'types',
    'withIssues',
    'state',
    'schedWeeks',
    'status',
    'showPastDue',
    'statusList',
    'milestonesOnly',
    'outlineSortKeyPrefixList',
    'projectedLabor',
    'actualLabor',
    'costCode',
    'phaseCode',
    'customCode',
    'csiCode',
    'view',
    'ganttCols',
    'documentId',
    // issue filters
    'issueType',
    'impact',
    'costImpact',
    'culpableOrgId',
    'costTrackingNumber',
    'showChat',
  ] as (keyof TaskFilterQuery)[];
  const preparedFilters = {};
  for (const field of validFields) {
    const fieldValue = filters[field];
    if (Array.isArray(fieldValue) ? fieldValue.length : fieldValue) {
      Object.assign(preparedFilters, {[field]: fieldValue});
    }
  }

  if ((schedWeeks && schedEndFirst) || (schedEndFirst && view === TasksViewMode.dailies)) {
    Object.assign(preparedFilters, {
      schedEndFirst: schedEndFirst && getTimestampString(schedEndFirst),
    });
  }
  return preparedFilters;
};

export const addStatusToFilters = (status: string, params: TaskFilterQuery): TaskFilterQuery & TaskStatusQuery => {
  if (status === 'archived') {
    return {
      ...params,
      deleted: false,
      statusList: !params.statusList.includes(TaskStatus.archived)
        ? [...params.statusList, TaskStatus.archived]
        : params.statusList,
      includeArchived: true,
    };
  }
  if (status === 'active') {
    if (params.status) {
      return {...params, deleted: false, status: params.status};
    } else {
      return {...params, deleted: false};
    }
  }
  if (status === 'deleted') {
    return {...params, deleted: true, includeArchived: true};
  }
  return {...params};
};
