import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import {CtrlButton} from 'shared/components/CoreNewUI';

import Member from '../../../Member/Member';
import s from '../../../Responsible/Responsible.module.scss';
import {useAssigneesTabContext} from '../AssigneesTabContext';

type ResponsibleProps = {
  className?: string;
  onChooseResponsible: () => void;
  disabled?: boolean;
};

const ResponsibleSelected = ({className, disabled, onChooseResponsible}: ResponsibleProps) => {
  const {t} = useTranslation('task');
  const {responsible} = useAssigneesTabContext();

  return (
    <section className={cn(s.Responsible, className)}>
      <header className={s.Responsible__header}>
        <h2 className={s.Responsible__title}>{t('responsible_panel.title', 'Responsible')}</h2>
        <CtrlButton disabled={disabled} view="link" onClick={onChooseResponsible}>
          {t('responsible_panel.choose_responsible', 'Choose Responsible')}
        </CtrlButton>
      </header>
      <div className={s.Responsible__body}>
        <Member memberName={responsible[0].memberName} memberProfilePicUrl={responsible[0].memberProfilePicUrl} />
      </div>
    </section>
  );
};

export default ResponsibleSelected;
