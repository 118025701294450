import {useTranslation} from 'react-i18next';

import {OptionType} from 'shared/components';
import {getPrettyTaskStatuses, sortedTasksStatuses} from 'shared/constants/statuses';
import {useWorkflowStatuses, UseWorkflowStatusesParams} from 'shared/hooks/useWorkflowStatuses';

export function useTaskStatusOptions({shared, enabled, getById}: UseWorkflowStatusesParams) {
  const {t} = useTranslation(['common']);
  const [workflowStatuses, isLoading] = useWorkflowStatuses({getById, enabled, shared});
  const statuses = getPrettyTaskStatuses(t);

  const options = sortedTasksStatuses.map<OptionType>((status) => ({
    label: statuses[status],
    value: status,
    id: status,
  }));
  return {workflowStatuses, isLoading, options};
}
