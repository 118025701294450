import {GanttTaskColors} from 'shared/helpers/task';

export enum GanttNames {
  gantt = 'gantt',
  ganttVisual = 'ganttVisual',
  lookahead = 'lookahead',
  table = 'table',
  issues = 'issues',
  dailies = 'dailies',
}

export const GANTT_CLOSED_TASK_COLOR = '#808080FF';

export const GANTT_DEFAULT_TASK_COLORS: GanttTaskColors = {
  Scheduled: '#DEDEDE',
  Done: '#01964E',
  InProgress: '#053FE3',
  Blocked: '#FF9900',
  Failed: '#FA380D',
};

export type CustomGanttNames = 'DataLoaded' & GanttNames;
