import cs from 'classnames';
import React, {CSSProperties, FC} from 'react';

import SpriteSVG from 'assets/images/svg-statuses.svg';
import {taskStatusIcons} from 'shared/constants/statuses';
import {TaskStatus} from 'shared/models/task';

export type StatusIconProps = {
  name: TaskStatus;
  className?: string;
  fill?: boolean;
  colorFill?: boolean;
  width?: number | string;
  height?: number | string;
  size?: number;
  onClick?: (e) => void;
  style?: CSSProperties;
};

const TaskStatusIcon: FC<StatusIconProps> = ({
  name,
  className,
  fill = true,
  colorFill = false,
  width = 24,
  height = 24,
  size,
  onClick,
  style,
}) => {
  return (
    <svg
      className={cs('icon', className, {
        'icon--fill': fill,
        'icon--color-fill': colorFill,
      })}
      width={size || width}
      height={size || height}
      onClick={onClick}
      style={style}
    >
      <use xlinkHref={`${SpriteSVG}#${taskStatusIcons[name] || name}`} />
    </svg>
  );
};
export default TaskStatusIcon;
