import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import {Avatar, TaskStatusIcon} from 'shared/components';
import StatusElement from 'shared/components/StatusElement';
import {getPrettyTaskStatuses, taskStatusClassNameOverride} from 'shared/constants/statuses';
import {convertToTimezone} from 'shared/helpers/dates';
import {TaskHistoryType, TaskStatus} from 'shared/models/task';

import s from './InfoHistory.module.scss';

type InfoHistoryProps = {
  className?: string;
  historyItem: TaskHistoryType;
  timezone: string;
};

const InfoHistory = ({className, historyItem, timezone}: InfoHistoryProps) => {
  const {t} = useTranslation(['task', 'gantt']);
  return (
    <article className={cn(s.infoHistory, className)}>
      {/* TODO: need worker profile image*/}
      <Avatar src="" className={s.infoHistory__avatar} />
      <div className={s.infoHistory__description}>
        <div className={s.infoHistory__line}>
          <span className={s.infoHistory__workerName}>{historyItem.workerName || historyItem.guestName}</span>
          <StatusElement
            label={getPrettyTaskStatuses(t)[historyItem.status]}
            size="xs"
            className={`status-element--${taskStatusClassNameOverride[historyItem.status] || historyItem.status} ${
              s.infoHistory__status
            }`}
            icon={<TaskStatusIcon name={historyItem.status as TaskStatus} size={12} className="status-element__icon" />}
          />
        </div>
        <div className={s.infoHistory__line}>
          <span className={s.infoHistory__time}>
            {convertToTimezone(historyItem.timeUpdated, timezone).format(`LT`)}
          </span>
        </div>
      </div>
    </article>
  );
};

export default InfoHistory;
