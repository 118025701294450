import cn from 'classnames';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useRouteMatch} from 'react-router';

import {IconMapType} from 'modules/Tasks/types/icons';
import {CtrlButton, Dropdown} from 'shared/components/CoreNewUI';
import {TasksViewMode} from 'shared/constants/common';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';

import {IconsMap} from '../../constants/icons';
import {throttle} from '../../helpers/throttle';

import s from './WorkspaceSwitcher.module.scss';

interface WorkspaceSwitcherProps {
  viewMode?: TasksViewMode;
  updateViewMode?: (viewMode: TasksViewMode) => void;
  projectId: string;
}

type WorkspaceItems = {
  key: string;
  label: string;
  icon: IconMapType;
  onClick: () => void;
  selected: boolean;
  showSeparatorBefore?: boolean;
};

const WorkspaceSwitcher = ({projectId, viewMode, updateViewMode}: WorkspaceSwitcherProps) => {
  const history = useHistory<{query?: string; from?: string; switch?: boolean}>();
  const routes = useLocalizedRoutes();
  const {t} = useTranslation(['task', 'common']);
  const match = useRouteMatch<{projectId: string}>({
    path: [routes.workers, routes.tasks, routes.projectWorkers, routes.subcontractors, routes.subcontractor],
    exact: true,
  });
  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode, isReadOnlyMode: !hasAnyAdminRole}});
  const mixpanelEvents = mixpanel.events.tasks;
  const isProjectWorker = match?.path === routes.projectWorkers;
  const isTasksPage = match?.path === routes.tasks;
  const isSubcontractors = match?.path === routes.subcontractors || match?.path === routes.subcontractor;

  useEffect(() => {
    const throttledHandleResize = throttle(() => {
      setIsSmallScreen(window.innerWidth <= 800);
    }, 250);

    window.addEventListener('resize', throttledHandleResize);
    throttledHandleResize();

    return () => {
      window.removeEventListener('resize', throttledHandleResize);
    };
  }, []);

  const openTasks = (viewMode: TasksViewMode) => {
    if (updateViewMode) {
      updateViewMode(viewMode);
    } else {
      history.replace({
        pathname: generatePath(routes.tasks, {projectId: match?.params?.projectId}),
        state: {...history.location.state, switch: true},
        search: `view=${viewMode}`,
      });
    }
    mixpanel.track(mixpanelEvents.switchViewMode[viewMode]);
  };

  const openWorkers = () => {
    history.push({
      pathname: generatePath(routes.projectWorkers, {projectId: match?.params?.projectId}),
      search: history.location.search || history.location.state?.query,
      state: {query: history.location.search || history.location.state?.query},
    });
  };

  const openSubcontractors = () => {
    history.push({
      pathname: generatePath(routes.subcontractors, {projectId: match?.params?.projectId}),
      // TODO: delete state and search within the task: https://journey-builders.atlassian.net/browse/CNA-3017
      search: history.location.search || history.location.state?.query,
      state: {query: history.location.search || history.location.state?.query},
    });
  };

  const adminItems: WorkspaceItems[] = [
    {
      key: 'people',
      label: t('task:gantt.workspace_switcher.people', 'People'),
      icon: IconsMap.group_equal,
      onClick: openWorkers,
      selected: isProjectWorker,
      showSeparatorBefore: true,
    },
    {
      key: 'companies',
      label: t('task:gantt.workspace_switcher.companies', 'Companies'),
      icon: IconsMap.company,
      onClick: openSubcontractors,
      selected: isSubcontractors,
    },
  ];

  const items: WorkspaceItems[] = [
    {
      key: 'lookahead',
      label: t('task:gantt.workspace_switcher.lookahead', 'Lookahead'),
      icon: IconsMap.lookahead_2,
      onClick: () => openTasks(TasksViewMode.lookahead),
      selected: isTasksPage && viewMode === TasksViewMode.lookahead,
    },
    {
      key: 'gantt',
      label: t('task:gantt.workspace_switcher.gantt', 'Gantt'),
      icon: IconsMap.timeline,
      onClick: () => openTasks(TasksViewMode.gantt),
      selected: isTasksPage && viewMode === TasksViewMode.gantt,
    },
    {
      key: 'map',
      label: t('task:gantt.workspace_switcher.ganttMap', 'Map'),
      icon: IconsMap.drawing,
      onClick: () => openTasks(TasksViewMode.ganttVisual),
      selected: isTasksPage && viewMode === TasksViewMode.ganttVisual,
    },
    {
      key: 'issues',
      label: t('task:gantt.workspace_switcher.issues', 'Issues'),
      icon: IconsMap.table_2,
      onClick: () => openTasks(TasksViewMode.issues),
      selected: isTasksPage && viewMode === TasksViewMode.issues,
    },
    {
      key: 'dailies',
      label: t('task:gantt.workspace_switcher.dailies', 'Dailies'),
      icon: IconsMap.document_text_outlined,
      onClick: () => openTasks(TasksViewMode.dailies),
      selected: isTasksPage && viewMode === TasksViewMode.dailies,
    },
  ];

  const menuItems = hasAnyAdminRole ? items.concat(adminItems) : items;
  const shouldShowDropDown = hasAnyAdminRole && isSmallScreen;
  const shouldSlice = shouldShowDropDown ? -2 : menuItems.length;
  const dropdownStyle = cn({[s['workspaceSwitcher__dropdown']]: shouldShowDropDown});

  return (
    <div className={cn(s.workspaceSwitcher)}>
      {isSmallScreen && (
        <Dropdown
          toggleElement={
            <CtrlButton color="tertiary" size="xs" icon={IconsMap.more_vertical} iconOnly={true}>
              More
            </CtrlButton>
          }
        >
          <div className={dropdownStyle}>
            {menuItems.slice(-2).map((item) => (
              <CtrlButton
                key={item.key}
                className={s.workspaceSwitcher__dropdownItem}
                color="tertiary"
                view="selector"
                icon={item.icon}
                size="xs"
                onClick={item.onClick}
                selected={item.selected}
              >
                {item.label}
              </CtrlButton>
            ))}
          </div>
        </Dropdown>
      )}
      {menuItems.slice(0, shouldSlice).map((item) => (
        <CtrlButton
          key={item.key}
          className={s.workspaceSwitcher__item}
          color="tertiary"
          view="selector"
          icon={item.icon}
          size="xs"
          onClick={item.onClick}
          selected={item.selected}
        >
          {item.label}
        </CtrlButton>
      ))}
    </div>
  );
};
export default WorkspaceSwitcher;
