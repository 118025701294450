import {GanttStatic} from 'dhtmlx-gantt';
import React, {useRef} from 'react';
import {useParams} from 'react-router';
import StateManager from 'react-select';

import {useInlineSelectController} from 'modules/Tasks/components/Gantt/components/Editors/useInlineSelectController';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import TaskStatusSelect from 'shared/components/CoreForm/Select/TaskStatusSelect';
import {taskStatusClassNameOverride} from 'shared/constants/statuses';
import {useMount} from 'shared/hooks/core';
import {TaskStatus} from 'shared/models/task';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
  allowedStatuses?: TaskStatus[];
};
const StatusEditor = ({gantt, task, allowedStatuses}: Props) => {
  const selectRef = useRef<StateManager>();
  // TODO: will fix in next iteration
  const {projectId} = useParams<{projectId: string}>();
  const {updateValue, onKeyUp, onKeyDown, onClose} = useInlineSelectController(gantt);

  useMount(() => {
    selectRef.current.focus();
  });

  const onChange = async (value: string) => {
    if (task.status !== value) {
      updateValue(task, 'status', value);
    }
  };
  return (
    <TaskStatusSelect
      projectId={projectId || task.projectId || task.project_id}
      value={task.status}
      className={`react-select react-select--status react-select--status-${
        taskStatusClassNameOverride[task?.status] || task?.status
      } gantt-status`}
      ref={selectRef}
      menuPlacement="auto"
      size="xs"
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      menuIsOpen={true}
      closeMenuOnSelect={false}
      tabSelectsValue={false}
      allowedStatuses={allowedStatuses}
    />
  );
};

const ISSUES_ALLOWED_STATUSES = [TaskStatus.inProgress, TaskStatus.closed, TaskStatus.tba];
StatusEditor.Issue = (props: Props) => {
  return <StatusEditor {...props} allowedStatuses={ISSUES_ALLOWED_STATUSES} />;
};

export default StatusEditor;
