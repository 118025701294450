import {GanttStatic} from 'dhtmlx-gantt';
import React, {useEffect, useState} from 'react';

import ActivityPanel from './ActivityPanel/ActivityPanel';
import IssuePanel from './IssuePanel/IssuePanel';

export enum SidebarType {
  activity = 'activity',
  issue = 'issue',
}

export type StackOpenPanels = {
  id: string;
  type: SidebarType;
};

type Props = {
  sidebarType: SidebarType;
  taskId: string;
  onClose: () => void;
  updateActivityDetailsTask: (value: string) => void;
  gantt: GanttStatic;
};

const SidebarPanel = ({sidebarType, taskId, onClose, updateActivityDetailsTask, gantt}: Props) => {
  const [stackOpenPanels, setStackOpenPanels] = useState<StackOpenPanels[]>([{id: taskId, type: sidebarType}]);
  const lastOpenedItem = stackOpenPanels.length - 1;

  const onOpenNextPanel = (panelInfo: StackOpenPanels) => {
    setStackOpenPanels(stackOpenPanels.concat([panelInfo]));
  };

  const onBackToParent = () => {
    setStackOpenPanels(stackOpenPanels.slice(0, -1));
  };

  const resetStackPanels = () => {
    setStackOpenPanels([{id: taskId, type: sidebarType}]);
  };

  useEffect(() => {
    resetStackPanels();
  }, [taskId]);

  return (
    <>
      {stackOpenPanels[lastOpenedItem].type === SidebarType.activity ? (
        <ActivityPanel
          taskId={stackOpenPanels[lastOpenedItem].id}
          onClose={onClose}
          updateActivityDetailsTask={updateActivityDetailsTask}
          gantt={gantt}
          onOpenNextPanel={onOpenNextPanel}
          onBackToParent={onBackToParent}
          stackOpenPanels={stackOpenPanels}
        />
      ) : (
        <IssuePanel
          taskId={stackOpenPanels[lastOpenedItem].id}
          onClose={onClose}
          onBackToParent={onBackToParent}
          stackOpenPanels={stackOpenPanels}
        />
      )}
    </>
  );
};

export default SidebarPanel;
