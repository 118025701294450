import * as Sentry from '@sentry/browser';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {TasksApi} from 'api';
import {BulkProps} from 'modules/Tasks/utils/constants';
import {Confirmation} from 'shared/components';
import AsyncProjectWorkerSelect from 'shared/components/CoreForm/Select/AsyncProjectWorkerSelect';
import {runAllSettled} from 'shared/helpers/api';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {GroupMemberRole} from 'shared/models/task';
import {CompanyWorker} from 'shared/models/worker';

import {butchUpdateIssues} from './utils';

const BulkResponsible = ({onClose, gantt, projectId}: BulkProps) => {
  const [selectedMember, setSelectedMember] = useState<CompanyWorker[]>(null);
  const {t} = useTranslation('gantt');

  const assignResponsible = async (worker: CompanyWorker) => {
    const promises = gantt
      .getSelectedTasks()
      .map((id) => gantt.getTask(id))
      .map((task) =>
        TasksApi.addIssueAssignee(projectId, task.id, {
          memberId: worker.workerId,
          memberRole: GroupMemberRole.responsible,
        }),
      );
    const {fulfilled, rejected} = await runAllSettled(promises);

    if (fulfilled.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // TODO: fix to allow use proper dto model
      butchUpdateIssues(gantt, fulfilled as any);
      toast.success(t('toast.success.issues.bulk_assign', 'The changes has been saved.'));
    }

    if (rejected.length) {
      const reason = rejected[0].reason;
      const error = isAxiosError(reason) ? extractAxiosError(reason) : reason;
      toast.error(error);
      Sentry.captureException(error);
    }
  };

  return (
    <Confirmation
      visible
      title={t('bulk_responsible.confirmation.title', 'Assignee')}
      description={t('bulk_responsible.confirmation.description', 'Responsible to Multiple Tasks')}
      cancelButton={t('bulk_assign.confirmation.buttons.cancel', 'Cancel')}
      acceptButton={t('bulk_assign.confirmation.buttons.accept', 'Assign')}
      onAccept={() => {
        assignResponsible(selectedMember[0]);
      }}
      onClose={onClose}
      isDisabledAcceptBtn={!selectedMember}
      field={
        <AsyncProjectWorkerSelect
          projectId={projectId}
          onSelectChange={setSelectedMember}
          placeholder={t('bulk_responsible.confirmation.select.placeholder', 'Select assignee')}
          loadingPlaceholder={t('bulk_assign.confirmation.select.loading_placeholder', 'Loading...')}
        />
      }
    />
  );
};

export default BulkResponsible;
