import React, {FunctionComponent, useEffect} from 'react';

import Firebase from 'services/Firebase';
import {useRootDispatch, useRootSelector} from 'store';
import {fullLogout} from 'store/ducks/auth/actions';
import {loadWorker, loadWorkerCompanies, loadWorkerCompanyWorkers} from 'store/profile/actions';
import {profileDataLoaded} from 'store/selectors';

import {useAuth} from '../AuthUserProvider';

const ProfileLoader: FunctionComponent = ({children}) => {
  const {isAuthenticated} = useAuth();
  const dispatch = useRootDispatch();
  const {worker, profileReady} = useRootSelector((state) => ({
    worker: state.profile.worker,
    companies: state.profile.companies,
    profileReady: profileDataLoaded(state),
  }));

  useEffect(() => {
    const workerId = Firebase.getWorkerIdFromLS();
    if (isAuthenticated() && !worker) {
      Promise.all([
        dispatch(loadWorker(workerId)),
        dispatch(loadWorkerCompanies(workerId)),
        dispatch(loadWorkerCompanyWorkers(workerId)),
      ])
        .then(([, , companyWorkers]) => {
          if (loadWorkerCompanyWorkers.rejected.match(companyWorkers)) {
            dispatch(fullLogout());
          }
        })
        .catch((error) => {
          if (error?.message.toLowerCase() !== 'network error') {
            dispatch(fullLogout());
          }
        });
    }
  }, [isAuthenticated, dispatch, worker]);

  return isAuthenticated && profileReady ? <div>{children}</div> : null;
};
export default ProfileLoader;
