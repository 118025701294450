import {useQuery} from 'react-query';

import {FeedbackService} from 'api';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

export function useFetchFeedback(taskId: string) {
  return useQuery([QUERY_CACHE_KEYS.feedback, taskId], () => FeedbackService.getFeedback(taskId), {
    enabled: Boolean(taskId),
  });
}
