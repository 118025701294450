import dayjs from 'dayjs';

import {MessageTypeMap} from 'shared/models/feedback';
import {FeedbackProjectModelDTO} from 'shared/models/task/task';

export type OrganizedGroupImage = {
  thumbnailUrl: string;
  imageUrl: string;
};

export interface OrganizedGroupComment {
  id: string;
  body: string;
  timeCreated: string;
  workerId: string;
}

export interface OrganizedGroupPDF {
  id: string;
  body: string;
  timeCreated: string;
  workerId: string;
  url: string;
}

export interface OrganizedComment {
  comment: OrganizedGroupComment;
  images: OrganizedGroupImage[];
  pdf: OrganizedGroupPDF;
  key: string;
}

export function groupGeneralComments(data: FeedbackProjectModelDTO[]) {
  // group all entries by same baseId
  const groupsByBaseId = data.reduce<Record<string, FeedbackProjectModelDTO[]>>((acc, curr) => {
    if (!acc[curr.baseId]) {
      acc[curr.baseId] = [];
    }

    acc[curr.baseId].push(curr);
    return acc;
  }, {});

  // sort by timeCreated
  Object.keys(groupsByBaseId).forEach((baseId) => {
    groupsByBaseId[baseId].sort(
      (a: {timeCreated: FeedbackProjectModelDTO['date']}, b: {timeCreated: FeedbackProjectModelDTO['date']}) =>
        dayjs(a.timeCreated).diff(dayjs(b.timeCreated)),
    );
  });

  // organize each baseId object into the required data structure
  const organizedComments: OrganizedComment[] = Object.keys(groupsByBaseId).map((baseId) => {
    const group = groupsByBaseId[baseId];
    const comment: OrganizedGroupComment = {} as OrganizedGroupComment;
    const images: OrganizedGroupImage[] = [];
    const pdf: OrganizedGroupPDF = {} as OrganizedGroupPDF;

    group.forEach((item) => {
      const isMessage = item.feedbackType === MessageTypeMap.message;
      const isImage = item.feedbackType === MessageTypeMap.image && item.payload.mImage?.fullImageUrl;
      const isPDF = item.feedbackType === MessageTypeMap.pdf;
      if (isMessage) {
        Object.assign(comment, {
          id: item.id,
          body: item.payload.mMessage.content.body,
          timeCreated: item.timeCreated,
          workerId: item.workerId,
        });
      } else if (isImage) {
        images.push({
          thumbnailUrl: `${item.payload.mImage?.thumbnailUrl}?width=36&height=36&crop=true`,
          imageUrl: item.payload.mImage?.fullImageUrl,
        });
      } else if (isPDF) {
        Object.assign(pdf, {
          id: item.id,
          body: item.payload.mMapPdf.content.body,
          timeCreated: item.timeCreated,
          workerId: item.workerId,
          url: item.payload.mMapPdf.sharedUrl,
        });
      }
    });

    return {
      key: baseId,
      comment,
      images,
      pdf,
    };
  });
  return organizedComments;
}
