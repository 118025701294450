import {GanttStatic} from 'dhtmlx-gantt';

export const OSK_SEGMENT_LENGTH = 10;

export enum TasksBulkTypes {
  responsible = 'responsible',
  watcher = 'watcher',
  subcontractor = 'subcontractor',
  type = 'type',
  location = 'location',
  color = 'color',
  delete = 'delete',
  status = 'status',
}

export const mixpanelTasksBulkTitles = {
  [TasksBulkTypes.responsible]: 'Assignees',
  [TasksBulkTypes.watcher]: 'Watcher',
  [TasksBulkTypes.subcontractor]: 'Subcontractor',
  [TasksBulkTypes.type]: 'Type',
  [TasksBulkTypes.location]: 'Location',
  [TasksBulkTypes.color]: 'Color',
  [TasksBulkTypes.delete]: 'Delete',
  [TasksBulkTypes.status]: 'Status',
};

export enum IssuesBulkTypes {
  responsible = 'responsible',
  status = 'status',
}

export const mixpanelIssuesBulkTitles = {
  [TasksBulkTypes.responsible]: 'Assignees',
  [TasksBulkTypes.status]: 'Status',
};

export type BulkProps = {
  onClose: () => void;
  gantt: GanttStatic;
  projectId: string;
  className?: string;
};
