import {GanttStatic} from 'dhtmlx-gantt';
import {forwardRef, useMemo, useRef, useState} from 'react';
import {useQueryClient} from 'react-query';

import {useFilterContext} from 'modules/Tasks/components/Filters';
import {CtrlButton, Dropdown} from 'shared/components/CoreNewUI';
import CtrlColorPicker from 'shared/components/CoreNewUI/CtrlColorPicker/CtrlColorPicker';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useResponsibleOrgColors} from 'shared/hooks/useResponsibleOrgColors';

import {useAnalyticsService} from '../../../../../../shared/hooks';

import s from './ColorDropdown.module.scss';
import {CtrlDropdownActions} from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';

interface ColorDropdownProps {
  gantt: GanttStatic;
  projectId: string;
  disabled?: boolean;
  className?: string;
}

const ColorDropdown = forwardRef<any, ColorDropdownProps>(
  ({gantt, projectId, disabled, className}: ColorDropdownProps, ref) => {
    const queryClient = useQueryClient();
    const {lookaheadColors, mappedLookaheadColors} = useResponsibleOrgColors();
    const [updating, setUpdating] = useState(false);
    const ctrlDropRef = useRef<CtrlDropdownActions>(null);

    const preparedLookaheadColors = useMemo(() => {
      return mappedLookaheadColors ? Object.keys(mappedLookaheadColors) : [];
    }, [mappedLookaheadColors]);

    const {viewMode} = useFilterContext();
    const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
    const mixpanelEvent = mixpanel.events.tasks.toolbar.bulkActions;

    const updateTaskColor = (abbrev: string, color: string): boolean => {
      let isUpdated = false;
      gantt.batchUpdate(() => {
        gantt.eachSelectedTask((id) => {
          const task = gantt.getTask(id);
          const newColor = color ? mappedLookaheadColors?.[color] : color;
          if (task.lookahead_color !== newColor) {
            task.lastChangedFields.lookaheadColor = {newValue: newColor};
            task.lookahead_color = newColor;
            isUpdated = true;
          }
          if (task.abbrev !== abbrev) {
            task.abbrev = abbrev;
            task.lastChangedFields.abbrev = {newValue: abbrev};
            isUpdated = true;
          }

          gantt.updateTask(task.id, {...task});
        });
      });
      return isUpdated;
    };

    const bulkUpdate = async (abbrev: string, color: string) => {
      setUpdating(true);
      if (updateTaskColor(abbrev, color)) {
        mixpanel.track(abbrev && color ? mixpanelEvent.setColor : mixpanelEvent.resetColor);
        queryClient.refetchQueries(QUERY_CACHE_KEYS.projectSubcontractors(projectId));
      }
      setUpdating(false);
    };

    const bulkUpdateAndClose = async (abbr: string, color: string) => {
      await bulkUpdate(abbr, color);
      ctrlDropRef.current?.close();
    };

    const resetColorSettings = async () => {
      await bulkUpdateAndClose(null, null);
    };

    function getInitialValues(gantt: GanttStatic): {color: string; abbrev: string} {
      let abbrev = '';
      let color = '';
      if (typeof gantt.getSelectedTasks === 'function') {
        const selectedIds = gantt.getSelectedTasks();
        if (selectedIds.length) {
          const selectedTasks = gantt.getTaskBy('id', selectedIds);
          const [firstSelected] = selectedTasks;
          // check all tasks must have the same abbrev and color
          const allTheSame = selectedTasks.every(
            (task) =>
              task?.abbrev === firstSelected?.abbrev && task?.lookahead_color === firstSelected?.lookahead_color,
          );
          if (firstSelected && allTheSame) {
            abbrev = firstSelected.abbrev;
            color = lookaheadColors[firstSelected.lookahead_color]?.fill;
          }
        }
      }
      return {
        abbrev,
        color,
      };
    }

    return (
      <Dropdown
        className={className}
        color="action"
        menuWidth={232}
        ref={ctrlDropRef}
        toggleElement={
          <CtrlButton
            ref={ref}
            color="action"
            icon="paint_bucket"
            size="xs"
            iconOnly
            disabled={disabled}
            onClick={() => mixpanel.track(mixpanelEvent.color)}
          >
            Colors
          </CtrlButton>
        }
      >
        <CtrlColorPicker
          disabled={updating}
          colors={preparedLookaheadColors}
          className={s.colorDropdown__control}
          onSave={bulkUpdateAndClose}
          onReset={resetColorSettings}
          initialValues={getInitialValues(gantt)}
        />
      </Dropdown>
    );
  },
);

export default ColorDropdown;
