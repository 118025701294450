import * as Sentry from '@sentry/browser';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import {ExternalSourcesApi} from 'api';
import {useFilterContext} from 'modules/Tasks/components/Filters';
import {OptionType} from 'shared/components/CoreForm';
import {Button} from 'shared/components/index';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {useAnalyticsService, useProfile} from 'shared/hooks';
import {useMount, usePrevious} from 'shared/hooks/core';

import {useTasksImportContext} from '../../TasksImportContext';
import {useProcoreOauth} from '../../useProcoreOauth';
import {TasksImportSourceType} from '../../utils/constants';

const TasksImportProcoreSelector: FunctionComponent = () => {
  const [{sourceType}, actions] = useTasksImportContext();
  const {authenticated, init, check, error, reset, logout} = useProcoreOauth();
  const [authStarted, setAuthStarted] = useState(false);
  const [checkInProgress, setCheckInProgress] = useState(true);
  const prev = usePrevious(authenticated);
  const profile = useProfile();
  const {t} = useTranslation('import');
  const showMainActionButton = !sourceType || sourceType !== TasksImportSourceType.proCore || !authenticated;

  const {projectId} = useParams<{projectId: string}>();
  const taskViewModeCtx = useFilterContext();
  const {mixpanel} = useAnalyticsService({
    extraMeta: {projectId, viewMode: taskViewModeCtx ? taskViewModeCtx.viewMode : null},
  });
  const mixpanelEvents = mixpanel.events.tasks;

  const isActive = sourceType === TasksImportSourceType.proCore;
  const activeStyle = isActive ? {color: '#053fe3'} : null;

  function start() {
    if (!authenticated) {
      mixpanel.track(mixpanelEvents.import.loginProCore);
      setAuthStarted(true);
      init();
    } else {
      setSource();
    }
  }

  useEffect(() => {
    if (!prev && authenticated && authStarted) {
      setAuthStarted(false);
      setSource();
    }
  }, [authenticated, prev]);

  useEffect(() => {
    if (!authenticated && authStarted && error) {
      setAuthStarted(false);
      reset();
    }
  }, [authStarted, error, authenticated]);

  function setSource() {
    actions.setProcoreSource();
  }

  useEffect(() => {
    if (authenticated) loadProcoreObjects();
  }, [authenticated]);

  useMount(() => {
    setCheckInProgress(true);
    check().finally(() => setCheckInProgress(false));
  });

  async function loadProcoreObjects() {
    try {
      const data = await ExternalSourcesApi.get(profile.id);
      if (data.sources.length) {
        actions.setProcoreProjects(
          data.sources.map(
            (source) =>
              ({
                value: source.identifier,
                label: source.name,
              } as OptionType),
          ),
        );
      }
    } catch (e) {
      if (isAxiosError(e)) {
        toast.error(extractAxiosError(e));
      }
      Sentry.captureException(e);
    }
  }

  function getButtonText() {
    if (checkInProgress) {
      return t('procore.buttons.check_auth', 'Checking your authentication...');
    } else if (authStarted) {
      return t('procore.buttons.finish_auth', 'Finish authentication flow');
    } else {
      return authenticated
        ? t('procore.buttons.import', 'Import from Procore')
        : t('procore.buttons.login', 'Log In to your Procore Account');
    }
  }

  function onLogoutClick() {
    mixpanel.track(mixpanelEvents.import.logoutProCore);
    actions.setSourceType(null);
    logout();
  }

  return (
    <>
      <header className="form-compare__header" style={activeStyle}>
        <h2 className="form-compare__title">{t('procore.title', 'Import from Procore Punchlist')}</h2>
      </header>
      <div className="ctrl-import-procore form-compare__upload">
        <picture className="picture ctrl-import-procore__logo">
          <img className="picture__image" src="/images/logo/logo-procore.svg" alt="" />
        </picture>

        <div className="ctrl-import-procore__description">
          <p style={{marginBottom: '15px'}}>
            {authenticated
              ? t('procore.description.auth', 'You are already logged in.')
              : t('procore.description.no_auth', 'Log In to your Procore Account.')}
          </p>
          {t(
            'procore.description.full',
            `, select your project, and we’ll import your Procore Punchlist into C4 as Activities that your crew can work
            on together.`,
          )}
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          {showMainActionButton && (
            <Button
              className="ctrl-import-procore__button"
              onClick={authenticated ? setSource : start}
              disabled={authStarted || checkInProgress}
              isLoading={authStarted || checkInProgress}
            >
              {getButtonText()}
            </Button>
          )}
          {authenticated && (
            <Button
              className="ctrl-import-procore__button"
              colorStyle="discard"
              onClick={onLogoutClick}
              isLoading={authStarted || checkInProgress}
              disabled={authStarted || checkInProgress}
            >
              Log Out
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default TasksImportProcoreSelector;
