import cs from 'classnames';
import React, {FC, ReactElement} from 'react';

import {IconProps} from 'shared/components/Icon';

type Props = {label: string; className?: string; icon?: ReactElement<IconProps>; size?: 'default' | 'xs'};

const StatusElement: FC<Props> = ({label, className, icon, size = 'default'}) => {
  return (
    <div className={cs('status-element', className, {[`status-element--size-${size}`]: size !== 'default'})}>
      {!!icon && icon}
      <span className="status-element__name">{label}</span>
    </div>
  );
};
export default StatusElement;
