import React, {FC, ReactNode} from 'react';

import {CtrlButton, SearchInput} from 'shared/components/CoreNewUI';
import Toolbar from 'shared/components/Toolbar';

type Props = {
  handleInputChange?: (value: string) => void;
  buttonTitle?: string;
  buttonIcon?: string;
  handleAddItem?: () => void;
  children?: ReactNode;
  onFocusInput?: () => void;
};

const ProjectLevelToolbar: FC<Props> = ({
  children,
  handleAddItem,
  handleInputChange,
  buttonTitle,
  buttonIcon,
  onFocusInput,
}: Props) => {
  return (
    <Toolbar spaceBetweenParts={false}>
      <Toolbar.Part withBorder={false}>
        <CtrlButton icon={buttonIcon} onClick={handleAddItem} dataCy="add_worker_bt">
          {buttonTitle}
        </CtrlButton>
      </Toolbar.Part>
      <Toolbar.Part isSearchInput>
        <SearchInput name="wildcard" onChange={handleInputChange} onFocus={onFocusInput} />
      </Toolbar.Part>
      <Toolbar.Part>{children}</Toolbar.Part>
    </Toolbar>
  );
};
export default ProjectLevelToolbar;
