import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

import {Avatar, Button, Icon, Popup} from 'shared/components';
import {getFormattedCurrency} from 'shared/helpers/common';
import {safeFormatDate} from 'shared/helpers/dates';

import {useSubscriptionContext} from '../CheckoutContext';
import {subscriptionActionsCreators, SubscriptionStep} from '../state';
import {useUpdateBillingInfo} from '../utils/useUpdateBillingInfo';

const SubscriptionPreview: FunctionComponent = () => {
  const {
    state: {subscription},
    company,
    dispatch,
  } = useSubscriptionContext();
  const needToUpgrade = company.currentSeatCount > subscription.seatCount;
  const {edit} = useUpdateBillingInfo(company?.id, subscription?.id);
  const {t} = useTranslation('company');

  const upgrade = () => {
    dispatch(subscriptionActionsCreators.setStep(SubscriptionStep.upgrading));
  };

  const cancelSubscription = () => {
    dispatch(subscriptionActionsCreators.setStep(SubscriptionStep.canceling));
  };

  return (
    <Popup.Body>
      <div className="popup__item popup__item--size-m">
        <div className="popup__body">
          <div className="form-plan form-plan--only">
            <h2 className="form-plan__title">{t('subscription.preview.title', 'Manage your subscription!')}</h2>
            <section className="card-company-ultra form-plan__card-info">
              <div className="card-company-ultra__container card-company-ultra__container--company">
                <Avatar className="card-company-ultra__avatar" src={company.logoUrl} />

                <h2 className="card-company-ultra__title">{company.companyName}</h2>
                <div className="card-company-ultra__info">
                  <div className="card-company-ultra__info-item">
                    <Icon
                      colorFill
                      className="card-company-ultra__info-icon card-company-ultra__info-icon--verified"
                      name="verified"
                    />
                    {t('subscription.preview.logo', 'Crews by Core Pro')}
                  </div>
                </div>
                <div className="card-company-ultra__actions">
                  <Button className="ctrl-btn--color-light ctrl-btn--shadow card-company-ultra__button" onClick={edit}>
                    {t('subscription.preview.buttons.edit', 'Edit billing info')}
                  </Button>
                  <Button className="ctrl-btn--shadow card-company-ultra__button" onClick={upgrade}>
                    {t('subscription.preview.buttons.change', 'Change Plan')}
                  </Button>
                </div>
              </div>
              <div className="card-company-ultra__container card-company-ultra__container--info">
                <div className="lava-list">
                  <div className="lava lava-list__item">
                    <div className="lava__label">{t('subscription.preview.abbr.recipient', 'Recipient')}</div>
                    <div className="lava__value">{t('subscription.preview.abbr.builders', 'Journey Builders LLC')}</div>
                  </div>

                  <div className="lava lava-list__item">
                    <div className="lava__label">
                      {t('subscription.preview.abbr.worker_count', 'Number of workers')}
                    </div>
                    <div
                      className="lava__value lava__value--users"
                      style={{
                        color: !needToUpgrade ? '#01964E' : '#FA380D',
                      }}
                    >
                      <Icon
                        colorFill
                        name="group_equal"
                        className="lava__icon"
                        style={{
                          color: !needToUpgrade ? '#01964E' : '#FA380D',
                        }}
                      />
                      {company.currentSeatCount}
                    </div>
                  </div>

                  <div className="lava lava-list__item">
                    <div className="lava__label">{t('subscription.preview.abbr.licenses', 'Current Licenses')}</div>
                    <div className="lava__value">{subscription?.seatCount || 0}</div>
                  </div>

                  <div className="lava lava-list__item">
                    <div className="lava__label">{t('subscription.preview.abbr.invoice', 'Next invoice on')}</div>
                    <div className="lava__value">
                      {safeFormatDate(subscription?.stripeSubscriptionInfo?.nextInvoice, 'll')}
                    </div>
                  </div>

                  <div className="lava lava-list__item">
                    <div className="lava__label">{t('subscription.preview.abbr.payment', 'Monthly Payment')}</div>
                    <div className="lava__value">
                      {!!subscription?.stripeSubscriptionInfo &&
                        getFormattedCurrency(
                          subscription.stripeSubscriptionInfo.amount,
                          subscription.stripeSubscriptionInfo.currency,
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="form-plan__actions">
              <button className="form-plan__button-cancel" type="button" onClick={cancelSubscription}>
                {t('subscription.preview.buttons.cancel', 'Cancel subscription')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Popup.Body>
  );
};
export default SubscriptionPreview;
