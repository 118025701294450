import * as Sentry from '@sentry/browser';
import classNames from 'classnames';
import {ConfirmationResult, User} from 'firebase/auth';
import {Formik} from 'formik';
import {FormikProps} from 'formik/dist/types';
import {TFunction} from 'i18next';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory} from 'react-router';
import {toast} from 'react-toastify';

import {CompanyApi, WorkerApi} from 'api';
import {SupportedLoginTypes} from 'api/worker';
import InviteSignupForm from 'modules/InviteSignup/InviteSignupForm';
import Firebase from 'services/Firebase/Firebase';
import {Button, Loader, useAuth} from 'shared/components';
import DownloadAppLink from 'shared/components/DownloadAppLink';
import {splitPhoneByCountryCode} from 'shared/constants/common';
import env from 'shared/constants/env';
import {getHumanizedFbError} from 'shared/constants/firebase';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {getLocalizedLink} from 'shared/helpers/common';
import {useAnalyticsService, useParsedQuery} from 'shared/hooks';
import {useMount} from 'shared/hooks/core';
import useUserAgent from 'shared/hooks/useUserAgent';
import {OnboardingLayout} from 'shared/layout/base/OnboardingLayout';
import {InvitedWorker, Worker} from 'shared/models/worker';
import {useRootDispatch} from 'store';
import {profileActions} from 'store/profile';
import {loadWorkerCompanies, loadWorkerCompanyWorkers} from 'store/profile/actions';
import {getAllProjects} from 'store/projects/actions';

import CompanyInfo from '../SignUp/Alternative/GetStarted/DownloadAppContainer/CompanyInfo';

import {emailValidationSchema, getInitialValues, InviteSignUpValues, phoneValidationSchema} from './utils';

const InviteSignup: FC = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation(['invite_signup', 'errors']);
  const routes = useLocalizedRoutes();
  const formik = useRef<FormikProps<InviteSignUpValues>>();
  const [invitedWorkerInfo, setInvitedWorkerInfo] = useState<InvitedWorker>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [worker, setWorker] = useState<Worker>();
  const dispatch = useRootDispatch();
  const {user} = useAuth();
  const history = useHistory();
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>(null);
  const [loginType, setLoginType] = useState<SupportedLoginTypes>('phone');
  const initialLoginType = useRef<SupportedLoginTypes>(null);
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.inviteSignup;
  const userAgent = useUserAgent();

  const {invite_code: inviteCode} = useParsedQuery<{company_id: string; invite_code: string}>({
    schema: {invite_code: 'string'},
    defaultParams: {invite_code: null},
  });

  const onErrorLoadPrefilledData = useCallback(() => {
    toast(t('invite_signup:errors.link_expired', 'The invite link is expired or invalid.'));
    history.push(routes.getStarted);
  }, [t, history, routes]);

  useEffect(() => {
    if (!inviteCode) {
      history.push(routes.getStarted);
    }
  }, [inviteCode, history]);

  useEffect(() => {
    if (user && inviteCode && !invitedWorkerInfo) {
      CompanyApi.getInvitedWorkerByCode(inviteCode).then(setInvitedWorkerInfo).catch(onErrorLoadPrefilledData);
    }
  }, [user, invitedWorkerInfo, inviteCode, onErrorLoadPrefilledData]);

  useEffect(() => {
    initialLoginType.current = invitedWorkerInfo?.email ? 'password' : 'phone';
    if (invitedWorkerInfo?.email) {
      formik.current.setFieldValue('emailAddress', invitedWorkerInfo.email);
      setLoginType(initialLoginType.current);
      mixpanel.track(mixpanelEvents.landByEmail);
    } else if (invitedWorkerInfo?.mobileNumber) {
      const [countryCode, phoneNumber] = splitPhoneByCountryCode(invitedWorkerInfo.mobileNumber);
      formik.current.setValues({...formik.current.values, countryCode, phoneNumber});
      setLoginType(initialLoginType.current);
      mixpanel.track(mixpanelEvents.landByPhone);
    }
  }, [invitedWorkerInfo]);

  useMount(() => {
    if (!user) {
      Firebase.signInAnonymously();
    }
  });

  const onSubmit = async (values: InviteSignUpValues) => {
    setIsLoading(true);
    try {
      let user;
      if (loginType === 'phone') {
        user = await createUserWithPhoneNumber(values.verificationCode);
      } else {
        user = await createUserWithEmailAndPassword(values);
      }
      const worker = await registerWorker(user);
      const updated = await WorkerApi.updateWorkerData(
        {...worker, agreePrivacyPolicy: true, agreeDigestSms: true},
        worker.id,
      );
      const [companies, companyWorkers] = await Promise.all([
        dispatch(loadWorkerCompanies(worker.id)),
        dispatch(loadWorkerCompanyWorkers(worker.id)),
      ]);
      if (!companies.length) {
        history.push(routes.getStarted);
      }
      dispatch(profileActions.setWorker(updated));
      Firebase.setWorkerIdToLS(worker.id);
      if (loadWorkerCompanyWorkers.fulfilled.match(companyWorkers)) {
        if (
          companyWorkers.payload.some((cw) => cw.roles.includes('company_admin') || cw.roles.includes('project_admin'))
        ) {
          if (companyWorkers.payload.find((cw) => cw.companyId === invitedWorkerInfo.companyId)) {
            dispatch(profileActions.setActiveCompany(invitedWorkerInfo.companyId));
          }
          setWorker(worker);
          mixpanel.track(mixpanelEvents.allSetScreen);
          if (userAgent.device.type === 'mobile') {
            history.push(generatePath(routes.mobileAccountComplete));
          }
        }
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const parsedError = extractAxiosError(e);
        if (typeof parsedError === 'string') toast.error(parsedError);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const commonErrorHandler = (error) => {
    Sentry.captureException(error);
    if (error.code === 'auth/email-already-in-use') {
      toast(t('invite_signup:errors.email_already_in_use', 'This email address is already in use. Please, login.'));
    } else {
      toast(getHumanizedFbError(error, t as TFunction));
    }
  };

  const registerWorker = async (user: User) => {
    const token = await user.getIdToken();
    return await WorkerApi.loginOrSignupWorker({user, idToken: token, loginType, inviteCode});
  };

  const createUserWithEmailAndPassword = async (values: InviteSignUpValues) => {
    const email = values.emailAddress;
    const password = values.password;
    try {
      return await Firebase.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      commonErrorHandler(error);
      throw error;
    }
  };

  const createUserWithPhoneNumber = async (verificationCode: string) => {
    try {
      const userCred = await confirmationResult.confirm(verificationCode);
      return userCred.user;
    } catch (error) {
      commonErrorHandler(error);
      throw error;
    }
  };

  const onGoToProject = async () => {
    const projects = await dispatch(getAllProjects(invitedWorkerInfo.companyId));
    if (getAllProjects.fulfilled.match(projects) && projects.payload.length) {
      history.push(generatePath(routes.tasks, {projectId: projects.payload[0]?.id}));
    } else {
      history.push(routes.projects);
    }
  };

  const handleFooterClick = () => {
    if (worker) {
      mixpanel.trackWithAction(onGoToProject, mixpanelEvents.buttons.finish);
    } else {
      mixpanel.trackWithAction(formik.current.handleSubmit, mixpanelEvents.buttons.next);
    }
  };

  return (
    <>
      <OnboardingLayout formClassName="form-onboarding-z--without-nav" headerClear={false}>
        <Formik
          innerRef={formik}
          initialValues={getInitialValues()}
          onSubmit={onSubmit}
          validationSchema={loginType === 'phone' ? phoneValidationSchema : emailValidationSchema}
        >
          {() => (
            <>
              <div className="form-onboarding-z__container">
                {isLoading && <Loader />}
                <header className="form-onboarding-z__header">
                  <h1 className="form-onboarding-z__title">
                    {worker
                      ? t('invite_signup:header.worker_exist.title', "We're all set!")
                      : t('invite_signup:header.worker_does_not_exist.title', 'Create Your Account')}
                  </h1>
                  <CompanyInfo companyInfo={invitedWorkerInfo} />
                  <div className="form-onboarding-z__description">
                    {worker
                      ? t(
                          'invite_signup:header.worker_exist.description',
                          'Download the App - With your new mobile app account, you can see projects, activities, and chat with other crew members. Post updates and photos to your tasks.',
                        )
                      : t(
                          'invite_signup:header.worker_does_not_exist.description',
                          'You`ve been invited to join the crew.',
                        )}
                  </div>
                </header>
                {worker ? (
                  <div className="form-onboarding-z__grid">
                    <div className="form-onboarding-z__item">
                      <div className="woohoo">
                        <picture className="picture woohoo__image">
                          <source srcSet="images/phones/phone-1@1x.png 1x, images/phones/phone-1@2x.png 2x" />
                          <img className="picture__image" src="images/phones/phone-1@1x.png" alt="" />
                        </picture>

                        <section className="download-app woohoo__download">
                          <h3 className="download-app__title">
                            {t('invite_signup:download_app.title', 'Get the App for your Crews')}
                          </h3>
                          <div className="download-app__grid">
                            <DownloadAppLink.iOS
                              baseClassName="ctrl-btn-download"
                              className="download-app__link"
                              onClick={() => mixpanel.track(mixpanelEvents.download.ios)}
                            />
                            <DownloadAppLink.Android
                              baseClassName="ctrl-btn-download"
                              className="download-app__link"
                              onClick={() => mixpanel.track(mixpanelEvents.download.android)}
                            />
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                ) : (
                  !!invitedWorkerInfo && (
                    <InviteSignupForm
                      isAdmin
                      initialLoginType={initialLoginType.current}
                      loginType={loginType}
                      onCodeSent={setConfirmationResult}
                      onLoginTypeChanged={setLoginType}
                    />
                  )
                )}
              </div>
              <footer className="form-onboarding-z__footer">
                {!worker && (
                  <div className="form-onboarding-z__footer-note">
                    {t('invite_signup:footer.notice.1', 'By clicking this button, I agree with')}{' '}
                    <a
                      href={getLocalizedLink(env.legalPages.termsOfService, language)}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => mixpanel.track(mixpanelEvents.links.terms)}
                    >
                      {' '}
                      {t('invite_signup:footer.notice.2', 'Core Terms')}
                    </a>{' '}
                    {t('invite_signup:footer.notice.3', 'of Service and')}{' '}
                    <a
                      href={getLocalizedLink(env.legalPages.privacyPolicy, language)}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => mixpanel.track(mixpanelEvents.links.policy)}
                    >
                      {' '}
                      {t('invite_signup:footer.notice.4', 'Privacy Policy')}
                    </a>
                  </div>
                )}
                {!!worker && (
                  <div className="form-onboarding-z__footer-actions form-onboarding-z__footer-actions--prev">
                    <Button
                      className="ctrl-btn--color-light ctrl-btn--shadow form-onboarding-z__footer-button"
                      onClick={() => {
                        dispatch(profileActions.setCreateNewCompany(true));
                        history.push(routes.companyProfile);
                      }}
                    >
                      {t('invite_signup:footer.actions.createCompany', 'Create my own company')}
                    </Button>
                  </div>
                )}
                <div className="form-onboarding-z__footer-actions form-onboarding-z__footer-actions--next">
                  <Button
                    className={classNames('form-onboarding-z__footer-button', worker && 'ctrl-btn--color-success')}
                    type="button"
                    onClick={handleFooterClick}
                  >
                    {worker
                      ? t('invite_signup:footer.actions.goToProject', 'Go To Project')
                      : t('invite_signup:footer.actions.next', 'Next')}
                  </Button>
                </div>
              </footer>
            </>
          )}
        </Formik>
      </OnboardingLayout>
    </>
  );
};

export default InviteSignup;
