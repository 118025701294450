import cn from 'classnames';
import React, {CSSProperties, PropsWithChildren, ReactNode} from 'react';

import {usePopupScrollHandler} from 'shared/hooks/usePopupScrollHandler';

import Button from '../Button';
import {Icon} from '../index';

import Description from './components/Description';
import s from './NewConfirmation.module.scss';

type Props = {
  title?: string;
  onClose?: () => void;
  description?: string | ReactNode;
  className?: string;
  style?: CSSProperties;
};
const ConfirmationCleanLayout = ({
  onClose,
  description,
  className,
  title,
  style = null,
  children,
}: PropsWithChildren<Props>) => {
  usePopupScrollHandler();
  return (
    <section style={style} className={cn(s.confirmation, className)} onMouseDown={(event) => event.stopPropagation()}>
      <div className={s.confirmation__item}>
        <header className={s.confirmation__header}>
          {!!title && <h2 className={s.confirmation__title}>{title}</h2>}
          {onClose && (
            <Button
              iconOnly
              data-cy="btnConfirmClose"
              className={s['confirmation__button-close']}
              type="button"
              onClick={onClose}
              icon={<Icon name="clear" colorFill className="ctrl-btn-clear__icon" size={24} />}
            >
              Closes
            </Button>
          )}
          {!!description && <Description description={description} />}
        </header>
        {children}
      </div>
    </section>
  );
};
export default ConfirmationCleanLayout;
