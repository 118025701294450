import {ReactNode} from 'react';

import {Logo} from 'shared/components';

import s from './MobileAuthStep.module.scss';

type MobileAuthStepProps = {
  media?: ReactNode;
  children?: ReactNode;
};

const MobileAuthStep = ({media, children}: MobileAuthStepProps) => {
  return (
    <section className={s.mobileAuthStep}>
      <div className={s.mobileAuthStep__box}>
        <Logo className={s.mobileAuthStep__logo} />
        {media && media}
        <div className={s.mobileAuthStep__panel}>
          <div className={s.mobileAuthStep__content}>{children}</div>
        </div>
      </div>
    </section>
  );
};

export default MobileAuthStep;
