import * as Sentry from '@sentry/browser';
import axios from 'axios';
import {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useHistory, generatePath} from 'react-router';
import {toast} from 'react-toastify';

import {ProjectsApi} from 'api';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useLocalizedRoutes} from 'shared/constants/routes';

type UseCloneProjectStatusProps = {
  projectId: string;
  asyncUploadId: string;
};

const POLLING_INTERVAL = 1_000; // 1 second

export const useCloneProjectStatus = ({projectId, asyncUploadId}: UseCloneProjectStatusProps) => {
  const {t} = useTranslation(['project']);
  const [progress, setProgress] = useState<number>(0);
  const [notified, setNotified] = useState<boolean>(false);
  const [status, setStatus] = useState<'finished' | 'failed' | 'idle' | 'running'>('idle');
  const [pollingEnabled, setPollingEnabled] = useState<boolean>(true);
  const previousAsyncUploadIdRef = useRef<string | undefined>();
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const cloneFailure = status === 'failed';
  const cloneFinished = status === 'finished';
  const cloneInProgress = status === 'running';

  const fetchCloneStatus = async () => {
    try {
      return await ProjectsApi.pollExtractResult(projectId, asyncUploadId, (percent) => {
        setProgress(percent || 0);
        setStatus('running');
      });
    } catch (error) {
      throw error;
    }
  };

  const query = useQuery(QUERY_CACHE_KEYS.cloneProjectStatus(projectId, asyncUploadId), fetchCloneStatus, {
    enabled: pollingEnabled && Boolean(asyncUploadId),
    refetchInterval: POLLING_INTERVAL,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    onSuccess: async (data) => {
      if (data.status === 'finished' && !notified) {
        setNotified(true);
        setStatus(data.status);
        setPollingEnabled(false); // Stop polling upon success
        history.push(generatePath(routes.project, {id: data.result.projectId}), {
          afterCloningProject: true,
        });
        toast.success(t('project:tooltip.success.project.cloned', 'Project cloned'));
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      setPollingEnabled(false);
      if (axios.isAxiosError(error)) {
        setStatus('failed');
        toast.error(error.response?.data?.message || error.message);
      }
    },
  });

  useEffect(() => {
    if (asyncUploadId && asyncUploadId !== previousAsyncUploadIdRef.current) {
      setNotified(false);
      setPollingEnabled(true);
    }

    previousAsyncUploadIdRef.current = asyncUploadId;
  }, [asyncUploadId]);

  return {
    ...query,
    progress,
    pollingEnabled,
    cloneFailure,
    cloneFinished,
    cloneInProgress,
    setStatus,
    setProgress,
    setNotified,
  };
};
