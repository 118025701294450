import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';

import {TasksApi} from 'api';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {safeParseFromLocalStorage} from 'shared/helpers/ls';
import {BaselineTasks, TaskObjectSubType, TaskObjectType} from 'shared/models/task';

import {BASELINE_SETTINGS} from '../../../components/ActionsBar/components/BaselineDropdown/utils';
import {getBaselineDateRange, getVisibleDateRange, isPlaceholderTask} from '../../../components/Gantt/utils';

type MappingBaselineTasksById = {
  [key: string]: BaselineTasks;
};

export const baselineClassNames = {
  container: 'gantt-container_baseline',
};

const getMappingBaselineTasksById = (tasks: BaselineTasks[]): MappingBaselineTasksById =>
  tasks.reduce((acc, item) => {
    if (item.id) {
      acc[item.id] = item;
    }
    return acc;
  }, {});

const createBaselineElement = (gantt: GanttStatic, task: GanttTask) => {
  const sizes = gantt.getTaskPosition(task, ...getVisibleDateRange(gantt, getBaselineDateRange(task)));
  const el = document.createElement('div');
  const isMilestone = task.object_type === TaskObjectType.milestone;
  const milestoneLeftOffset =
    task.object_subtype === TaskObjectSubType.end ? sizes.left + sizes.width + 'px' : sizes.left + 'px';
  el.className = isMilestone ? 'baseline-milestone' : 'baseline-task';
  el.dataset.taskId = task.id;
  el.style.left = isMilestone ? milestoneLeftOffset : sizes.left + 'px';
  el.style.width = isMilestone ? '' : sizes.width + 'px';
  el.style.top = (sizes.top || 0) + gantt.config.bar_height + 13 + 'px';
  return el;
};

const addBaselineData = (task: GanttTask, baselineTasksById: MappingBaselineTasksById) => {
  const baselineStart = dayjs(baselineTasksById[task.id]?.baseline_start).startOf('day').toDate();
  const baselineEnd = dayjs(baselineTasksById[task.id]?.baseline_end).startOf('day').toDate();
  task.baseline_start = dayjs(baselineStart).isValid() ? baselineStart : null;
  task.baseline_end = dayjs(baselineEnd).isValid() ? baselineEnd : null;
  task.variance = baselineTasksById[task.id]?.variance;
  return task;
};

export function addBaseLineLayer(gantt: GanttStatic) {
  gantt.config.bar_height = 16;
  const baselineTasks = gantt.baselineTasks;
  const baselineTasksById = getMappingBaselineTasksById(baselineTasks);
  const ganttContainer = document.getElementsByClassName('gantt-container')?.[0];
  const layerId = gantt.addTaskLayer((task: GanttTask) => {
    if (isPlaceholderTask(gantt, task)) return false;
    const extendedTask = addBaselineData(task, baselineTasksById);
    if (extendedTask.baseline_start && extendedTask.baseline_end) {
      return createBaselineElement(gantt, extendedTask);
    }
    return false;
  });
  gantt.dRender();

  if (ganttContainer && !ganttContainer.classList.contains(baselineClassNames.container)) {
    ganttContainer.classList.add(baselineClassNames.container);
  }

  return () => {
    removeBaseLineLayer(gantt, layerId);
  };
}

export function removeBaseLineLayer(gantt: GanttStatic, id: string) {
  const ganttContainer = document.getElementsByClassName(baselineClassNames.container)?.[0];
  if (ganttContainer) ganttContainer.classList.remove(baselineClassNames.container);
  gantt.removeTaskLayer(id);
  gantt.dRender();
}

export const updateTaskVariance = async (gantt: GanttStatic, task: GanttTask) => {
  const baselineTasks = await TasksApi.getBaselineTasks(task.projectId, gantt.baselineCutoff, [task.id]);
  const updatedBaselineTask = baselineTasks.tasks[0];
  if (typeof updatedBaselineTask.variance === 'number') {
    task.variance = updatedBaselineTask.variance;
    const baselineTasks = gantt.baselineTasks;
    gantt.baselineTasks = baselineTasks.map((t) => {
      if (t.id === task.id) {
        t.variance = updatedBaselineTask.variance;
      }
      return t;
    });
  }
  task.meta.variance = false;
  gantt.refreshTask(task.id);
};

export const updateAffectedTasksVariance = async (gantt: GanttStatic, projectId: string, affectedTaskIds: string[]) => {
  // show loader
  gantt.getTaskByTime().forEach((t) => {
    if (affectedTaskIds.includes(t.id)) {
      t.meta.variance = true;
      gantt.refreshTask(t.id);
    }
  });
  const res = await TasksApi.getBaselineTasks(projectId, gantt.baselineCutoff, affectedTaskIds);
  gantt.baselineTasks.forEach((task) => {
    if (affectedTaskIds.includes(task.id)) {
      const updated = res.tasks.find((t) => t.id === task.id);
      if (typeof updated.variance === 'number') {
        task.variance = updated.variance;
        gantt.getTask(task.id).meta.variance = false;
        gantt.refreshTask(task.id);
      }
    }
  });
};

export const isBaseLineMode = () => {
  return !!safeParseFromLocalStorage(BASELINE_SETTINGS);
};
