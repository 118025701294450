import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect} from 'react';

import {TasksApi} from 'api';
import {SortOrder} from 'shared/constants/common';
import {extractAxiosError} from 'shared/helpers/axios';
import {IssueModelRawDTO} from 'shared/models/task';

import {IssuesDictionary} from '../components/Gantt/types';

type UseIssuesDictionaryProps = {
  projectId: string;
  gantt: GanttStatic;
};

export const useIssuesDictionary = ({gantt, projectId}: UseIssuesDictionaryProps) => {
  useEffect(() => {
    getIssues();
    gantt.updateIssuesDictionary = getIssues;
  }, [projectId]);

  const getIssues = async () => {
    try {
      const {data} = await TasksApi.getTaskIssues(projectId, {
        rangeParams: {
          offset: 0,
          limit: 1000,
        },
        sortParams: {
          sortField: 'description',
          sortOrder: SortOrder.DESC,
        },
      });
      preparedIssues(data);
      gantt.render();
    } catch (e) {
      extractAxiosError(e);
    }
  };

  const preparedIssues = (issues: IssueModelRawDTO[]) => {
    const issuesDictionary: IssuesDictionary = {};
    issues.forEach((issue) => {
      issuesDictionary[issue.id] = {
        startDate: dayjs(issue['start_date']).toDate(),
        endDate: issue['end_date'] ? dayjs(issue['end_date']).toDate() : null,
      };
    });
    gantt.issuesDictionary = issuesDictionary;
  };
};
