import {VirtualElement} from '@popperjs/core';
import {GanttStatic} from 'dhtmlx-gantt';
import {FC, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {usePopper} from 'react-popper';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import {Icon, Loader} from 'shared/components';
import {CtrlButton} from 'shared/components/CoreNewUI';
import {RouteParams} from 'shared/constants/routes';
import {toShortIso} from 'shared/helpers/dates';
import {useOutsideClick} from 'shared/hooks/core';
import {useProject} from 'shared/hooks/useProject';
import {useRootDispatch} from 'store';
import * as projectAsyncActions from 'store/projects/actions';
import {updateProject} from 'store/projects/actions';

import {setProjectCalendarExceptions} from '../../utils';

import s from './ProjectCalendarConvertDay.module.scss';

type Props = {
  boundary: VirtualElement;
  gantt: GanttStatic;
  date: Date;
  onClosePopup: () => unknown;
};

const ProjectCalendarConvertDay: FC<Props> = ({boundary, gantt, date, onClosePopup}: Props) => {
  const dispatch = useRootDispatch();
  const {projectId} = useParams<RouteParams['tasks']>();
  const {project} = useProject(projectId);
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation('gantt');

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const {styles, attributes} = usePopper(boundary, popperElement, {
    placement: 'bottom-start',
    modifiers: [{name: 'offset', options: {offset: [0, 0]}}],
  });

  useOutsideClick({
    ref: popperElement ? {current: popperElement} : undefined,
    callback: () => {
      onClosePopup();
    },
  });

  const isWorkDay = useMemo(() => {
    const calendar = gantt.getCalendar(projectId);
    return calendar ? calendar.isWorkTime(date) : false;
  }, [date, projectId]);

  const updateProjectCalendarExceptions = async () => {
    if (isLoading) return;

    setIsLoading(true);
    const {
      calendar: {exceptions},
    } = project;
    const filteredExceptions = exceptions.filter((exp) => new Date(exp.date) !== date);
    const res = await dispatch(
      projectAsyncActions.updateProject({
        ...project,
        calendar: {
          ...project.calendar,
          exceptions: filteredExceptions.concat({date: toShortIso(date), working: !isWorkDay}),
        },
      }),
    );
    if (updateProject.fulfilled.match(res)) {
      setProjectCalendarExceptions(gantt, res.payload);
      gantt.dRender();
      toast.success(t('toast.success.convert_calendar_day', 'Choose date format for Export'));
      onClosePopup();
    } else {
      toast.error(t('toast.error.unexpected', 'Unexpected error, refresh'));
    }
    setIsLoading(false);
  };

  return (
    <div
      className={`loader-container ${s.convertDayPopup}`}
      ref={setPopperElement}
      style={{...styles.popper, minHeight: 'auto'}}
      {...attributes.popper}
      onClick={updateProjectCalendarExceptions}
    >
      {isLoading && <Loader />}
      <Icon name="chrome_calendar" />
      <CtrlButton view="link">
        {isWorkDay
          ? t('convertCalendarDayHoliday', 'Convert to Holiday')
          : t('convertCalendarDayWeekday', 'Convert to Calendar day')}
      </CtrlButton>
    </div>
  );
};

export default ProjectCalendarConvertDay;
