import classNames from 'classnames';
import React, {Children, cloneElement, ReactElement} from 'react';

type ElementWithClassName = ReactElement<{className?: string}>;
type SlidePanelBodyProps = {
  fullHeight?: boolean;
  children: ElementWithClassName | ElementWithClassName[];
};
import css from './slidePanelBody.module.scss';

const SlidePanelBody = ({children, fullHeight = false}: SlidePanelBodyProps) => {
  const childrenArray = Children.toArray(children) as ElementWithClassName[];

  return (
    <div
      className={classNames('slide-panel__body', css.slidePanel__body, fullHeight && css.slidePanel__body_fullHeight)}
    >
      {childrenArray.map((child) =>
        cloneElement(child, {className: classNames(child.props.className, css.slidePanel__content)}),
      )}
    </div>
  );
};
export default SlidePanelBody;
