import React, {FC, useRef} from 'react';
import {Route, RouteComponentProps, RouteProps, useRouteMatch, withRouter} from 'react-router';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import ProtectedRoute from '../ProtectedRoute';

type AnimatedRouteProps = RouteProps & {
  animationName?: string;
  protected?: boolean;
};

const AnimatedRoute: FC<AnimatedRouteProps & RouteComponentProps> = ({
  history,
  location,
  match,
  staticContext,
  protected: protectedRoute = true,
  animationName = 'slide-panel',
  ...routeProps
}) => {
  const nodeRef = useRef();
  const targetRouteMatch = useRouteMatch(typeof routeProps.path === 'string' ? routeProps.path : [...routeProps.path]);
  return (
    <TransitionGroup>
      <CSSTransition
        nodeRef={nodeRef}
        key={targetRouteMatch ? 'animate' : 'not-animate'}
        timeout={300}
        classNames={animationName}
      >
        {protectedRoute ? (
          <ProtectedRoute {...routeProps} location={location} />
        ) : (
          <Route ref={nodeRef} {...routeProps} location={location} />
        )}
      </CSSTransition>
    </TransitionGroup>
  );
};
export default withRouter(AnimatedRoute);
