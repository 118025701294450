import classNames from 'classnames';
import {PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from 'shared/components';
import DownloadAppLink from 'shared/components/DownloadAppLink';
import {getNormalizedWorkerRoles} from 'shared/helpers/worker';
import {useAnalyticsService} from 'shared/hooks';
import {useRootSelector} from 'store';

import {getCompanyWorkersByRole} from './utils/getUniqueWorkerRoles';

interface CreateCompanyDescProps {
  className?: string;
  onCreate?: () => void;
  onLogout?: () => void;
}

export function CreateCompanyDesc({className, onCreate, onLogout}: PropsWithChildren<CreateCompanyDescProps>) {
  const {t} = useTranslation('company');
  const {companyWorkers} = useRootSelector((state) => ({
    companyWorkers: state.profile.companyWorkers,
  }));
  const hasCompanies = !!companyWorkers.length;
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.company;

  const workers = getCompanyWorkersByRole(companyWorkers);

  return (
    <div className={classNames('company-finish', className)}>
      <h2 className="company-finish__title">
        {hasCompanies
          ? `${t('companies_info.part_of_company.title.main', "You're part of the company")}${
              companyWorkers.length > 1
                ? t('companies_info.part_of_company.title.plural_end', 'ies')
                : t('companies_info.part_of_company.title.single_end', 'y')
            } ${t('companies_info.part_of_company.title.end', 'below:')}`
          : t('companies_info.no_company.title', 'Create Company')}
      </h2>
      {hasCompanies && (
        <div className="company-finish__roles">
          {Object.keys(workers).map((companyId) => (
            <div className="company-finish__roles-item" key={workers[companyId].id}>
              <div className="company-finish__roles-company">{workers[companyId].companyName}</div>
              <div className="company-finish__roles-role">
                {getNormalizedWorkerRoles(workers[companyId].roles, t) +
                  ` Role${workers[companyId].roles.length > 1 ? 's' : ''}`}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="company-finish__description">
        {hasCompanies
          ? t(
              'companies_info.part_of_company.description',
              'Install the app to start collaborating with your crew(s) above!',
            )
          : t(
              'companies_info.no_company.description',
              ' You don`t have a company profile. Add your company details to identify your organization.',
            )}
      </div>
      {hasCompanies && (
        <section className="download-app company-finish__download">
          <div className="download-app__grid">
            <DownloadAppLink.iOS baseClassName="ctrl-btn-download" className="download-app__link" />
            <DownloadAppLink.Android baseClassName="ctrl-btn-download" className="download-app__link" />
          </div>
        </section>
      )}
      <div className="company-finish__actions">
        <button className="ctrl-btn-link company-finish__button-logout" type="button" onClick={onLogout}>
          {t('companies_info.actions.logout', 'Logout')}
        </button>

        <div className="company-finish__actions-divider">or</div>
        <Button
          className="company-finish__button-create"
          onClick={() => mixpanel.trackWithAction(onCreate, mixpanelEvents.createYourOwnCompany)}
        >
          {t('companies_info.actions.create_company', 'Create Your Own Company')}
        </Button>
      </div>
    </div>
  );
}
