import {GanttStatic} from 'dhtmlx-gantt';
import {useRef} from 'react';

import {GanttPluginBase} from 'modules/Tasks/components/Gantt/plugins/ganttPlugin';
import {GANTT_COLUMNS_NAMES, isPlaceholderTask} from 'modules/Tasks/components/Gantt/utils';
import {debounce} from 'shared/helpers/debounce';
import {useMount} from 'shared/hooks/core';

export class CheckboxColumnPlugin extends GanttPluginBase {
  private events: string[] = [];
  private initialized: boolean;
  private _allSelected = false;
  get allSelected() {
    return this._allSelected;
  }

  constructor(gantt: GanttStatic) {
    super(gantt);
    gantt.checkPlugin = this;
  }
  init() {
    if (this.initialized) return;
    this.events.push(
      this.gantt.attachEvent(
        'onBeforeMultiSelect',
        (event?: MouseEvent) => {
          if (!event || event.ctrlKey || event.shiftKey) {
            return true;
          } else if (event?.target instanceof Element) {
            if (event.target.closest(`[data-column-name="${GANTT_COLUMNS_NAMES.checkbox}"]`)) {
              const id = event.target.closest<HTMLDivElement>('.gantt_row')?.dataset?.taskId;
              if (id && !isPlaceholderTask(this.gantt, this.gantt.getTask(id))) {
                this.gantt.toggleTaskSelection(id);
                this.gantt.refreshTask(id);
                // duplicate event with event type
                this.gantt.callEvent('onTaskMultiSelect', [id, this.gantt.isSelectedTask(id), event]);
                return false;
              }
            } else {
              return false;
            }
          }
        },
        undefined,
      ),
    );
    this.events.push(
      this.gantt.attachEvent(
        'onGridHeaderClick',
        (name: GANTT_COLUMNS_NAMES, event: MouseEvent) => {
          if (event.target instanceof HTMLElement) {
            const column = this.isCheckboxColumn(event.target);
            if (column) {
              this.toggle(this.getInput()?.checked);
            }
          }
        },
        undefined,
      ),
    );
    this.events.push(
      this.gantt.attachEvent(
        'onTaskMultiSelect',
        debounce(() => {
          let allSelected = true;
          const allTasks = this.gantt.getTaskByTime();
          for (const task of allTasks) {
            if (!this.gantt.isSelectedTask(task.id) && task.type !== this.gantt.config.types.placeholder) {
              allSelected = false;
              break;
            }
          }
          this.getInput().checked = allSelected;
          this._allSelected = allSelected;
        }, 100),
        undefined,
      ),
    );
    this.events.push(
      this.gantt.attachEvent(
        'onGanttRender',
        () => {
          const input = this.getInput();
          input && (input.checked = this.allSelected);
        },
        undefined,
      ),
    );
    this.initialized = true;
  }
  private isCheckboxColumn(et: EventTarget) {
    return (
      et instanceof HTMLElement && et.closest<HTMLDivElement>(`[data-column-id="${GANTT_COLUMNS_NAMES.checkbox}"]`)
    );
  }
  private getInput() {
    return this.gantt.$grid_scale?.querySelector<HTMLInputElement>(
      `[data-column-id="${GANTT_COLUMNS_NAMES.checkbox}"] input`,
    );
  }
  selectAll() {
    this.gantt.eachTask((task) => {
      if (task.type !== this.gantt.config.types.placeholder) {
        this.gantt.selectTask(task.id);
      }
    });
    this.gantt.callEvent('selectAll', []);
  }
  unselectAll() {
    this.gantt.eachSelectedTask((taskId) => {
      this.gantt.unselectTask(taskId);
    });
    this.gantt.callEvent('deselectAll', []);
  }
  toggle(selectAll?: boolean) {
    this.gantt.batchUpdate(() => {
      if (selectAll) {
        this.selectAll();
      } else {
        this.unselectAll();
      }
    });
  }
  clear() {
    this.events.forEach(this.gantt.detachEvent);
  }
}

export function useCheckboxColumnPlugin(gantt) {
  const ref = useRef<CheckboxColumnPlugin>();
  useMount(() => {
    ref.current = new CheckboxColumnPlugin(gantt);
  });

  return ref.current;
}

declare module 'dhtmlx-gantt' {
  export interface GanttCustomProperties {
    checkPlugin?: CheckboxColumnPlugin;
  }
}
