const DEFAULT_FIELD_SEPARATOR = ',';
const DEFAULT_DECIMAL_SEPARATOR = '.';
const EOL = '\r\n';
const CSV_EXTENSION = '.csv';

type ExportToCSVData = Record<string, unknown>[];
export type ExportToCSVOptions = {
  fieldSeparator: string;
  decimalSeparator: string;
  useKeysAsOptions?: boolean;
  headers?: string[];
};
const defaultOptions: ExportToCSVOptions = {
  fieldSeparator: DEFAULT_FIELD_SEPARATOR,
  decimalSeparator: DEFAULT_DECIMAL_SEPARATOR,
  useKeysAsOptions: true,
};
export class ExportToCSV {
  private _csv = '';
  private data: ExportToCSVData;
  private options: ExportToCSVOptions;
  private blob: Blob;
  get csv() {
    return this._csv;
  }
  constructor(private userOptions?: Partial<ExportToCSVOptions>) {
    this.options = Object.assign({}, defaultOptions, userOptions || {});
  }

  public generateCSV(data: Record<string, unknown>[]) {
    this._csv = '';
    this.blob = null;
    this.data = data;
    this._csv += this.getHeaders();
    this._csv += this.getContent();
  }

  public download(name: string, csv?: string, href?: string) {
    this.blob = csv ? new Blob([csv], {type: `text/${CSV_EXTENSION};charset=utf8;`}) : null;
    const link = document.createElement('a');
    link.href = href || URL.createObjectURL(this.blob);
    link.download = name + CSV_EXTENSION;
    link.click();
    setTimeout(function () {
      URL.revokeObjectURL(link.href);
    }, 4e4); // 40s
  }

  private getHeaders() {
    if (this.options.useKeysAsOptions) {
      return this.makeLine(Object.keys(this.data[0])) + EOL;
    }
    return this.makeLine(this.options.headers) + EOL;
  }

  private getContent() {
    const keys = Object.keys(this.data[0]);
    return this.data.reduce((content, dataItem, index) => {
      content += this.makeLine(keys.map((key) => this.prepareValue(dataItem[key])));
      if (index < this.data.length - 1) {
        content += EOL;
      }
      return content;
    }, '');
  }

  private prepareValue(value: unknown) {
    if (typeof value === 'string') return '"' + value.replace(/"/g, '""') + '"';
    if (typeof value === 'boolean') return value ? 'TRUE' : 'FALSE';
    if (value === undefined || value === null) return '';
  }

  private isDouble(value: unknown): value is number {
    return +value === value && value % 1 !== 0;
  }

  private makeLine(items: string[]) {
    return items.join(this.options.fieldSeparator);
  }
}
