import dayjs from 'dayjs';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters';
import {TaskDetailsModelDTO, TaskObjectType} from 'shared/models/task';

import {countActivitiesAndReports, taskHasFeedback} from '../utils/formatting';

import {useCurrentDateReports} from './useCurrentDateReports';
import {useFetchDailiesTasks} from './useFetchDailiesTasks';

export function useDailiesReports() {
  const {projectId} = useParams<{projectId: string}>();
  const {t} = useTranslation('dailies');
  const {queryParams} = useFilterContext();
  const oskMap = useRef<{[osk: string]: TaskDetailsModelDTO}>({});
  const {data, isLoading} = useFetchDailiesTasks(projectId);
  const dateForReport = dayjs(queryParams.schedEndFirst);
  const startOfDay = dateForReport.startOf('day');
  const endOfDay = dateForReport.endOf('day');
  const tasksForCurrentDay = useCurrentDateReports({tasks: data, startOfDay, endOfDay, oskMap});

  const filteredReports = tasksForCurrentDay?.filter((task) => task.objectType !== TaskObjectType.summary);
  const {totalActivities, totalReportedProgress, totalReportedManpower} = countActivitiesAndReports(filteredReports);
  const tasksWithReports = filteredReports?.filter((task) => taskHasFeedback(task));
  const tasksWithNoReports = filteredReports?.filter((task) => !taskHasFeedback(task));
  const hasTasksListForCurrentDay = tasksForCurrentDay?.length > 0;
  const taskWithReportTitle = tasksWithReports?.length ? t('dailies_report.reports_for_activity') : null;
  const taskWithNoReportTitle = tasksWithNoReports?.length ? t('dailies_report.no_reports_for_activity') : null;

  return {
    hasTasksListForCurrentDay,
    isLoading,
    oskMap,
    taskWithNoReportTitle,
    taskWithReportTitle,
    tasksWithNoReports,
    tasksWithReports,
    totalActivities,
    totalReportedManpower,
    totalReportedProgress,
  };
}
