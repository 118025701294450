import {useMutation} from 'react-query';
import {useParams, useRouteMatch} from 'react-router';
import {toast} from 'react-toastify';

import {ProjectsApi} from 'api';
import {SubcontractorFormValues} from 'modules/Subcontractors/utils/utils';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError} from 'shared/helpers/axios';
import {useQueryCache} from 'shared/hooks';
import {CompanyOrgs} from 'shared/models/company';

export const useCreateSubcontractor = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {cacheHelper} = useQueryCache();

  const updateSubcontractorsCache = (response, projectId) => {
    cacheHelper.findRecentPagedQuery(QUERY_CACHE_KEYS.projectSubcontractorsWithPaginate(projectId))?.setData((data) => {
      return cacheHelper.prependToPagedData(data, response);
    });
  };

  return useMutation(
    (values: SubcontractorFormValues) => {
      return ProjectsApi.createOrg(projectId, values);
    },
    {
      onSuccess: (subcontractor) => updateSubcontractorsCache(subcontractor, projectId),
      onError: (error) => {
        const message = extractAxiosError(error);
        if (typeof error === 'string') toast.error(message);
      },
    },
  );
};

export const useUpdateSubcontractor = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {cacheHelper} = useQueryCache();
  const routes = useLocalizedRoutes();
  const match = useRouteMatch<{id: string}>(routes.subcontractor);

  const updateSubcontractorsCache = (response, projectId) => {
    cacheHelper.findRecentPagedQuery(QUERY_CACHE_KEYS.projectSubcontractorsWithPaginate(projectId))?.setData((data) => {
      return cacheHelper.updatePagedData(data, response);
    });
  };

  return useMutation(
    (values: Partial<CompanyOrgs>) => {
      return ProjectsApi.updateOrg(projectId, match?.params?.id, values);
    },
    {
      onSuccess: (subcontractor) => updateSubcontractorsCache(subcontractor, projectId),
    },
  );
};
