import {TaskStates} from 'shared/models/task';

import {useParsedQuery} from './useParsedQuery';

//  this hook used only to get state query value from the url
export function useTasksUrlState() {
  const {state} = useParsedQuery<{state: string}>({
    defaultParams: {state: 'active'},
    schema: {state: 'string'},
  });

  return state as TaskStates;
}
