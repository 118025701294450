import React, {FC} from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';

import {useAuth} from 'shared/components/AuthUserProvider/AuthUserProvider';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useMountedState} from 'shared/hooks/core';

type UnauthenticatedRouteProps = RouteProps;

/* Redirect if user already authenticated, check run only before first mount */
const UnauthenticatedRoute: FC<UnauthenticatedRouteProps> = (props) => {
  const {isAuthenticated} = useAuth();
  const routes = useLocalizedRoutes();
  const isMounted = useMountedState();
  if (!isMounted() && isAuthenticated()) {
    return <Redirect to={routes.projects} />;
  }
  return <Route {...props} />;
};
export default UnauthenticatedRoute;
