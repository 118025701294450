import React, {FC, ReactElement, ReactNode} from 'react';

import {useInitializeMatrixSession} from 'shared/components/ProgressReport/hooks/useInitializeMatrixSession';

import Header from '../Header';

type LayoutProps = {
  children: ReactNode;
  aside?: ReactElement;
};

const Layout: FC<LayoutProps> = ({aside, children}) => {
  useInitializeMatrixSession();
  return (
    <div className="screen">
      <Header />
      <div className="screen__content" style={{backgroundColor: '#f2f2f2'}}>
        {children}
      </div>
      {aside}
    </div>
  );
};
export default Layout;
