import {TaskModelRawDTO, TaskObjectType} from 'shared/models/task';

import {expandDates} from './date-expander';
import {ChangeSet, ProjectCalendar} from './types';

const OSK_SEGMENT_LENGTH = 10;
const OSK_SEGMENT_PLUS_SEPARATOR_LENGTH = 11;

// Types of this function signature are way off.  We need to standardize on whatever comes
// from the openapi document, so the implementation has a natural way to codeshare in python
export function expandUpdate(
  calendar: ProjectCalendar,
  task: Partial<TaskModelRawDTO>,
  updates: Partial<TaskModelRawDTO>,
): {[key: string]: object} {
  const dtUpdates = expandDates(calendar, task, updates);

  // Here should reflow the schedule if dtUpdates rescheduled the top level task

  return dtUpdates;
}

// increment an outline_sort_key of variable segment count of the format "0000000001.0000000001" by 1
// segments must be zero padded to 10 digits
function incrementSortKey(sortKey: string) {
  const segments = sortKey.split('.');
  const lastSegment = segments.pop();
  const nextSegment = `${parseInt(lastSegment, OSK_SEGMENT_LENGTH) + 1}`.padStart(OSK_SEGMENT_LENGTH, '0');
  return [...segments, nextSegment].join('.');
}

/**
 * Determine the parent sort key from a given sort key.
 * @param {string} sortKey Sort key of the task.
 * @return {string} Parent sort key.
 */
function getParentSortKey(sortKey: string): string {
  return sortKey.split('.').slice(0, -1).join('.') || '0';
}

/**
 * Produce changeset necessary to move a task to a new position.
 * @param {Partial<TaskModelRawDTO>[]} existingTasks Full project for context
 * @param {Partial<TaskModelRawDTO>} task Task to be moved
 * @param {string} parentId Target position parent ID
 * @param {number} index Target 0-based index within siblings below parent ID
 * @return {ChangeSet} Changes necessary to move the task
 */
export function expandMove(
  existingTasks: Partial<TaskModelRawDTO>[],
  task: Partial<TaskModelRawDTO>,
  parentId: string,
  index: number,
): ChangeSet {
  let targetParentSortKey = '0';

  // convert an array of tasks whose tree position is defined by outline_sort_key into a tree
  // data structure.  Root nodes have outline_sort_key of the form "0000000001" and children
  // have outline_sort_key of the form "0000000001.0000000001".  The parent sort key of root nodes
  // will be "0"
  const taskTree: {[key: string]: Partial<TaskModelRawDTO>[]} = existingTasks.reduce(
    (tree, task: Partial<TaskModelRawDTO>) => {
      // Get the parent outline sort key
      const parentKey = getParentSortKey(task.outline_sort_key);
      if (parentId === task.id) {
        targetParentSortKey = task.outline_sort_key;
      }

      if (!tree[parentKey]) {
        tree[parentKey] = [];
      }

      tree[parentKey].push(task);

      return tree;
    },
    {} as {[key: string]: Partial<TaskModelRawDTO>[]},
  );

  const changeSet = {};
  if (parentId) {
    const siblings = taskTree[targetParentSortKey] || [];
    const sortedSibs = siblings.sort((a, b) => a.outline_sort_key.localeCompare(b.outline_sort_key));
    // Sort key is that of the task being displaced or 0000000001 if no siblings displaced
    let sortKey: string;
    if (index === 0) {
      const prefix = targetParentSortKey === '0' ? '' : `${targetParentSortKey}.`;
      sortKey = prefix + '0000000001';
    } else if (sortedSibs.length > 0 && index >= sortedSibs.length) {
      sortKey = incrementSortKey(sortedSibs[sortedSibs.length - 1].outline_sort_key);
    } else {
      sortKey = sortedSibs[index]?.outline_sort_key;
    }
    changeSet[task.id] = {outline_sort_key: sortKey};
    while (sortedSibs[index]) {
      sortKey = incrementSortKey(sortKey);
      if (sortedSibs[index].outline_sort_key !== sortKey) {
        changeSet[sortedSibs[index].id] = {outline_sort_key: sortKey};
        // If a task is renumbered, renumber all its child tasks/grandchild tasks/etc
        const subtreeChangeSet = renumberSubtree(
          taskTree,
          sortedSibs[index].outline_sort_key,
          sortKey,
          sortedSibs[index].outline_sort_key,
        );
        Object.assign(changeSet, subtreeChangeSet);
      }
      index += 1;
    }
  }

  return changeSet;
}

function renumberSubtree(
  tree: {[key: string]: Partial<TaskModelRawDTO>[]},
  prefix: string,
  newPrefix: string,
  osk: string,
): ChangeSet {
  const changeSet = {};
  for (const child of tree[osk] || []) {
    changeSet[child.id] = {outline_sort_key: child.outline_sort_key.replace(prefix, newPrefix)};
    Object.assign(changeSet, renumberSubtree(tree, prefix, newPrefix, child.outline_sort_key));
  }
  return changeSet;
}

/**
 * Based on targeted position in the hierarchy, calculate necessary sort key changes to add task.
 * @param {Partial<TaskModelRawDTO>[]} existingTasks Tasks that already exist
 * @param {Partial<TaskModelRawDTO>} task Task being created
 * @param {number | null} parentId Task ID of parent or '0'
 * @param {number} index 0-based offset within sibling list
 * @return {ChangeSet}
 */
export const expandCreate = (
  existingTasks: Partial<TaskModelRawDTO>[],
  task: Partial<TaskModelRawDTO>,
  parentId: string | null,
  index: number,
): ChangeSet => {
  let changeSet: ChangeSet;
  if (parentId) {
    changeSet = expandMove(existingTasks, task, parentId, index);
  } else {
    // Start with a dumb implementation that always puts the new task at the end.
    // for sort key of the style 0000000001.0000000001, find the last task
    const lastTask = existingTasks.sort((a, b) => a.outline_sort_key.localeCompare(b.outline_sort_key)).pop();
    if (lastTask) {
      changeSet = {[task.id]: {outline_sort_key: incrementSortKey(lastTask.outline_sort_key)}};
    } else {
      changeSet = {[task.id]: {outline_sort_key: '0000000001'}};
    }
  }

  return changeSet;
};

function getIndentLevel(osk: string) {
  return osk.split('.').length;
}

function tasksToIndentAttributes(tasks: Partial<TaskModelRawDTO>[]) {
  return tasks
    .map((task) => {
      return {
        id: task.id,
        object_type: task.object_type,
        outline_sort_key: task.outline_sort_key,
        indent_level: getIndentLevel(task.outline_sort_key),
      };
    })
    .sort((a, b) => a.outline_sort_key.localeCompare(b.outline_sort_key));
}

function includeSubtrees(idSet: Set<number | string>, tasks: IndentAttributes[]): void {
  let idx = 0;
  while (idx < tasks.length) {
    if (idSet.has(tasks[idx]['id'])) {
      const prefix = tasks[idx]['outline_sort_key'];
      while (idx < tasks.length && tasks[idx]['outline_sort_key'].startsWith(prefix)) {
        idSet.add(tasks[idx]['id']);
        idx++;
      }
    } else {
      idx++;
    }
  }
}

type IndentAttributes = {
  id: number | string;
  outline_sort_key: string;
  object_type: TaskObjectType;
  indent_level: number;
};

function normalizeIndents(tasks: IndentAttributes[]): void {
  let refIdx = 0;
  let idx = 1;

  while (idx < tasks.length) {
    // Any excessively indented rows get un-indented to one more than parent
    // Ex:  1: 1
    //      3: 1.1.1 -> 2: will be renumbered to 1.1
    //      3: 1.1.2 -> 2: will be renumbered to 1.2
    //      2: 2
    if (tasks[idx]['indent_level'] > tasks[refIdx]['indent_level'] + 1) {
      tasks[idx]['indent_level'] = tasks[refIdx]['indent_level'] + 1;
    } else {
      refIdx = idx;
    }
    idx++;
  }
}

function correctObjectTypes(tasks: IndentAttributes[], updates: ChangeSet): void {
  for (let idx = 0; idx < tasks.length - 1; idx++) {
    // if the immediate successor of a task in the list has indent_level = task.indent_level + 1,
    // the object_type should be TaskObjectType.summary.  If the immediate successor has
    // indent_level <= task.indent_level, the object_type should be TaskObjectType.activity
    const currentTask = tasks[idx];
    const nextTask = tasks[idx + 1];
    if (currentTask.indent_level + 1 === nextTask.indent_level) {
      if (currentTask.object_type !== TaskObjectType.summary) {
        if (!updates[currentTask.id]) {
          updates[currentTask.id] = {};
        }
        updates[currentTask.id].object_type = TaskObjectType.summary;
      }
    } else if (currentTask.indent_level >= nextTask.indent_level) {
      if (currentTask.object_type !== TaskObjectType.activity) {
        if (!updates[currentTask.id]) {
          updates[currentTask.id] = {};
        }
        updates[currentTask.id].object_type = TaskObjectType.activity;
      }
    }
  }
}

function equalIndentSortKey(tasks: IndentAttributes[], idx: number): string {
  const task = tasks[idx];
  const prevTask = tasks[idx - 1];
  const currLevel = getIndentLevel(task.outline_sort_key);

  // Find nearest prior entry in tasks list with the same indent level and if
  // necessary increment from there.  Otherwise, leave it alone
  let nextOsk = task.outline_sort_key;

  for (let siblingIdx = idx - 1; siblingIdx >= 0; siblingIdx--) {
    if (task.indent_level === tasks[siblingIdx].indent_level) {
      if (task.outline_sort_key <= tasks[siblingIdx].outline_sort_key || currLevel !== task.indent_level) {
        nextOsk = incrementSortKey(prevTask.outline_sort_key);
      }
      break;
    }
  }

  return nextOsk;
}

function incrIndentSortKey(tasks: IndentAttributes[], idx: number): string {
  const task = tasks[idx];
  const prevTask = tasks[idx - 1];
  const currLevel = task.outline_sort_key.split('.').length;
  const prefix = task.outline_sort_key.slice(0, -1 * OSK_SEGMENT_PLUS_SEPARATOR_LENGTH);

  // Try to reuse the current sort key. Possible only if it's not changing indent level
  // and if this task is
  if (currLevel === task.indent_level) {
    if (prefix && prevTask.outline_sort_key.startsWith(prefix)) {
      // Already was at the correct indent. Can keep the current sort key
      return task.outline_sort_key;
    }
  }

  return prevTask.outline_sort_key + '.0000000001';
}

function decrIndentSortKey(tasks: IndentAttributes[], idx: number): string {
  const task = tasks[idx];
  const currLevel = task.outline_sort_key.split('.').length;

  // 1 to several outdents relative to prev_task.  Scan backward looking
  // for same-level sibling and increment that sortkey.  If possible, keep
  // the existing outline_sort_key if it's greater than the same-level sibling
  let nextOsk = task.outline_sort_key;

  for (let siblingIdx = idx - 1; siblingIdx >= 0; siblingIdx--) {
    if (task.indent_level === tasks[siblingIdx].indent_level) {
      if (currLevel !== task.indent_level || task.outline_sort_key <= tasks[siblingIdx].outline_sort_key) {
        // task changed indent level or curr OSK isn't higher than sibling
        nextOsk = incrementSortKey(tasks[siblingIdx].outline_sort_key);
      }
      break;
    }
  }

  return nextOsk;
}

function getNextOSK(tasks: IndentAttributes[], idx: number): string {
  const task = tasks[idx];
  const prevTask = tasks[idx - 1];

  if (prevTask.indent_level === task.indent_level) {
    return equalIndentSortKey(tasks, idx);
  } else if (prevTask.indent_level + 1 === task.indent_level) {
    return incrIndentSortKey(tasks, idx);
  } else if (task.indent_level < prevTask.indent_level) {
    return decrIndentSortKey(tasks, idx);
  } else {
    throw new Error(
      `${task.id} indent ${task.indent_level} too much more than previous task ${prevTask.id} indent ${prevTask.indent_level}`,
    );
  }
}

function renumberIndents(tasks: IndentAttributes[]): ChangeSet {
  const updates: ChangeSet = {};

  normalizeIndents(tasks);

  for (let idx = 1; idx < tasks.length; idx++) {
    const nextOsk = getNextOSK(tasks, idx);
    if (!nextOsk) {
      continue;
    }

    const task = tasks[idx];
    if (task.outline_sort_key !== nextOsk) {
      updates[task.id] = {outline_sort_key: nextOsk};
      // apply sort key to in-memory set so next iteration of the loop will increment
      // based on the updated value
      task.outline_sort_key = nextOsk;
    }
  }

  correctObjectTypes(tasks, updates);

  return updates;
}

function preValidateIndents(tasks: IndentAttributes[]): void {
  const errors: IndentOutdentValidationFailure[] = [];
  for (let idx = 1; idx < tasks.length; idx++) {
    const task = tasks[idx];
    const prevTask = tasks[idx - 1];

    if (task.indent_level > prevTask.indent_level + 1) {
      errors.push({
        taskId: tasks[idx].id as string,
        errorCode: 'excess_indent',
      });
    }
  }

  if (errors.length) {
    throw new IndentOutdentError(errors);
  }
}

export function expandIndent(tasks: Partial<TaskModelRawDTO>[], taskIds: string[]): ChangeSet {
  const idSet = new Set<number | string>(taskIds);

  // Prepare attributes needed to compute indent change
  const tasksForIndent = tasksToIndentAttributes(tasks);

  // include all subtrees of the selected tasks
  includeSubtrees(idSet, tasksForIndent);

  // increment indentLevel by 1 if the id is in idSet
  for (const task of tasksForIndent) {
    if (idSet.has(task.id)) {
      task.indent_level += 1;
    }
  }

  preValidateIndents(tasksForIndent);

  return renumberIndents(tasksForIndent);
}

function validateOutdents(tasks: IndentAttributes[]): IndentOutdentValidationFailure[] {
  const errors: IndentOutdentValidationFailure[] = [];

  for (let idx = 0; idx < tasks.length; idx++) {
    if (tasks[idx].indent_level <= 0) {
      errors.push({
        taskId: tasks[idx].id as string,
        errorCode: 'excess_outdent',
      });
    }
  }

  return errors;
}

type IndentOutdentValidationFailure = {
  taskId: string;
  errorCode: string;
};

export class IndentOutdentError extends Error {
  validationErrors: IndentOutdentValidationFailure[];
  constructor(public errors: IndentOutdentValidationFailure[]) {
    super('Outdent errors');
    this.validationErrors = errors;
  }
}

function getLineageList(osk: string): string[] {
  const retList = [];

  if (!osk) {
    return retList;
  }

  while (osk.length > OSK_SEGMENT_PLUS_SEPARATOR_LENGTH) {
    osk = osk.slice(0, -1 * OSK_SEGMENT_PLUS_SEPARATOR_LENGTH);
    retList.push(osk);
  }

  return retList;
}

function validateNoOrphans(tasks: IndentAttributes[], updates: ChangeSet): IndentOutdentValidationFailure[] {
  const errors: IndentOutdentValidationFailure[] = [];

  const oskMap = {};
  for (const task of tasks) {
    const id = task.id;
    if (id in updates) {
      oskMap[id] = updates[id].outline_sort_key;
    } else {
      oskMap[id] = task.outline_sort_key;
    }
  }

  const oskSet = new Set(Object.values(oskMap));

  for (const task of tasks) {
    const id = task.id;
    const osk = task.outline_sort_key;
    const lineage = getLineageList(osk);
    for (const parentOsk of lineage) {
      if (!oskSet.has(parentOsk)) {
        errors.push({
          taskId: id as string,
          errorCode: 'task_orphaned',
        });
      }
    }
  }

  return errors;
}

export function expandOutdent(tasks: Partial<TaskModelRawDTO>[], taskIds: string[]): ChangeSet {
  const idSet = new Set<number | string>(taskIds);

  // Prepare attributes needed to compute indent change
  const tasksForIndent = tasksToIndentAttributes(tasks);

  // include all subtrees of the selected tasks
  includeSubtrees(idSet, tasksForIndent);

  // increment indentLevel by 1 if the id is in idSet
  for (const task of tasksForIndent) {
    if (idSet.has(task.id)) {
      task.indent_level -= 1;
    }
  }

  let errors = validateOutdents(tasksForIndent);
  if (errors.length) {
    throw new IndentOutdentError(errors);
  }

  const changeSet = renumberIndents(tasksForIndent);

  errors = validateNoOrphans(tasksForIndent, changeSet);
  if (errors.length) {
    throw new IndentOutdentError(errors);
  }

  return changeSet;
}
