import {TaskStatus} from 'shared/models/task';

export const statusColors: Record<TaskStatus, string> = {
  [TaskStatus.tba]: '#ff7c43',
  [TaskStatus.assigned]: '#bc5eaa',
  [TaskStatus.inProgress]: '#4764a4',
  [TaskStatus.rework]: '#ffd777',
  [TaskStatus.blocked]: '#de425b',
  [TaskStatus.done]: '#45cba3',
  [TaskStatus.verified]: '#88b388',
  [TaskStatus.approved]: '#0b991b',
  [TaskStatus.archived]: '#818181',
  [TaskStatus.closed]: '#000000',
  [TaskStatus.unblocked]: '#cccccc', // TODO: need color
  [TaskStatus.notDone]: '#cccccc', // TODO: need color
};

export function getTaskColorByStatus(taskStatus: TaskStatus, fallbackColor = '#A1A1A1'): string {
  const MAP_TASK_STATUS_TO_COLOR = {
    [TaskStatus.tba]: '#A1A1A1',
    [TaskStatus.assigned]: '#122D8B',
    [TaskStatus.inProgress]: '#5779F2',
    [TaskStatus.unblocked]: '#5779F2',
    [TaskStatus.blocked]: '#FA380D',
    [TaskStatus.done]: '#63E37F',
    [TaskStatus.notDone]: '#DB9F06',
    [TaskStatus.verified]: '#089326',
    [TaskStatus.closed]: '#282828',
  };
  return MAP_TASK_STATUS_TO_COLOR[taskStatus] || fallbackColor;
}

export const orderedStatusList: TaskStatus[] = [
  TaskStatus.closed,
  TaskStatus.archived,
  TaskStatus.approved,
  TaskStatus.verified,
  TaskStatus.done,
  TaskStatus.blocked,
  TaskStatus.unblocked,
  TaskStatus.notDone,
  TaskStatus.rework,
  TaskStatus.inProgress,
  TaskStatus.assigned,
  TaskStatus.tba,
];
