import {useCallback, useEffect, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router';

import {useTasksActiveTabStore} from 'modules/Tasks/components/Gantt/hooks/useGanttLayoutSettings';
import {TasksViewMode} from 'shared/constants/common';

export function useQueryViewMode() {
  const {search} = useLocation();
  return useMemo(() => new URLSearchParams(search).get('view') as TasksViewMode, [search]);
}
export function useViewMode(projectId: string) {
  const history = useHistory();
  const [getStoredActiveTab, updateStoredActiveTab] = useTasksActiveTabStore(projectId);
  const queryMode = useQueryViewMode();
  const currentView = queryMode || getStoredActiveTab() || TasksViewMode.lookahead;

  useEffect(() => {
    updateStoredActiveTab(queryMode);
  }, [queryMode]);

  const updateView = useCallback(
    (view: TasksViewMode) => {
      const search = new URLSearchParams(history.location.search);
      search.set('view', view);
      history.push({
        ...history.location,
        search: search.toString(),
      });
    },
    [history],
  );

  return [currentView, updateView] as const;
}
