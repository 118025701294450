import {useParams} from 'react-router';

import {WorkersApi} from 'api';
import {useCompany} from 'shared/hooks';

import {useCompanyOrgs, useCompanyWorker, useCompanyWorkerProjects, useCompanyWorkerTasks} from './queries';
import WorkerDetails from './WorkerDetails';

const inviteMember = WorkersApi.inviteToCompany.bind(WorkersApi);
const updateMember = WorkersApi.updateInvitedCompanyWorker.bind(WorkersApi);

const CompanyWorker = () => {
  const company = useCompany();
  const {projectId, id: workerId} = useParams<{projectId: string; id: string}>();
  const orgsOptions = useCompanyOrgs(company?.id, !projectId);
  const {data: worker, refetch: refetchProfile} = useCompanyWorker(company?.id, workerId);
  const {data: projects} = useCompanyWorkerProjects(company?.id, workerId);

  const {data: workersTasks, isFetching: isFetchingTasks} = useCompanyWorkerTasks(company?.id, workerId);

  return (
    <WorkerDetails
      worker={worker}
      projects={projects}
      refetchProfile={refetchProfile}
      orgsOptions={orgsOptions}
      workerTasks={workersTasks}
      isFetchingTasks={isFetchingTasks}
      inviteMember={inviteMember}
      updateMember={updateMember}
    />
  );
};

export default CompanyWorker;
