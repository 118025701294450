import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';

import {TasksApi} from 'api';
import {Activity} from 'modules/Tasks/components/Gantt/types';
import {ObserverAction, ObserverActionSource, useTasksObserver} from 'services/TasksObserver';
import {Loader} from 'shared/components';
import {CtrlButton} from 'shared/components/CoreNewUI';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useAnalyticsService} from 'shared/hooks';
import {IssueModel, TaskProjection} from 'shared/models/task';

import {NEW_ACTIVITY_ID} from '../../constants';

import s from './ActivitiesList.module.scss';
import {ActivityListItem} from './ActivityListItem';
import {mapActivitiesToSelectTaskOptions} from './utils/mapActivitiesToSelectTaskOptions';

type ActivitiesListProps = {
  issue: IssueModel;
  issueLoading: boolean;
  projectId: string;
  updateActivities?: (values: Activity[]) => void;
  updateTaskIds: (values: string[]) => void;
};

export type SelectTaskOption = {
  value: string;
  uniqId: string;
  label: string;
};

const ActivitiesList = ({issue, issueLoading, projectId, updateActivities, updateTaskIds}: ActivitiesListProps) => {
  const [activities, setActivities] = useState<SelectTaskOption[]>([]);
  const [creating, setCreating] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const mixpanelEvents = mixpanel.events.issues.sidePanel;
  const {t} = useTranslation(['task', 'common']);
  const disabled = creating || saving || deleting;
  const observer = useTasksObserver();

  const {isFetching} = useQuery(
    QUERY_CACHE_KEYS.linkedActivitiesWithIssue(issue?.id),
    async () => {
      if (!issue?.taskIds?.length) return [];
      const {data} = await TasksApi.getProjectTasks({
        params: {ids: issue.taskIds, objectTypeList: ['activity'], projectId: projectId},
      });
      setActivities(mapActivitiesToSelectTaskOptions(data));
      updateActivities(data.map((act) => ({name: act.name, id: act.id})));
      return data;
    },
    {
      enabled: !!projectId && !!issue?.id,
      refetchOnWindowFocus: false,
    },
  );

  const onBindNewActivity = async () => {
    setCreating(true);
    const newTask = {value: NEW_ACTIVITY_ID, label: '', uniqId: ''};
    setActivities([newTask, ...activities]);
    setCreating(false);
  };

  const updateCache = (ids: string[]) => {
    observer.load({
      ids,
      projectId,
      source: ObserverActionSource.taskDetails,
    });
  };

  const handleUpdate = async (newActivity: SelectTaskOption, oldActivity: SelectTaskOption, isNew: boolean) => {
    mixpanel.track(mixpanelEvents.actions.linkActivity);
    try {
      setSaving(true);
      let filteredIds = [];
      let updatedTasks = [];

      if (isNew) {
        filteredIds = [newActivity.value, ...issue.taskIds];
        updatedTasks = activities.map((task) => (task.value === NEW_ACTIVITY_ID ? newActivity : task));
      } else {
        filteredIds = issue.taskIds.filter((_id) => _id !== oldActivity.value);
        filteredIds.push(newActivity.value);
        updatedTasks = activities.map((task) => (task.value === oldActivity.value ? newActivity : task));
      }
      const payload = {
        id: issue.id,
        projectId,
        taskIds: [...filteredIds],
      };
      await TasksApi.updateIssue(payload);
      if (isNew) {
        toast.success(t('task:activities_list.link.success', 'Activity linked.'));
      } else {
        toast.success(t('task:activities_list.update.success', 'Activity updated.'));
      }
      updateTaskIds(filteredIds);
      const updatedActivities = updatedTasks.map((task) => ({name: task.label, id: task.value}));
      updateActivities(updatedActivities);
      observer.emit([{data: {...issue, activities: updatedActivities}}], {
        projection: TaskProjection.taskDetail,
        projectId: projectId,
        action: ObserverAction.update,
        source: ObserverActionSource.taskDetails,
      });
      setActivities(updatedTasks);
      updateCache([newActivity.value, oldActivity.value].filter((actId) => actId !== NEW_ACTIVITY_ID));
    } catch (err) {
      if (isNew) {
        toast.error(t('task:activities_list.link.error', 'Activity linking error.'));
      } else {
        toast.error(t('task:activities_list.update.error', 'Activity updated error.'));
      }
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async (id: string) => {
    mixpanel.track(mixpanelEvents.actions.unlinkActivity);
    try {
      setDeleting(true);
      const filteredIds = issue.taskIds.filter((_id) => _id !== id);
      const filteredTasks = activities.filter((task) => task.value !== id);
      await TasksApi.updateIssue({id: issue.id, projectId, taskIds: filteredIds});
      toast.success(t('task:activities_list.delete.success', 'Activity unlinked.'));
      const updatedActivities = filteredTasks.map((task) => ({name: task.label, id: task.value}));
      setActivities(filteredTasks);
      updateActivities(updatedActivities);
      observer.emit([{data: {...issue, activities: updatedActivities}}], {
        projection: TaskProjection.taskDetail,
        projectId: projectId,
        action: ObserverAction.update,
        source: ObserverActionSource.taskDetails,
      });
      updateTaskIds(filteredIds);
      updateCache([id]);
    } catch (err) {
      toast.error(t('task:activities_list.delete.error', 'Activity unlinking error.'));
    } finally {
      setDeleting(false);
    }
  };

  const handleBlur = (id: string) => {
    if (id === NEW_ACTIVITY_ID) {
      const updatedTasks = activities.filter((task) => task.value !== NEW_ACTIVITY_ID);
      setActivities(updatedTasks);
    }
  };

  return (
    <div className={s.activitiesList}>
      <div className={s.activitiesList__header}>
        <p className={s.activitiesList__title}>{t('task:activities_list.title', 'Activities')}</p>
        <CtrlButton view="link" onClick={onBindNewActivity} disabled={disabled}>
          {t('task:activities_list.add_activity', 'Bind Activity')}
        </CtrlButton>
      </div>
      {isFetching || issueLoading ? (
        <div className={s.activitiesList__loader}>
          <Loader />
        </div>
      ) : (
        <div className={s.activitiesList__list}>
          {activities.map((activity) => (
            <ActivityListItem
              key={activity.value}
              issueId={issue.id}
              disabled={disabled}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
              onBlur={handleBlur}
              activity={activity}
              tasksIds={issue?.taskIds}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ActivitiesList;
