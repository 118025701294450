import {GanttStatic} from 'dhtmlx-gantt';
import {FormikProps} from 'formik';
import {FC, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import ShortWorkerForm from 'modules/Worker/WorkerForm/ShortWorkerForm';
import {Button, Popup} from 'shared/components';
import {TaskDetailsModelDTO} from 'shared/models/task';
import {CompanyWorker, WorkerFormValues} from 'shared/models/worker';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
  afterChangeResponsible: (task: TaskDetailsModelDTO) => void;
  invite: (gantt: GanttStatic, task: GanttTask, worker: CompanyWorker) => Promise<TaskDetailsModelDTO>;
};

const InviteTaskResponsible: FC<Props> = ({gantt, task, afterChangeResponsible, invite}: Props) => {
  const history = useHistory();
  const {t} = useTranslation('gantt');
  const location = useLocation();
  const formik = useRef<FormikProps<WorkerFormValues>>(null);

  const hideForm = () => {
    const {state} = location;
    if (state['inviteTaskResponsible']) {
      delete state['inviteTaskResponsible'];
      history.replace({state});
    }
  };

  const sendInvite = async () => {
    const worker = (await formik.current.submitForm()) as unknown;
    if (worker) {
      const updTask = await invite(gantt, task, worker);
      afterChangeResponsible(updTask);
      hideForm();
    }
  };

  return (
    <Popup visible={true} onClose={hideForm} style={{maxWidth: '500px'}} closeOnOutsideClick={false}>
      <Popup.Header>
        <h3>{t('responsible.invite.popup.title', 'Invite Responsible')}</h3>
      </Popup.Header>
      <Popup.Body>
        <ShortWorkerForm ref={formik} projectId={task.projectId} />
      </Popup.Body>
      <Popup.Footer>
        <Button data-cy="invite_responsible_submit_btn" className="popup__button" onClick={sendInvite}>
          {t('responsible.invite.popup.submit_btn', 'Send')}
        </Button>
      </Popup.Footer>
    </Popup>
  );
};
export default InviteTaskResponsible;
