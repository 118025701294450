import cn from 'classnames';

import {Icon} from 'shared/components';

import s from './CtrlColor.module.scss';

type CtrlColorProps = {
  className?: string;
  colorName: string;
  inputName: string;
  selectColor?: (color: string) => void;
  checked?: boolean;
  colorId?: string;
};

export const CtrlColor = ({className, colorName, inputName, selectColor, checked, colorId}: CtrlColorProps) => {
  return (
    <label className={cn(s.ctrlColor, className)} onClick={() => selectColor(colorId)}>
      <input className={s.ctrlColor__field} type="radio" name={inputName} checked={checked} />
      <span className={s.ctrlColor__visualBox}>
        <span className={s.ctrlColor__visual} style={{backgroundColor: colorName}}>
          &nbsp;
        </span>
      </span>
      <span className={s.ctrlColor__iconBox}>
        <Icon className={s.ctrlColor__icon} name="check" colorFill />
      </span>
      <span className={s.ctrlColor__text}>Select color: {colorName}</span>
    </label>
  );
};
