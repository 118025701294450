import {useState, useEffect, useMemo} from 'react';

export interface UseCropperOptions {
  originalUrl?: string;
  previewUrl?: string;
  edit?: boolean;
}
export function useCropper({originalUrl, previewUrl, edit}: UseCropperOptions = {}) {
  const [selectedAvatar, setSelectedAvatar] = useState<File | string>(originalUrl);
  const [avatarPreview, setAvatarPreview] = useState<string>(previewUrl);
  const [croppedAvatar, setCroppedAvatar] = useState<Blob>(null);
  const [cropperZoom, setCropperZoom] = useState<number>(0);
  const [cropperRotate, setCropperRotate] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(edit);

  useEffect(() => {
    if (previewUrl) {
      setAvatarPreview(previewUrl);
    }
    if (originalUrl) {
      setSelectedAvatar(originalUrl);
    }
  }, []);

  useEffect(() => {
    let url;
    if (croppedAvatar) {
      url = setAvatarPreview(URL.createObjectURL(croppedAvatar));
    }
    return () => url && URL.revokeObjectURL(url);
  }, [croppedAvatar]);

  const selectedAvatarURL = useMemo(
    () => (selectedAvatar instanceof File ? URL.createObjectURL(selectedAvatar) : selectedAvatar),
    [selectedAvatar],
  );

  const selectedAvatarName = useMemo(
    () =>
      selectedAvatar instanceof File
        ? selectedAvatar.name
        : typeof selectedAvatar === 'string'
        ? selectedAvatar.substring(selectedAvatar.lastIndexOf('/' + 1))
        : undefined,
    [selectedAvatar],
  );

  const selectedAvatarType = useMemo(
    () => (selectedAvatar instanceof File ? selectedAvatar.type : undefined),
    [selectedAvatar],
  );

  function reset() {
    setSelectedAvatar(null);
    setAvatarPreview(null);
    setCroppedAvatar(null);
    setCropperZoom(0);
    setCropperRotate(0);
    setIsEdit(false);
  }

  function resetImageMeta(cancelPress?: boolean) {
    if (cancelPress) {
      setAvatarPreview(previewUrl);
      setCroppedAvatar(null);
    }
    setCropperZoom(0);
    setCropperRotate(0);
    setIsEdit(false);
  }

  return {
    reset,
    resetImageMeta,
    selectedFile: selectedAvatar,
    selectedFileUrl: selectedAvatarURL,
    selectedFileName: selectedAvatarName,
    selectedFileType: selectedAvatarType,
    setSelectedFile: setSelectedAvatar,
    preview: avatarPreview,
    croppedFile: croppedAvatar,
    setCropperFile: setCroppedAvatar,
    zoom: cropperZoom,
    setZoom: setCropperZoom,
    rotate: cropperRotate,
    setRotate: setCropperRotate,
    edit: isEdit,
    setEdit: setIsEdit,
  };
}
