import {Field, FieldProps, useFormikContext} from 'formik';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';

import {CompanyFormValues} from 'modules/CompanyProfile/types';
import {FormControl, Geocoder} from 'shared/components';
import CoreSelectField from 'shared/components/CoreForm/Select/Select';
import AvatarCrop from 'shared/components/Cropper/Cropper';
import {useCropper} from 'shared/components/Cropper/useCropper';
import {COMPANY_SIZE_OPTIONS, COUNTRY_OPTIONS} from 'shared/constants/common';

type CompanyFormProps = {
  logoUrl: string;
  isNewCompany: boolean;
};

const CompanyForm: FC<CompanyFormProps> = ({logoUrl, isNewCompany = false}) => {
  const {t} = useTranslation('company');
  const {setFieldValue, values} = useFormikContext<CompanyFormValues>();
  const cropper = useCropper({originalUrl: logoUrl, previewUrl: logoUrl, edit: true});

  const onAvatarChange = (file: File) => {
    cropper.setSelectedFile(file);
    if (!file) {
      setFieldValue('logo', null);
    }
  };
  return (
    <form className="form-default">
      <div className="form-default__container">
        <header className="form-default__header">
          <h2 className="form-default__title">{t('slide_panel.edit_mode.form.title', 'Edit Company Profile')}</h2>
        </header>
        <div className="form-default__grid">
          <div className="form-default__item form-default__item--full">
            <Field name="logoUrl">
              {() => {
                return (
                  <AvatarCrop
                    classNames="form-edit-resume__item"
                    previewImg={cropper.preview}
                    image={cropper.selectedFileUrl}
                    fileType={cropper.selectedFileType}
                    onImageChange={onAvatarChange}
                    edit={cropper.edit}
                    onEdit={cropper.setEdit}
                    zoom={cropper.zoom}
                    rotate={cropper.rotate}
                    onCropped={(file) => {
                      cropper.setCropperFile(file);
                      setFieldValue('logo', file);
                    }}
                    onRotate={cropper.setRotate}
                    onZoom={cropper.setZoom}
                  />
                );
              }}
            </Field>
          </div>
          <div className="form-default__item form-default__item--full">
            <FormControl name="companyName" label={t('slide_panel.edit_mode.form.companyName.label', 'Company Name')}>
              <Field
                as="input"
                className="ctrl-textfield"
                type="text"
                placeholder={t('slide_panel.edit_mode.form.companyName.placeholder', 'Company Name')}
              />
            </FormControl>
          </div>
          <div className="form-default__item form-default__item--full">
            <FormControl
              name="businessUrl"
              label={t('slide_panel.edit_mode.form.businessUrl.label', 'Company Website')}
            >
              <Field
                as="input"
                className="ctrl-textfield"
                type="text"
                placeholder={t('slide_panel.edit_mode.form.businessUrl.placeholder', 'Company Website')}
              />
            </FormControl>
          </div>
          {isNewCompany ? (
            <>
              <div className="form-default__item form-default__item--full">
                <FormControl
                  name="companyCountry"
                  label={t('slide_panel.edit_mode.form.companyCountry.label', 'Country')}
                >
                  <Field name="companyCountry">
                    {({field, form}: FieldProps) => (
                      <CoreSelectField
                        {...field}
                        options={COUNTRY_OPTIONS}
                        placeholder={t('slide_panel.edit_mode.form.companyCountry.placeholder', 'Select Country')}
                        onChange={(value) => {
                          form.setValues({...values, [field.name]: value, companyState: '', companyCity: ''});
                        }}
                      />
                    )}
                  </Field>
                </FormControl>
              </div>
              <div className="form-default__item form-default__item--full">
                <FormControl name="companyState" label={t('slide_panel.edit_mode.form.companyState.label', 'State')}>
                  <Geocoder
                    onChanged={(item) => {
                      setFieldValue('companyState', item);
                    }}
                    placeholder={t('slide_panel.edit_mode.form.companyState.placeholder', 'Enter State')}
                    mapboxQueryTypes={['region']}
                    formatItem={(item) => item.text}
                    value={values.companyState}
                    hideOnSelect={true}
                    disableInput={!values.companyCountry}
                    allowCustom={true}
                    selectedCountry={values.companyCountry}
                    limit={3}
                  />
                </FormControl>
              </div>
              <div className="form-default__item form-default__item--full">
                <FormControl name="companyCity" label={t('slide_panel.edit_mode.form.companyCity.label', 'City')}>
                  <Geocoder
                    onChanged={(item) => {
                      setFieldValue('companyCity', item);
                    }}
                    placeholder={t('slide_panel.edit_mode.form.companyCity.placeholder', 'Enter City')}
                    mapboxQueryTypes={['place']}
                    formatItem={(item) => item.text}
                    value={values.companyCity}
                    disableInput={!values.companyCountry || !values.companyState}
                    hideOnSelect={true}
                    allowCustom={true}
                    selectedCountry={values.companyCountry}
                    selectedState={values.companyState}
                    limit={3}
                  />
                </FormControl>
              </div>
            </>
          ) : (
            <div className="form-default__item form-default__item--full">
              <FormControl
                name="companyAddress"
                label={t('slide_panel.edit_mode.form.companyAddress.label', 'Location')}
              >
                <Field
                  as="input"
                  className="ctrl-textfield"
                  type="text"
                  placeholder={t('slide_panel.edit_mode.form.companyAddress.placeholder', 'Location')}
                />
              </FormControl>
            </div>
          )}
          <div className="form-default__item form-default__item--full">
            <FormControl
              name="pitchText"
              label={t('slide_panel.edit_mode.form.companyDescription.label', 'Company Description')}
            >
              <Field>
                {({field}: FieldProps) => {
                  return (
                    <TextareaAutosize
                      style={{overflowY: 'hidden'}}
                      className="ctrl-textfield"
                      placeholder={t('slide_panel.edit_mode.form.companyDescription.placeholder', 'Description')}
                      maxLength={200}
                      {...field}
                    />
                  );
                }}
              </Field>
            </FormControl>
          </div>
          {isNewCompany && (
            <div className="form-default__item form-default__item--full">
              <FormControl
                name="companySize"
                label={t('slide_panel.edit_mode.form.companySize.label', 'Number of Workers')}
              >
                <Field name="companySize">
                  {({field, form}: FieldProps) => (
                    <CoreSelectField
                      {...field}
                      options={COMPANY_SIZE_OPTIONS}
                      maxMenuHeight={220}
                      menuPlacement="auto"
                      placeholder={t('slide_panel.edit_mode.form.companySize.placeholder', 'Select Number of Workers')}
                      onChange={(value) => form.setFieldValue(field.name, value)}
                    />
                  )}
                </Field>
              </FormControl>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};
export default CompanyForm;
