import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {camelize} from 'humps';
import React, {useCallback, useRef, useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import {createPortal} from 'react-dom';
import {usePopper} from 'react-popper';

import {useGanttContext} from 'modules/Tasks/components/Gantt/components/GanttContext';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {GANTT_COLUMNS_NAMES} from 'modules/Tasks/components/Gantt/utils';
import {DatePicker} from 'shared/components';
import {KEYCODE} from 'shared/constants/common';
import {addDays, safeParseDate} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks';
import {useMount, useOutsideClick} from 'shared/hooks/core';
import {prepareDateForGantt} from 'shared/mapping/task';

const DATE_FORMAT = 'yyyy-MM-dd';

const getMinDate = (selectedTask: GanttTask, columnName: string): Date | null => {
  if (columnName === GANTT_COLUMNS_NAMES.actualEnd) {
    return safeParseDate(selectedTask.actual_end);
  }
  if (columnName === GANTT_COLUMNS_NAMES.endDate) {
    return safeParseDate(selectedTask.end_date);
  }
  return null;
};

export const DateEditor = ({gantt}: {gantt: GanttStatic}) => {
  const {selectedTask, selector, onStopEditAction} = useGanttContext();
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const refEl = typeof selector === 'string' ? document.querySelector<HTMLDivElement>(selector) : null;
  const dateColumnName = refEl?.dataset?.columnName;
  const datePickerRef = useRef<ReactDatePicker>();
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.gantt.inlineEdit;

  const isEnd = dateColumnName === GANTT_COLUMNS_NAMES.actualEnd;
  const isSchedEnd = dateColumnName === GANTT_COLUMNS_NAMES.endDate;

  const {styles, attributes} = usePopper(refEl, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [6, 10],
        },
      },
    ],
  });

  useMount(() => {
    datePickerRef?.current?.setOpen(true);
    const onEnterHandler = (e: KeyboardEvent) => {
      if (e.code === KEYCODE.ENTER) {
        onStopEditAction();
      }
    };
    gantt.$container.addEventListener('keydown', onEnterHandler);
    return () => {
      gantt.$container.removeEventListener('keydown', onEnterHandler);
    };
  });

  useOutsideClick({
    ref: popperElement ? {current: popperElement} : undefined,
    callback: (e) => {
      if (!(e.target as HTMLDivElement).closest('.react-datepicker')) {
        onStopEditAction();
      }
    },
  });

  const updateTask = (date: Date | [Date, Date]) => {
    mixpanel.track(mixpanelEvents, {viewMode: gantt.name, column: dateColumnName});
    // it means schedStartDate & schedEndDate
    let isStartDateAfterEndDate = false;
    if (isSchedEnd || isEnd) {
      date = addDays(date as Date, 1).toDate();
    }

    if (isSchedEnd) {
      if (dayjs(selectedTask.start_date).isAfter(date as Date)) {
        selectedTask.lastChangedFields.startDate = {newValue: addDays(date as Date, -1).toDate()};
        isStartDateAfterEndDate = true;
      }
    }

    if (!isStartDateAfterEndDate) {
      selectedTask.lastChangedFields[camelize(dateColumnName)] = {
        newValue: date,
      };
    }

    if (selectedTask.datesIsPristine) selectedTask.datesIsPristine = false;
    gantt.updateTask(selectedTask.id, selectedTask);
    gantt.dRender();
    onStopEditAction();
  };

  const showSelected = useCallback(() => {
    const date: string = selectedTask[dateColumnName];
    if (date === undefined) {
      return null;
    }

    return prepareDateForGantt(date);
  }, [dateColumnName, isEnd, isSchedEnd, selectedTask]);

  return refEl
    ? createPortal(
        <div
          style={styles.popper}
          ref={setPopperElement}
          className="react-datepicker-popper date-editor-popper"
          {...attributes.popper}
          data-placement={attributes.popper?.['data-popper-placement']}
        >
          <DatePicker
            className="bulk-input_gantt"
            dateFormat={DATE_FORMAT}
            selected={showSelected()}
            onChange={(date) => updateTask(date)}
            placeholderText={''}
            showIcon={false}
            inline
            ref={datePickerRef}
            minDate={getMinDate(selectedTask, dateColumnName)}
          />
        </div>,
        document.body, // TODO: create separate container for gantt poppers
      )
    : null;
};
