import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {CtrlButton} from 'shared/components/CoreNewUI';

import MembersClear from '../../../MembersClear/MembersClear';

type Props = {
  addWatcher: () => void;
  disabled?: boolean;
};

const WatchersEmpty: FC<Props> = ({addWatcher, disabled}: Props) => {
  const {t} = useTranslation('task');

  return (
    <>
      <MembersClear
        title={t('responsible_panel.watchers', 'Watchers')}
        description={t('responsible_panel.watchers_empty', 'No watchers yet. You can add them.')}
        action={
          <CtrlButton disabled={disabled} view="link" onClick={addWatcher}>
            {t('responsible_panel.watchers_add', 'Add Watchers')}
          </CtrlButton>
        }
      />
    </>
  );
};
export default WatchersEmpty;
