import cs from 'classnames';
import {useFormikContext} from 'formik';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PROPLAN_MAX_RANGE_VALUE} from 'modules/CompanyProfile/SubscriptionModal/utils/constants';
import {parseNumberAsPrice} from 'modules/CompanyProfile/SubscriptionModal/utils/helpers';
import {calcMonthlyPayment, CalcMonthlyPaymentResult} from 'shared/helpers/subscriptions';
import {FormattedProPlanTiers} from 'shared/models/checkout';

type Props = {
  tiers: FormattedProPlanTiers[];
  onInputChange?: (quantity: number) => void;
  onResultChange?: (res: CalcMonthlyPaymentResult) => void;
  className?: string;
};
const MonthlyPaymentCalculator: FC<Props> = ({tiers, onInputChange, onResultChange, className}) => {
  const [countWorkers, setCountWorkers] = useState(1);
  const {t} = useTranslation('common');
  const formik = useFormikContext();

  const calcResult = useMemo(() => {
    return calcMonthlyPayment(countWorkers, tiers);
  }, [countWorkers, tiers]);

  useEffect(() => {
    onResultChange && onResultChange(calcResult);
  }, [calcResult, onResultChange]);

  return (
    <div className={cs('plan-calc', className)}>
      <h2 className="plan-calc__title">{t('tiers.calculator.title', 'Calculate your monthly cost:')}</h2>
      <div className="plan-calc__form">
        <div className="plan-calc__form-item">
          <div className={cs('ctrl-form', formik && formik.errors['quantity'] && 'is-error')}>
            <div className="ctrl-form__header">
              <label className="ctrl-form__label">
                {t('tiers.calculator.description', 'How many people in your crew?')}
              </label>
            </div>
            <div className="ctrl-form__body">
              <input
                className="ctrl-textfield"
                type="text"
                placeholder="10"
                name="quantity"
                value={countWorkers}
                onChange={(e) => {
                  const parsed = +e.target.value;
                  if (parsed <= PROPLAN_MAX_RANGE_VALUE) {
                    setCountWorkers(parsed);
                    onInputChange && onInputChange(parsed);
                  }
                }}
              />
              {formik && formik.errors['quantity'] && (
                <div className="ctrl-form__validation">{formik.errors['quantity']}</div>
              )}
            </div>
          </div>
        </div>
        <div className="plan-calc__form-result">
          {!!calcResult?.amount && (
            <>
              <span>{parseNumberAsPrice(calcResult.amount)}</span>
              <small>{t('tiers.interval.month_full', 'per month')}</small>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default MonthlyPaymentCalculator;
