import equal from 'fast-deep-equal';
import {Formik, FormikProps} from 'formik';
import {TFunction} from 'i18next';
import React, {ChangeEvent, FC, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {useLocationsOptions} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/useLocationsOptions';
import {useProjectSubcontractorOptions} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/useProjectSubcontractorOptions';
import {useResponsibleOptions} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/useResponsibleOptions';
import {useTaskStatusOptions} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/useTaskStatusOptions';
import {useTaskTypeOptions} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/useTaskTypeOptions';
import {WBSAutocomplete} from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/WBSAutocomplete';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {getFilterTabs} from 'modules/Tasks/components/Filters/utils/constants';
import {Icon} from 'shared/components';
import {
  Autocomplete,
  CtrlBtnOption,
  CtrlButton,
  CtrlCheckOption,
  CtrlSwitcher,
  Dropdown,
  SearchInput,
} from 'shared/components/CoreNewUI';
import {RouteParams} from 'shared/constants/routes';
import {toTitleCase} from 'shared/helpers/common';
import {useAnalyticsService, useTasksUrlState} from 'shared/hooks';
import {useDebounce} from 'shared/hooks/core';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {TaskFilterQuery} from 'shared/models/task';

import styles from '../ActionsBar/components/FilterDropdown/FilterDropdown.module.scss';
import SearchField from '../ActionsBar/components/SearchField/SearchField';

type Props = {
  className?: string;
  active?: boolean;
  buttonIconOnly?: boolean;
  sideContentDirection?: 'left' | 'right';
};

const getVisibilityOptions = (t: TFunction) => {
  return [
    {
      label: t('filters:tasks.visible.unassigned', 'Show Only Unassigned Activities'),
      name: 'unassignedOnly',
    },
    {
      label: t('filters:tasks.visible.issues', 'Show Activity with Issues'),
      name: 'withIssues',
    },
  ];
};
const TasksFilterDropdown: FC<Props> = ({className, active, sideContentDirection}: Props) => {
  const tasksState = useTasksUrlState();
  const {projectId} = useParams<RouteParams['tasks']>();
  const {t} = useTranslation(['filters', 'gantt']);
  const {updateSearchParams, onSelectState, reset, queryParams, taskState, filtersState} = useFilterContext();
  const {viewMode} = useFilterContext();
  const {isProjectAdmin} = useCompanyWorkerRoles(projectId);
  const taskStatusOptions = useTaskStatusOptions(queryParams.status, tasksState);
  const visibilityOptions = useMemo(() => getVisibilityOptions(t), [t]);
  const tabs = useMemo(() => getFilterTabs(t), [t]);
  const responsibleOptions = useResponsibleOptions(projectId);
  const {data: taskTypesOptions, isLoading: isLoadingTaskTypes} = useTaskTypeOptions(projectId);
  const {data: subcontractorOptions, isLoading: isLoadingSubcontractors} = useProjectSubcontractorOptions(projectId);
  const preparedLocations = useLocationsOptions(projectId, queryParams.locations);

  const {
    mixpanel: {events, ...mixpanel},
  } = useAnalyticsService({extraMeta: {projectId, isReadOnlyMode: !isProjectAdmin, viewMode}});
  const formik = useRef<FormikProps<TaskFilterQuery>>(null);

  const syncFormikStateWithQuery = useDebounce(() => {
    if (!equal(formik.current?.values, queryParams)) {
      formik.current?.setValues(queryParams);
    }
  }, 500);

  useEffect(() => {
    syncFormikStateWithQuery();
  }, [queryParams]);

  const onChangeVisibility = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = e.target;
    if (checked) {
      mixpanel.track(events.gantt.filterDropdown.visibility[name]);
    }
    formik.current.setFieldValue(name, checked);
  };

  const getVisibilityCount = () => {
    const {values = null} = formik.current || {};
    return visibilityOptions.reduce((acc, option) => (values?.[option.name] ? ++acc : acc), 0);
  };

  const onSearchInputChange = (value: string) => {
    updateSearchParams({...queryParams, q: value});
  };

  const applyFilters = (values: TaskFilterQuery) => {
    if (!equal(queryParams, values)) {
      updateSearchParams({...queryParams, ...values});
    }
  };

  const getFilledCount = () => {
    const values = formik.current?.values;
    const filledCodesCount = values
      ? [values.costCode, values.csiCode, values.phaseCode, values.customCode].filter(Boolean).length
      : 0;
    const filledLaborCount = values ? [values.actualLabor, values.projectedLabor].filter(Boolean).length : 0;
    return {codes: filledCodesCount, labor: filledLaborCount};
  };

  return (
    <Dropdown
      viewportPosition="right"
      className={className}
      toggleElement={
        <SearchField
          name="filterSearch"
          onChange={onSearchInputChange}
          value={queryParams.q}
          active={active}
          className={styles.filterDropdown__input}
          inputClassName={styles.filterDropdown__input_size}
        />
      }
      toggleElementId="toolbarFiltersToggle"
      header={{
        title: t('filters:dropdown.header', 'Filters'),
        button: {
          title: t('filters:dropdown.reset', 'Reset all'),
          onClick: () => reset({exclude: ['q', 'schedWeeks', 'schedEndFirst', 'showPastDue']}),
        },
      }}
    >
      <CtrlSwitcher view="block">
        {tabs.map((tab) => {
          return (
            <CtrlButton
              key={tab.state}
              color="tertiary"
              view="selector"
              selected={tasksState === tab.state}
              onClick={() =>
                mixpanel.trackWithAction(() => onSelectState(tab.state), events.tasks.taskList(toTitleCase(tab.state)))
              }
            >
              {tab.title}
            </CtrlButton>
          );
        })}
      </CtrlSwitcher>
      <Formik<TaskFilterQuery>
        innerRef={formik}
        onSubmit={applyFilters}
        initialValues={filtersState.current[viewMode][taskState]}
      >
        {({handleSubmit, values, setFieldValue, setValues}) => {
          const counts = getFilledCount();
          return (
            <>
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.milestones.title', 'Milestones')}
                icon={<Icon colorFill name="milestone" />}
                iconRight={
                  values.milestonesOnly ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('milestonesOnly', false)} />
                  ) : null
                }
                countSelected={values.milestonesOnly ? 1 : 0}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <CtrlCheckOption
                    key="milestonesOnly"
                    label={t('filters:tasks.options.milestones.showMilestonesOnly', 'Show Milestones Only')}
                  >
                    <input
                      name="milestonesOnly"
                      onChange={() =>
                        mixpanel.trackWithAction(
                          () => setFieldValue('milestonesOnly', !values.milestonesOnly),
                          events.gantt.filterDropdown.milestonesOnly,
                          {},
                          values.milestonesOnly,
                        )
                      }
                      checked={values.milestonesOnly}
                    />
                  </CtrlCheckOption>
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.status', 'Status')}
                countSelected={values.statusList.length}
                iconRight={
                  values.statusList.length ? (
                    <Icon
                      name="clear"
                      colorFill
                      onClick={() => {
                        setFieldValue('statusList', []);
                      }}
                    />
                  ) : null
                }
                icon={<Icon colorFill name="status" />}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    hideSearch
                    items={taskStatusOptions}
                    name="statusList"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), events.gantt.filterDropdown.status)
                    }
                    selected={values.statusList}
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.responsible', 'Responsible')}
                countSelected={values.responsible ? 1 : 0}
                iconRight={
                  values.responsible ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('responsible', null)} />
                  ) : null
                }
                icon={<Icon colorFill name="assign" />}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    items={responsibleOptions}
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(
                        () => setFieldValue(name, value),
                        events.gantt.filterDropdown.responsible,
                      )
                    }
                    selected={values.responsible}
                    name="responsible"
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.location', 'Location')}
                countSelected={values.locations.length}
                icon={<Icon colorFill name="location" />}
                iconRight={
                  values.locations.length ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('locations', [])} />
                  ) : null
                }
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    items={preparedLocations}
                    name="locations"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), events.gantt.filterDropdown.locations)
                    }
                    selected={values.locations}
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.subcontractor', 'Subcontractor')}
                countSelected={values.responsibleOrgIds.length}
                icon={<Icon colorFill name="subcontractor" />}
                iconRight={values.responsibleOrgIds.length ? <Icon name="clear" colorFill /> : null}
                onClick={() => setFieldValue('responsibleOrgIds', [])}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    items={subcontractorOptions}
                    name="responsibleOrgIds"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(
                        () => setFieldValue(name, value),
                        events.gantt.filterDropdown.subcontractor,
                      )
                    }
                    selected={values.responsibleOrgIds}
                    disabled={isLoadingSubcontractors}
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.wbs', 'WBS')}
                countSelected={values.outlineSortKeyPrefixList.length}
                icon={<Icon colorFill name="parenttask" />}
                iconRight={
                  values.outlineSortKeyPrefixList.length ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('outlineSortKeyPrefixList', [])} />
                  ) : null
                }
                nestedContentPlacement={sideContentDirection}
                nested={
                  <WBSAutocomplete
                    projectId={projectId}
                    taskState={tasksState}
                    isMulti
                    onSelect={setFieldValue}
                    selected={values.outlineSortKeyPrefixList}
                    name="outlineSortKeyPrefixList"
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.type', 'Activity Type')}
                countSelected={values.types.length}
                iconRight={
                  values.types.length ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('types', [])} />
                  ) : null
                }
                icon={<Icon colorFill name="active_type" />}
                nestedContentPlacement={`${sideContentDirection}Bottom`}
                nested={
                  <Autocomplete
                    isMulti
                    items={taskTypesOptions}
                    name="types"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(() => setFieldValue(name, value), events.gantt.filterDropdown.types)
                    }
                    selected={values.types}
                    disabled={isLoadingTaskTypes}
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.visibility', 'Visibility')}
                countSelected={getVisibilityCount()}
                icon={<Icon colorFill name="show" />}
                iconRight={
                  getVisibilityCount() ? (
                    <Icon
                      name="clear"
                      colorFill
                      onClick={() => {
                        visibilityOptions.forEach((option) => {
                          setFieldValue(option.name, false);
                        });
                      }}
                    />
                  ) : null
                }
                nestedContentPlacement={`${sideContentDirection}Bottom`}
                nested={
                  <>
                    {visibilityOptions.map(({label, name}) => {
                      return (
                        <CtrlCheckOption key={name} label={label}>
                          <input name={name} onChange={onChangeVisibility} checked={values[name]} />
                        </CtrlCheckOption>
                      );
                    })}
                  </>
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.labor', 'Manpower')}
                countSelected={counts.labor}
                icon={<Icon colorFill name="group_three" />}
                iconRight={
                  counts.labor ? (
                    <Icon
                      name="clear"
                      colorFill
                      onClick={() => {
                        setValues({...values, actualLabor: '', projectedLabor: ''});
                      }}
                    />
                  ) : null
                }
                nestedContentPlacement={`${sideContentDirection}Bottom`}
                nested={
                  <>
                    <SearchInput
                      className={styles.filterDropdown__formControl}
                      label="Projected"
                      name="projectedLabor"
                      value={values.projectedLabor}
                      onChange={(value) => setFieldValue('projectedLabor', value)}
                    />
                    <SearchInput
                      className={styles.filterDropdown__formControl}
                      label="Actual"
                      name={'actualLabor'}
                      value={values.actualLabor}
                      onChange={(value) => setFieldValue('actualLabor', value)}
                    />
                  </>
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.codes', 'Codes')}
                countSelected={counts.codes}
                icon={<Icon colorFill name="lightbulb" />}
                iconRight={
                  counts.codes ? (
                    <Icon
                      name="clear"
                      colorFill
                      onClick={() => {
                        setValues({
                          ...values,
                          customCode: '',
                          csiCode: '',
                          phaseCode: '',
                          costCode: '',
                        });
                      }}
                    />
                  ) : null
                }
                nestedContentPlacement={`${sideContentDirection}Bottom`}
                nested={
                  <>
                    <SearchInput
                      className={styles.filterDropdown__formControl}
                      label="Cost"
                      name="costCode"
                      value={values.costCode}
                      onChange={(value) => setFieldValue('costCode', value)}
                    />
                    <SearchInput
                      className={styles.filterDropdown__formControl}
                      label="Phase"
                      name="phaseCode"
                      value={values.phaseCode}
                      onChange={(value) => setFieldValue('phaseCode', value)}
                    />
                    <SearchInput
                      className={styles.filterDropdown__formControl}
                      label="CSI"
                      name="csiCode"
                      value={values.csiCode}
                      onChange={(value) => setFieldValue('csiCode', value)}
                    />
                    <SearchInput
                      className={styles.filterDropdown__formControl}
                      label="Custom"
                      name="customCode"
                      value={values.customCode}
                      onChange={(value) => setFieldValue('customCode', value)}
                    />
                  </>
                }
              />
              <CtrlButton
                style={{width: '100%', marginTop: '15px'}}
                type="submit"
                onClick={() => mixpanel.trackWithAction(handleSubmit, events.gantt.filterDropdown.applyButton)}
              >
                {t('filters:dropdown.apply', 'Apply')}
              </CtrlButton>
            </>
          );
        }}
      </Formik>
    </Dropdown>
  );
};
export default TasksFilterDropdown;
