import cn from 'classnames';
import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import React, {ChangeEvent, FC, UIEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import CtrlDates from 'modules/Tasks/components/ActionsBar/components/CtrlDates/CtrlDates';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {getGanttVisualWeeksFilter} from 'modules/Tasks/components/Filters/utils/constants';
import {DatePicker} from 'shared/components';
import {CtrlButton, CtrlCheck, CtrlChip, CtrlChipGroup, Dropdown} from 'shared/components/CoreNewUI';
import {TasksViewMode} from 'shared/constants/common';
import {RouteParams} from 'shared/constants/routes';
import {addDays, formatDate, subtract} from 'shared/helpers/dates';
import {debounce} from 'shared/helpers/debounce';
import {useAnalyticsService} from 'shared/hooks';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';

import s from './DateDropdown.module.scss';

type Props = {
  className?: string;
  active?: boolean;
  gantt?: GanttStatic;
  onOpenDateFilter?: () => void;
};

const DEFAULT_SCHED_WEEKS = '3';

const GanttVisualDateDropdown: FC<Props> = ({className, gantt, active, onOpenDateFilter}: Props) => {
  const {projectId} = useParams<RouteParams['tasks']>();
  const {queryParams, updateSearchParams, reset} = useFilterContext();
  const [dateRange, setDateRange] = useState('');
  const {t} = useTranslation(['filters', 'gantt']);
  const {isProjectAdmin} = useCompanyWorkerRoles(projectId);
  const {mixpanel} = useAnalyticsService({
    extraMeta: {projectId, isReadOnlyMode: !isProjectAdmin, viewMode: TasksViewMode.ganttVisual},
  });
  const mixpanelEvents = mixpanel.events.tasks.toolbar;
  const selectedDate = useMemo(
    () => (queryParams.schedEndFirst ? dayjs(queryParams.schedEndFirst).toDate() : null),
    [queryParams.schedEndFirst],
  );
  const schedWeeks = queryParams.schedWeeks;
  const onChangeDate = (date: Date) => {
    const newSearchParams = {...queryParams, schedEndFirst: date};
    if (!newSearchParams.schedWeeks) {
      newSearchParams.schedWeeks = DEFAULT_SCHED_WEEKS;
    }
    updateSearchParams(newSearchParams);
    mixpanel.track(mixpanelEvents.dateDropdown.selectDate);
  };

  const onChangeWeeks = (e: ChangeEvent<HTMLInputElement>) => {
    updateSearchParams({...queryParams, schedWeeks: e.target.value});
    mixpanel.track(mixpanelEvents.dateDropdown.selectWeeks, {weeks: e.target.value});
  };

  const toggleShowPastDue = () => {
    updateSearchParams({...queryParams, showPastDue: !queryParams?.showPastDue});
  };

  const weekOptions = useMemo(() => getGanttVisualWeeksFilter(t), [t]);

  const goToWeek = (direction: 'backward' | 'forward') => {
    const NUMBER_DAYS = schedWeeks === '0' ? 1 : 7;
    const nextDate: Date =
      direction === 'forward'
        ? addDays(queryParams.schedEndFirst, NUMBER_DAYS).toDate()
        : subtract(queryParams.schedEndFirst, NUMBER_DAYS).toDate();
    updateSearchParams({...queryParams, schedEndFirst: nextDate});
  };

  const nexButton = (e: UIEvent) => {
    e?.stopPropagation();
    goToWeek('forward');
    mixpanel.track(mixpanelEvents.dateDropdown['nextWeek']);
  };

  const prevButton = (e: UIEvent) => {
    e?.stopPropagation();
    goToWeek('backward');
    mixpanel.track(mixpanelEvents.dateDropdown['prevWeek']);
  };

  useEffect(() => {
    const onRender = debounce(() => {
      let start: Date = gantt.config.start_date;
      let end: Date = gantt.config.end_date;
      if (!start || !end) {
        const {start_date, end_date} = gantt.getSubtaskDates();
        start = start_date;
        end = end_date;
      }
      if (schedWeeks === '0') {
        setDateRange(formatDate(start, 'MMMM D'));
      } else {
        setDateRange(`${formatDate(start, 'MMM D')} — ${formatDate(subtract(end, 1), 'MMM D')}`);
      }
    }, 500);

    const event = gantt.attachEvent('onGanttRender', onRender, undefined);
    onRender();

    return () => {
      gantt.detachEvent(event);
      // Cancel debounce function to avoid leading or trailing invocation that cause memory leaks
      onRender.cancel();
    };
  }, [gantt, schedWeeks]);

  const resetFilters = () => reset({newState: {schedWeeks: null, schedEndFirst: null, showPastDue: false}});

  return (
    <Dropdown
      className={cn(s.dateDropdown, className)}
      color="second"
      header={{
        title: t('gantt:toolbar.date.title', 'Dates'),
      }}
      footer={
        <>
          <CtrlChipGroup title={t('gantt:toolbar.date.weeks.title', 'Weeks Ahead')}>
            {weekOptions?.map(({value, title}) => {
              return (
                <CtrlChip
                  key={`${value}|${title}`}
                  name="schedWeeks"
                  label={title}
                  value={value}
                  onChange={onChangeWeeks}
                  checked={parseInt(schedWeeks) === value}
                />
              );
            })}
          </CtrlChipGroup>
          <CtrlCheck
            className={s.showPastDue}
            fieldType="checkbox"
            label={t('gantt:toolbar.date.showPastDue', 'Show Past Due Activities')}
            labelSize="s"
          >
            <input checked={!!queryParams.showPastDue} type="checkbox" onChange={toggleShowPastDue} />
          </CtrlCheck>
          <div></div>
          <CtrlButton className={cn(s.dateDropdownButton, s.dateDropdownButton_reset)} onClick={resetFilters}>
            {t('filters:dropdown.resetFilters', 'Reset filters')}
          </CtrlButton>
        </>
      }
      toggleElement={
        <CtrlDates
          active={active}
          onClick={onOpenDateFilter}
          prevButton={
            <CtrlButton color="clear" size="xs" icon={'chevron-left'} iconOnly={true} onClick={prevButton}>
              {t('filters:dropdown.prev_button')}
            </CtrlButton>
          }
          nextButton={
            <CtrlButton color="clear" size="xs" icon={'chevron-right'} iconOnly={true} onClick={nexButton}>
              {t('filters:dropdown.next_button')}
            </CtrlButton>
          }
          text={dateRange}
        />
      }
    >
      <DatePicker inline isClearable={false} dateFormat="dd/MM/yy" onChange={onChangeDate} selected={selectedDate} />
    </Dropdown>
  );
};
export default GanttVisualDateDropdown;
