import React, {FunctionComponent, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {QueryKey, useInfiniteQuery} from 'react-query';
import {generatePath, useHistory, useLocation, useParams, useRouteMatch} from 'react-router';
import {Column, SortingRule} from 'react-table';

import {WorkersApi} from 'api';
import {TextArrayColumn} from 'modules/Workers/Columns/TextArrayColumn';
import {HelpModal} from 'shared/components';
import EmptyGridBubble from 'shared/components/EmptyGridBubble';
import ProjectLevelToolbar from 'shared/components/ProjectLevelToolbar';
import ProjectNameInlineEdit from 'shared/components/ProjectNameInlineEdit';
import Table from 'shared/components/Table';
import {CenteredColumn} from 'shared/components/Table/components';
import WorkspaceSwitcher from 'shared/components/WorkspaceSwitcher';
import {formatPhoneNumber} from 'shared/constants/common';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {prepareQueryString} from 'shared/helpers/queryParams';
import {getCompanyWorkerStatus} from 'shared/helpers/statuses';
import {
  getNormalizedWorkerRoles,
  getNormalizedWorkerTrade,
  mapOrgsIds,
  prepareWorkerFiltersForUrl,
} from 'shared/helpers/worker';
import {useAnalyticsService, useCompany, useExactMatchParsedQuery, useProjectSelector} from 'shared/hooks';
import {useSaveLastSelectedProjectId} from 'shared/hooks/useSaveLastSelectedProjectId';
import {Panel, ScreenGreed} from 'shared/layout/admin';
import {CompanyWorker, CompanyWorkerFilterParams} from 'shared/models/worker';
import {getWorkerMixpanelLabel} from 'shared/utils/inviteWorker';

import {useWorkerPath} from '../Worker/hooks/useWorkerPath';
import {useProjectOrgs} from '../Worker/queries';

import {NameColumn, OrgsColumn, StatusColumn} from './Columns';
import FilterDropdown from './FilterDropdown';
import {DEFAULT_FILTER_PARAMS, DEFAULT_SORT_RULE} from './utils/constants';
import s from './Workers.module.scss';

const EXCLUDED_COLUMN_FOR_COMPANY_WORKERS = ['orgsMappingIds'];

const Workers: FunctionComponent = () => {
  const company = useCompany();
  const history = useHistory();
  const location = useLocation();
  const routes = useLocalizedRoutes();
  const [sortBy, setSortBy] = useState<SortingRule<CompanyWorker>[]>([]);
  const [pageSize] = useState(20);
  const queryKeyRef = useRef<QueryKey>();
  const {mixpanel} = useAnalyticsService();
  const {t} = useTranslation('workers');
  const match = useRouteMatch([routes.projectWorkers, routes.workers]);
  const {projectId} = useParams<{projectId: string}>();
  const project = useProjectSelector(projectId);
  const {workerPath} = useWorkerPath();
  const mixpanelEvents = mixpanel.events.workers;
  useSaveLastSelectedProjectId(routes.projectWorkers);
  const isProjectWorkers = match?.path === routes.projectWorkers;

  const {params: queryParams} = useExactMatchParsedQuery<CompanyWorkerFilterParams>({
    defaultParams: DEFAULT_FILTER_PARAMS,
    path: isProjectWorkers ? routes.projectWorkers : routes.workers,
    schema: {
      wildcard: 'string',
      trade: 'string',
      blendedStatus: 'string',
      orgList: 'string[]',
    },
  });

  useEffect(() => {
    mixpanel.track(mixpanelEvents.screen(getWorkerMixpanelLabel(isProjectWorkers)));
  }, [isProjectWorkers]);

  const addWorker = () => {
    mixpanel.track(mixpanelEvents.addWorkerBtn(getWorkerMixpanelLabel(!!projectId)));
    history.push(generatePath(workerPath, {id: 'new', projectId}), {
      from: history.location.pathname,
      queryKey: queryKeyRef.current,
    });
  };

  const updateSearchParams = (values: CompanyWorkerFilterParams) => {
    history.push({
      pathname: location.pathname,
      search: prepareQueryString({
        values,
        prepareFunction: prepareWorkerFiltersForUrl,
      }),
      // TODO: delete state within the task: https://journey-builders.atlassian.net/browse/CNA-3017
      state: location.state,
    });
  };

  const searchWorker = (value: string) => {
    updateSearchParams({...queryParams, wildcard: value});
  };

  const {data, hasNextPage, isFetching, fetchNextPage} = useInfiniteQuery(
    ['workers', company?.id, projectId, queryParams, sortBy],
    ({pageParam = 0, queryKey}) => {
      queryKeyRef.current = queryKey;
      const formattedSortFields = {};
      sortBy.length ? Object.assign(formattedSortFields, {sortField: sortBy[0]?.id}) : '';
      sortBy.length ? Object.assign(formattedSortFields, {sortOrder: sortBy[0]?.desc ? 'DESC' : 'ASC'}) : '';
      return WorkersApi[isProjectWorkers ? 'getAllProjectWorkers' : 'getAllCompaniesWorkers'](
        isProjectWorkers ? projectId : company?.id,
        {
          filterParams: {...queryParams, companyLevel: true},
          offset: pageParam * pageSize,
          limit: pageSize,
          ...formattedSortFields,
        },
      ).then((res: CompanyWorker[]) => {
        const indexMap: Record<string, number> = {};
        const uniqWorkers: CompanyWorker[] & {total?: number} = [];
        res
          .sort((workerA, workerB) => {
            const mainAdminRole = isProjectWorkers ? 'project_admin' : 'company_admin';
            if (workerA.workerId === workerB.workerId) {
              if (workerA.roles.includes(mainAdminRole)) return -1;
              if (workerB.roles.includes(mainAdminRole)) return 1;
            }
            return 0;
          })
          .forEach((worker) => {
            const workerId = worker.workerId;
            if (indexMap[workerId] !== undefined) {
              const existingWorker = uniqWorkers[indexMap[workerId]];
              existingWorker.roles = (existingWorker.roles || []).concat(worker.roles || []);
            } else {
              indexMap[workerId] = uniqWorkers.length;
              uniqWorkers.push({...worker});
            }
          });

        uniqWorkers.total = res.length;
        return uniqWorkers;
      });
    },
    {
      enabled: !!company?.id,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastpage, allPages) => (lastpage.total < pageSize ? undefined : allPages.length),
    },
  );

  const orgsOptions = useProjectOrgs(projectId);

  const handleSort = async (sortBy: SortingRule<CompanyWorker>[]) => {
    setSortBy(sortBy);
  };

  const workers = useMemo(() => data?.pages.reduce((prev, cur) => prev.concat(cur), []) || [], [data]);

  const showNowData = !workers?.length && !isFetching && !!company?.id;

  const columns = useMemo(
    () =>
      [
        {
          Header: t('columns.name', 'Name'),
          id: 'firstName',
          accessor: 'workerFull',
          Cell: NameColumn,
          minWidth: 200,
          width: 400,
        },
        {
          Header: t('columns.trade', 'Trade'),
          id: 'trade',
          accessor: ({workerFull}) => getNormalizedWorkerTrade(workerFull.trade, t),
          Cell: CenteredColumn,
          minWidth: 100,
        },
        {
          Header: t('columns.role', 'Role'),
          id: 'role',
          accessor: ({roles}) => getNormalizedWorkerRoles(roles, t),
          Cell: TextArrayColumn,
          disableSortBy: true,
          minWidth: 100,
        },
        {
          Header: t('columns.organization', 'Organization'),
          id: 'orgsMappingIds',
          accessor: ({orgMappingIds}) => mapOrgsIds(orgMappingIds, orgsOptions),
          Cell: OrgsColumn,
          disableSortBy: true,
          minWidth: 100,
        },
        {
          Header: t('columns.status', 'Status'),
          id: 'status',
          accessor: status,
          Cell: StatusColumn,
          disableSortBy: true,
          width: 80,
          minWidth: 80,
        },
        {
          Header: t('columns.phone', 'Phone'),
          id: 'phone',
          accessor: ({workerFull, status}) =>
            workerFull.mobileNumber && getCompanyWorkerStatus(status, workerFull.status) !== 'removed'
              ? formatPhoneNumber(workerFull.mobileNumber)
              : '',
          disableSortBy: true,
          Cell: CenteredColumn,
          width: 300,
          minWidth: 300,
        },
        {
          Header: t('columns.email', 'Email'),
          id: 'email',
          accessor: ({workerFull, status}) =>
            getCompanyWorkerStatus(status, workerFull.status) !== 'removed' ? workerFull.email : '',
          disableSortBy: true,
          Cell: CenteredColumn,
          width: 300,
          minWidth: 300,
        },
      ] as Column<CompanyWorker>[],
    [orgsOptions, t],
  );

  const companyColumns = columns.filter((column) => !EXCLUDED_COLUMN_FOR_COMPANY_WORKERS.includes(column.id));
  const preparedColumns = isProjectWorkers ? columns : companyColumns;

  const downloadApp = () => {
    window.open('https://bycore.com/download?fromMobileApp=true', '_blank');
  };

  const tableInitialState = useMemo(() => {
    return {sortBy: DEFAULT_SORT_RULE};
  }, []);

  return (
    <>
      <Panel
        title={
          isProjectWorkers ? (
            <ProjectNameInlineEdit pretext={t('project_title', 'Project Workers for')} project={project} />
          ) : (
            t('title', 'Company Users')
          )
        }
        actions={isProjectWorkers && <WorkspaceSwitcher projectId={projectId} />}
      />
      <ProjectLevelToolbar
        handleAddItem={addWorker}
        handleInputChange={searchWorker}
        buttonTitle={t('buttons.add')}
        buttonIcon="worker_add"
        onFocusInput={() => mixpanel.track(mixpanelEvents.searchInput(getWorkerMixpanelLabel(isProjectWorkers)))}
      >
        <FilterDropdown queryParams={queryParams} isProjectWorkers={isProjectWorkers} />
      </ProjectLevelToolbar>
      <ScreenGreed
        content={
          <>
            {showNowData ? (
              <EmptyGridBubble text={t('empty.title', 'No results found. Please try again or reset filters.')} t={t} />
            ) : (
              <div className={s.workers__grid}>
                <Table<CompanyWorker>
                  data={workers}
                  columns={preparedColumns}
                  isLoading={isFetching}
                  hasMore={hasNextPage}
                  onSort={handleSort}
                  next={fetchNextPage}
                  initialState={tableInitialState}
                  onRowClick={({original}) =>
                    history.push(
                      generatePath(workerPath, {
                        id: original.id,
                        projectId,
                      }),
                      {
                        from: history.location.pathname,
                        queryKey: queryKeyRef.current,
                      },
                    )
                  }
                  skeletonLoader={<Skeleton style={{margin: '24px 0'}} count={6} height={32} />}
                />
              </div>
            )}
          </>
        }
      />
      <HelpModal
        title={t('help.title', 'Workers')}
        text={t(
          'help.text',
          'Workers that sign up using the text when the “Notify with SMS” toggle is turned to ON will be able to download the Crews by Core app to manage activities, chat with their Crew and update their status while at the job site!',
        )}
        buttonText={t('buttons.download', 'Download the App!')}
        buttonAction={() =>
          mixpanel.trackWithAction(downloadApp, mixpanelEvents.screen(getWorkerMixpanelLabel(isProjectWorkers)))
        }
        iconName="download_outlined"
      />
    </>
  );
};
export default Workers;
