import {createAsyncThunk} from '@reduxjs/toolkit';

import {WorkerApi} from 'api';
import {SupportedLoginTypes} from 'api/worker';
import FirebaseDefault from 'services/Firebase';
import {deleteNullFields} from 'shared/helpers/common';
import {Worker} from 'shared/models/worker';

type UpdateWorkerObject = {
  id: string;
  workerObject?: Partial<Worker> & {profileImage?: File};
  isNewAvatarUploaded?: boolean;
};

type RegisterWorker = {
  loginType: SupportedLoginTypes;
  phoneNumber?: string;
};

export const updateWorkerObject = createAsyncThunk(
  'onboarding/updateWorkerObject',
  async ({id, workerObject, isNewAvatarUploaded}: UpdateWorkerObject) => {
    try {
      if (workerObject) {
        let payload = {...workerObject};
        if (workerObject.fullName) {
          const [firstName, ...lastName] = workerObject.fullName.split(' ');
          payload = {...payload, firstName, lastName: lastName.join(' ')};
          delete payload.fullName;
        }

        deleteNullFields(payload);

        if (workerObject.profileImage && isNewAvatarUploaded) {
          const {profilePicUrl, profilePicOrigUrl} = await WorkerApi.uploadWorkerImage(id, payload.profileImage);
          delete payload.profileImage;
          return await WorkerApi.updateWorkerData({...payload, profilePicUrl, profilePicOrigUrl}, id);
        }
        delete payload.profileImage;

        return await WorkerApi.updateWorkerData(payload, id);
      }

      return (await WorkerApi.getWorkerById(id)) as Worker;
    } catch (error) {
      throw error;
    }
  },
);

export const registerWorker = createAsyncThunk(
  'onboarding/registerWorker',
  async ({loginType = 'phone', phoneNumber = ''}: RegisterWorker) => {
    const currentUser = await FirebaseDefault.getCurrentUser();
    const idToken = await FirebaseDefault.getUserIdToken();

    return await WorkerApi.loginOrSignupWorker({
      user: currentUser,
      idToken: idToken,
      loginType: loginType,
      phoneNumber: phoneNumber,
    });
  },
);
