import {Availability} from 'shared/constants/availability';

import {JobPreferences} from './jobPreferences';
import {TaskCountStatus} from './reports';
import {WithTimestamps} from './withTimestamps';

export type WorkerStatus = 'anonymous' | 'open' | 'closed' | 'invited';
export type WorkerBlendedStatus = 'invited' | 'active' | 'removed' | 'closed';
export type CompanyWorkerStatus = 'invited' | 'active' | 'removed';
export type WorkerQueryStatus = CompanyWorkerStatus | 'closed';
export enum WorkerProfileLevel {
  anonymous = 'anonymous',
  empty = 'empty',
  signedUp = 'signed_up',
  match = 'match',
  complete = 'complete',
}

export type WorkerCounts = {
  workerId: string;
  counts: TaskCountStatus;
};

export type WorkerTaskLoad = {
  workerCounts: WorkerCounts[];
};

export interface WorkerResume {
  firstName?: string;
  lastName?: string;
  trade?: string;
  yearsOfXp?: string;
  addressTxt?: string;
  hiremePitch?: string;
  hourlyRate?: string;
  mobileNumber?: string;
  agreePrivacyPolicy?: boolean;
  agreeDigestSms?: boolean;
  unionName?: string;
}
export interface WorkerResumeFile {
  file: File;
}

export interface HourlyRateDesired {
  amount: number;
  currency: 'USD';
}

export interface Worker extends WorkerResume {
  id?: string;
  firstName?: string;
  lastName?: string;
  addressGeo?: WorkerAddressGeo;
  email?: string;
  emailStatus?: EmailStatus;
  hourlyRateDesired?: HourlyRateDesired;
  hourlyRate?: string;
  availability?: Availability;
  resumeLevel?: number;
  softSkills?: WorkerSkillOrTrait[];
  tradeSubsJson?: string;
  addressTxt?: string;
  workerTitle?: string;
  agreePrivacyPolicy?: boolean;
  agreeDigestSms?: boolean;
  unionId?: string;
  unionName?: string;
  companyWhitelist?: string;
  gender?: string;
  race?: string;
  disability?: string;
  tradeSkills?: WorkerSkillOrTrait[];
  trainingJson?: string;
  physicalTraits?: WorkerSkillOrTrait[];
  certifications?: WorkerCertificate[];
  profilePicUrl?: string;
  profilePicOrigUrl?: string;
  resumeUrl?: string;
  aboutMe?: string;
  workExperiences?: WorkerExperience[];
  jobPreferences?: JobPreferences;
  roles?: string;
  companyId?: string;
  workReferrals?: Verification[];
  status?: WorkerStatus;
  fullName?: string;
  extChatId?: string;
  profileLevel?: WorkerProfileLevel;
}
export interface WorkerSkillOrTrait {
  id?: string;
  description: string;
  category?: string;
  reviewStatus?: string;
  tag?: string;
  type: string;
  workerId?: string;
  status?: string;
  verificationLevel?: VerificationLevel;
  verificationCount?: number;
  unusedVerifiers?: string[];
  pendingVerifiers?: string[];
  completedVerifiers?: string[];
}
export interface WorkerSkillSuggestion {
  action: string;
  category: string;
  description: string;
  id: string;
  status: string;
  suggestionWeight: number;
  tag: string;
  type: string;
}
export interface WorkerCertificate {
  certificateName: string;
  instituteName: string;
  jurisdiction: string;
  issuedId: string;
  issuedDate?: Date;
  expirationDate?: Date;
  isVerified?: boolean;
}

export interface ApprovedSkill {
  id: string;
  action: string;
  category: string;
  description: string;
  status: string;
  tag: string;
  type: string;
  suggestionWeight: number;
}

export interface ExternalMessage {
  contactType?: string;
  contactInfo: string;
  message?: string;
  messageType?: string;
}

export interface Interview {
  firstName: string;
  lastName: string;
  jobDescriptionLink?: string;
  message?: string;
  mobileNumber: string;
}

export enum VerificationStatus {
  CREATED = 'created',
  QUEUED = 'queued',
  PULLED = 'pulled',
  SENT = 'sent',
  RECEIVED = 'received',
  UPDATED = 'updated',
  COMPLETED = 'completed',
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
}

export interface Verification extends WithTimestamps {
  id?: string;
  allowReference?: true;
  contactInfo?: string;
  contactCompany?: string;
  contactName?: string;
  contactTitle?: string;
  contactType?: string;
  otherSkills?: string;
  skillsVerification?: WorkerSkillOrTrait[];
  status?: VerificationStatus;
  tradeVerification?: string;
  useDecision?: boolean;
  workHistory?: string;
  workerId?: string;
  verifierNotes?: string;
  sendCount?: number;
}

export interface Contact {
  id?: string;
  email?: string;
  name?: string;
  phoneNumber?: string;
  title?: string;
  company?: string;
}

export interface WorkerExperience {
  companyGeoId?: string;
  companyGeoIdType?: string;
  companyName: string;
  endDate?: Date;
  jobDescription?: string;
  jobTitle?: string;
  location?: string;
  projectName?: string;
  sortOrder?: number;
  startDate?: Date;
  timeCreated?: string;
  verificationStatus?: VerificationStatus;
  workReferences?: Contact[];
}
export type WorkerAddressGeo = {
  coordinates: Array<number | string>;
  type: string;
};

export enum EmailStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  SPAM = 'spam',
  PARTNER_VERIFIED = 'partner_verified',
}

export enum VerificationLevel {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NOT_OBSERVED = 'na',
}

export type CompanyWorkerRole = 'hirer' | 'company_admin' | 'project_admin' | 'worker' | 'foreman';
export type CompanyWorker = {
  companyId?: string;
  projectId?: string;
  companyName?: string | null;
  expirationDate?: string;
  id?: string;
  notes?: string;
  orgMappingIds?: string[] | null;
  orgs?: string[] | null;
  relation?: 'employee' | 'contract';
  roles?: CompanyWorkerRole[] | null;
  status?: CompanyWorkerStatus;
  timeCreated?: string;
  workerId?: string;
  workercard?: Worker;
  workerFull?: Worker;
  blendedStatus?: WorkerBlendedStatus;
};

export type CompanyWorkerSmall = Pick<
  CompanyWorker,
  'companyId' | 'id' | 'companyName' | 'relation' | 'roles' | 'status' | 'timeCreated' | 'workerId' | 'orgMappingIds'
>;

export type CompanyWorkerFilterParams = {
  wildcard?: string;
  blendedStatus?: CompanyWorkerStatus | WorkerQueryStatus[];
  trade?: string;
  status?: CompanyWorkerStatus;
  orgList?: string | string[];
  workerIdList?: string[];
  companyLevel?: boolean;
  projectId?: string;
};
export type CompanyWorkerRequestParams = {
  filterParams: CompanyWorkerFilterParams;
  limit: number;
  offset: number;
  sortField?: string;
  sortOrder?: string;
};

export type SharedLogin = {
  scopes: string[];
  taskIds: string[];
  loginExpireSec?: number;
};

export type InvitedWorker = {
  companyId: string;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  inviteCode: string;
  logoUrl: string;
  mobileNumber: string;
  workerId: string;
};

export enum InviteBy {
  Email = 'email',
  Phone = 'phone',
}

export type CompanyInvite = {
  companyId?: string;
  workerId?: string;
  projectId?: string;
};

export type WorkerFormValues = {
  fullName: string;
  phoneCode: string;
  phoneNumber?: string;
  workerEmail?: string;
  inviteBy?: InviteBy;
  trade: string;
  sendEmail?: boolean;
  sendSms?: boolean;
  roles: CompanyWorkerRole[];
  orgMappingIds?: string[];
} & CompanyInvite;

export type ProfileFormValues = {profilePic?: File} & Pick<Worker, 'fullName' | 'email' | 'trade'>;

export type CompanyInfoType = Pick<InvitedWorker, 'logoUrl' | 'companyName'>;

export const WorkerTrades = {
  administration: 'Administration',
  ALC: 'ALC',
  'app developer': 'App Developer',
  architect: 'Architect',
  'BIM Coordinator': 'BIM Coordinator',
  'business development manager': 'Business Development Manager',
  carpenter: 'Carpenter',
  'concrete finisher': 'Concrete Finisher',
  developer: 'Developer',
  'digital fabrication specialist': 'Digital Fabrication Specialist',
  draftsman: 'Draftsman',
  driver: 'Driver',
  drywall: 'Drywall',
  'drywall finisher': 'Drywall Finisher',
  electrician: 'Electrician',
  engineer: 'Engineer',
  'equipment operator': 'Equipment operator',
  'fire alarm': 'Fire Alarm',
  fireproofing: 'Fireproofing',
  flooring: 'Flooring',
  foreman: 'Foreman',
  'general contractor': 'General contractor',
  glazier: 'Glazier',
  handyman: 'Handyman',
  'human resources specialist': 'Human Resources Specialist',
  HVAC: 'HVAC',
  'HVAC Control Engineer': 'HVAC Control Engineer',
  'innovation engineer': 'Innovation engineer',
  insulator: 'Insulator',
  ironwork: 'Ironwork',
  laborer: 'Laborer',
  landscaper: 'Landscaper',
  manager: 'Manager',
  'manufacturing engineer': 'Manufacturing Engineer',
  mason: 'Mason / Concreter',
  'metal framer': 'Metal Framer',
  millwork: 'Millwork',
  millwright: 'Millwright',
  owner: 'Owner',
  painter: 'Painter',
  pipefitter: 'Pipefitter',
  plastering: 'Plastering',
  plumber: 'Plumber',
  'production operator': 'Production Operator',
  'program manager': 'Program Manager',
  'project manager': 'Project Manager',
  'purchase agent': 'Purchase Agent',
  'quality control inspector': 'Quality Control Inspector',
  receptionist: 'Receptionist',
  'reinforcing ironworker': 'Reinforcing Ironworker',
  rigger: 'Rigger',
  roofer: 'Roofer',
  scaffolding: 'Scaffolding',
  security: 'Security',
  'sheet metal worker': 'Sheet metal worker',
  'site supervisor': 'Site Supervisor',
  'structural ironworker': 'Structural Ironworker',
  'structured cabling': 'Structured Cabling',
  subcontractor: 'Subcontractor',
  superintendent: 'Superintendent',
  supplier: 'Supplier',
  unspecified: 'Unspecified',
  waterproofer: 'Waterproofer',
  welder: 'Welder',
} as const;

export type WorkerTrade = keyof typeof WorkerTrades;
