import cn from 'classnames';
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {WorkersApi} from 'api';
import {Loader, PhoneControl} from 'shared/components';
import {CtrlCheck, FormControl} from 'shared/components/CoreNewUI';
import CtrlCheckGroup from 'shared/components/CoreNewUI/CtrlCheckGroup/CtrlCheckGroup';
import {DEFAULT_COUNTRY_CODES} from 'shared/constants/common';
import {useAnalyticsService} from 'shared/hooks';
import {InviteBy, WorkerFormValues} from 'shared/models/worker';
import {getInviteMemberHandler, getWorkerMixpanelLabel} from 'shared/utils/inviteWorker';

import s from '../../Tasks/components/SidebarPanel/components/MemberInvite/MemberInvite.module.scss';
import {useInviteWorker} from '../mutations';

import {getValidationSchema} from './validation';

type Props = {
  projectId: string;
};

const defaultValues: WorkerFormValues = {
  fullName: '',
  phoneCode: DEFAULT_COUNTRY_CODES[0].value,
  phoneNumber: '',
  workerEmail: '',
  trade: 'unspecified',
  sendEmail: false,
  sendSms: false,
  roles: ['worker'],
  orgMappingIds: null,
  inviteBy: InviteBy.Email,
};

const inviteMember = WorkersApi.inviteToProject.bind(WorkersApi);

const ShortWorkerForm = forwardRef(({projectId}: Props, ref) => {
  const formik = useRef<FormikProps<WorkerFormValues>>();
  const {t} = useTranslation('worker');
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.worker.formFields;

  useImperativeHandle(ref, () => formik.current);

  const inviteWorker = useInviteWorker(inviteMember, formik);
  const {handleSubmit, checkRole} = getInviteMemberHandler(formik, projectId, onSubmit);

  async function onSubmit(values: WorkerFormValues) {
    try {
      return await inviteWorker.mutateAsync({values});
    } catch (e) {
      console.error(e);
    }
  }

  const validationSchema = useCallback(() => getValidationSchema(t), [t]);

  const getEmailFields = (setFieldValue, setValues, values, error) => (
    <>
      <div className={s.MemberInvite__formItem}>
        <FormControl label={t('form.email.label', 'Email Address')} name="workerEmail" error={error}>
          <Field
            as="input"
            className="ctrl-textfield"
            placeholder={t('form.email.placeholder', 'example@email.com')}
            onChange={(e) => {
              setValues({
                ...values,
                workerEmail: e.target.value,
                sendEmail: true,
              });
            }}
          />
        </FormControl>
      </div>

      <div className={s.MemberInvite__formItem}>
        <Field name="sendEmail">
          {({field}: FieldProps) => (
            <CtrlCheck
              fieldType="checkbox"
              label={`${t('form.email.send', 'Send invitation to')} ${
                values.workerEmail || t('form.email.placeholder', 'example@email.com')
              }`}
              labelSize="s"
            >
              <input
                {...field}
                checked={!!field.value}
                type="checkbox"
                onChange={(e) =>
                  mixpanel.trackWithAction(
                    () => setFieldValue(field.name, e.target.checked),
                    mixpanelEvents.notifyEmail(getWorkerMixpanelLabel(!!projectId)),
                  )
                }
              />
            </CtrlCheck>
          )}
        </Field>
      </div>
    </>
  );

  const getPhoneFields = (setFieldValue, setValues, values) => (
    <>
      <div className={s.MemberInvite__formItem}>
        <FormControl label="Phone number">
          <PhoneControl
            className={s.MemberInvite__phoneCode}
            disabled={values.roles.includes('company_admin')}
            onChange={(changes) => {
              setValues({
                ...values,
                phoneCode: changes.code,
                phoneNumber: changes.phone,
                sendSms: true,
              });
            }}
            value={{phone: values.phoneNumber, code: values.phoneCode}}
          />
        </FormControl>
      </div>

      <div className={s.MemberInvite__formItem}>
        <Field name="sendSms">
          {({field}: FieldProps) => (
            <CtrlCheck
              fieldType="checkbox"
              label={`${t('form.phone.send', 'Send SMS invitation to')} ${values.phoneCode} ${
                values.phoneNumber || t('form.phone.placeholder', '(XXX) XXX-XXXX')
              }`}
              labelSize="s"
            >
              <input
                {...field}
                checked={!!field.value}
                type="checkbox"
                name="sendToPhone"
                onChange={(e) =>
                  mixpanel.trackWithAction(
                    () => setFieldValue(field.name, e.target.checked),
                    mixpanelEvents.notifySMS(getWorkerMixpanelLabel(!!projectId)),
                  )
                }
              />
            </CtrlCheck>
          )}
        </Field>
      </div>
    </>
  );

  return (
    <Formik<WorkerFormValues>
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={defaultValues}
      innerRef={formik}
    >
      {({setFieldValue, values, setValues, errors, isSubmitting}) => (
        <Form>
          {isSubmitting && <Loader />}
          <section className={cn(s.MemberInvite)}>
            <div className={s.MemberInvite__form}>
              <div className={`${s.MemberInvite__formPart} ${s.MemberInvite__formPart_lastEl_field}`}>
                <div className={s.MemberInvite__formItem}>
                  <FormControl label={t('form.name.label', 'Full Name')} name="fullName" error={errors.fullName}>
                    <Field
                      as="input"
                      className="ctrl-textfield"
                      placeholder={t('form.name.placeholder', 'Full Name')}
                    />
                  </FormControl>
                </div>
              </div>
              <div className={s.MemberInvite__formPart}>
                <CtrlCheckGroup title="Member's Role">
                  <Field name="roles" type="radio" value="company_admin">
                    {({field}: FieldProps) => (
                      <CtrlCheck
                        {...field}
                        className={s.MemberInvite__roles}
                        fieldType="radio"
                        label={t('form.admin.label_short', 'Admin')}
                        description={t('form.admin.tip', 'Admin users can create activities and manage workers')}
                      >
                        <input
                          type="radio"
                          name="membersRole"
                          checked={!!values.roles.includes(field.value)}
                          onChange={() =>
                            mixpanel.trackWithAction(
                              () => checkRole(field.name, field.value),
                              mixpanelEvents.makeAdmin(getWorkerMixpanelLabel(!!projectId)),
                            )
                          }
                        />
                      </CtrlCheck>
                    )}
                  </Field>
                  <Field name="roles" type="radio" value="foreman">
                    {({field}: FieldProps) => (
                      <CtrlCheck
                        {...field}
                        className={s.MemberInvite__roles}
                        fieldType="radio"
                        label={t('form.foreman.label', 'Make Foreman')}
                        description={t(
                          'form.foreman.tip',
                          'Foreman users can add new workers by scanning QR code using the app, and create/edit activities',
                        )}
                      >
                        <input
                          type="radio"
                          name="membersRole"
                          checked={!!values.roles.includes(field.value)}
                          onChange={() =>
                            mixpanel.trackWithAction(
                              () => checkRole(field.name, field.value),
                              mixpanelEvents.makeForeman(getWorkerMixpanelLabel(!!projectId)),
                            )
                          }
                        />
                      </CtrlCheck>
                    )}
                  </Field>
                  <Field name="roles" type="radio" value="worker">
                    {({field}: FieldProps) => (
                      <CtrlCheck
                        {...field}
                        className={s.MemberInvite__roles_last}
                        fieldType="radio"
                        label={t('form.worker.label', 'Make Worker')}
                        description={t(
                          'form.worker.tip',
                          'Worker users can use the app to update task status, add photos/ comments',
                        )}
                      >
                        <input
                          type="radio"
                          name="membersRole"
                          checked={!!values.roles.includes(field.value)}
                          onChange={() =>
                            mixpanel.trackWithAction(
                              () => checkRole(field.name, field.value),
                              mixpanelEvents.makeForeman(getWorkerMixpanelLabel(!!projectId)),
                            )
                          }
                        />
                      </CtrlCheck>
                    )}
                  </Field>
                </CtrlCheckGroup>
              </div>
              <div className={s.MemberInvite__formPart}>
                <div className={s.MemberInvite__formItem}>
                  <CtrlCheckGroup title={t('form.invited.title', 'Invited By')} direction="line">
                    <CtrlCheck fieldType="radio" label={t('form.invited.email', 'Email')}>
                      <Field
                        as="input"
                        className="ctrl-radios__field"
                        type="radio"
                        name="inviteBy"
                        value={InviteBy.Email}
                      />
                    </CtrlCheck>
                    <CtrlCheck
                      fieldType="radio"
                      label={t('form.invited.phone', 'Phone Number')}
                      disabled={values.roles.includes('company_admin')}
                    >
                      <Field
                        as="input"
                        className="ctrl-radios__field"
                        type="radio"
                        name="inviteBy"
                        value={InviteBy.Phone}
                        disabled={values.roles.includes('company_admin')}
                      />
                    </CtrlCheck>
                  </CtrlCheckGroup>
                </div>
                {values.inviteBy === InviteBy.Email
                  ? getEmailFields(setFieldValue, setValues, values, errors.workerEmail)
                  : getPhoneFields(setFieldValue, setValues, values)}
              </div>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
});
export default ShortWorkerForm;
