import React, {FC} from 'react';

import {AssignmentState} from '../../utils/constants';
import {useAssigneesTabContext} from '../AssigneesTabContext';

import ReassignmentResponsible from './ReassignmentResponsible';
import ResponsibleEmpty from './ResponsibleEmpty';
import ResponsibleSelected from './ResponsibleSelected';

type Props = {
  assignmentState: AssignmentState;
  disabled?: boolean;
};

const ResponsibleSection: FC<Props> = ({assignmentState, disabled}: Props) => {
  const {responsible, setAssignmentState} = useAssigneesTabContext();

  return (
    <>
      {assignmentState === AssignmentState.assignResponsible && (
        <ReassignmentResponsible onClose={() => setAssignmentState(AssignmentState.empty)} />
      )}
      {!!responsible.length && assignmentState !== AssignmentState.assignResponsible && (
        <ResponsibleSelected
          disabled={disabled}
          onChooseResponsible={() => setAssignmentState(AssignmentState.assignResponsible)}
        />
      )}
      {!responsible.length && assignmentState !== AssignmentState.assignResponsible && (
        <ResponsibleEmpty
          disabled={disabled}
          onChooseResponsible={() => setAssignmentState(AssignmentState.assignResponsible)}
        />
      )}
    </>
  );
};

export default ResponsibleSection;
