import {GanttStatic} from 'dhtmlx-gantt';
import React, {FC, useRef} from 'react';
import StateManager from 'react-select';

import {CoreSelect} from 'shared/components';
import {getProjectCustomField} from 'shared/helpers/project';
import {useMount} from 'shared/hooks/core';
import {useProject} from 'shared/hooks/useProject';
import {ProjectCustomFieldType} from 'shared/models/project';

import {GanttTask} from '../../../../types';
import {updateTaskCustomFieldValue} from '../../helpers';
import {useInlineSelectController} from '../../useInlineSelectController';

import s from './CustomFieldSelectorEditor.module.scss';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
  fieldName: string;
};

export const CustomFieldSelectEditor: FC<Props> = ({gantt, task, fieldName}: Props) => {
  const {onKeyUp, onKeyDown, onClose, focusOrHide} = useInlineSelectController(gantt);
  const {project} = useProject(task.projectId);
  const selectRef = useRef<StateManager>();

  const projectField = getProjectCustomField(project, fieldName);
  const isMulti = projectField?.fieldType === ProjectCustomFieldType.multiselect;
  const taskField = task.custom_fields.find(({internal_field_name}) => internal_field_name === fieldName);

  useMount(() => {
    selectRef.current.focus();
  });

  const getSelectOptions = () => {
    if (!projectField?.fieldData) return [];
    return JSON.parse(projectField?.fieldData).map((value) => ({value, label: value}));
  };

  const onChange = async (value: string) => {
    const prepared = JSON.stringify([].concat(value ?? ''));
    updateTaskCustomFieldValue(task, {field: fieldName, value: prepared});
    gantt.updateTask(task.id);
    focusOrHide();
  };

  const getValue = () => {
    if (!taskField?.value) return '';
    const value = JSON.parse(taskField.value);
    return isMulti ? value : value[0];
  };

  return (
    <CoreSelect
      className={s.customFieldSelector}
      isMulti={isMulti}
      isClearable={true}
      ref={selectRef}
      value={getValue()}
      options={getSelectOptions()}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      menuIsOpen={true}
      closeMenuOnSelect={false}
      tabSelectsValue={false}
    />
  );
};
