import * as Sentry from '@sentry/browser';
import {confirmPasswordReset, verifyPasswordResetCode} from 'firebase/auth';
import {Field, Formik, FormikProps} from 'formik';
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {toast} from 'react-toastify';
import {object, ref, string} from 'yup';

import Firebase from 'services/Firebase';
import FirebaseService from 'services/Firebase';
import {Button, FormControl, Loader, MetaTags, useAuth} from 'shared/components';
import {usePasswordVisibility} from 'shared/components/CoreForm/hooks/usePasswordVisibility';
import DownloadAppLink from 'shared/components/DownloadAppLink';
import ZetOnboardingContainer from 'shared/components/OnboardingLayout/ZetOnboardingContainer';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {parseUrlQuery} from 'shared/helpers/queryParams';
import {useAnalyticsService, useLandingStyles} from 'shared/hooks';
import {useMount, useScrollToTopOnMount} from 'shared/hooks/core';
import OnboardingLayout from 'shared/layout/base/OnboardingLayout';

import css from './resetPassword.module.scss';

type ResetPasswordForm = {emailAddress: string; password: string; repeatPassword: string};

const validationSchema = object({
  emailAddress: string().email('Must be a valid email address').required('This field is required'),
  password: string().min(6, 'Must be at least 6 characters long').max(255, 'Must not exceed 255 characters long'),
  passwordRepeat: string().oneOf([ref('password'), null], 'Passwords must match'),
});

const ResetPassword: FC = () => {
  const [passwordHasChanged, setPasswordHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const formikRef = useRef<FormikProps<ResetPasswordForm>>();
  const {user} = useAuth();
  const history = useHistory();
  const routes = useLocalizedRoutes();
  const location = useLocation();
  const actionCode = useRef<string>('');
  const [isInvalidActionCode, setIsInvalidActionCode] = useState(false);
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.forgotPassword;

  useScrollToTopOnMount();
  useLandingStyles();

  const passwordVisibility = usePasswordVisibility();
  const confirmVisibility = usePasswordVisibility();

  function verifyResetCode() {
    const {oobCode = ''} = parseUrlQuery<{oobCode: string}>(location.search, {oobCode: 'string'});
    if (oobCode) {
      verifyPasswordResetCode(Firebase.auth, oobCode)
        .then((email) => {
          formikRef.current.setFieldValue('emailAddress', email);
          actionCode.current = oobCode;
        })
        .catch((e) => {
          Sentry.captureException(e);
          setIsInvalidActionCode(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      history.push(routes.logIn);
    }
  }

  useMount(() => {
    verifyResetCode();
    mixpanel.track(mixpanelEvents.pages.confirmPassword, {'User Email': formikRef.current?.values.emailAddress || ''});
  });

  useEffect(() => {
    if (user && !user.isAnonymous) {
      history.push(routes.projects);
    }
  }, [user, history]);

  const onboardingCopy = useMemo(() => {
    if (isInvalidActionCode) {
      return {
        title: 'Oops!',
        description: 'The password reset link is invalid, expired or has already been used.',
      };
    }

    if (passwordHasChanged) {
      return {
        title: 'Password Reset',
        description: 'Password has been successfully reset. Please login with new password.',
      };
    }

    return {
      title: 'Password Reset',
      description: 'Enter a new password and re-enter to confirm.',
    };
  }, [passwordHasChanged, isInvalidActionCode]);

  const metaTags = useMemo(() => {
    return {
      title: !passwordHasChanged ? 'Reset Password' : 'Updated Password',
      description: !passwordHasChanged
        ? 'Update your password so you can get back to Crews by Core'
        : "Your updated password has been saved. You're ready to log back in to Crews by Core and manage your activities.",
      keywords:
        'Construction Productivity, Task Management, Crew Management, Jobsite, Foreman, Project Delivery, Construction Management, Collaboration, Construction Technology, Construction software',
    };
  }, [passwordHasChanged]);

  const submitForm = async (values) => {
    if (passwordHasChanged) {
      mixpanel.track(mixpanelEvents.buttons.loginAfterSubmit, {'User Email': values.emailAddress});
      return history.push(routes.logIn);
    }
    mixpanel.track(mixpanelEvents.buttons.confirmPassword, {'User Email': values.emailAddress});
    await handleResetPassword(values.password);
  };

  const handleResetPassword = async (newPassword: string) => {
    setIsLoading(true);
    try {
      await confirmPasswordReset(FirebaseService.auth, actionCode.current, newPassword);
      setPasswordHasChanged(true);
    } catch (e) {
      Sentry.captureException(e);
      if (isAxiosError(e)) {
        toast.error(extractAxiosError(e));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onResetPasswordBtnClick = (event) => {
    event.preventDefault();
    formikRef.current.submitForm();
  };

  return (
    <>
      <MetaTags title={metaTags.title} description={metaTags.description} keywords={metaTags.keywords} />
      <OnboardingLayout formClassName="form-onboarding-z--without-nav form-onboarding-z--without-footer">
        <ZetOnboardingContainer title={onboardingCopy.title} description={onboardingCopy.description}>
          {!isInvalidActionCode && (
            <Formik<ResetPasswordForm>
              initialValues={{emailAddress: '', password: '', repeatPassword: ''}}
              onSubmit={submitForm}
              innerRef={formikRef}
              validationSchema={validationSchema}
            >
              {({isSubmitting}) => (
                <>
                  {!passwordHasChanged && (
                    <>
                      <div className="form-onboarding-z__item">
                        <FormControl name="emailAddress" label="Email Address">
                          <Field className="ctrl-textfield" placeholder="Enter Email Address" readOnly />
                        </FormControl>
                      </div>
                      <div className="form-onboarding-z__item">
                        <FormControl
                          name="password"
                          label="Password"
                          iconName={passwordVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
                          onIconClick={passwordVisibility.toggleVisibility}
                        >
                          <Field
                            name="password"
                            type={passwordVisibility.visible ? 'text' : 'password'}
                            className="ctrl-textfield"
                            placeholder="Enter password"
                          />
                        </FormControl>
                      </div>
                      <div className="form-onboarding-z__item">
                        <FormControl
                          name="passwordRepeat"
                          label="Repeat Password"
                          iconName={confirmVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
                          onIconClick={confirmVisibility.toggleVisibility}
                        >
                          <Field
                            type={confirmVisibility.visible ? 'text' : 'password'}
                            className="ctrl-textfield"
                            placeholder="Repeat password"
                          />
                        </FormControl>
                      </div>
                    </>
                  )}
                  <div className="form-onboarding-z__item form-onboarding-z__item--actions">
                    <Button onClick={onResetPasswordBtnClick} disabled={isSubmitting} data-cy="submit_btn">
                      {!passwordHasChanged ? 'Reset Password' : 'Log In to Crews by Core Pro'}
                    </Button>
                    {passwordHasChanged && (
                      <div className={css.formOnboardingZ__app}>
                        <span className="form-onboarding-z__text">or Log in to the app</span>
                        <div className={css.formOnboardingZ__downloadLinks}>
                          <DownloadAppLink.iOS onClick={() => mixpanel.track(mixpanelEvents.download.ios)} />
                          <DownloadAppLink.Android onClick={() => mixpanel.track(mixpanelEvents.download.android)} />
                        </div>
                      </div>
                    )}
                  </div>
                  {isLoading && <Loader />}
                </>
              )}
            </Formik>
          )}
        </ZetOnboardingContainer>
      </OnboardingLayout>
    </>
  );
};
export default ResetPassword;
