import classnames from 'classnames';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import StatusElement from 'shared/components/StatusElement';
import {taskStatusClassNameOverride, taskStatusIcons, getPrettyTaskStatuses} from 'shared/constants/statuses';
import {TaskStatus as TaskStatusEnum} from 'shared/models/task';

import {TaskStatusIcon} from '../';

type TaskStatusProps = {value: string; className?: string};

const TaskStatus: FC<TaskStatusProps> = ({value, className}) => {
  const {t} = useTranslation(['gantt', 'common']);
  const statuses = getPrettyTaskStatuses(t);
  return (
    <StatusElement
      className={classnames(`status-element--${taskStatusClassNameOverride[value] || value}`, className)}
      label={statuses[value]}
      icon={
        taskStatusIcons[value] && (
          <TaskStatusIcon className="status-element__icon" name={value as TaskStatusEnum} size={12} colorFill />
        )
      }
    />
  );
};
export default TaskStatus;
