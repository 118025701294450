import dayjs from 'dayjs';

import {Feedback} from 'shared/models/feedback';
import {FeedbackProjectModelDTO} from 'shared/models/task';

import ApiAxios from './axios';

export class FeedbackService {
  private getBasePath(taskId: string) {
    return `/tasks/${taskId}/feedback`;
  }

  async postFeedback(taskId: string, body: Feedback[]): Promise<FeedbackProjectModelDTO[]> {
    const res = await ApiAxios.post(this.getBasePath(taskId), body);
    return res.data;
  }

  async getFeedback(taskId: string) {
    const res = await ApiAxios.get<FeedbackProjectModelDTO[]>(this.getBasePath(taskId));
    return res.data;
  }

  async getAllFeedBackForProject(projectId: string, afterTimeCreated?: Date) {
    const isoDate = dayjs(afterTimeCreated).toISOString();
    const queryString = afterTimeCreated ? `?after_time_created=${isoDate}` : '';
    const res = await ApiAxios.get<FeedbackProjectModelDTO[]>(`/projects/${projectId}/feedback${queryString}`);
    return res.data;
  }
}

export default new FeedbackService();
