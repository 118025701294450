import cn from 'classnames';
import React, {
  cloneElement,
  CSSProperties,
  forwardRef,
  isValidElement,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {KEYCODE} from 'shared/constants/common';
import {useOutsideClick} from 'shared/hooks/core';
import {useKey} from 'shared/hooks/useKey';

import CtrlButton from '../CtrlButton/CtrlButton';

import s from './CtrlDrop.module.scss';

export type AllowedViewPortPosition = 'center' | 'left' | 'right';

type Props = {
  className?: string;
  toggleElement: ReactNode;
  toggleElementId?: string;
  style?: CSSProperties;
  header?: {
    title: string;
    button?: {
      title: string;
      onClick: (e) => void;
    };
    buttonClose?: boolean;
  };
  footer?: ReactNode;
  color?: string;
  menuWidth?: number;
  maxMenuHeight?: number;
  dataCy?: string;
  viewportPosition?: AllowedViewPortPosition;
  viewportStyle?: 'popup';
  disabled?: boolean;
  excludeClassOutsideClick?: string[];
  closeIconStyle?: CSSProperties;
  viewportAlwaysInPage?: boolean;
  onAfterToggleClick?: (isActive: boolean) => void;
};

export type CtrlDropdownActions = {
  close: () => void;
  toggle: (e: MouseEvent<HTMLDivElement>) => void;
};

const CtrlDrop = forwardRef<CtrlDropdownActions, PropsWithChildren<Props>>(
  (
    {
      className,
      toggleElement,
      toggleElementId,
      header,
      children,
      color,
      footer,
      menuWidth,
      maxMenuHeight,
      style,
      dataCy,
      viewportPosition,
      viewportStyle,
      disabled,
      excludeClassOutsideClick,
      closeIconStyle,
      viewportAlwaysInPage,
      onAfterToggleClick,
    },
    ref,
  ) => {
    const [isActive, setIsActive] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const toggle = () => setIsActive((prev) => !prev);
    const onClickHandler = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        if (e.target instanceof Element && (!toggleElementId || !!e.target.closest(`#${toggleElementId}`))) {
          e.stopPropagation();
          toggle();
          if (onAfterToggleClick) onAfterToggleClick(!isActive);
        }
      },
      [isActive, toggleElementId],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          close: () => setIsActive(false),
          toggle: (e) => onClickHandler(e),
        };
      },
      [onClickHandler],
    );

    useOutsideClick({
      ref: containerRef,
      callback: (e) => {
        if (excludeClassOutsideClick?.some((c) => (e.target as HTMLDivElement).closest('.' + c))) {
          return;
        }
        isActive && toggle();
      },
    });

    const menuStyles: CSSProperties = Object.assign(
      {},
      menuWidth ? {width: typeof menuWidth === 'number' ? `${menuWidth}px` : menuWidth} : null,
      maxMenuHeight
        ? {maxHeight: typeof maxMenuHeight === 'number' ? `${maxMenuHeight}px` : maxMenuHeight, overflow: 'auto'}
        : null,
    );

    useKey({
      condition: true,
      key: KEYCODE.ESC,
      actionFn: () => {
        if (isActive) toggle();
      },
    });

    return (
      <div
        data-cy={dataCy}
        className={cn(s.ctrlDrop, isActive && s.ctrlDrop__toggled, color && s[`ctrlDrop_color_${color}`], className, {
          [s.ctrlDrop_disabled]: disabled,
        })}
        style={style}
        ref={containerRef}
      >
        {isValidElement(toggleElement) &&
          cloneElement(toggleElement, {
            ...toggleElement.props,
            className: cn(s.ctrlDrop__button, toggleElement.props.className),
            onClick: (e) => {
              if (typeof toggleElement.props.onClick === 'function') {
                toggleElement.props.onClick(e);
              }
              onClickHandler(e);
            },
          })}
        <div
          className={cn(
            s.ctrlDrop__viewport,
            viewportPosition && s[`ctrlDrop__viewport_position_${viewportPosition}`],
            viewportStyle && s[`ctrlDrop__viewport_style_${viewportStyle}`],
            viewportAlwaysInPage && s[`ctrlDrop__viewport_in-page`],
          )}
        >
          <div className={cn(s.ctrlDrop__content)} style={menuStyles}>
            {header?.title && (
              <div className={s.ctrlDrop__header}>
                <div className={s.ctrlDrop__title}>{header.title}</div>
                {/* TODO: should be composition*/}
                {header?.button?.title && (
                  <button className={s.ctrlDrop__buttonReset} type="button" onClick={header.button.onClick}>
                    {header.button.title}
                  </button>
                )}
                {header?.buttonClose && (
                  <CtrlButton
                    className={s.ctrlDrop__buttonClose}
                    closeIconStyle={closeIconStyle}
                    color="action"
                    size="xs"
                    icon="clear"
                    iconOnly={true}
                    onClick={() => setIsActive(false)}
                  >
                    Close
                  </CtrlButton>
                )}
              </div>
            )}
            <div className={s.ctrlDrop__body}>{children}</div>
            {footer && <div className={s.ctrlDrop__footer}>{footer}</div>}
          </div>
        </div>
      </div>
    );
  },
);
export default CtrlDrop;
CtrlDrop.displayName = 'Dropdown';
