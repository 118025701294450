import {Formik, FormikProps, Field, FieldProps} from 'formik';
import React, {FC, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {object, number} from 'yup';

import {SubscriptionApi} from 'api';
import {Avatar, Button, Icon, Popup, useConfirm} from 'shared/components';
import TiersPricingTable from 'shared/components/ProPlanPriceTable';

import {useSubscriptionContext} from '../CheckoutContext';
import {subscriptionActionsCreators, SubscriptionStep} from '../state';
import {PROPLAN_MAX_RANGE_VALUE} from '../utils/constants';
import {CheckoutNavigationProps} from '../utils/types';
// TODO: remove those style fix when will it be fixed in main css files
import 'shared/components/ProPlanPriceTable/style.css';

type PickPlanProps = CheckoutNavigationProps;

const PickPlan: FC<PickPlanProps> = ({close}) => {
  const {t} = useTranslation('company');
  const {confirm} = useConfirm();
  const [inProgress, setInProgress] = useState(false);
  const formik = useRef<FormikProps<{quantity: number}>>();
  const {
    state: {proPlan, subscription, status, currentPlan},
    dispatch,
    company,
  } = useSubscriptionContext();

  const validationSchema = useMemo(() => {
    return object({
      quantity: number()
        .min(
          company.currentSeatCount,
          t(
            'subscription.upgrade_plan.validation_error.min_seat_count',
            'Your license count is less then number of workers. To keep service uninterrupted, please adjust number of licenses accordingly.',
          ),
        )
        .max(PROPLAN_MAX_RANGE_VALUE),
    });
  }, [company.currentSeatCount, t]);

  const startStripeCheckout = async () => {
    setInProgress(true);
    try {
      const session = await SubscriptionApi.createCheckoutSession(company.id, {
        priceid: proPlan.priceId,
        quantity: String(formik.current.values.quantity),
        successUrl: `${window.origin}/checkout-result.html?success=1`,
        cancelUrl: `${window.origin}/checkout-result.html?success=0`,
      });
      dispatch(subscriptionActionsCreators.setSession(session));
      dispatch(subscriptionActionsCreators.setStep(SubscriptionStep.checkout));
    } catch (error) {
      toast.error(t('subscription.upgrade_plan.errors.failed_checkout_session', 'Failed to create checkout session'));
      setInProgress(false);
    }
  };

  const upgradePlan = async () => {
    if (
      await confirm({
        description: t(
          'subscription.upgrade_plan.confirmation.description',
          'Are you sure you want to upgrade your subscription plan?',
        ),
        cancelButton: t('subscription.upgrade_plan.confirmation.cancelButtonText', 'Cancel'),
        acceptButton: t('subscription.upgrade_plan.confirmation.acceptButtonText', 'Upgrade Now'),
      })
    ) {
      setInProgress(true);
      try {
        const subscriptions = await SubscriptionApi.changeSubscriptionPlan(
          company.id,
          subscription.id,
          proPlan.priceId,
          formik.current.values.quantity,
        );
        dispatch(subscriptionActionsCreators.setSubscription(subscriptions.find((sub) => sub.id === subscription.id)));
        dispatch(subscriptionActionsCreators.setStep(SubscriptionStep.finished));
      } catch {
        setInProgress(false);
        toast.error(t('subscription.upgrade_plan.errors.failed_upgrade_plan', 'Failed to upgrade plan'));
      }
    }
  };

  const startAction = async () => {
    status === SubscriptionStep.upgrading ? upgradePlan() : startStripeCheckout();
  };

  const closeAndReset = () => {
    close();
    dispatch(subscriptionActionsCreators.reset());
  };

  return (
    <Formik
      initialValues={{quantity: company.currentSeatCount}}
      validationSchema={validationSchema}
      onSubmit={startAction}
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={false}
      innerRef={formik}
    >
      {({submitForm, setFieldValue}) => (
        <>
          <Popup.Body>
            <div className="form-plan">
              <div>
                <h2 className="form-plan__title">
                  {currentPlan
                    ? t('subscription.upgrade_plan.popup.title.update', 'Upgrade your plan')
                    : t('subscription.upgrade_plan.popup.title.new', 'Pick your plan')}
                </h2>
                <section className="card-company form-plan__card-info">
                  <div className="card-company__container">
                    <Avatar className="card-company__avatar" src={company.logoUrl} />
                    <div className="card-company__info">
                      <h2 className="card-company__title">{company.companyName}</h2>
                      <div className="card-company__workers">
                        {company.currentSeatCount}{' '}
                        {company.currentSeatCount > 1
                          ? t('subscription.upgrade_plan.popup.currentSeatCount.plural', 'workers')
                          : t('subscription.upgrade_plan.popup.currentSeatCount.single', 'worker')}
                      </div>
                      <div className="card-company__workers">
                        {subscription?.stripeSubscriptionInfo?.quantity || 0}{' '}
                        {t('subscription.upgrade_plan.popup.license_count', 'Current Licenses')}
                      </div>
                    </div>
                  </div>
                </section>
                <Field name="quantity">
                  {({field}: FieldProps) => (
                    <TiersPricingTable.PickPlan
                      proPlan={proPlan}
                      onSelectPlan={(quantity) => setFieldValue(field.name, quantity)}
                    />
                  )}
                </Field>
              </div>
            </div>
          </Popup.Body>
          <Popup.Footer>
            <Button
              icon={<Icon name="arrow_backward" colorFill />}
              className="ctrl-btn--color-light ctrl-btn--shadow popup__button popup__button--back"
              onClick={closeAndReset}
            >
              {t('subscription.upgrade_plan.popup.actions.back_to_company', 'Back to company')}
            </Button>
            <Button
              disabled={inProgress}
              className={`popup__button${inProgress ? ' is-processing' : ''}`}
              icon={inProgress ? <Icon colorFill name="autorenew" size={24} /> : null}
              onClick={submitForm}
            >
              {status === SubscriptionStep.upgrading
                ? t('subscription.upgrade_plan.popup.actions.upgrade_plan', 'Upgrade')
                : t('subscription.upgrade_plan.popup.actions.go_to_checkout', 'Checkout')}
            </Button>
          </Popup.Footer>
        </>
      )}
    </Formik>
  );
};

export default PickPlan;
