import {groupFeedback} from 'modules/Tasks/components/SidebarPanel/components/CommentsTab/CommentsTimeline/CommentCard/hooks/utils/groupFeedback';
import {FeedbackDailyTypeMap, DailyType, EventTypeMap} from 'shared/models/feedback';
import {TaskDetailsModelDTO, FeedbackProjectModelDTO, FeedbackByDateTask} from 'shared/models/task';
import {FeedbackGrouped} from 'shared/models/task/comments';
import {CompanyWorker} from 'shared/models/worker';

export function mergeFeedbackIntoTask(
  task: TaskDetailsModelDTO,
  feedbackResponses: FeedbackProjectModelDTO[],
  projectWorkers: CompanyWorker[],
  schedIntersect: [string, string],
): {feedbackByDate: FeedbackByDateTask[]; comments: FeedbackGrouped[]} {
  const copyOfFeedbackBydate = [...task.feedbackByDate];
  const filteredFeedbackResponse = feedbackResponses.filter(
    (fb) =>
      fb.feedbackType === FeedbackDailyTypeMap.DailyCompletion || fb.feedbackType === FeedbackDailyTypeMap.DailyLabor,
  );

  const groupedFeedback = groupFeedback({
    feedbackList: feedbackResponses,
    findReportSubmitterById: (workerId: string) => {
      return projectWorkers?.find((worker: CompanyWorker) => worker?.workerId === workerId);
    },
    filterFeedbackByType: [
      FeedbackDailyTypeMap.DailyCompletion,
      FeedbackDailyTypeMap.DailyLabor,
      EventTypeMap.taskEvent,
    ],
    filterFeedbackByDate: schedIntersect,
  });

  const structuredFeedback = Array.from(groupedFeedback?.values() ?? []);

  filteredFeedbackResponse?.forEach((response) => {
    const feedbackField = response.feedbackType; // 'daily_labor' or 'completion_amount'

    let feedbackByDateEntry = copyOfFeedbackBydate.find((entry) => entry.field === feedbackField);

    if (!feedbackByDateEntry) {
      feedbackByDateEntry = {field: feedbackField as DailyType, feedbackByDate: []};
      copyOfFeedbackBydate.push(feedbackByDateEntry);
    }

    const {date, workerId, timeCreated} = response;
    const {valueInteger} = response.payload;

    const newFeedbackEntry = {
      date,
      timeCreated,
      value: valueInteger,
      workerId,
    };

    // Check if there's already an entry for this date and timeCreated
    const existingEntryIndex = feedbackByDateEntry.feedbackByDate.findIndex(
      (entry) => entry.timeCreated === response.timeCreated && entry.date === response.date,
    );

    if (existingEntryIndex > -1) {
      // Update existing entry if found
      feedbackByDateEntry.feedbackByDate[existingEntryIndex] = newFeedbackEntry;
    } else {
      // Add new entry if not found
      feedbackByDateEntry.feedbackByDate.push(newFeedbackEntry);
    }
  });

  // Return the newly constructed feedbackByDate array and the structured feedback
  return {feedbackByDate: copyOfFeedbackBydate, comments: structuredFeedback};
}
