import {prepareValuesForUrl} from './tasks';

type PrepareQueryStringOptions<T> = {
  values: T;
  prepareFunction?: (values: T) => {[key: string]: string};
  queryString?: string;
};

export const prepareQueryString = <T>({
  values,
  prepareFunction = prepareValuesForUrl,
  queryString,
}: PrepareQueryStringOptions<T>) => {
  const params = new URLSearchParams(queryString || '');
  for (const [key, value] of Object.entries(prepareFunction(values))) {
    value ? params.set(key, value) : params.delete(key);
  }
  return params.toString();
};

export const queryParsers = {
  string: (value) => (typeof value === 'string' ? value : null),
  boolean: (value) => {
    const parsed = JSON.parse(value);
    return typeof parsed === 'boolean' ? parsed : null;
  },
  date: (value) => {
    const parsed = new Date(parseInt(value));
    return !isNaN(parsed.getTime()) ? parsed : null;
  },
  number: (value) => {
    const parsed = Number(value);
    return !isNaN(parsed) ? parsed : null;
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'string[]': (value) => {
    if (typeof value === 'string') {
      return value.length ? value.split(',') : [];
    }
    return value;
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'boolean[]': (value) => {
    if (typeof value === 'string') {
      return value.length ? value.split(',').map((v) => v === 'true') : [];
    }
    return value;
  },
};

export type UrlSchemaParser = keyof typeof queryParsers;
export type UrlParseSchema<T extends Record<string, unknown>> = {[K in keyof T]?: UrlSchemaParser};
export function parseUrlQuery<T extends Record<string, unknown>>(url: string, schema: UrlParseSchema<T>) {
  const params = new URLSearchParams(url);
  return Array.from(params.keys()).reduce((prev, cur) => {
    const value = params.get(cur);
    const parser = schema[cur];
    if (parser) {
      const parsedValue = queryParsers[parser](value);
      if (parsedValue !== null) {
        return {...prev, [cur]: parsedValue};
      }
    }
    return prev;
  }, {} as T);
}
