import React, {
  cloneElement,
  EventHandler,
  FC,
  isValidElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  TouchEvent,
  useEffect,
  useState,
} from 'react';
import {usePopper} from 'react-popper';

import TiersPricingTable from 'shared/components/ProPlanPriceTable';
import {useOutsideClick} from 'shared/hooks/core';
import {FormattedProPlanTiers} from 'shared/models/checkout';

import './popper-arrow-fix.scss';

// delete after r.pogorelov include that css rules to layout project
type PricingDetailsTooltipProps = {
  children: ReactNode;
  proPlanTiers: FormattedProPlanTiers[];
};

const PricingDetailsTooltip: FC<PricingDetailsTooltipProps> = ({children, proPlanTiers}) => {
  const [showPriceDetailsTooltip, setShowPriceDetailsTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const {styles, attributes, update} = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      {name: 'offset', options: {offset: [0, 10]}},
      {name: 'flip', options: {fallbackPlacements: ['bottom']}},
    ],
  });

  useEffect(() => {
    if (proPlanTiers && update) {
      update();
    }
  }, [proPlanTiers, update]);

  useOutsideClick({
    ref: popperElement ? {current: popperElement} : undefined,
    callback: () => {
      setShowPriceDetailsTooltip(false);
    },
  });

  const onPriceDetailsClick: EventHandler<MouseEvent | TouchEvent> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPriceDetailsTooltip((prev) => !prev);
  };

  return (
    <>
      {isValidElement(children) &&
        cloneElement(children as ReactElement, {
          onMouseDown: onPriceDetailsClick,
          onTouchStart: onPriceDetailsClick,
          ref: setReferenceElement,
        })}
      <div
        className="plan-current__tooltip"
        style={{visibility: showPriceDetailsTooltip ? 'visible' : 'hidden', ...styles.popper}}
        ref={setPopperElement}
        {...attributes.popper}
      >
        <TiersPricingTable.Tooltip tiers={proPlanTiers} />
      </div>
    </>
  );
};
export default PricingDetailsTooltip;
