import {logoutUser} from 'store/actions';

export const LOGIN_SUCCESS = 'auth/login-success';
export const LOGIN_FAILURE = 'auth/login-failure';
export const LOGIN_INPROGRESS = 'auth/login-inprogress';
export const LOGIN_SIGNOUT = 'auth/login-signout';
export const REQUESTING_PHONE_CODE_INPROGRESS = 'auth/requesting-phone-code-inprogress';
export const REQUESTING_PHONE_CODE_SUCCESS = 'auth/requesting-phone-code-success';
export const REQUESTING_PHONE_CODE_FAILED = 'auth/requesting-phone-code-failed';

export interface LoginSuccessed {
  type: typeof LOGIN_SUCCESS;
  payload?: {
    token: string;
    workerId: string;
  };
}

export interface LoginSignOut {
  type: typeof LOGIN_SIGNOUT;
}

export interface LoginFailure {
  type: typeof LOGIN_FAILURE;
  payload: string;
}

export interface LoginInProgress {
  type: typeof LOGIN_INPROGRESS;
}

export interface RequestingPhoneCodeInProgress {
  type: typeof REQUESTING_PHONE_CODE_INPROGRESS;
}

export interface RequestingPhoneCodeSuccess {
  type: typeof REQUESTING_PHONE_CODE_SUCCESS;
}

export interface RequestingPhoneCodeFailed {
  type: typeof REQUESTING_PHONE_CODE_FAILED;
}

export interface AuthStore {
  isLoggedIn: boolean;
  loginError: string;
  loginInProgress: boolean;
  isRequestingPhoneCode: boolean;
}
export type AuthUser = any; // eslint-disable-line @typescript-eslint/no-explicit-any

export type LoginActions =
  | LoginSuccessed
  | LoginInProgress
  | LoginFailure
  | RequestingPhoneCodeInProgress
  | RequestingPhoneCodeSuccess
  | RequestingPhoneCodeFailed
  | ReturnType<typeof logoutUser>;
