import {TFunction} from 'i18next';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {Icon, Popup} from 'shared/components/index';
import {Worker} from 'shared/models/worker';

type TaskImportErrorProps = {
  tryAgain: () => void;
  textToShow?: string;
  errorCode?: string;
  profile?: Worker;
  companyName?: string;
};

const getErrorMessages = (t: TFunction) => ({
  unsupported_file_type: t('import:errors.messages.file_type', 'Unsupported file type'),
  unsupported_date_format: t('import:errors.messages.date_format', 'Unsupported date format'),
  file_read_error: t(
    'import:errors.messages.read_error',
    'Unable to read the file. Please check your file and try again',
  ),
});

const TasksImportMajorError: FC<TaskImportErrorProps> = ({
  tryAgain,
  errorCode = '500',
  textToShow,
  companyName,
  profile,
}) => {
  const {t} = useTranslation('import');
  const errorMessages = getErrorMessages(t);

  const getProperErrorMessage = () => {
    return errorMessages.hasOwnProperty(errorCode)
      ? errorMessages[errorCode]
      : t('errors.messages.server_error', 'Unexpected server error');
  };
  return (
    <Popup.Body>
      <div className="message-complete">
        <header className="message-complete__header">
          <h1 className="message-complete__title">{t('errors.title', 'Import error')}</h1>
          <div className="message-complete__description">{textToShow}</div>
        </header>
        <div className="legend-compare message-complete__total">
          <div className="legend-compare__item legend-compare__item--error">
            <Icon colorFill size={24} className="legend-compare__icon" name="warning" />
            <span className="legend-compare__text">{getProperErrorMessage()}</span>
          </div>
        </div>
        <picture className="picture message-complete__image">
          <source srcSet="/images/other/complete-2@1x.png 1x, images/other/complete-2@2x.png 2x" />
          <img className="picture__image" src="/images/other/complete-2@1x.png" alt="" />
        </picture>
        <div className="message-complete__actions">
          <div className="message-complete__button-box">
            <button className="ctrl-btn ctrl-btn--color-light ctrl-btn--shadow message-complete__button" type="button">
              <a
                href={`mailto:c4@bycore.com?subject=Help Importing activities for ${companyName} ${profile.fullName} - ${errorCode}`}
                className="ctrl-btn__text"
                style={{textDecoration: 'none'}}
              >
                {t('errors.buttons.support', 'Contact Support')}
              </a>
            </button>
          </div>
          <div className="message-complete__button-box">
            <button onClick={tryAgain} className="ctrl-btn ctrl-btn--shadow message-complete__button" type="button">
              <span className="ctrl-btn__text">{t('errors.buttons.try_again', 'Try Again')}</span>
            </button>
          </div>
        </div>
      </div>
    </Popup.Body>
  );
};
export default TasksImportMajorError;
