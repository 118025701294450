import cn from 'classnames';
import React, {ReactNode} from 'react';

import s from './PanelSection.module.scss';

type PanelSectionProps = {
  className?: string;
  title: string;
  width?: 'narrow';
  headerAction?: ReactNode;
  description?: string;
  mainAction?: ReactNode;
  moreAction?: ReactNode;
  children?: ReactNode[] | ReactNode;
};

const PanelSection = ({
  className,
  title,
  width,
  headerAction,
  description,
  mainAction,
  moreAction,
  children,
}: PanelSectionProps) => {
  return (
    <section className={cn(s.panelSection, width && s[`panelSection_width_${width}`], className)}>
      <header className={s.panelSection__header}>
        <h2 className={s.panelSection__title}>{title}</h2>
        {headerAction && <div className={s.panelSection__headerActions}>{headerAction}</div>}
      </header>
      {description && <div className={s.panelSection__description}>{description}</div>}
      {mainAction && <div className={s.panelSection__mainAction}>{mainAction}</div>}
      {children && <div className={s.panelSection__body}>{children}</div>}
      {moreAction && <div className={s.panelSection__more}>{moreAction}</div>}
    </section>
  );
};

export default PanelSection;
