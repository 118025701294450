import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import {Icon, SkeletonPreloader} from 'shared/components';
import env from 'shared/constants/env';
import {getFormattedCurrency} from 'shared/helpers/common';
import {safeFormatDate} from 'shared/helpers/dates';
import {useFormattedProPlanTiers} from 'shared/hooks';
import {ProSubscription, ProSubscriptionTypes} from 'shared/models/checkout';
import {CompanyModel} from 'shared/models/company';
import {useRootDispatch, useRootSelector} from 'store';
import {profileActions, selectCurrentSubscription} from 'store/profile';
import {getProPlans} from 'store/profile/actions';

import SubscriptionModal from '../SubscriptionModal';

import PricingDetailsTooltip from './PricingDetailsTooltip';
import {useFreeTrialDates} from './useFreeTrialDates';

type CompanyLicenseCardProps = {values: CompanyModel};

const CompanyLicenseCard: FC<CompanyLicenseCardProps> = ({values}) => {
  const {t} = useTranslation('company');
  const dispatch = useRootDispatch();
  const {subscription, proPlan, subscriptionLoaded} = useRootSelector((state) => ({
    subscription: selectCurrentSubscription(state),
    subscriptionLoaded: !!state.profile.companySubscriptions,
    proPlan: state.profile.proPlan,
  }));
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [, daysTillEnd] = useFreeTrialDates(values?.proStartTime);
  const isTrialPeriod = false; // this is hard coded to off now
  const isSubscribed = subscription?.type === ProSubscriptionTypes.PRO && subscription?.status === 'active';

  const proPlanTiers = useFormattedProPlanTiers(proPlan, 'short');

  useEffect(() => {
    dispatch(getProPlans());
  }, [dispatch]);

  const manageSubscription = async () => {
    setShowSubscriptionModal(true);
  };

  const onSubscriptionCanceled = () => {
    dispatch(profileActions.cancelSubscription(subscription.id));
  };

  const onSubscriptionUpdated = (sub: ProSubscription) => {
    dispatch(profileActions.updateSubscription([sub]));
  };

  const getLicensePreloader = () => {
    return (
      <div className="lava-list">
        <Skeleton width="100%" />
        {Array.from({length: 5}).map((_, i) => (
          <div key={i} className="lava lava-list__item">
            <div className="lava__label">
              <Skeleton width={50} height={18} />
            </div>
            <div className="lava__value">
              <Skeleton width={100} height={18} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getLicenseFields = () => {
    return (
      <>
        <div className="lava-list">
          <div className="lava lava-list__item">
            <div className="lava__label">{t('license.invoice.next_payment_date', 'Next invoice on')}</div>
            <div className="lava__value">{safeFormatDate(subscription?.stripeSubscriptionInfo?.nextInvoice, 'll')}</div>
          </div>
          <div className="lava lava-list__item">
            <div className="lava__label">{t('license.invoice.number_of_workers', 'Number of workers')}</div>
            <div
              className="lava__value lava__value--users"
              style={{
                color: values.currentSeatCount <= subscription.seatCount ? '#01964E' : '#FA380D',
              }}
            >
              <Icon colorFill className="plan-current__icon-users" name="group_equal" />
              {values.currentSeatCount}
            </div>
          </div>
          <div className="lava lava-list__item">
            <div className="lava__label">{t('license.invoice.current_licenses_qty', 'Current Licenses')}</div>
            <div className="lava__value">{subscription.seatCount}</div>
          </div>
          <div className="lava lava-list__item">
            <div className="lava__label">{t('license.invoice.payment_amount', 'Monthly Payment')}</div>
            <div className="lava__value">
              {subscription &&
                getFormattedCurrency(
                  subscription?.stripeSubscriptionInfo.amount,
                  subscription?.stripeSubscriptionInfo.currency,
                )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <section className="license">
      <header className="license__header">
        <h2 className="license__title">{t('license.tab.title', 'License')}</h2>
      </header>
      <div className="license__body">
        <section className="plan-current">
          {subscriptionLoaded && (
            <header className="plan-current__header">
              <h3 className="plan-current__title">{t('license.tab.plan.crews', 'Crews by Core')}</h3>
              {!subscription || subscription?.type === ProSubscriptionTypes.FOREMAN_FREE ? (
                <SkeletonPreloader when={!proPlanTiers.length}>
                  <div className="plan-current__counter">
                    {isTrialPeriod
                      ? `${t('license.trial_in_progress.text', 'Your free trial ends in')} ${daysTillEnd} ${
                          daysTillEnd > 1
                            ? t('license.trial_in_progress.plural', 'days')
                            : t('license.trial_in_progress.single', 'day')
                        }`
                      : t('license.trial_ends.guarantee', 'Risk-free trial')}
                  </div>
                </SkeletonPreloader>
              ) : null}
            </header>
          )}
          <div className="plan-current__body">
            {subscriptionLoaded && values
              ? subscription?.stripeSubscriptionInfo
                ? getLicenseFields()
                : null
              : getLicensePreloader()}
            {subscriptionLoaded && (
              <>
                <div className="plan-current__actions">
                  <SkeletonPreloader when={!proPlanTiers.length} height={49}>
                    <button
                      className="ctrl-btn ctrl-btn--view-border plan-current__button-continue"
                      type="button"
                      onClick={manageSubscription}
                    >
                      <span className="ctrl-btn__text">
                        {!isSubscribed
                          ? t('license.pay_now', 'Pay Now')
                          : t('license.active_subscription', 'Manage subscription')}
                      </span>
                    </button>
                  </SkeletonPreloader>
                </div>
                <div className="plan-current__more">
                  <SkeletonPreloader when={!proPlanTiers.length}>
                    <PricingDetailsTooltip proPlanTiers={proPlanTiers}>
                      <button className="plan-current__button-more">
                        {t('license.show_pricing', 'See pricing details')}
                      </button>
                    </PricingDetailsTooltip>
                  </SkeletonPreloader>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
      {env.stripePublishableKey ? (
        <SubscriptionModal
          company={values}
          subscription={subscription}
          plan={{...proPlan, tiers: proPlanTiers}}
          visible={showSubscriptionModal}
          onVisibleChange={setShowSubscriptionModal}
          onSubscriptionCanceled={onSubscriptionCanceled}
          onSubscriptionUpdated={onSubscriptionUpdated}
        />
      ) : null}
    </section>
  );
};
export default CompanyLicenseCard;
