import axios, {AxiosError, AxiosResponse} from 'axios';
import {camelize} from 'humps';

import {getApiResponseDataRange} from 'shared/helpers/common';
import {GeneralizedApiError} from 'shared/models/apiError';

export function isAxiosError<T>(payload: unknown): payload is AxiosError<T> {
  return axios.isAxiosError(payload);
}

const UNEXPECTED_ERROR = 'Unexpected error';

type ExtractedError = Record<string, string> | string;

export function extractAxiosError(error: unknown, errorsMapping?: Record<string, string>): ExtractedError {
  if (isAxiosError<GeneralizedApiError>(error)) {
    if (typeof error.response?.data === 'string') return error.response.data;
    if ('detail' in error?.response?.data) {
      const errorData = error.response.data;
      if (typeof errorData.detail === 'string') {
        return errorData.detail;
      } else {
        const errors = errorData.detail.reduce((prev, cur) => {
          const errorField = errorsMapping?.[cur.field] || camelize(cur.field);
          return Object.assign(prev, {[errorField]: cur.error});
        }, {});
        return Object.keys(errors).length ? errors : UNEXPECTED_ERROR;
      }
    } else {
      return error.response.data?.message || error.response.data?.error || UNEXPECTED_ERROR;
    }
  } else {
    return UNEXPECTED_ERROR;
  }
}

export function stringifyAxiosError(error: unknown, useFirstError = false) {
  const extract = extractAxiosError(error);
  if (typeof extract === 'string') return extract;
  if (useFirstError) {
    return Object.values(extract)[0];
  }
  return Object.values(extract).join('\r\n');
}

export async function fetchAllBlocking<R>(request: (offset: number, take: number) => Promise<AxiosResponse<R[]>>) {
  return fetchAll(request, 100, null, null).promise;
}

export function fetchAll<R>(
  request: (offset: number, take: number) => Promise<AxiosResponse<R[]>>,
  take = 100,
  handleFirstResponse: (data: R[]) => void | null,
  handleResponse: (data: R[]) => void | null,
) {
  // TODO: add axios abort controller
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let interrupt = false;
  const fetcher = async () => {
    const first = await request(0, take);
    handleFirstResponse && handleFirstResponse(first.data);
    const {total} = getApiResponseDataRange(first);
    const chunks = Math.ceil((total - take) / take);
    const requests = Array.from({length: chunks}).map((_, i) => request(i * take + take, take));
    const rest = await Promise.all(requests);
    const restData = rest.reduce((acc, cur) => acc.concat(cur.data), [] as R[]);
    handleResponse && handleResponse(restData);

    return first.data.concat(restData) as R[];
  };
  const cancel = () => {
    interrupt = true;
  };
  return {
    cancel,
    promise: fetcher(),
  };
}

type FetchAllGeneratorOptions<R> = {
  request: (offset: number, take: number) => Promise<AxiosResponse<R[]>>;
  take?: number;
  maxRetries?: number;
  retryDelay?: number;
  abortSignal?: AbortSignal;
  parallelCalls?: number;
};

export async function* fetchAllGenerator<R>(options: FetchAllGeneratorOptions<R>): AsyncGenerator<R[], void, unknown> {
  const {request, take = 500, maxRetries = 3, retryDelay = 1000, abortSignal, parallelCalls = 3} = options;

  let offset = 0;
  let totalItems: number | undefined;

  const fetchWithRetry = async (offset: number): Promise<R[]> => {
    let retries = 0;
    while (retries <= maxRetries) {
      try {
        if (abortSignal?.aborted) {
          throw new Error('Operation aborted');
        }
        const response = await request(offset, take);
        return response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
          throw new Error('Request cancelled');
        }
        retries++;
        if (retries > maxRetries) {
          throw error;
        }
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }
    throw new Error('Unexpected error in fetchWithRetry');
  };

  while (true) {
    if (abortSignal?.aborted) {
      throw new Error('Operation aborted');
    }

    const fetchPromises = Array.from({length: parallelCalls}, (_, i) => fetchWithRetry(offset + i * take));

    try {
      const results = await Promise.all(fetchPromises);
      let allItems: R[] = [];

      for (const items of results) {
        if (totalItems === undefined) {
          // Assuming the first response contains the total count
          // You might need to adjust this based on your API response structure
          totalItems = (items as any).total;
        }
        allItems = allItems.concat(items);
        if (items.length < take) {
          yield allItems;
          return;
        }
      }

      yield allItems;

      offset += allItems.length;

      if (allItems.length < take * parallelCalls) {
        return;
      }
    } catch (error) {
      throw error;
    }
  }
}

export async function fetchAllWithGenerator<R>(options: FetchAllGeneratorOptions<R>): Promise<R[]> {
  const allItems: R[] = [];
  for await (const items of fetchAllGenerator(options)) {
    allItems.push(...items);
  }
  return allItems;
}
