import {Dictionary} from '@reduxjs/toolkit';
import React, {cloneElement, CSSProperties, FC, MutableRefObject, ReactElement, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {usePopper} from 'react-popper';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import {ExportApi} from 'api';
import {ExportToCSV} from 'services/ExportToCSV';
import CoreDropPanel from 'shared/components/DropPanel';
import {addStatusToFilters} from 'shared/helpers/tasks';
import {useAnalyticsService, useTasksUrlState} from 'shared/hooks';
import {useProject} from 'shared/hooks/useProject';
import {CompanyModel} from 'shared/models/company';
import {ProjectModel} from 'shared/models/project';

import TasksExportForm from './TasksExportForm';

interface ButtonProps {
  className: string;
  onClick: () => void;
  ref: MutableRefObject<HTMLButtonElement>;
}

export type TaskExportButtonProps = {
  status?: string;
  projects?: Dictionary<ProjectModel>;
  company: CompanyModel;
  queryParams: Record<string, unknown>;
  button: ReactElement<Partial<ButtonProps>>;
  buttonStyle?: CSSProperties;
  dropPanelStyles?: CSSProperties;
  setIsExporting?: (boolean) => void;
  mixpanelEvents: {[name: string]: string};
};

const TaskExportButton: FC<TaskExportButtonProps> = ({
  queryParams,
  company,
  button,
  buttonStyle,
  dropPanelStyles,
  setIsExporting,
  mixpanelEvents,
}) => {
  const [showPopper, setShowPopper] = useState(false);
  const buttonRef = useRef(null);
  const popperRef = useRef(null);
  const {
    attributes: {popperAttributes},
  } = usePopper(buttonRef.current, popperRef.current, {
    placement: 'bottom',
  });
  const {mixpanel} = useAnalyticsService();
  const {id} = useParams<{id: string}>();
  const {project} = useProject(id);
  const tasksState = useTasksUrlState();
  const {t} = useTranslation('project');

  const startExport = async (dateFormat: string) => {
    const res = await ExportApi.exportTasks(company.id, {
      dateFormat,
      params: addStatusToFilters(tasksState, queryParams),
    });
    setIsExporting(true);
    setShowPopper(false);
    const exportData = await ExportApi.pollExportTasksResults(company.id, res.data.id, 200);
    if (exportData.status === 'finished') {
      if (exportData.result.errors) {
        toast(exportData.result.errors[0].code);
      } else if (exportData.result.error) {
        toast(t('toast.error.unexpected', 'Unexpected server error'));
      } else {
        const csvExport = new ExportToCSV();
        csvExport.download(company.companyName + '_' + new Date().toLocaleString(), '', exportData.resultFileUrl);
      }
    }
    setIsExporting(false);
  };

  const createButton = () => {
    return cloneElement(button, {
      ...button.props,
      onClick: () =>
        mixpanel.trackWithAction(() => setShowPopper(!showPopper), mixpanelEvents.button, {
          'Project Name': project?.name,
        }),
      ref: buttonRef,
    });
  };

  return (
    <div className="panel__button" style={buttonStyle}>
      {createButton()}
      {showPopper && (
        <CoreDropPanel {...popperAttributes} dropPanelStyles={dropPanelStyles}>
          <TasksExportForm
            onSubmit={(dateFormat) =>
              mixpanel.trackWithAction(() => startExport(dateFormat), mixpanelEvents.startExport)
            }
          />
        </CoreDropPanel>
      )}
    </div>
  );
};
export default TaskExportButton;
