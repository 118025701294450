import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, matchPath, useHistory, useLocation, useRouteMatch} from 'react-router';

import env from 'shared/constants/env';
import {IconsMap} from 'shared/constants/icons';
import {ROUTES, useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks';
import {useCompany} from 'shared/hooks/useCompany';
import {useIsCompanyAdmin} from 'shared/hooks/useIsCompanyAdmin';
import {useRootSelector} from 'store';
import {selectAllProjects} from 'store/projects';

import {sortAlphabetically} from '../../../helpers/common';

import NavMenuItem from './NavMenuItem';

export type SubItems = Pick<NavItem, 'title' | 'onClick' | 'isActive' | 'onClickEdit'>;

export type NavItem = {
  title: string;
  icon: string;
  onClick: () => void;
  isActive: boolean;
  to: string;
  onClickEdit?: () => void;
  subItems?: SubItems[];
  tooltip?: string;
  dataCy?: string;
  hide?: boolean;
};

const NavMenu: FC = () => {
  const history = useHistory();
  const location = useLocation<{query?: string}>();
  const routes = useLocalizedRoutes();
  const isCompanyAdmin = useIsCompanyAdmin();
  const activeCompany = useCompany();
  const projects = useRootSelector(selectAllProjects);
  const sortedProjects = projects ? sortAlphabetically(projects, 'name') : projects;
  const {
    mixpanel: {
      events: {projects: mixpanelEvents},
      ...mixpanel
    },
  } = useAnalyticsService();
  const {t} = useTranslation('left_menu');
  const params = new URLSearchParams(location.search);
  const showChat = params.get('showChat') === 'true';

  const match = useRouteMatch<{projectId?: string; id?: string}>({
    path: Object.values(ROUTES),
    exact: true,
  });

  const openChatWindow = () => window.open(env.webChatClient, '_blank');

  const onLinkClick = (to: string, search = '', state?: {query?: string}) => {
    history.push({
      pathname: to,
      search: search || location.search,
      state: state || location.state,
    });
  };

  const navItems: NavItem[] = useMemo(() => {
    const activeProjectId = match?.params?.projectId || match?.params?.id;

    return [
      {
        title: t('tabs.project.title', 'Schedule'),
        icon: IconsMap.folder_labeled,
        to: routes.projects,
        onClick: () => mixpanel.trackWithAction(() => onLinkClick(routes.projects), mixpanelEvents.nav.leftNavHeader),
        isActive: !!matchPath(location.pathname, {path: routes.projects}),
        subItems: sortedProjects.map(({name, id}) => {
          return {
            title: name,
            onClick: () =>
              mixpanel.trackWithAction(
                // TODO: delete location.state?.query within the task: https://journey-builders.atlassian.net/browse/CNA-3017
                () => onLinkClick(generatePath(routes.tasks, {projectId: id}), location.state?.query),
                mixpanelEvents.nav.viewActiveTasksList,
              ),
            isActive: activeProjectId === id,
            onClickEdit: () =>
              mixpanel.trackWithAction(
                () => onLinkClick(generatePath(routes.project, {id})),
                mixpanelEvents.nav.leftNavEditProject,
              ),
          };
        }),
      },
      {
        title: t('tabs.worker.title', 'Company Users'),
        icon: IconsMap.group_equal,
        to: routes.workers,
        onClick: () => {
          // TODO: delete state within the task: https://journey-builders.atlassian.net/browse/CNA-3017
          const isFromTasksPage = !!matchPath(location.pathname, {path: routes.projects});
          const state = isFromTasksPage ? {query: location.search} : null;
          onLinkClick(routes.workers, null, state);
        },
        isActive: !!matchPath(location.pathname, {path: routes.workers}),
        hide: !isCompanyAdmin(activeCompany?.id),
        dataCy: 'navMenuWorkers',
      },
      {
        title: t('tabs.report.title', 'Reports'),
        icon: IconsMap.chart_pie_1,
        to: routes.reports,
        onClick: () => onLinkClick(routes.reports),
        isActive: !!matchPath(location.pathname, {path: routes.reports}),
        hide: !isCompanyAdmin(activeCompany?.id),
      },
      ...(showChat
        ? [
            {
              title: t('tabs.chat.title', 'Chat'),
              icon: IconsMap.chat,
              to: 'chat',
              onClick: openChatWindow,
              isActive: false,
            },
          ]
        : []),
    ];
  }, [projects, routes, location.pathname, match?.params]);

  return (
    <nav className="nav header__nav">
      <ul className="nav__list">
        {navItems.map((item) => {
          return item.hide ? null : <NavMenuItem key={item.to} item={item} />;
        })}
      </ul>
    </nav>
  );
};

export default NavMenu;
