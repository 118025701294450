import cn from 'classnames';
import {useFormikContext} from 'formik';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {SubcontractorFormValues} from 'modules/Subcontractors/utils/utils';
import {useAnalyticsService} from 'shared/hooks';
import {useResponsibleOrgColors} from 'shared/hooks/useResponsibleOrgColors';

import {CtrlColor} from '../CtrlColor/CtrlColor';

import s from './ColorsList.module.scss';

type ColorsListProps = {
  className?: string;
};

const ColorsList = ({className}: ColorsListProps) => {
  const {
    values: {lookaheadColor},
    setFieldValue,
  } = useFormikContext<SubcontractorFormValues>();
  const {mappedLookaheadColors} = useResponsibleOrgColors();
  const {t} = useTranslation('panel');
  const {projectId} = useParams<{projectId: string}>();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const mixpanelEvents = mixpanel.events.projectOrgs;

  const selectColor = (color: string) =>
    mixpanel.trackWithAction(() => setFieldValue('lookaheadColor', color), mixpanelEvents.selectColor, {color});

  const colorsList = useMemo(
    () =>
      mappedLookaheadColors
        ? Object.keys(mappedLookaheadColors)?.map((color, index) => {
            return (
              <CtrlColor
                key={`${color}_${index}`}
                className={s.colorsList__item}
                colorId={mappedLookaheadColors[color]}
                colorName={color}
                inputName="tempRadioName"
                selectColor={selectColor}
                checked={color === lookaheadColor}
              />
            );
          })
        : [],
    [mappedLookaheadColors, lookaheadColor],
  );

  return (
    <div className={cn(s.colorsList, className)}>
      <div className={s.colorsList__label}>{t('tab.subcontractors.colorsList.label', 'Display color')}</div>
      <div className={s.colorsList__grid}>{colorsList}</div>
    </div>
  );
};

export default ColorsList;
