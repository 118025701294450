import cs from 'classnames';
import {FC, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {TaskStatusIcon, Icon, OptionType} from 'shared/components';
import StatusElement from 'shared/components/StatusElement';
import {taskStatusClassNameOverride} from 'shared/constants/statuses';
import {useOutsideClick, useToggle} from 'shared/hooks/core';
import {TaskStatus} from 'shared/models/task';

type Props = {
  status: TaskStatus;
  className?: string;
  onSelect: (status: TaskStatus) => void;
  statusOptions: OptionType[];
  visibleOptions: OptionType[];
};

const TaskStatusSelect: FC<Props> = ({status, className, onSelect, statusOptions, visibleOptions}: Props) => {
  const {isToggled, toggle} = useToggle();
  const containerRef = useRef();
  const {t} = useTranslation(['shared_task', 'gantt']);

  useOutsideClick({
    ref: containerRef,
    callback: () => {
      if (isToggled) toggle();
    },
  });

  const selected = useMemo(() => statusOptions.find((option) => option.value === status), [status]);

  const onClickOption = (value: TaskStatus) => {
    return () => {
      onSelect(value);
      toggle();
    };
  };

  return (
    <div
      ref={containerRef}
      className={cs(
        'app-task-status',
        className,
        {'is-open': isToggled},
        `app-task-status--${taskStatusClassNameOverride[status] || status}`,
        'app-actions__status',
      )}
    >
      <div className="app-task-status__overlap"></div>
      <button className="app-task-status__toggle" type="button" onClick={() => toggle()}>
        <span className="app-task-status__toggle-content">
          <span className="app-task-status__toggle-label">{t('shared_task:select.title', 'Current Status')}</span>
          <div
            className={`status-element status-element--${
              taskStatusClassNameOverride[status] || status
            } app-task-status__toggle-status`}
          >
            <TaskStatusIcon className="status-element__icon" name={status} size={24} />
            <span className="status-element__name">{selected?.label}</span>
          </div>
        </span>
        <Icon name={isToggled ? 'chevron-top' : 'chevron-bottom'} size={24} />
      </button>
      <div className="app-task-status__viewport">
        <ul className="app-task-status__list">
          {visibleOptions.map(({value, label, id}) => (
            <li className="app-task-status__list-item" key={id}>
              <button
                className={`app-task-status__list-button app-task-status__list-button--${
                  taskStatusClassNameOverride[value] || value
                }`}
                type="button"
                onClick={onClickOption(value as TaskStatus)}
              >
                <StatusElement
                  label={label}
                  className={`status-element--${taskStatusClassNameOverride[value] || value}`}
                  icon={<TaskStatusIcon name={value as TaskStatus} size={12} className="status-element__icon" />}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default TaskStatusSelect;
