import {FieldProps} from 'formik';
import React, {FC, InputHTMLAttributes} from 'react';

import {Trim} from '../Trim';

type Props = {className?: string} & InputHTMLAttributes<HTMLInputElement> & FieldProps;

const EmailInput: FC<Props> = ({className = 'ctrl-textfield', ...props}: Props) => {
  return (
    <Trim>
      <input type="text" className={className} {...props} />
    </Trim>
  );
};
export default EmailInput;
