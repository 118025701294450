import cs from 'classnames';
import React, {FC, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {Icon} from 'shared/components';
import env from 'shared/constants/env';
import {useAnalyticsService} from 'shared/hooks';
import {useOutsideClick, useToggle} from 'shared/hooks/core';
import useUserAgent from 'shared/hooks/useUserAgent';

const DownloadAppPart: FC = () => {
  const userAgent = useUserAgent();
  const {mixpanel} = useAnalyticsService({waitCompany: false, waitAuth: false});
  const mixpanelEvents = mixpanel.events.share;
  const {isToggled, toggle} = useToggle();
  const containerRef = useRef();
  const {t} = useTranslation('shared_task');

  const link = useMemo(() => {
    if (userAgent?.os.name == 'iOS') return env.mobileAppLinks.appStore;
    if (userAgent?.os.name == 'Android') return env.mobileAppLinks.googlePlay;

    return env.downloadAppLinkPage;
  }, [userAgent?.os.name]);

  useOutsideClick({
    ref: containerRef,
    callback: () => {
      if (isToggled) toggle();
    },
  });

  return (
    <>
      <div
        className={cs('app-task-download app-task__item app-task__item--download', {'is-open': isToggled})}
        ref={containerRef}
      >
        <button
          className="ctrl-btn-download-app app-task-download__button-toggle"
          type="button"
          onClick={() => toggle()}
        >
          <picture className="picture ctrl-btn-download-app__icon-app">
            <img className="picture__image" src="/images/logo/icons/icon-app.svg" alt="Crews by Core" />
          </picture>
          <span className="ctrl-btn-download-app__info">
            <span className="ctrl-btn-download-app__name">{t('download.title', 'Get the app')}</span>
            <span className="ctrl-btn-download-app__description">
              {t('download.description.start', 'Unlock the whole toolkit with the Crews by Core App')}
            </span>
          </span>
          <Icon colorFill className="ctrl-btn-download-app__icon" name="chevron-right" />
        </button>

        <div className="app-task-download__viewport">
          <picture className="picture app-task-download__media">
            <source srcSet="/images/downloads/screens-1@1x.jpg 1x, /images/downloads/screens-1@2x.jpg 2x" />
            <img className="picture__image" src="/images/downloads/screens-1@1x.jpg" alt="" />
          </picture>
          <div className="app-task-download__body">
            <h2 className="app-task-download__title">
              {t('download.description.free', 'Unlock the whole toolkit with the free Crews by Core App')}
            </h2>
            <ul className="app-task-download__list">
              <li className="app-task-download__list-item">
                <svg
                  className="icon-inline icon-inline--color-fill app-task-download__list-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path d="M18.336 6.237c-.322-.316-.837-.316-1.159 0l-7.85 7.85-2.484-2.468c-.15-.16-.36-.251-.58-.251-.22 0-.429.091-.58.251l-.878.878c-.157.155-.245.367-.245.588 0 .221.088.433.245.588l3.892 3.925c.15.16.36.251.58.251.22 0 .429-.091.58-.251l9.307-9.307c.157-.155.245-.367.245-.588 0-.221-.088-.432-.245-.588l-.828-.878z" />
                </svg>
                <span className="app-task-download__list-text">
                  {t('download.description.manage', 'Manage tasks, job sites and Crew')}
                </span>
              </li>
              <li className="app-task-download__list-item">
                <svg
                  className="icon-inline icon-inline--color-fill app-task-download__list-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path d="M18.336 6.237c-.322-.316-.837-.316-1.159 0l-7.85 7.85-2.484-2.468c-.15-.16-.36-.251-.58-.251-.22 0-.429.091-.58.251l-.878.878c-.157.155-.245.367-.245.588 0 .221.088.433.245.588l3.892 3.925c.15.16.36.251.58.251.22 0 .429-.091.58-.251l9.307-9.307c.157-.155.245-.367.245-.588 0-.221-.088-.432-.245-.588l-.828-.878z" />
                </svg>
                <span className="app-task-download__list-text">
                  {t('download.description.add', 'Add photos, comments, edit punchlists')}
                </span>
              </li>
              <li className="app-task-download__list-item">
                <svg
                  className="icon-inline icon-inline--color-fill app-task-download__list-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path d="M18.336 6.237c-.322-.316-.837-.316-1.159 0l-7.85 7.85-2.484-2.468c-.15-.16-.36-.251-.58-.251-.22 0-.429.091-.58.251l-.878.878c-.157.155-.245.367-.245.588 0 .221.088.433.245.588l3.892 3.925c.15.16.36.251.58.251.22 0 .429-.091.58-.251l9.307-9.307c.157-.155.245-.367.245-.588 0-.221-.088-.432-.245-.588l-.828-.878" />
                </svg>
                <span className="app-task-download__list-text">
                  {t('download.description.chat', 'Chat with your crew')}
                </span>
              </li>
            </ul>
            <div className="app-task-download__actions" onClick={() => mixpanel.track(mixpanelEvents.downloadApp)}>
              <a
                className="ctrl-btn ctrl-btn--color-success app-task-download__button"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="ctrl-btn__text">{t('download.buttons.download', 'Download App')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DownloadAppPart;
