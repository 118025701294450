import {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {Link} from 'react-router-dom';

import Firebase from 'services/Firebase';
import {Button, MetaTags} from 'shared/components';
import ZetOnboardingContainer from 'shared/components/OnboardingLayout/ZetOnboardingContainer';
import {useLocalizedRoutes} from 'shared/constants/routes';
import useCountDown from 'shared/helpers/hooks/useCountDown';
import {parseUrlQuery} from 'shared/helpers/queryParams';
import {useLandingStyles} from 'shared/hooks';
import {useScrollToTopOnMount} from 'shared/hooks/core';
import {OnboardingLayout} from 'shared/layout/base/OnboardingLayout';

enum ActionCodeMode {
  revertSecondFactorAddition = 'revertSecondFactorAddition',
  resetPassword = 'resetPassword',
  recoverEmail = 'recoverEmail',
  verifyEmail = 'verifyEmail',
}

const ApplyAuthCode = () => {
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const location = useLocation();

  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const {counter, start, reset} = useCountDown(10);

  useLayoutEffect(() => {
    checkActionCode();
  }, []);

  function goToLogin() {
    history.push(routes.logIn);
  }

  const checkActionCode = async () => {
    const {oobCode = '', mode} = parseUrlQuery<{oobCode: string; mode: string}>(location.search, {
      oobCode: 'string',
      mode: 'string',
    });

    if (oobCode) {
      switch (mode) {
        case ActionCodeMode.recoverEmail:
        case ActionCodeMode.revertSecondFactorAddition:
        case ActionCodeMode.verifyEmail:
          await applyActionCode(oobCode);
          break;
        case ActionCodeMode.resetPassword:
          history.push({pathname: routes.resetPassword, search: location.search});
          break;
        default:
          goToLogin();
      }
    } else {
      return goToLogin();
    }
  };

  const applyActionCode = async (oobCode: string) => {
    try {
      setIsLoading(true);
      // TODO: add checkActionCode call
      await Firebase.applyActionCode(oobCode);

      // timer
      reset();
      start();

      setIsVerified(true);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getDescriptionByMode = () => {
    const {mode = ''} = parseUrlQuery<{mode: string}>(location.search, {mode: 'string'});
    if (mode === 'verifyEmail') {
      return 'Your email address has been verified';
    }
    if (mode === 'revertSecondFactorAddition') {
      return 'The second factor authorization has been disabled.';
    }
  };

  const onboardingCopy = useMemo(() => {
    if (isVerified) {
      return {
        title: 'Congratulations!',
        description: (
          <div>
            <span>{getDescriptionByMode()}</span>
            <div style={{fontSize: '12px', marginTop: '4px'}}>
              You will automatically redirected to the sign in page in {counter} sec...
            </div>
          </div>
        ),
      };
    }

    if (isError) {
      return {
        title: 'Sorry!',
        description: 'Looks like the link is invalid or expired.',
      };
    }

    return {
      title: 'Please, wait...',
    };
  }, [isError, isVerified, counter]);

  useEffect(() => {
    if (!counter) {
      history.push(routes.logIn);
    }
  }, [counter]);

  useScrollToTopOnMount();
  useLandingStyles();

  return (
    <>
      <MetaTags title="Verify Email Address" description="Verify your Email Address" keywords="" />
      <OnboardingLayout formClassName="form-onboarding-z--without-nav form-onboarding-z--without-footer">
        <ZetOnboardingContainer title={onboardingCopy.title} description={onboardingCopy.description}>
          {!isLoading && (
            <div className="form-onboarding-z__item form-onboarding-z__item--actions">
              <Link to={routes.logIn}>
                <Button>Log In</Button>
              </Link>
            </div>
          )}
        </ZetOnboardingContainer>
      </OnboardingLayout>
    </>
  );
};
export default ApplyAuthCode;
