import {HSpacer} from 'shared/components/Spacer';

import classes from './InlineFormElements.module.scss';

interface InlineSelectProps<T> {
  options: T[];
  onChange: (option: string) => void;
  isEditable: boolean;
  label: string;
  value: T;
}

const InlineSelect = <T extends {label: string; value: string}>({
  isEditable,
  label,
  onChange,
  options,
  value,
}: InlineSelectProps<T>) => {
  return (
    <div className={classes.row} style={{alignSelf: 'flex-end'}}>
      <span>{label}:</span>
      {isEditable ? (
        <select className={classes.input} name="select" onChange={(e) => onChange(e.target.value)} value={value.value}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <>
          <HSpacer />
          <span>{value.label}</span>
        </>
      )}
    </div>
  );
};

export default InlineSelect;
