import cn from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {date, mixed, object, string, ValidationError} from 'yup';

import {ActionModel} from 'modules/Tasks/components/SidebarPanel/utils/types';
import {Loader} from 'shared/components';
import {
  CtrlBtnOption,
  CtrlButton,
  CtrlCard,
  CtrlCheck,
  Dropdown,
  FieldInline,
  FormControlInline,
} from 'shared/components/CoreNewUI';
import Icon from 'shared/components/Icon';
import {DEFAULT_REQUIRED_MESSAGE} from 'shared/helpers/validationSchemas';
import {useAnalyticsService} from 'shared/hooks';
import {useDebounce, useEffectOnce, usePrevious} from 'shared/hooks/core';
import {TaskStatus} from 'shared/models/task';
import {CompanyWorker} from 'shared/models/worker';

import ActionsForm from '../ActionsForm/ActionsForm';

type ActionProps = {
  className?: string;
  data: ActionModel;
  onChange: (data: ActionModel) => Promise<void>;
  workers: CompanyWorker[];
  onDelete: () => Promise<void> | void;
  onOpen: () => void;
  focus?: boolean;
  disabled?: boolean;
};

const schema = object({
  endDate: date().required(DEFAULT_REQUIRED_MESSAGE),
  responsible: mixed().required(DEFAULT_REQUIRED_MESSAGE),
  name: string().required(DEFAULT_REQUIRED_MESSAGE),
});

const Action = ({className, data, disabled, onChange, onDelete, workers, onOpen, focus}: ActionProps) => {
  const [action, setAction] = useState(data);
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.tasks.sidePanel;
  const {t} = useTranslation(['task']);
  const container = useRef<HTMLDivElement>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [nameValue, setNameValue] = useState(action.name);
  const prevAction = usePrevious(action);

  const validate = (action: ActionModel): Record<string, string> => {
    try {
      schema.validateSync(action, {abortEarly: false});
      setErrors(null);
      return null;
    } catch (e) {
      if (e instanceof ValidationError && e.inner) {
        const errors = (e as ValidationError).inner.reduce((acc, cur) => {
          return Object.assign(acc, {[cur.path]: cur.errors});
        }, {});
        setErrors(errors);
        return errors;
      }
      return {};
    }
  };

  const onUpdate = useDebounce(async (action: ActionModel) => {
    await onChange(action);
  }, 500);

  const onFieldChange = async (model: Partial<ActionModel>) => {
    if ('name' in model) {
      setNameValue(model.name);
    }
    if ('endDate' in model) {
      mixpanel.track(mixpanelEvents.finishDate);
    }
    if ('responsible' in model) {
      mixpanel.track(mixpanelEvents.responsible);
    }
    setAction((prev) => {
      return {...prev, ...model};
    });
  };

  async function withLoader(cb: () => Promise<void> | void) {
    setLoading(true);
    try {
      await cb();
    } catch (e) {
      // should be handled in parent
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setAction(data);
    validate(data);
  }, [data]);

  useEffect(() => {
    if (action && prevAction !== action && action !== data) {
      if (!validate(action)) {
        onUpdate(action);
      }
    }
  }, [action, data, prevAction, onUpdate]);

  useEffectOnce(
    () => {
      setTimeout(() => container.current?.scrollIntoView({behavior: 'smooth', block: 'center'}), 100);
    },
    [focus],
    !!focus,
  );

  return (
    <CtrlCard
      ref={container}
      className={cn(className, 'loader-container')}
      check={
        <CtrlCheck
          disabled={disabled}
          fieldType="checkbox"
          label={t('task:activity_actions.action.checked.label', 'Check Action')}
          labelIsHidden
          view="square"
        >
          <input
            type="checkbox"
            disabled={disabled}
            checked={action.status === TaskStatus.done}
            onChange={(e) => onFieldChange({status: e.target.checked ? TaskStatus.done : TaskStatus.inProgress})}
          />
        </CtrlCheck>
      }
      titleField={
        <FormControlInline
          error={errors?.name}
          label={t('task:activity_actions.action.name.label', 'Action name')}
          labelIsHidden
        >
          <FieldInline
            disabled={disabled}
            element="input"
            value={nameValue}
            onChange={(e) => onFieldChange({name: e.target.value})}
            placeholder={t('task:activity_actions.action.name.placeholder', 'Name')}
          />
        </FormControlInline>
      }
      actions={
        disabled ? null : (
          <Dropdown
            menuWidth={120}
            viewportPosition="right"
            toggleElement={
              <CtrlButton color="tertiary" icon="more_vertical" iconOnly size="s">
                Settings
              </CtrlButton>
            }
          >
            <CtrlBtnOption
              size="s"
              onClick={onOpen}
              title={t('task:activity_actions.menu.buttons.details', 'Details')}
              icon={<Icon name="edit" colorFill size={24} />}
            />
            <CtrlBtnOption
              onClick={() => withLoader(onDelete)}
              size="s"
              title={t('task:activity_actions.menu.buttons.delete', 'Delete')}
              icon={<Icon name="remove_from_trash" colorFill size={24} />}
            />
          </Dropdown>
        )
      }
    >
      <ActionsForm disabled={disabled} data={action} onChange={onFieldChange} workers={workers} />
      {loading && <Loader />}
    </CtrlCard>
  );
};

export default Action;
