import cn from 'classnames';
import {Field, FieldProps, useFormikContext} from 'formik';
import {ChangeEvent, FocusEvent, InputHTMLAttributes, useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams, useRouteMatch} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters';
import {Collapse} from 'modules/Tasks/components/SidebarPanel/components/Collapse';
import {CoreSelect, CoreStatusSelect, SkeletonFieldPreloader, SkeletonPreloader} from 'shared/components';
import Button from 'shared/components/Button';
import CoreNativeDatePicker from 'shared/components/CoreForm/CoreNativeDatePicker';
import {AsyncProjectSubcontractorSelect} from 'shared/components/CoreForm/Select/AsyncProjectSubcontractorSelect';
import AsyncTaskTypeSelect from 'shared/components/CoreForm/Select/AsyncTaskTypeSelect';
import {CtrlButton, CtrlCheck, FieldInline, FormControl, FormControlInline} from 'shared/components/CoreNewUI';
import Icon from 'shared/components/Icon';
import {useProgressReportPopupContext} from 'shared/components/ProgressReportingPopup/ProgressReportPopupProvider';
import {GANTT_PREFERENCES_KEY} from 'shared/constants/common';
import {CompletionUnits, taskCompletionUnitOptions} from 'shared/constants/completionUnits';
import {addDays, toShortIso} from 'shared/helpers/dates';
import {useAnalyticsService, useLocalStorageState, useOptionsForSelect, useProjectSelector} from 'shared/hooks';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {CompanyOrgs} from 'shared/models/company';

import {parseDateWith2DigitYear} from '../../../Gantt/utils';
import {AVAILABLE_FIELDS_IN_READONLY_VIEW, DISABLED_FIELDS_BY_TYPE} from '../../utils/constants';
import {FormValues} from '../../utils/types';
import {CustomFieldSection} from '../CustomFieldSection';
import InfoGallery from '../InfoGallery/InfoGallery';
import InfoLinksList from '../InfoLinksList/InfoLinksList';

import s from './ActivityForm.module.scss';
import {FLOATS_REGEX, INTEGER_REGEX, ZEROS_REGEX} from './constants';

type ActivityFormProps = {
  className?: string;
  taskId?: string;
  loading?: boolean;
  archived?: boolean;
  deleted?: boolean;
  isWbs?: boolean;
};

interface InputNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  allowFloats?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const InputNumber = ({allowFloats, onChange, value, ...props}: InputNumberProps) => {
  const validateInput = useMemo(() => {
    const regex = allowFloats ? FLOATS_REGEX : INTEGER_REGEX;
    return (input: string) => {
      if (!regex.test(input)) return false;
      if (input === '' || input === '-' || input === '0' || input === '-0') return true;
      return regex.test(input) && !ZEROS_REGEX.test(input);
    };
  }, [allowFloats]);

  const onChangeWithCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (validateInput(e.target.value)) {
      onChange(e);
    }
  };
  const inputValue = !value ? '' : value.toString();

  return <input {...props} className="ctrl-textfield" onChange={onChangeWithCheck} value={inputValue} />;
};

const ActivityForm = ({className, taskId, loading, archived, deleted}: ActivityFormProps) => {
  const {updateCurrentTaskId} = useProgressReportPopupContext();
  const {values, setValues, setFieldValue} = useFormikContext<FormValues>();
  const disabledFields = DISABLED_FIELDS_BY_TYPE[values.objectType];
  const match = useRouteMatch<{projectId: string}>();
  const project = useProjectSelector(match.params.projectId);
  const {viewMode} = useFilterContext();
  const {projectId} = useParams<{status: string; id: string; projectId: string}>();
  const mixpanelMeta = useMemo(
    () => Object.assign({}, {'Project Name': project?.name, place: 'side_panel'}),
    [project],
  );
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.task;
  const {locationOptions} = useOptionsForSelect(projectId);
  const {t} = useTranslation(['task']);
  const [expanded, setExpanded] = useLocalStorageState({
    key: GANTT_PREFERENCES_KEY,
    enabled: !!projectId,
    path: `byProject.${projectId}.panel.expanded`,
    defaultValue: [] as string[],
  });

  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);
  const hasAdminRole = hasAnyAdminRole;

  // non-admins can edit completion amount & status
  const isDisabledField = useCallback(
    (field: keyof FormValues): boolean => {
      if (archived || deleted || loading || disabledFields?.has(field)) return true;
      if (values.actualStart && field === 'startDate') {
        return true;
      }
      if (values.actualEnd && field === 'endDate') return true;
      if (values.actualEnd && field === 'duration') return true;
      if (hasAdminRole) return false;
      return !AVAILABLE_FIELDS_IN_READONLY_VIEW.has(field);
    },
    [hasAnyAdminRole, disabledFields, archived, deleted, loading, projectId, values.actualStart, values.actualEnd],
  );
  const notEditable = !hasAdminRole || archived || deleted || loading;

  const onChangeStartDate = (e: FocusEvent<HTMLInputElement>) => {
    const {value} = e.target;
    // reset endDate, because duration calculate on server
    setValues(Object.assign(values, {startDate: value, endDate: null}));
  };

  const parseDatesOnBlur = (fieldName: string) => {
    setFieldValue(fieldName, parseDateWith2DigitYear(toShortIso(values[fieldName])));
  };

  const onChangeDuration = (e) => {
    const {value} = e.target;
    const newDuration = value > 1 ? value - 1 : 0;
    const newEndDate = addDays(values.startDate, newDuration);
    setValues(Object.assign(values, {duration: value, endDate: newEndDate}));
  };

  const updateSubcontractor = async (createdSubcontractor: CompanyOrgs) => {
    setValues(Object.assign(values, {responsibleOrgId: createdSubcontractor.id}));
  };

  const onChangeActualStart = (e) => {
    const actualStart = e.target.value;
    if (actualStart) {
      setValues(Object.assign(values, {actualStart: actualStart, startDate: actualStart}));
    } else {
      setValues(Object.assign(values, {actualStart: null, actualEnd: null}));
    }
  };

  const onChangeActualEnd = (e) => {
    const actualEnd = e.target.value;
    if (actualEnd) {
      setValues(Object.assign(values, {actualEnd: actualEnd, endDate: actualEnd}));
    } else {
      setValues(Object.assign(values, {actualEnd: null}));
    }
  };

  const onBlurActualEnd = () => {
    const endD = values.endDate;
    const updates = {...values};
    if (typeof endD === 'string') {
      const endDateValue = parseDateWith2DigitYear(endD);
      updates.endDate = endDateValue;
      updates.actualEnd = endDateValue;
    }
    if (endD && !values.actualStart) {
      updates.actualStart = values.startDate;
    }
    setValues(updates);
  };

  const onExpandChange = (isOpen: boolean, blockName: string) =>
    setExpanded((expanded) => (isOpen ? expanded.concat(blockName) : expanded.filter((exp) => exp !== blockName)));

  const [showCalendarDaysTooltip, setShowCalendarDaysTooltip] = useState(false);
  const toggleShowCalendarDaysTooltip = () => setShowCalendarDaysTooltip((prev) => !prev);
  const tooltipRef = useRef(null);
  const getCalendarDaysTooltip = () => {
    if (showCalendarDaysTooltip) {
      return (
        <div
          ref={tooltipRef}
          className="react-popper react-popper--help react-popper--with-button-close"
          style={{position: 'absolute', bottom: '100%', right: 0}}
        >
          <div className="react-popper__title">{t('task:task_form.tooltips.calendarDays.title', 'Calendar Days')}</div>
          <div className="react-popper__text">{t('task:task_form.tooltips.calendarDays.description')}</div>
        </div>
      );
    }
  };

  return (
    <div className={cn(s.activityForm, className)}>
      <div className={s.activityForm__part}>
        <div className={s.activityForm__partHeader}>
          <h3 className={s.activityForm__partTitle}>{t('task:task_form.parts.dates.title', 'Dates')}</h3>
          <Field name="calendarDays">
            {({field: {name, value}}: FieldProps) => (
              <SkeletonPreloader when={loading}>
                <CtrlCheck
                  label="Use Calendar Days"
                  labelColor="#787676"
                  fieldType="checkbox"
                  reversed={true}
                  iconHelp={
                    <Button
                      style={{marginLeft: '5px'}}
                      iconOnly
                      icon={<Icon colorFill size={16} name="help" />}
                      onMouseEnter={toggleShowCalendarDaysTooltip}
                      onMouseLeave={toggleShowCalendarDaysTooltip}
                    >
                      Help
                    </Button>
                  }
                  tooltip={getCalendarDaysTooltip()}
                >
                  <input type="checkbox" checked={value} onChange={() => setFieldValue(name, !value)} />
                </CtrlCheck>
              </SkeletonPreloader>
            )}
          </Field>
        </div>
        <div className={s.activityForm__partBody}>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik name="startDate" label={t('task:task_form.form.start_date.label', 'Start Date')}>
              {({field: {value, name}}) => (
                <CoreNativeDatePicker
                  disabled={isDisabledField(name as keyof FormValues)}
                  minDate={new Date('1925-01-01')}
                  onBlur={() => parseDatesOnBlur(name)}
                  value={value}
                  onChange={onChangeStartDate}
                />
              )}
            </FormControl.Formik>
          </div>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik name="endDate" label={t('task:task_form.form.due_date.label', 'Due Date')}>
              {({field: {value, name, ...fieldProps}, form: {values}}: FieldProps) => (
                <CoreNativeDatePicker
                  {...fieldProps}
                  disabled={isDisabledField(name as keyof FormValues)}
                  maxDate={new Date('2100-01-01')}
                  minDate={values.startDate}
                  onChange={(e) => {
                    fieldProps.onChange(e);
                    setFieldValue('duration', '');
                  }}
                  value={value}
                />
              )}
            </FormControl.Formik>
          </div>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik
              name="actualStart"
              label={t('task:task_form.form.actual_start_date.label', 'Actual Start Date')}
            >
              {({field: {value, name}, form: {values}}: FieldProps) => (
                <CoreNativeDatePicker
                  disabled={isDisabledField(name as keyof FormValues)}
                  maxDate={values.actualEndDate}
                  minDate={new Date('1925-01-01')}
                  onChange={onChangeActualStart}
                  onBlur={() => {
                    parseDatesOnBlur(name);
                    parseDatesOnBlur('startDate');
                  }}
                  value={value}
                />
              )}
            </FormControl.Formik>
          </div>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik
              name="actualEnd"
              label={t('task:task_form.form.actual_finish_date.label', 'Actual Finished Date')}
            >
              {({field: {value, name}, form: {values}}: FieldProps) => (
                <CoreNativeDatePicker
                  disabled={isDisabledField(name as keyof FormValues)}
                  minDate={values.actualStart}
                  maxDate={new Date('2100-01-01')}
                  value={value}
                  onChange={onChangeActualEnd}
                  onBlur={onBlurActualEnd}
                />
              )}
            </FormControl.Formik>
          </div>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik name="duration" label={t('task:task_form.form.duration.label', 'Duration')}>
              {({field: {onChange, name, value, ...fieldProps}}: FieldProps) => (
                <SkeletonPreloader when={loading}>
                  <input
                    {...fieldProps}
                    className="ctrl-textfield"
                    onChange={onChangeDuration}
                    type="number"
                    min={0}
                    step={1}
                    value={value || ''}
                    disabled={isDisabledField(name as keyof FormValues)}
                  />
                </SkeletonPreloader>
              )}
            </FormControl.Formik>
          </div>
        </div>
      </div>

      <div className={s.activityForm__part}>
        <div className={s.activityForm__partHeader}>
          <h3 className={s.activityForm__partTitle}>{t('task:task_form.parts.details.title', 'Details')}</h3>
          {/* TODO
          <CtrlButton className={s.activityForm__partAction} view="link">
            Hide
          </CtrlButton> */}
        </div>
        <div className={s.activityForm__partBody}>
          <div className={cn(s.activityForm__partItem, s.activityForm__partItem_size_full)}>
            <FormControl.Formik label={t('task:task_form.form.location.label', 'Location')} name="location">
              {({field: {name, value, onBlur}, form}) => (
                <SkeletonFieldPreloader when={loading}>
                  <CoreSelect.Creatable
                    isClearable
                    isDisabled={isDisabledField(name as keyof FormValues)}
                    name={name}
                    value={value}
                    placeholder={t('task:task_form.form.location.placeholder', 'Set Location')}
                    options={locationOptions}
                    onBlur={onBlur}
                    onChange={(value) =>
                      mixpanel.trackWithAction(
                        () => form.setFieldValue(name, value || ''),
                        mixpanelEvents.formFields[name],
                        mixpanelMeta,
                      )
                    }
                  />
                </SkeletonFieldPreloader>
              )}
            </FormControl.Formik>
          </div>
          <div className={cn(s.activityForm__partItem, s.activityForm__partItem_size_full)}>
            <FormControl.Formik name="responsibleOrgId" label={t('task:task_form.form.subcontractor.label', 'Company')}>
              {({field: {value, name, ...fieldProps}, form: {setFieldValue}}) => (
                <SkeletonFieldPreloader when={loading}>
                  <AsyncProjectSubcontractorSelect
                    {...fieldProps}
                    isCreatable
                    isDisabled={isDisabledField(name as keyof FormValues)}
                    onChange={(value) =>
                      mixpanel.trackWithAction(
                        () => setFieldValue(name, value),
                        mixpanelEvents.formFields[name],
                        mixpanelMeta,
                      )
                    }
                    onAfterCreateOption={updateSubcontractor}
                    value={value}
                    placeholder={t('task:task_form.form.subcontractor.placeholder', 'Choose Company')}
                  />
                </SkeletonFieldPreloader>
              )}
            </FormControl.Formik>
          </div>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik name="type" label={t('task:task_form.form.type.label', 'Type')}>
              {({field: {value, name, ...fieldProps}, form: {setFieldValue}}) => (
                <AsyncTaskTypeSelect
                  {...fieldProps}
                  isCreatable
                  onChange={(value) =>
                    mixpanel.trackWithAction(
                      () => setFieldValue(name, value),
                      mixpanelEvents.formFields[name],
                      mixpanelMeta,
                    )
                  }
                  value={value}
                  name={name}
                  isDisabled={isDisabledField(name as keyof FormValues)}
                  placeholder={t('task:task_form.form.type.placeholder', 'Choose Type')}
                  menuPosition="fixed"
                />
              )}
            </FormControl.Formik>
          </div>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik name="status" label={t('task:task_form.form.status.label', 'Status')}>
              {({field: {name, ...field}}) => (
                <CoreStatusSelect.Task
                  {...field}
                  projectId={project?.id}
                  className="react-select--colored"
                  isDisabled={isDisabledField(name as keyof FormValues)}
                  placeholder={t('task:task_form.form.type.placeholder', 'Choose Status')}
                  menuPosition="absolute"
                  onChange={(value) =>
                    mixpanel.trackWithAction(
                      () => setFieldValue(name, value),
                      mixpanelEvents.formFields[name],
                      mixpanelMeta,
                    )
                  }
                />
              )}
            </FormControl.Formik>
          </div>
          {/* <div className={s.activityForm__partItem}>*/}
          {/*  <FormControl name="manpower" label="Projected Daily Manpower">*/}
          {/*    <input type="text" placeholder="None" />*/}
          {/*  </FormControl>*/}
          {/* </div>*/}
        </div>
      </div>
      <div className={s.activityForm__part}>
        <FormControlInline.Formik
          label={t('task:task_form.form.description.label', 'Description')}
          labelSize="m"
          name="description"
        >
          {({field}) => (
            <SkeletonPreloader when={loading}>
              <FieldInline
                element="textarea"
                sizeClass="s"
                placeholder={t('task:task_form.form.description.placeholder', 'Click here to add a description...')}
                {...field}
                disabled={isDisabledField(field.name as keyof FormValues)}
              />
            </SkeletonPreloader>
          )}
        </FormControlInline.Formik>
      </div>
      {values.description ? (
        <div className={s.activityForm__partSection}>
          <InfoLinksList text={values?.description} />
        </div>
      ) : null}

      {!disabledFields?.has('completionAmount') && (
        <div className={s.activityForm__part}>
          <div className={s.activityForm__partHeader}>
            <h3 className={s.activityForm__partTitle}>
              {t('task:task_form.parts.completion.title', 'Completion Amount')}
            </h3>
            {/* TODO*/}
            {/* <CtrlButton className={s.activityForm__partAction} view="link">*/}
            {/*  Hide*/}
            {/* </CtrlButton>*/}
          </div>

          <div className={`${s.activityForm__partBody} ${s.activityForm__partBody_amount}`}>
            <div className={`${s.activityForm__partItem} ${s.activityForm__partItem_size_s}`}>
              <FormControl.Formik
                button={
                  <Button
                    iconOnly
                    icon={<Icon name="edit" />}
                    onClick={() => {
                      updateCurrentTaskId(taskId);
                    }}
                  />
                }
                name="completionAmount"
                errorWidth="160px"
                label={t('task:task_form.form.progress.label', 'Progress')}
              >
                {({field}) => (
                  <SkeletonPreloader when={loading} height={40}>
                    <input type="text" placeholder="10" {...field} disabled />
                  </SkeletonPreloader>
                )}
              </FormControl.Formik>
            </div>
            <div
              className={`${s.activityForm__partItem} ${s.activityForm__partItem_size_s} ${s.activityForm__partItem_units}`}
            >
              <FormControl.Formik
                name="completionUnit"
                errorWidth="160px"
                label={t('task:task_form.form.unit.label', 'Unit')}
              >
                {({field: {name, ...field}, form: {values}}: FieldProps) => (
                  <SkeletonPreloader when={loading}>
                    <CoreSelect
                      {...field}
                      isDisabled={notEditable}
                      options={taskCompletionUnitOptions}
                      onChange={(value) =>
                        mixpanel.trackWithAction(
                          () =>
                            setValues({
                              ...values,
                              completionTarget:
                                value === CompletionUnits.Percent ? '100' : values.completionTarget || '0',
                              [name]: value,
                            }),
                          mixpanelEvents.formFields[name],
                          mixpanelMeta,
                        )
                      }
                    />
                  </SkeletonPreloader>
                )}
              </FormControl.Formik>
            </div>
            <div
              className={`${s.activityForm__partItem} ${s.activityForm__partItem_size_s} ${s.activityForm__partItem_target}`}
            >
              <FormControl.Formik
                name="completionTarget"
                label={t('task:task_form.form.completion_target.label', 'Target Amount')}
              >
                {({field, form: {values}}) => (
                  <SkeletonPreloader when={loading} height={40}>
                    <input
                      type="text"
                      placeholder="100"
                      {...field}
                      disabled={notEditable || values.completionUnit === CompletionUnits.Percent}
                    />
                  </SkeletonPreloader>
                )}
              </FormControl.Formik>
            </div>
          </div>
        </div>
      )}
      {!!project.customFieldDef.length && (
        <CustomFieldSection projectId={projectId} loading={loading} isReadOnly={!hasAdminRole} />
      )}
      <Collapse
        className={s.activityForm__part}
        defaultOpen={expanded.includes('labor')}
        onChange={(isOpen) => onExpandChange(isOpen, 'labor')}
        toggleElement={(isOpen, toggle) => (
          <div className={s.activityForm__partHeader} onClick={toggle}>
            <h3 className={s.activityForm__partTitle}>{t('task:task_form.parts.manpower.title', 'Manpower')}</h3>
            <CtrlButton view="link">
              {isOpen ? t('task:task_form.parts.action.hide', 'Hide') : t('task:task_form.parts.action.show', 'Show')}
            </CtrlButton>
          </div>
        )}
      >
        <div className={`${s.activityForm__partBody} ${s.activityForm__partBody_amount}`}>
          <div className={`${s.activityForm__partItem} ${s.activityForm__partItem_size_s}`}>
            <FormControl.Formik
              name="estLaborHours"
              label={t('task:task_form.form.estimated_labor_hours.label', 'Estimated Labor Hours')}
            >
              {({field}) => (
                <SkeletonPreloader when={loading}>
                  <InputNumber {...field} allowFloats className="ctrl-textfield" maxLength={100} />
                </SkeletonPreloader>
              )}
            </FormControl.Formik>
          </div>
          <div className={`${s.activityForm__partItem} ${s.activityForm__partItem_size_s}`}>
            <FormControl.Formik
              name="projectedLabor"
              label={t('task:task_form.form.estimated_labor.label', 'Estimated Labor')}
            >
              {({field}) => (
                <SkeletonPreloader when={loading}>
                  <InputNumber {...field} className="ctrl-textfield" maxLength={100} />
                </SkeletonPreloader>
              )}
            </FormControl.Formik>
          </div>
          <div
            className={`${s.activityForm__partItem} ${s.activityForm__partItem_size_s} ${s.activityForm__partItem_target}`}
          >
            <FormControl.Formik
              button={
                <Button
                  iconOnly
                  icon={<Icon name="edit" />}
                  onClick={() => {
                    updateCurrentTaskId(taskId);
                  }}
                />
              }
              name="averageLabor"
              label={t('task:task_form.form.average_labor.label', 'Average Labor')}
            >
              {({field}) => (
                <SkeletonPreloader when={loading}>
                  <input type="text" {...field} value={field.value} disabled />
                </SkeletonPreloader>
              )}
            </FormControl.Formik>
          </div>
        </div>
      </Collapse>
      <Collapse
        className={s.activityForm__part}
        defaultOpen={expanded.includes('codes')}
        onChange={(isOpen) => onExpandChange(isOpen, 'codes')}
        toggleElement={(isOpen, toggle) => (
          <div className={s.activityForm__partHeader} onClick={toggle}>
            <h3 className={s.activityForm__partTitle}>{t('task:task_form.parts.codes.title', 'Codes')}</h3>
            <CtrlButton view="link">
              {isOpen ? t('task:task_form.parts.action.hide', 'Hide') : t('task:task_form.parts.action.show', 'Show')}
            </CtrlButton>
          </div>
        )}
      >
        <div className={s.activityForm__partBody}>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik name="phaseCode" label={t('task:task_form.form.phase_code.label', 'Phase ')}>
              {({field}) => (
                <SkeletonPreloader when={loading}>
                  <input {...field} value={field.value || ''} className="ctrl-textfield" maxLength={12} />
                </SkeletonPreloader>
              )}
            </FormControl.Formik>
          </div>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik name="costCode" label={t('task:task_form.form.sost_code.label', 'Cost')}>
              {({field}) => (
                <SkeletonPreloader when={loading}>
                  <input {...field} value={field.value || ''} className="ctrl-textfield" maxLength={12} />
                </SkeletonPreloader>
              )}
            </FormControl.Formik>
          </div>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik name="csiCode" label={t('task:task_form.form.csi_code.label', 'CSI')}>
              {({field}) => (
                <SkeletonPreloader when={loading}>
                  <input {...field} value={field.value || ''} className="ctrl-textfield" maxLength={12} />
                </SkeletonPreloader>
              )}
            </FormControl.Formik>
          </div>
          <div className={s.activityForm__partItem}>
            <FormControl.Formik name="customCode" label={t('task:task_form.form.custom_code.label', 'Custom')}>
              {({field}) => (
                <SkeletonPreloader when={loading}>
                  <input {...field} value={field.value || ''} className="ctrl-textfield" maxLength={12} />
                </SkeletonPreloader>
              )}
            </FormControl.Formik>
          </div>
        </div>
      </Collapse>
      <div className={s.activityForm__part}>
        <InfoGallery taskId={taskId} />
      </div>
    </div>
  );
};

export default ActivityForm;
