import cs from 'classnames';
import {FieldMetaProps, useField} from 'formik';
import {cloneElement, FC, isValidElement, MouseEventHandler, ReactElement} from 'react';

import {Icon} from '..';
import Button from '../Button';

type FormControlProps = {
  name: string;
  label?: string;
  labelClassName?: string;
  className?: string;
  labelHidden?: boolean;
  secondColor?: boolean;
  iconName?: string;
  iconHelpName?: string;
  tooltip?: ReactElement | null;
  onIconClick?: MouseEventHandler<HTMLButtonElement>;
  onHelpClick?: MouseEventHandler<HTMLButtonElement>;
  footerNote?: string;
  showError?: boolean;
  error?: string;
  errorClassName?: string;
  errorWidth?: string;
  'data-cy'?: string;
  iconColor?: string;
};

const FormControl: FC<FormControlProps> = ({
  name,
  label,
  className,
  labelHidden = false,
  secondColor = false,
  labelClassName = 'ctrl-form__label',
  iconName,
  iconHelpName,
  onHelpClick,
  tooltip,
  onIconClick,
  footerNote,
  children,
  showError,
  error,
  errorClassName,
  errorWidth,
  'data-cy': dataCy,
  iconColor = '',
}) => {
  let meta: FieldMetaProps<string>;
  try {
    [, meta] = useField(name);
  } catch (error) {
    if (process.env.NODE_ENV === 'development' && localStorage.getItem('debug')) {
      console.warn(`field with name ${name} doesn't exist`);
    }
  }

  return (
    <div
      className={cs(
        'ctrl-form',
        className,
        {
          'is-error': (!!meta && meta.error && meta.touched) || showError || !!error,
        },
        labelHidden && 'ctrl-form--label-hidden',
        secondColor && 'ctrl-form--color-second',
        iconName && 'ctrl-form--button',
        iconHelpName && 'ctrl-form__header',
      )}
      data-cy={dataCy || name}
    >
      {label && (
        <div className="ctrl-form__header">
          <label className={labelClassName}>{label}</label>
        </div>
      )}
      {iconHelpName && (
        <Button
          className="ctrl-form__button-help"
          onClick={onHelpClick}
          style={{top: 5}}
          iconOnly
          icon={<Icon colorFill size={24} name={iconHelpName} />}
        >
          Help
        </Button>
      )}
      <div className="ctrl-form__body">
        {isValidElement(children)
          ? cloneElement(children as ReactElement, {
              name,
            })
          : children}
        {iconName && (
          <button
            className="ctrl-btn-clear ctrl-form__button"
            type="button"
            onClick={(e) => typeof onIconClick === 'function' && onIconClick(e)}
          >
            <span className="ctrl-btn-clear__text">{label}</span>
            <Icon
              colorFill
              className="ctrl-btn-clear__icon"
              size={24}
              name={iconName}
              style={iconColor ? {color: iconColor} : null}
            />
          </button>
        )}
        {((!!meta && meta.error && meta.touched) || !!error) && (
          <div
            className={cs('ctrl-form__validation', errorClassName)}
            style={errorWidth ? {maxWidth: 'unset', width: errorWidth} : null}
          >
            {meta?.error || error}
          </div>
        )}
      </div>
      {footerNote && (
        <div className="ctrl-form__footer">
          <span className="ctrl-form__note">{footerNote}</span>
        </div>
      )}
      {tooltip}
    </div>
  );
};
export default FormControl;
