import dayjs from 'dayjs';
import {RouteComponentProps} from 'react-router';

import {updateSearchQuery} from 'modules/Tasks/utils/updateSearchQuery';
import {TasksViewMode} from 'shared/constants/common';
import {TaskActiveTab} from 'shared/models/task';

interface RedirectProps {
  activeTab: TaskActiveTab;
  history: RouteComponentProps['history'];
  newIssueFocus?: boolean;
  schedEndFirst?: Date;
  taskId: string;
}

export function redirectToGanttView({
  taskId,
  activeTab,
  history,
  newIssueFocus = false,
  schedEndFirst = undefined,
}: RedirectProps) {
  const dailyReportDate = dayjs(schedEndFirst).valueOf().toString() || undefined;
  const searchParams = updateSearchQuery(history.location.search, {
    view: TasksViewMode.gantt,
    activeTab: TaskActiveTab[activeTab],
    taskId,
  });

  history.replace({
    pathname: history.location.pathname,
    state: {
      taskId,
      activeTab,
      dailyReportDate,
      newIssueFocus,
    },
    search: searchParams,
  });
}
