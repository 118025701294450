import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Popup} from 'shared/components';
import {useAnalyticsService, useCompany, useProfile} from 'shared/hooks';

type Props = {
  visible: boolean;
  onClose: () => void;
};

const CongratsPopup: FC<Props> = ({visible, onClose}: Props) => {
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.signup.happyWorkingScreen;
  const {t} = useTranslation('signup');
  const company = useCompany();
  const profile = useProfile();
  const [sentScreenEvent, setSentScreenEvent] = useState(false);

  useEffect(() => {
    if (visible && !sentScreenEvent && company?.id && profile?.id) {
      mixpanel.track(mixpanelEvents.showed);
      setSentScreenEvent(true);
    }
  }, [visible, company, profile, sentScreenEvent]);

  useEffect(() => {
    if (sentScreenEvent && !visible) setSentScreenEvent(false);
  }, [visible, sentScreenEvent]);

  const onCloseModal = () => {
    mixpanel.track(mixpanelEvents.buttons.dismiss);
    onClose();
  };

  return (
    <Popup visible={visible} onClose={onCloseModal} className="popup__item--size-m">
      <Popup.Body style={{borderRadius: '16px'}}>
        <form className="form-onboarding-z form-onboarding-z--without-nav form-onboarding-z--without-footer">
          <div className="form-onboarding-z__container">
            <header className="form-onboarding-z__header">
              <h1 className="form-onboarding-z__title">{t('congrats_popup.title', `You're In.`)}</h1>
              <div className="form-onboarding-z__description">
                {t(
                  'congrats_popup.description',
                  'Your Lookaheads will save you time building. Share your short-interval schedules!',
                )}
              </div>
            </header>
            <div className="form-onboarding-z__grid">
              <div className="form-onboarding-z__item">
                <div className="onboarding-finish">
                  <div className="onboarding-finish__container">
                    <picture className="picture onboarding-finish__media">
                      <source srcSet="/images/screenshots/screenshot-1@1x.jpg 1x, /images/screenshots/screenshot-1@2x.jpg 2x" />
                      <img
                        className="picture__image"
                        src="/images/screenshots/screenshot-1@1x.jpg"
                        alt="Congrats Popup"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Popup.Body>
      <Popup.Footer>
        <Button
          data-cy="signup_finish_btn"
          className="popup__button"
          colorStyle="success"
          onClick={() => mixpanel.trackWithAction(onClose, mixpanelEvents.buttons.finish)}
        >
          {t('congrats_popup.button', 'Get Started')}
        </Button>
      </Popup.Footer>
    </Popup>
  );
};
export default CongratsPopup;
