import cn from 'classnames';
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import {forwardRef, MouseEventHandler, useImperativeHandle, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams, useRouteMatch} from 'react-router';

import {
  CoreSelect,
  FormControl,
  FormikChangeWatcher,
  Icon,
  OptionType,
  PhoneControl,
  Switcher,
  Tooltip,
} from 'shared/components';
import AsyncOrgsSelect from 'shared/components/CoreForm/Select/AsyncCompanyOrgsSelect';
import {DEFAULT_COUNTRY_CODES, splitPhoneByCountryCode} from 'shared/constants/common';
import {ROUTES} from 'shared/constants/routes';
import {defaultValues} from 'shared/constants/worker';
import {sortWorkerRoles} from 'shared/helpers/worker';
import {useAnalyticsService, useProfile} from 'shared/hooks';
import {useLoadWorkerTrades} from 'shared/hooks/useLoadWorkerTrades';
import {TaskStatus} from 'shared/models/task';
import {CompanyWorker, CompanyWorkerRole, InviteBy, WorkerFormValues} from 'shared/models/worker';
import {getInviteMemberHandler, getWorkerMixpanelLabel} from 'shared/utils/inviteWorker';

import {getValidationSchema} from './validation';

type WorkerFormProps = {
  profile?: CompanyWorker;
  onSubmit: (values: Partial<WorkerFormValues>) => Promise<unknown>;
  onValuesChange: (values: WorkerFormValues) => void;
  onRemove?: MouseEventHandler<HTMLButtonElement>;
  options?: OptionType[];
  excludedFields?: string[];
};

const WorkerForm = forwardRef<FormikProps<WorkerFormValues>, WorkerFormProps>(
  ({profile, onSubmit, onValuesChange, onRemove, options, excludedFields = []}, ref) => {
    const myProfile = useProfile();
    const formik = useRef<FormikProps<WorkerFormValues>>();
    const {t} = useTranslation(['worker', 'common']);
    const {path} = useRouteMatch();
    const {projectId} = useParams<{projectId: string}>();

    const {mixpanel} = useAnalyticsService();
    const mixpanelEvents = mixpanel.events.worker.formFields;

    const [phoneCode, phoneNumber] = profile ? splitPhoneByCountryCode(profile.workerFull.mobileNumber) : [];

    const isNewWorker = !profile?.id;
    const isInvited = profile?.workercard?.status === 'invited';
    const isClosed = profile?.workercard?.status === TaskStatus.closed;
    const isNewOrInvited = isNewWorker || isInvited;
    const isMe = myProfile?.id === profile?.workerId;
    const canRemove = !isNewWorker && profile?.status !== 'removed';
    const disableContacts = !isNewOrInvited || isClosed;
    const isProjectWorker = path === ROUTES.projectWorker;
    const adminRole = isProjectWorker ? 'project_admin' : 'company_admin';

    useImperativeHandle(ref, () => formik.current);

    const {handleSubmit, checkIsAdmin} = getInviteMemberHandler(formik, projectId, onSubmit);
    const {localizedTrades} = useLoadWorkerTrades();

    const selectRole = (value: CompanyWorkerRole) => {
      const updateFields = {...formik.current.values, roles: [value]};

      if (['company_admin', 'project_admin'].includes(value) && isNewOrInvited) {
        updateFields.inviteBy = InviteBy.Email;
        updateFields.sendEmail = true;
      }
      formik.current.setValues(updateFields);
    };

    function getInitialValues(profile?: CompanyWorker) {
      return profile
        ? {
            fullName: profile.workerFull.fullName,
            phoneCode: phoneCode || DEFAULT_COUNTRY_CODES[0].value,
            phoneNumber: phoneNumber,
            trade: profile.workerFull.trade,
            sendEmail: false,
            sendSms: false,
            inviteBy: profile.workerFull?.email ? InviteBy.Email : InviteBy.Phone,
            makeAdmin: checkIsAdmin(profile.roles),
            makeForeman: profile.roles.includes('foreman'),
            workerEmail: profile.workerFull?.email,
            roles: sortWorkerRoles(profile.roles).slice(0, 1),
            orgMappingIds: !!profile.orgMappingIds?.length ? profile.orgMappingIds : null,
          }
        : defaultValues;
    }

    return (
      <div>
        <Formik<WorkerFormValues>
          innerRef={formik}
          validationSchema={getValidationSchema(t)}
          initialValues={getInitialValues(profile)}
          onSubmit={handleSubmit}
        >
          {({setFieldValue, values, setValues}) => (
            <Form className="form-default">
              <FormikChangeWatcher debounce={200} onChange={onValuesChange} />
              <div className="form-default__container">
                <header className="form-default__header">
                  <h2 className="form-default__title">
                    {isNewWorker
                      ? t('worker:form.title_new', 'New Worker')
                      : t('worker:form.title_edit', 'Edit Worker')}
                  </h2>
                  {canRemove && (
                    <Tooltip
                      isHide={!isMe}
                      text={t('worker:form.tooltip', 'You can not remove yourself')}
                      placement="bottom-end"
                    >
                      <div className="form-default__actions">
                        <button
                          disabled={isMe}
                          data-cy="worker_delete_bt"
                          className="z-ctrl-btn z-ctrl-btn--color-second form-default__actions-button"
                          type="button"
                          onClick={onRemove}
                        >
                          <Icon size={24} colorFill className="z-ctrl-btn__icon" name="remove_from_trash" />
                          <span className="z-ctrl-btn__text">{t('worker:form.buttons.remove', 'Remove worker')}</span>
                        </button>
                      </div>
                    </Tooltip>
                  )}
                </header>
                <div className="form-default__grid">
                  <div className="form-default__item form-default__item--full">
                    <FormControl label={t('worker:form.name.label', 'Full Name')} name="fullName">
                      <Field
                        as="input"
                        className="ctrl-textfield"
                        placeholder={t('worker:form.name.placeholder', 'Full Name')}
                        disabled={!isNewOrInvited}
                      />
                    </FormControl>
                  </div>
                  <div className="form-default__item form-default__item--full">
                    <FormControl label={t('worker:form.trade.label', 'Select Trade')} name="trade">
                      <Field>
                        {({field}: FieldProps) => (
                          <CoreSelect
                            options={localizedTrades}
                            isLoading={!localizedTrades}
                            value={field.value}
                            onChange={(value) => setFieldValue('trade', value)}
                          />
                        )}
                      </Field>
                    </FormControl>
                  </div>

                  {isProjectWorker && !excludedFields.includes('orgMappingIds') && (
                    <div className="form-default__item form-default__item--full">
                      <FormControl label={t('worker:form.organization.label', 'Organization')} name="orgMappingIds">
                        <Field>
                          {({field: {name, value}}: FieldProps) => (
                            <AsyncOrgsSelect
                              isMulti={true}
                              name={name}
                              value={value}
                              onChange={(value) => setFieldValue(name, value)}
                              isDisabled={isClosed}
                              options={options}
                            />
                          )}
                        </Field>
                      </FormControl>
                    </div>
                  )}
                  <div className="form-default__item form-default__item--full">
                    <Field name="roles" type="radio" value={adminRole}>
                      {({field}: FieldProps) => (
                        <Switcher
                          {...field}
                          value={checkIsAdmin(values.roles)}
                          id={`worker_form_${field.name}_${field.value}`}
                          view="radio"
                          reverse
                          onChange={() =>
                            mixpanel.trackWithAction(
                              () => selectRole(field.value),
                              mixpanelEvents.makeAdmin(getWorkerMixpanelLabel(!!projectId)),
                            )
                          }
                          label={
                            isProjectWorker
                              ? t('worker:form.admin.label_project', 'Make Project Admin')
                              : t('worker:form.admin.label', 'Make Admin')
                          }
                          tip={t('worker:form.admin.tip', 'Admin users can create activities and manage workers')}
                          disabled={isClosed || isMe}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-default__item form-default__item--full ">
                    <Field name="roles" type="radio" value="foreman">
                      {({field: {name, value, ...field}}: FieldProps) => (
                        <Switcher
                          {...field}
                          reverse
                          value={values.roles.includes(value)}
                          onChange={() =>
                            mixpanel.trackWithAction(
                              () => selectRole(value),
                              mixpanelEvents.makeForeman(getWorkerMixpanelLabel(!!projectId)),
                            )
                          }
                          id={`worker_form_${name}_${value}`}
                          view="radio"
                          label={t('worker:form.foreman.label', 'Make Foreman')}
                          tip={t(
                            'worker:form.foreman.tip',
                            'Foreman users can add new workers by scanning QR code using the app, and create/edit activities',
                          )}
                          disabled={isClosed || isMe}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-default__item form-default__item--full">
                    <Field name="roles" type="radio" value="worker">
                      {({field: {name, value, ...field}}: FieldProps) => (
                        <Switcher
                          {...field}
                          value={values.roles.includes(value)}
                          onChange={() =>
                            mixpanel.trackWithAction(
                              () => selectRole(value),
                              mixpanelEvents.makeForeman(getWorkerMixpanelLabel(!!projectId)),
                            )
                          }
                          id={`worker_form_${name}_${value}`}
                          view="radio"
                          reverse
                          label={t('worker:form.worker.label', 'Make Worker')}
                          tip={t(
                            'worker:form.worker.tip',
                            'Worker users can use the app to update task status, add photos/ comments',
                          )}
                          disabled={isClosed || isMe}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-default__item form-default__item--full form-default__item--indent">
                    <h2
                      className={cn('form-default__title', {
                        ['form-default__title--disabled']: checkIsAdmin(values.roles) || !isNewOrInvited,
                      })}
                    >
                      {t('worker:form.invited.title', 'Invited By')}
                    </h2>
                    <div className="ctrl-radios">
                      <div className="ctrl-radios__grid">
                        <label className="ctrl-radios__item">
                          <Field
                            as="input"
                            className="ctrl-radios__field"
                            type="radio"
                            name="inviteBy"
                            value={InviteBy.Email}
                            disabled={!isNewOrInvited}
                          />
                          <span className="ctrl-radios__visual" />
                          <span className="ctrl-radios__label">{t('worker:form.invited.email', 'Email')}</span>
                        </label>
                        <label className="ctrl-radios__item">
                          <Field
                            as="input"
                            className="ctrl-radios__field"
                            type="radio"
                            name="inviteBy"
                            value={InviteBy.Phone}
                            disabled={checkIsAdmin(values.roles) || !isNewOrInvited}
                          />
                          <span className="ctrl-radios__visual" />
                          <span className="ctrl-radios__label">{t('worker:form.invited.phone', 'Phone Number')}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <>
                    {(values.inviteBy === InviteBy.Email && (
                      <>
                        <div className="form-default__item form-default__item--full">
                          <FormControl label={t('worker:form.email.label', 'Email Address')} name="workerEmail">
                            <Field
                              as="input"
                              className="ctrl-textfield"
                              placeholder={t('worker:form.email.placeholder', 'example@email.com')}
                              disabled={disableContacts}
                              onChange={(changes) => {
                                setValues({
                                  ...values,
                                  workerEmail: changes.target.value,
                                  sendEmail: true,
                                });
                              }}
                            />
                          </FormControl>
                        </div>
                        {isNewOrInvited && (
                          <div className="form-default__item form-default__item--full">
                            <Field name="sendEmail">
                              {({field}: FieldProps) => (
                                <Switcher
                                  {...field}
                                  disabled={disableContacts}
                                  onChange={(value) =>
                                    mixpanel.trackWithAction(
                                      () => setFieldValue(field.name, value),
                                      mixpanelEvents.notifyEmail(getWorkerMixpanelLabel(!!projectId)),
                                    )
                                  }
                                  label={`${t('worker:form.email.send', 'Send invitation to')} ${
                                    values.workerEmail || t('worker:form.email.placeholder', 'example@email.com')
                                  }`}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      </>
                    )) || (
                      <>
                        <div className="form-default__item form-default__item--full">
                          <PhoneControl
                            disabled={disableContacts || checkIsAdmin(values.roles)}
                            onChange={(changes) => {
                              setValues({
                                ...values,
                                phoneCode: changes.code,
                                phoneNumber: changes.phone,
                                sendSms: true,
                              });
                            }}
                            value={{phone: values.phoneNumber, code: values.phoneCode}}
                          />
                        </div>
                        {isNewOrInvited && (
                          <div className="form-default__item form-default__item--full">
                            <Field name="sendSms">
                              {({field}: FieldProps) => (
                                <Switcher
                                  {...field}
                                  disabled={disableContacts}
                                  id={`worker_form_${field.name}`}
                                  onChange={(value) =>
                                    mixpanel.trackWithAction(
                                      () => setFieldValue(field.name, value),
                                      mixpanelEvents.notifySMS(getWorkerMixpanelLabel(!!projectId)),
                                    )
                                  }
                                  label={`${t('worker:form.phone.send', 'Send SMS invitation to')} ${
                                    values.phoneCode
                                  } ${values.phoneNumber || t('worker:form.phone.placeholder', '(XXX) XXX-XXXX')}`}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  },
);
WorkerForm.displayName = 'WorkerForm';
export default WorkerForm;
