/* eslint-disable @typescript-eslint/naming-convention */
import * as Sentry from '@sentry/browser';
import {AxiosResponse} from 'axios';
import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {TFunction} from 'i18next';
import {render} from 'react-dom';
import {QueryClientProvider} from 'react-query';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import {toast} from 'react-toastify';

import {TasksApi} from 'api';
import {reactQueryClient} from 'App';
import {IssueTypeEditor} from 'modules/Tasks/components/Gantt/components/Editors/IssueTypeEditor';
import ResponsibleEditor from 'modules/Tasks/components/Gantt/components/Editors/ResponsibleEditor';
import {IssuesSeverityEditor} from 'modules/Tasks/components/Gantt/components/Editors/SeverityEditor';
import StatusEditor from 'modules/Tasks/components/Gantt/components/Editors/StatusEditor';
import SubcontractorEditor from 'modules/Tasks/components/Gantt/components/Editors/SubcontractorEditor';
import TaskTypeEditor from 'modules/Tasks/components/Gantt/components/Editors/TaskTypeEditor';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {
  formatTaskDependencies,
  getDependenciesDiff,
  getTaskByRowNumber,
  parseTaskDependencies,
} from 'modules/Tasks/components/Gantt/utils/gantt';
import {routerHistory} from 'routerHistory';
import TaskChainUpdateQueue from 'services/TaskChainUpdateQueue/TaskChainUpdateQueue';
import {TasksObserverProvider} from 'services/TasksObserver';
import {ConformationProvider} from 'shared/components';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {isAfter, isBefore, ISO_SHORT, isSameDay, safeFormatDate, subtract} from 'shared/helpers/dates';
import {QueryCacheHelper} from 'shared/hooks/useQueryCache/QueryCacheHelper';
import {TaskObjectType} from 'shared/models/task';
import {TaskDependencyToGanttLinkType} from 'shared/models/TaskDependency';
import store from 'store';

import CostImpactEditor from '../components/Editors/CostImpactEditor';
import {CustomFieldSelectEditor} from '../components/Editors/CustomFields/CustomFieldSelectEditor';
import LocationEditor from '../components/Editors/LocationEditor';

import {GANTT_COLUMNS_NAMES} from './constants';
import {parseDateWith2DigitYear} from './date';

const DISABLE_COLUMNS_VALIDATION = [
  GANTT_COLUMNS_NAMES.location,
  GANTT_COLUMNS_NAMES.csiCode,
  GANTT_COLUMNS_NAMES.phaseCode,
  GANTT_COLUMNS_NAMES.customCode,
  GANTT_COLUMNS_NAMES.costCode,
  GANTT_COLUMNS_NAMES.description,
  GANTT_COLUMNS_NAMES.estLaborHours,
];

function getAttrsString(attrs: Record<string, string | number | boolean>) {
  return attrs
    ? Object.entries(attrs)
        .reduce((acc, [key, value]) => acc.concat(`${key}="${value}"`), [] as string[])
        .join(' ')
    : '';
}

export function configureInlineEditors(gantt: GanttStatic, t: TFunction, cacheHelper: QueryCacheHelper) {
  const getInput = (node) => node.querySelector('input');
  const textEditor = gantt.config.editor_types.text;

  gantt.config.editor_types.generalEditor = gantt.mixin(
    {
      show: function (id, column, config, placeholder) {
        const attrs = getAttrsString(column.attrs);
        placeholder.innerHTML = `<div><input id=${id} data-cy="gantt_edit_${column.name}_input" type='text' name="${column.name}" ${attrs}/></div>`;
      },
      is_valid: function (value: string, id, column, node) {
        const input: HTMLInputElement = this.get_input(node);
        return (DISABLE_COLUMNS_VALIDATION.includes(column) || !!value.trim()) && input.checkValidity();
      },
      set_value: function (value: unknown, ...rest) {
        textEditor.set_value.apply(this, [value == undefined ? '' : value, ...rest]);
      },
      get_value: function (id, column, node) {
        // Trim leading/trailing whitespace from fields using general text editor
        return getInput(node).value.trim();
      },
      is_changed: function (value, id, column, node) {
        const currentValue = getInput(node).value;
        const prevValue = this.valueOnStartEdit ?? value;
        return currentValue !== prevValue;
      },
    },
    textEditor,
    false,
  );

  gantt.config.editor_types.generalEditorForCustomField = gantt.mixin(
    {
      set_value: function (value: unknown, id, column, node) {
        const task = gantt.getTask(id);
        getInput(node).value =
          task.custom_fields.find(({internal_field_name}) => internal_field_name === column.name)?.value ?? '';
      },
      is_valid: function () {
        // TODO: add validation?
        return true;
      },
    },
    gantt.config.editor_types.generalEditor,
    false,
  );

  gantt.config.editor_types.generalNumberEditor = gantt.mixin(
    {
      show: function (id, column, config, placeholder) {
        const attrs = getAttrsString(column.attrs);
        placeholder.innerHTML = `<div><input id=${id} data-cy="gantt_edit_${column.name}_input" type='number' name="${column.name}" ${attrs}/></div>`;
      },
    },
    gantt.config.editor_types.generalEditorForCustomField,
    false,
  );

  gantt.config.editor_types.durationEditor = gantt.mixin(
    {
      is_changed: function (value, id, column, node) {
        const task = gantt.getTask(id);
        const currentValue = getInput(node).value;
        const prevValue = this.valueOnStartEdit ?? Number(value);
        return task.datesIsPristine || currentValue !== prevValue;
      },
      set_value: function (value, id, column, node) {
        const task = gantt.getTask(id);
        getInput(node).value = task.datesIsPristine ? '' : Math.abs(parseInt(String(value)));
      },
      is_valid: (value) => {
        const parsed = value && parseInt(String(value));
        return !isNaN(parsed) && parsed >= 0;
      },
    },
    gantt.config.editor_types.number,
    false,
  );

  const requiredDate = (gantt.config.editor_types.requiredDate = {
    show: function (id, column, config, placeholder) {
      const task = gantt.getTask(id);
      const attributes = {};
      if (column.validate) {
        Object.keys(column.validate).forEach((key) => {
          Object.assign(attributes, {
            [key]: safeFormatDate(
              column.validate[key].includes('end')
                ? subtract(task[column.validate[key]], 1)
                : task[column.validate[key]],
              ISO_SHORT,
            ),
          });
        });
      }
      const html = `<input type='date' style='min-width: 125px' ${getAttrsString(attributes)} name='${column.name}'>`;
      placeholder.innerHTML = html;
    },
    is_changed: function (value, id, column, node) {
      const task = gantt.getTask(id);
      if (task.datesIsPristine) {
        task.datesIsPristine = false;
        gantt.refreshTask(id);
      }
      const currentValue = this.get_value(id, column, node);
      // do nothing if prev and current values are null
      if (currentValue === null && value === null) {
        return false;
      }
      // if one of values is null, then it's create/delete operation, pass
      if (value === null || currentValue === null) {
        return true;
      }
      // in other cases new and old value is date, compare it
      return !isSameDay(currentValue, this.valueOnStartEdit ?? value);
    },
    get_value: function (id, column, node) {
      const date = getInput(node).value;
      const result = date ? parseDateWith2DigitYear(date) : undefined;
      return result;
    },
    set_value: function (value, id, column, node) {
      getInput(node).value = safeFormatDate(value, ISO_SHORT);
    },
    is_valid: (value: string, id: string, columnName: string) => {
      if (!value) {
        return false;
      }
      const date = dayjs(value);
      // we need additional year check because dayjs() parse date string like '0121-12-25' as valid.
      if (value && (!date.isValid() || date.year() < 1900)) {
        return false;
      }
      const task: GanttTask = gantt.getTask(id);
      const config = gantt.getGridColumn(columnName);
      if (config.validate?.min) {
        if (
          task[columnName] &&
          !isAfter(value, task[config.validate.min]) &&
          task.object_type !== TaskObjectType.milestone
        ) {
          toast.error(t('gantt:toast.error.dates.end_date', 'The end date must be after the start date'));
          return false;
        }
      }
      if (config.validate?.max) {
        if (
          task[columnName] &&
          !isBefore(value, task[config.validate.max]) &&
          task.object_type !== TaskObjectType.milestone
        ) {
          toast.error(t('gantt:toast.error.dates.start_date', 'The end start must be before the end date'));
          return false;
        }
      }
      return true;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function get_input(node) {
      return node.querySelector('input');
    },
  });

  const optionalDate = (gantt.config.editor_types.actualStartDateEditor = gantt.mixin(
    {
      get_value: function (id, column, node) {
        const date = getInput(node).value;
        return date ? dayjs(date).toDate() : null;
      },
      is_valid(value, id, column) {
        return !value || requiredDate.is_valid.apply(this, [value, id, column]);
      },
    },
    requiredDate,
    false,
  ));

  const inclusiveDate = {
    set_value: function (value, id, column, node) {
      const task: GanttTask = gantt.getTask(id);
      getInput(node).disabled = task.isPending;
      getInput(node).value = dayjs(value).subtract(1, 'day').format(ISO_SHORT);
    },
  };

  gantt.config.editor_types.actualEndDateEditor = gantt.mixin(
    {
      ...inclusiveDate,
      get_value: function (id, column, node) {
        const date = requiredDate.get_value.apply(this, [id, column, node]);
        return date ? dayjs(date).subtract(-1, 'day').toDate() : null;
      },
    },
    optionalDate,
    false,
  );

  gantt.config.editor_types.endDateEditor = gantt.mixin(
    {
      ...inclusiveDate,
      get_value: function (id, column, node) {
        const date = requiredDate.get_value.apply(this, [id, column, node]);
        return date ? dayjs(date).subtract(-1, 'day').toDate() : undefined;
      },
    },
    requiredDate,
    false,
  );

  gantt.config.editor_types.predecessorEditor = gantt.mixin(
    {
      show: function (id, column, config, placeholder) {
        const style = 'width: 100%';
        placeholder.innerHTML = `<div><input autocomplete="off" data-cy="gantt_edit_${column.name}_input" type="text" style="${style}" name="${column.name}_predecessor" /></div>`;
      },
      get_value: function (id, column, node) {
        return getInput(node).value;
      },
      set_value: function (task, id, column, node) {
        getInput(node).disabled = task.isPending;
        getInput(node).value = formatTaskDependencies(gantt, task);
      },
      is_changed: function (task, id, column, node) {
        return formatTaskDependencies(gantt, task) !== String(getInput(node).value);
      },
      focus: function (node) {
        const input = getInput(node);
        if (!input) return;
        if (input.focus) input.focus();
        if (input.select) input.select();
      },
      // TODO: need to prevent save invalid data and keep editor open, see
      // https://docs.dhtmlx.com/gantt/desktop__inline_editing.html#validationofinputvalues
      is_valid: function (value: string, taskId: string) {
        const task = gantt.getTask(taskId);
        if (!value.trim() && !task.sourceDeps?.length) return false;
        try {
          const parsed = parseTaskDependencies(gantt, value, t);
          if (value && !parsed.length) {
            throw new Error(
              t(
                'gantt:toast.error.predecessor.wrong_format',
                'Sorry, you entered the wrong predecessor column format.',
              ),
            );
          }
          for (const dep of parsed) {
            const dependentTask = getTaskByRowNumber(gantt, dep.rownum);
            if (!gantt.isTaskExists(dependentTask?.id)) {
              throw new Error(
                // Needs to be root level for interpolation to work correctly on
                // i18next-parser
                t(
                  'gantt:toast.error.predecessor.wrong_rownum',
                  'The activity with row number {{rownum}} is not exist.',
                  {rownum: dep.rownum},
                ),
              );
            }
            if (dependentTask.id === taskId) {
              throw new Error(
                t('gantt:toast.error.predecessor.link_to_itself', 'You cannot make an activity predecessor of itself.'),
              );
            }
          }
          return true;
        } catch (e) {
          if (e instanceof Error) {
            toast.error(e.message, {autoClose: 3500});
          }
          return false;
        }
      },
      save: async function (taskId, column, node) {
        const task = gantt.getTask(taskId) as GanttTask;
        const dependencyCacheKey = QUERY_CACHE_KEYS.projectDependencies(task.projectId, gantt.name);
        const value = this.get_value(taskId, column, node);
        const {add, remove, update} = getDependenciesDiff(gantt, task, parseTaskDependencies(gantt, value));
        const hasDiff = [add, remove, update].some((acts) => !!acts.length);

        async function removeLinks(taskId: string) {
          const toRemove: Promise<AxiosResponse>[] = [];
          for (const depId of remove) {
            toRemove.push(TasksApi.deleteDependency(taskId, depId));
          }
          try {
            const results = await Promise.all(toRemove);
            for (const result of results) {
              if (result.status === 200) {
                const dep = result.data.dependency;
                cacheHelper?.removeItem(dependencyCacheKey, dep.id);
              }
            }
            return results;
          } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            toast.error('Something went wrong while deleting an old predecessor. Please, try again later.');
          }
        }

        async function addLinks(taskId: string) {
          const toAdd = [];
          for (const dep of add) {
            toAdd.push(
              TasksApi.saveDependency(taskId, {
                taskId: task.id,
                depTaskId: dep.predTaskId,
                predTaskId: dep.predTaskId,
                depType: dep.depType,
                delay: dep.lagDays,
                lagDays: dep.lagDays,
                delayUnit: 'days',
              }),
            );
          }
          try {
            const results = await Promise.all(toAdd);
            const depsToAdd = results.map((data) => data.dependency);
            depsToAdd.forEach((dep) => cacheHelper?.prependItem(dependencyCacheKey, dep));
            return results;
          } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            toast.error('Something went wrong while adding a new predecessor. Please, try again later.');
          }
        }

        async function updateLinks(taskId: string) {
          const toUpdate = [];
          for (const dep of update) {
            toUpdate.push(TasksApi.updateDependency(taskId, dep));
          }
          try {
            const results = await Promise.all(toUpdate);
            for (const result of results) {
              const dep = result.dependency;
              cacheHelper?.updateItem(dependencyCacheKey, dep);
              if (gantt.isLinkExists(dep.id)) {
                const link = gantt.getLink(dep.id);
                link.source = dep.predTaskId;
                link.target = taskId;
                link.type = gantt.config.links[TaskDependencyToGanttLinkType[dep.depType]];
                gantt.refreshLink(link.id);
              }
            }
            return results;
          } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            toast.error('Something went wrong while updating the predecessor. Please, try again later.');
          }
        }

        async function sendRequests(taskId) {
          const ganttTask = gantt.getTask(taskId);
          const results = [];
          if (remove.length) {
            results.push(...(await removeLinks(taskId)));
          }
          if (add.length) {
            results.push(...(await addLinks(taskId)));
          }
          if (update.length) {
            results.push(...(await updateLinks(taskId)));
          }
          cacheHelper.queryClient.refetchQueries(dependencyCacheKey);
          if (results.length) {
            toast.success('Changes saved.');
          }
          if (ganttTask?.meta) {
            ganttTask.meta = {};
          }
          ganttTask.meta.predecessor = false;
          gantt.refreshTask(ganttTask.id);
          return task['id'];
        }
        if (hasDiff) {
          if (!task?.meta) {
            task.meta = {};
          }
          task.meta.predecessor = true;
          gantt.refreshTask(task.id);
          TaskChainUpdateQueue.dispatch(task.id, sendRequests, 'extra');
        }
      },
    },
    textEditor,
    false,
  );

  gantt.config.editor_types.subcontractorEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <QueryClientProvider client={reactQueryClient}>
          <TasksObserverProvider>
            <Router history={routerHistory}>
              <SubcontractorEditor gantt={gantt} task={task} name={column.name} disabled={task.isPending} />
            </Router>
          </TasksObserverProvider>
        </QueryClientProvider>,
        placeholder,
      );
    },
    set_value: function () {
      return;
    },
    get_value: function () {
      return;
    },
    is_changed: function () {
      return;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function (node) {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.responsibleEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <Provider store={store}>
          <QueryClientProvider client={reactQueryClient}>
            <TasksObserverProvider>
              <Router history={routerHistory}>
                <ResponsibleEditor.Task gantt={gantt} task={task} />
              </Router>
            </TasksObserverProvider>
          </QueryClientProvider>
        </Provider>,
        placeholder,
      );
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    set_value: () => {
      return;
    },
    get_value: () => {
      return;
    },
    is_changed: () => {
      return;
    },
    get_input: (node) => {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.responsibleIssueEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <Provider store={store}>
          <QueryClientProvider client={reactQueryClient}>
            <TasksObserverProvider>
              <Router history={routerHistory}>
                <ResponsibleEditor.Issues gantt={gantt} task={task} />
              </Router>
            </TasksObserverProvider>
          </QueryClientProvider>
        </Provider>,
        placeholder,
      );
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    set_value: () => {
      return;
    },
    get_value: () => {
      return;
    },
    is_changed: () => {
      return;
    },
    get_input: (node) => {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.statusEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <QueryClientProvider client={reactQueryClient}>
          <TasksObserverProvider>
            <Router history={routerHistory}>
              <ConformationProvider>
                <StatusEditor gantt={gantt} task={task} />
              </ConformationProvider>
            </Router>
          </TasksObserverProvider>
        </QueryClientProvider>,
        placeholder,
      );
    },
    set_value: function () {
      return;
    },
    get_value: function () {
      return;
    },
    is_changed: function () {
      return;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function (node) {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.issueStatusEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <QueryClientProvider client={reactQueryClient}>
          <TasksObserverProvider>
            <Router history={routerHistory}>
              <ConformationProvider>
                <StatusEditor.Issue gantt={gantt} task={task} />
              </ConformationProvider>
            </Router>
          </TasksObserverProvider>
        </QueryClientProvider>,
        placeholder,
      );
    },
    set_value: function () {
      return;
    },
    get_value: function () {
      return;
    },
    is_changed: function () {
      return;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function (node) {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.issueTypeEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <QueryClientProvider client={reactQueryClient}>
          <TasksObserverProvider>
            <Router history={routerHistory}>
              <ConformationProvider>
                <IssueTypeEditor gantt={gantt} task={task} name={column.name} />
              </ConformationProvider>
            </Router>
          </TasksObserverProvider>
        </QueryClientProvider>,
        placeholder,
      );
    },
    set_value: function () {
      return;
    },
    get_value: function () {
      return;
    },
    is_changed: function () {
      return;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function (node) {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.issuesSeverityEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <QueryClientProvider client={reactQueryClient}>
          <TasksObserverProvider>
            <Router history={routerHistory}>
              <ConformationProvider>
                <IssuesSeverityEditor gantt={gantt} task={task} name={column.name} />
              </ConformationProvider>
            </Router>
          </TasksObserverProvider>
        </QueryClientProvider>,
        placeholder,
      );
    },
    set_value: function () {
      return;
    },
    get_value: function () {
      return;
    },
    is_changed: function () {
      return;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function (node) {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.taskTypeEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <QueryClientProvider client={reactQueryClient}>
          <TasksObserverProvider>
            <Router history={routerHistory}>
              <TaskTypeEditor gantt={gantt} task={task} />
            </Router>
          </TasksObserverProvider>
        </QueryClientProvider>,
        placeholder,
      );
    },
    set_value: function () {
      return;
    },
    get_value: function () {
      return;
    },
    is_changed: function () {
      return;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function (node) {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.locationEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <QueryClientProvider client={reactQueryClient}>
          <TasksObserverProvider>
            <Router history={routerHistory}>
              <Provider store={store}>
                <LocationEditor gantt={gantt} task={task} />
              </Provider>
            </Router>
          </TasksObserverProvider>
        </QueryClientProvider>,
        placeholder,
      );
    },
    set_value: function () {
      return;
    },
    get_value: function () {
      return;
    },
    is_changed: function () {
      return;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function (node) {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.costImpactEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <QueryClientProvider client={reactQueryClient}>
          <TasksObserverProvider>
            <Router history={routerHistory}>
              <Provider store={store}>
                <CostImpactEditor gantt={gantt} task={task} name={column.name} />
              </Provider>
            </Router>
          </TasksObserverProvider>
        </QueryClientProvider>,
        placeholder,
      );
    },
    set_value: function () {
      return;
    },
    get_value: function () {
      return;
    },
    is_changed: function () {
      return;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function (node) {
      return node.querySelector('input');
    },
  };

  gantt.config.editor_types.customFieldDateEditor = gantt.mixin(
    {
      show: function (id, column, config, placeholder) {
        const html = `<input type='date' style='min-width: 125px; padding-right: 8px; padding-left: 20px' name='${column.name}'>`;
        placeholder.innerHTML = html;
      },
      is_changed: function (value, id, column, node) {
        const currentValue = this.get_value(id, column, node);
        return !isSameDay(currentValue, this.valueOnStartEdit ?? value);
      },
      get_value: function (id, column, node) {
        const date = getInput(node).value;
        return date ? parseDateWith2DigitYear(date) : undefined;
      },
      set_value: function (value, id, column, node) {
        const task = gantt.getTask(id);
        const fieldValue =
          task.custom_fields.find(({internal_field_name}) => internal_field_name === column.name)?.value ?? '';
        getInput(node).value = safeFormatDate(fieldValue, ISO_SHORT);
      },
    },
    gantt.config.editor_types.date,
    false,
  );
  gantt.config.editor_types.customFieldDropdownEditor = {
    show: function (id, column, config, placeholder) {
      const task: GanttTask = gantt.getTask(id);
      render(
        <QueryClientProvider client={reactQueryClient}>
          <TasksObserverProvider>
            <Router history={routerHistory}>
              <Provider store={store}>
                <CustomFieldSelectEditor gantt={gantt} task={task} fieldName={column.name} />
              </Provider>
            </Router>
          </TasksObserverProvider>
        </QueryClientProvider>,
        placeholder,
      );
    },
    set_value: function () {
      return;
    },
    get_value: function () {
      return;
    },
    is_changed: function () {
      return;
    },
    is_valid: function () {
      return true;
    },
    focus: function (node) {
      const input = getInput(node);
      if (!input) return;
      if (input.focus) input.focus();
      if (input.select) input.select();
    },
    get_input: function (node) {
      return node.querySelector('input');
    },
  };
}
